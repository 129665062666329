import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { vars } from '../../Theme';

const useStyles = makeStyles(() => createStyles({
  blankItem: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '.25rem',
    height: '56px',
    paddingRight: '1rem',
    marginBottom: '8px',
    border: `2px ${vars.primary} dashed`,
    alignItems: 'center',
    color: vars.primary,
  },
  blankItemCenter: {
    display: 'flex',
  },
}),);

const BlankItem = () => {
  const classes = useStyles();

  return (
    <div className={classes.blankItem} />
  );
};

export default BlankItem;

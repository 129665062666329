// @ts-nocheck

import React, { useEffect, useState } from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIVideocamIcon from 'v2/icons/UIVideocamIcon';

const OpenInCamera = ({ object }) => {
  const [enabled, setEnabled] = useState();

  useEffect(() => {
    window.vms && window.vms.resources.resources()
      .then((items) => items.find((item) => item.id.includes(object.camera_id) && item.type === 'camera'))
      .then((item) => item && setEnabled(true));
  }, [])

  const onClick = () => {
    window.vms.tab.addItem(object.camera_id, {
      media: {
        timestampMs: object.timestamp * 1000
      }
    });
  }

  return enabled && (
    <UIWidgetAction byHover tooltip="Proceed to video footage" onClick={onClick}>
      <UIVideocamIcon />
    </UIWidgetAction>
  );
}

export default OpenInCamera;

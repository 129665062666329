// @ts-nocheck

import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const BasicAvatar = ({
  src, variant, h, w
}) => {
  const getStyles = () => {
    const style = {}
    switch (variant) {
      case 'large':
        style.height = '120px';
        style.width = '120px';
        break;
      case 'medium':
        style.height = '80px';
        style.width = '80px';
        break;
      default:
        style.height = h || '40px';
        style.width = w || '40px';
        break;
    }
    return style;
  }

  return (
    <Avatar style={getStyles()} src={src} />
  )
}

export default BasicAvatar;

// @ts-nocheck

import React from 'react';
import BasicAvatar from 'v2/components/basic/BasicAvatar';
// import BasicBadge from 'v2/components/basic/BasicBadge';

const UserAvatar = ({ variant, src }) => (
  // #1167 Online/offline indicator issues
  // <BasicBadge
  //   className={online ? 'UIAvatarBadgeStatus UIBadgeOnline' : 'UIAvatarBadgeStatus UIBadgeOffline'}
  // >
  <BasicAvatar
    variant={variant}
    src={src}
  />
  // </BasicBadge>
)

export default UserAvatar;

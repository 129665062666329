// @ts-nocheck
import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const Image = ({ theme }) => (
  <svg width="100%" height="100%" viewBox="0 0 167 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 38.5547H15.8872V69.0009H0V38.5547Z" fill="#595959" />
    <path d="M42.4131 0.117188H58.3003V69.0006H42.4131V0.117188Z" fill="#595959" />
    <path d="M17.873 20.7344H33.7603V69.0007H17.873V20.7344Z" fill={theme.palette.primary.main} />
    <path d="M60.2861 56.7734H76.1734V68.9999H60.2861V56.7734Z" fill={theme.palette.primary.main} />
    <path d="M103 49L119.625 31.3429L143.25 28.8653L166 14" stroke={theme.palette.primary.main} />
    <path d="M103 65L119.625 47.3429L143.25 44.8653L166 30" stroke="#595959" />
    <rect x="96" width="1" height="69" fill="#2c2c2c" />
    <rect x="96" y="70" width="1" height="69" transform="rotate(-90 96 70)" fill="#2c2c2c" />
  </svg>

);

export default withTheme(Image);

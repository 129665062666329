// @ts-nocheck

import React from 'react';
import { useSelector } from 'react-redux';
import SubMenu from 'v2/components/SubMenu';
import { ROUTES } from 'v2/utils/routes';

export default function Help() {
  const help = useSelector((state) => state.help.data);

  return (
    <SubMenu MenuListTitle={ROUTES.HELP.title} MenuListRoutes={ROUTES.HELP.ROUTES(help)} />
  );
}

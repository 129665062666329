// @ts-nocheck

import api from '../../../app/api';
import layouts from '../reducers/layouts';

const layoutsHandlers = (store) => ({
  fetchOne: ({ id }) => store.dispatch(layouts.dataFetch(id)).then((response) => response),
  update: (payload, id) => api.patch(`/layout-manager/v3/layouts/${id}`, payload),
  create: (payload) => api.post('/layout-manager/v3/layouts', payload),
  delete: (id) => api.delete(`/layout-manager/v1/layouts/${id}`),
  copy: (id, payload) => api.post('/layout-manager/v3/copy-layouts/', { id, ...payload }),
  share: (id) => api.post(`/layout-manager/v1/layouts/${id}/share/`),
});

export default layoutsHandlers;

// @ts-nocheck

import { ROUTES } from 'v2/utils/routes';

const SCHEME = {
  name: 'New layout',
  grid: [],
  default: true,
  shared: false,
}

/**
 * @typedef {Object} ILayoutsBehavior
 * @property {function} init
 * @property {function} create
 * @property {function} update
 * @property {function} delete
 * @property {function} select
 * @property {function} share
 * @property {function} copy
 */

/**
 * Кейсы сценариев рабочего пространства
 * @param {{layouts: ILayoutsAdapter}} source Адаптеры данных
 * @param {Object} externalHandlers - Внешние хэндлеры
 * @return {ILayoutsBehavior}
 */

export default ({ layouts, widgets }, { setLayouts, setLayout, setWidgets }) => {
  let layoutsList = [];
  let layout;

  const getActiveLayout = () => layoutsList.find((item) => item.default && !item.shared);

  const getFirstLayout = () => layoutsList.find((item) => !item.shared);

  const createDefault = async () => {
    await layouts.create({ ...SCHEME });
  }

  const fetchLayouts = async () => {
    layoutsList = (await layouts.fetchAll()).filter((item) => !item.shared);
    layout = getActiveLayout();
  }

  const fetchLayout = async () => {
    layout = await layouts.fetchOne(layout.id);
  }

  const updateState = () => {
    setLayouts(layoutsList);
    setLayout(layout);
  }

  const fetchWidgets = async () => {
    setWidgets(await widgets.fetchAll());
  }

  return {
    init: async () => {
      await fetchLayouts();

      if (!layout) {
        await createDefault();
        await fetchLayouts();
      }

      await fetchLayout();
      updateState();
    },
    create: async (payload) => {
      await layouts.update(layout.id, { default: false });
      layout = await layouts.create({ ...payload, default: true });

      await fetchLayouts();
      await fetchLayout();
      updateState();
    },
    update: async (payload) => {
      await layouts.update(layout.id, { ...payload });

      await fetchLayouts();
      await fetchLayout();
      updateState();
    },
    delete: async () => {
      await layouts.delete(layout.id);
      await fetchLayouts();

      const firstLayout = getFirstLayout();

      if (firstLayout) {
        await layouts.update(firstLayout.id, { default: true });
      } else {
        await createDefault();
      }

      await fetchLayouts();
      await fetchLayout();
      updateState();
    },
    select: async (id) => {
      await layouts.update(layout.id, { default: false });
      await layouts.update(id, { default: true });

      await fetchLayouts();
      await fetchLayout();
      updateState();
    },
    copy: async (payload) => {
      const { id } = layout;
      const { name } = payload;
      await layouts.update(id, { default: false });
      layout = await layouts.copy(id, { name, default: true });

      await fetchWidgets();
      await fetchLayouts();
      await fetchLayout();
      updateState();
    },
    share: async (companyID) => {
      const data = await layouts.share(layout.id);

      return new URL(ROUTES.SHARED_LAYOUT_WITH_COMPANY.url(companyID, data.id), document.location.href);
    },
  };
};

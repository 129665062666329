// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';
import { FETCH_STATE } from '../../utils/constants';

const layouts = DataReducer({
  name: 'layouts',
  dataProvider: async (id) => {
    const result = await api.get(`/layout-manager/v3/layouts/${id}`);
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching layouts',
    [FETCH_STATE.REJECTED]: 'Error fetch layouts',
  }
});
export default layouts;

// @ts-nocheck
import React from 'react';
import {
  Box, Button,
  FormControl, Grid, MenuItem, Select,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useModel } from 'v2/providers/ModelProvider';
import LayoutMenu from 'pages/dashboard/LayoutMenu/LayoutMenu';

const LayoutSelector = ({ layout, items, handleChange }) => {
  const { scenarios } = useModel();

  return (
    <Box display="flex" alignItems="center" width={1}>
      <Box width={1} mr={2} overflow="hidden">
        <FormControl size="small" variant="outlined" fullWidth>
          <Select
            value={layout.id}
            onChange={handleChange}
            variant="outlined"
            IconComponent={KeyboardArrowDownIcon}
          >
            {
              items && items.map((layout) => (
                <MenuItem key={layout.id} value={layout.id}>
                  {layout.name}
                </MenuItem>
              ))
          }
          </Select>
        </FormControl>
      </Box>
      <LayoutMenu
        layout={layout}
        actionCreate={scenarios.layouts.create}
        actionUpdate={scenarios.layouts.update}
        actionDelete={scenarios.layouts.delete}
        actionCopy={scenarios.layouts.copy}
        actionShare={scenarios.layouts.share}
      />
    </Box>
  )
};

export default LayoutSelector;

// @ts-nocheck
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['permissions'];

const STATUS = {
  GRANTED: 'granted',
};

export const PERMISSIONS = {
  CLIPBOARD_WRITE: 'clipboardWrite',
};

const usePermissionsQuery = () => {
  const permissionsQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const clipboardWrite = await navigator.permissions.query({ name: 'clipboard-write' });

      return {
        [PERMISSIONS.CLIPBOARD_WRITE]: STATUS.GRANTED === clipboardWrite.state,
      };
    },
    initialData: {},
  });

  return {
    permissions: permissionsQuery.data,
    permissionsQuery,
  };
};

export default usePermissionsQuery;

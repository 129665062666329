// @ts-nocheck
import { useQueryClient, useMutation } from '@tanstack/react-query';
import api from 'app/api';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['tokens'];

const useTokenQuery = () => {
  const queryClient = useQueryClient();

  const tokensQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.get('/auth-manager/v1/gateway_tokens', {
        params: {
          pgsize: Number.MAX_SAFE_INTEGER
        }
      });
      return result.data;
    },
  });

  const createToken = useMutation({
    mutationFn: async (data) => {
      const result = await api.post('/auth-manager/v1/gateway_token/', data);
      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  const deleteToken = useMutation({
    mutationFn: (id) => api.delete(`/auth-manager/v1/gateway_token/${id}`),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  return {
    createToken,
    deleteToken,
    tokens: tokensQuery.data,
    tokensQuery
  }
}

export default useTokenQuery;

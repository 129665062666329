// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useModel } from 'v2/providers/ModelProvider';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { routeLoadingData } from 'v2/utils/functions';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { handlers } from '../redux/store';

const RestoreSession = (props) => {
  const location = useLocation();
  const [render, setRender] = useState();
  const { scenarios } = useModel();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const tokenTimer = useRef();

  const finallyLoading = () => {
    hideBackdrop();
    setRender(true);
  }

  useEffect(() => {
    if (localStorage.getItem('access-token')) {
      showBackdrop('Loading Data');
      routeLoadingData(scenarios, location.pathname)
        .then(() => {
          tokenTimer.current = setInterval(() => {
            const jwt = localStorage.getItem('access-token');

            if (jwt) {
              const authData: JwtPayload = jwtDecode(jwt);
              const expiration = new Date(Number(authData.exp) * 1000);

              if (new Date() > expiration) {
                handlers.user.logout();
              }
            }
          }, 10000);
        })
        .catch(() => {})
        .finally(() => {
          hideBackdrop();
          setRender(true)
        });
    } else {
      setRender(true);
    }

    return () => {
      clearTimeout(tokenTimer.current);
    }
  }, [])

  return render && props.children
}

export default RestoreSession;

// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UILeftPanelOpenIcon from 'v2/icons/UILeftPanelOpenIcon';

const LeftPanelOpen = ({ onClick, tooltip = 'Open left panel' }) => (
  <UIWidgetAction byHover tooltip={tooltip} overlap onClick={onClick}>
    <UILeftPanelOpenIcon />
  </UIWidgetAction>
);

export default LeftPanelOpen;

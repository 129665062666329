// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import useUserCompanyQuery from 'v2/hooks/queries/useUserCompanyQuery';
import UIMetaData from 'v2/ui-kit/UIMetaData/UIMetaData';
import UICardOverlay from 'v2/ui-kit/UICardOverlay/UICardOverlay';
import { ROUTES } from 'v2/utils/routes';

import './UIPhotoCard.scss';

export const imageLoader = (source, fn) => {
  const img = new Image();
  img.src = source;
  img.onload = fn;
};

const UIPhotoCard = ({
  object,
  overlay,
  showMeta = false,
}) => {
  const [loaded, setLoaded] = useState(false);
  const { userCompanyQuery } = useUserCompanyQuery();

  useEffect(() => {
    imageLoader(object.image_url, () => setLoaded(true));
  }, []);

  return userCompanyQuery.isFetched && (
    <div className="UIPhotoCard">
      <NavLink
        className={clsx('UIPhotoCardImage', loaded && 'UILoaded')}
        to={ROUTES.OBJECT_DETAILS_WITH_COMPANY.url(userCompanyQuery.data.id, object.id)}
        style={{
          backgroundImage: `url(${object.image_url})`,
        }}
      />
      <div className="UIPhotoCardOverlay">
        <div className="relative h-full w-full">
          <UICardOverlay object={object} overlay={overlay} />
        </div>
        {showMeta && <UIMetaData object={object} />}
      </div>
    </div>
  );
};

export default UIPhotoCard;

// @ts-nocheck

import { SeriesOptionsType, Series, Options } from 'highcharts';
import { DetalizationType } from 'pages/dashboard/types';
import moment from 'moment-timezone';

import {
  DEFAULT_TIMEZONE, SIX_HOURS, THREE_DAYS, TWELVE_HOURS, TWO_WEEKS, WIDGET_TYPES,
} from 'utils/constants';
import ms from 'ms';
import {
  ONE_DAY, ONE_HOUR, ONE_MONTH, ONE_WEEK,
} from './constants';

export const CHART_TYPES = {
  COLUMN: 'column',
  LINE: 'line',
  PIE: 'pie',
};

export const getColor = (barData: any) => {
  if (barData.stack === 'male') {
    switch (barData.name) {
      case '0-10':
        return '#043A49';
      case '10-20':
        return '#07586E';
      case '20-30':
        return '#086680';
      case '30-40':
        return '#0A83A5';
      case '40-50':
        return '#0B92B7';
      case '50-60':
        return '#249DBF';
      case '60-70':
        return '#3CA8C5';
      case '70-80':
        return '#55B3CD';
      case '80-90':
        return '#6DBED4';
      case 'over 100':
        return '#85C9DB';
      default:
        return '#005A73';
    }
  } else {
    switch (barData.name) {
      case '0-10':
        return '#5C1E0D';
      case '10-20':
        return '#892D14';
      case '20-30':
        return '#A03417';
      case '30-40':
        return '#CE431E';
      case '40-50':
        return '#E54B21';
      case '50-60':
        return '#E85D38';
      case '60-70':
        return '#EA6F4D';
      case '70-80':
        return '#ED8164';
      case '80-90':
        return '#EF937A';
      case 'over 100':
        return '#F2A590';
      case 'van':
        return '#E012A9';
      case 'Unknown':
        return '#EE0943';
      case 'unknown':
        return '#EE0943';
      case 'minivan':
        return '#13E588';
      case 'truck':
        return '#FF9828';
      case 'wagon':
        return '#A5CEFC';
      case 'hatchback':
        return '#00ADFB';
      case 'suv':
        return '#941CEE';
      case 'sedan':
        return '#E5D02E';
      case 'convertible':
        return '#92B70B';
      case 'crossover':
        return '#00B496';
      case 'female':
        return '#E54B21';
      case 'male':
        return '#0B92B7';
      case 'objects':
        return '#9DAFEB';
      default:
        return '#611602';
    }
  }
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const formatSeries = (series: SeriesOptionsType[], isPieChart?: boolean, selectedChartType?: string) => {
  if (isPieChart) {
    return [{
      type: 'pie',
      data: series[0].data.map((value) => ({ ...value, color: getColor(value) })),
      name: series[0].name,
    }]
  }

  series.sort((a, b) => {
    if (a.stack > b.stack) {
      return -1;
    }
    if (a.stack < b.stack) {
      return 1;
    }
    return 0;
  });

  switch (selectedChartType) {
    case WIDGET_TYPES.chart.hist_chart:
      return series.map((barData: any) => {
        barData.type = CHART_TYPES.COLUMN;
        barData.color = getColor(barData);
        barData.stack = barData.stack || null;
        return barData;
      });
    case WIDGET_TYPES.chart.line_chart:
      return series.map((barData: any) => {
        barData.type = CHART_TYPES.LINE;
        barData.color = getColor(barData);
        barData.stack = barData.stack || null;
        return barData;
      });
    default:
      return series;
  }
};

const getChartDateFormat = (categories) => {
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  const format = [];

  const hours = categories.map((time) => moment.unix(Number(time)).tz(DEFAULT_TIMEZONE).hour()).filter(onlyUnique);
  const days = categories.map((time) => moment.unix(Number(time)).tz(DEFAULT_TIMEZONE).date()).filter(onlyUnique);
  const weeks = categories.map((time) => moment.unix(Number(time)).tz(DEFAULT_TIMEZONE).week()).filter(onlyUnique);
  const years = categories.map((time) => moment.unix(Number(time)).tz(DEFAULT_TIMEZONE).year()).filter(onlyUnique);

  if (hours.length >= 1 && years.length < 2) {
    format.unshift('hh:mm a');
  }

  if (days.length > 1 && weeks.length < 2) {
    format.unshift('ddd');
  }

  if (weeks.length > 1 && years.length < 2) {
    format.unshift('DD MMM');
  }

  if (years.length > 1) {
    format.unshift('MM/DD/YY');
  }

  return format.join(' ');
};

export const getChartOptions = ({
  series,
  categories,
  selectedChartType,
  isPieChart,
  showStackLabels,
}) => {
  const gridColor = '#3c3c3c';
  const labelsFontSize = '9px';

  return ({
    lang: {
      noData: 'No Data to Display',
    },
    noData: {
      style: {
        fontSize: '32px',
      },
    },
    chart: {
      // zoomType: 'x',
      animation: false,
      backgroundColor: 'transparent',
      events: {
        redraw() {
          setTimeout(() => {
            try {
              this.reflow();
            } catch (e) {
              (() => {
              })();
            }
          }, 30);
        },
      },
    },
    // chart: {
    //   backgroundColor: 'transparent',
    //   style: {
    //     fontFamily: 'Helvetica, Arial, sans-serif',
    //     fontSize: '15px',
    //   },
    //   animation: false,
    //   events: {
    //     redraw() {
    //       setTimeout(() => {
    //         try {
    //           this.reflow();
    //         } catch (e) { (() => {})() }
    //       }, 100)
    //     },
    //     load() {
    //       const title = this.legend.title as any;
    //
    //       if (title) {
    //         const legend = this.legend as any;
    //
    //         title.translate(title.translateX - title.width, 24);
    //         legend.options.x = title.width / 2;
    //       }
    //     },
    //   },
    // },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    series: formatSeries(series, isPieChart, selectedChartType),
    xAxis: {
      categories: (categories || []).map((item) => item * 1000),
      labels: {
        formatter() {
          return moment.unix(this.value / 1000).tz(DEFAULT_TIMEZONE).format(getChartDateFormat(categories)).toUpperCase();
        },
        style: {
          fontSize: labelsFontSize,
        },
      },
      lineColor: gridColor,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontSize: labelsFontSize,
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: gridColor,
      stackLabels: {
        enabled: showStackLabels,
        borderWidth: 0,
        style: {
          color: 'white',
          fontSize: labelsFontSize,
        },
      },
    },
    tooltip: {
      formatter() {
        if (isPieChart) {
          return `<span class="UIChartTooltipTitle">${this.key.toUpperCase()}: </span>${this.y}`;
        }

        const currentIndex = this.point.index;
        const timeStart = categories[currentIndex];
        const timeEnd = categories[currentIndex + 1] || Math.floor(Date.now() / 1000);

        return `
          <span class="UIChartTooltipTitle">From: </span>${moment.unix(timeStart).tz(DEFAULT_TIMEZONE).format('DD MMM YYYY hh:mm a').toUpperCase()}<br/>
          <span class="UIChartTooltipTitle">To: </span>${moment.unix(timeEnd).tz(DEFAULT_TIMEZONE).format('DD MMM YYYY hh:mm a').toUpperCase()}<br/>
          <span class="UIChartTooltipTitle">${this.series.userOptions.stack ? this.series.userOptions.stack.toString().toUpperCase() : ''} (${this.series.name.toUpperCase()}):</span> ${this.y}<br/>
        `;
      },
      style: {
        color: 'white',
      },
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
    plotOptions: {
      column: {
        animation: false,
        borderWidth: 0,
        stacking: 'normal',
      },
      pie: {
        animation: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
  });
};

export const getPieChartLabels = (pieChartSeries: Series, storedLabels: any[], groups: string[]) => {
  if (!pieChartSeries) {
    return [];
  }

  if (!groups.includes(String(pieChartSeries.name)) && pieChartSeries.data.length > 0) {
    groups.push(String(pieChartSeries.name));
  }

  return pieChartSeries.data.map((chartItem: any) => {
    const tempLabelName = `${chartItem.name}-${pieChartSeries.name}`;

    if (storedLabels.find((label) => label === tempLabelName)) {
      chartItem.setVisible(false);
    }

    return ({
      name: chartItem.name,
      color: chartItem.color,
      group: pieChartSeries.name,
      handler: () => {
        chartItem?.setVisible(!chartItem?.visible);
      },
    });
  });
};

export const getChartLabels = (series: Series[], storedLabels: any[], groups: string[]) => {
  if (!series) {
    return [];
  }

  return series.map((chartSeries) => {
    const tempLabelName = chartSeries.userOptions.stack === 'male' || chartSeries.userOptions.stack === 'female'
      ? `${chartSeries.name}-${chartSeries.userOptions.stack}`
      : chartSeries.name;

    if (storedLabels.find((label) => label === tempLabelName)) {
      chartSeries.setVisible(false);
    }

    if (chartSeries.userOptions.stack && !groups.includes(String(chartSeries.userOptions.stack))) {
      groups.push(String(chartSeries.userOptions.stack));
    }

    return {
      name: chartSeries.name,
      stack: chartSeries.options.stack,
      group: String(chartSeries.userOptions.stack),
      color: (chartSeries as any).color as string,
      handler: () => {
        chartSeries.setVisible(!chartSeries.visible);
      },
    };
  });
};

export const parseStoredChartLabels = (pathname: string, widgetId?: string) => {
  if (widgetId !== undefined) {
    const storedDisabledLabels = localStorage.getItem(widgetId);
    if (storedDisabledLabels !== null) return [...JSON.parse(storedDisabledLabels)];
  } else if (pathname === '/appearances') {
    const storedDisabledLabels = localStorage.getItem('appearances');
    if (storedDisabledLabels !== null) return [...JSON.parse(storedDisabledLabels)];
  }

  return [];
};

export const getDefaultActiveDetalization = (detalizationList: DetalizationType[], filtersObject: NodeJS.Dict<any>) => {
  if (filtersObject.detalization !== undefined) {
    const val_index = detalizationList.map((detail: DetalizationType) => detail.value)
      .indexOf(parseInt(filtersObject.detalization, 10));

    if (val_index === -1) {
      return detalizationList[0]?.value;
    }
    return detalizationList[val_index]?.value;
  }
  return detalizationList[0]?.value;
};

export const getDetalizationByValue = (detalizationValue: number, detalizationOptionsList: DetalizationType[]) => {
  if (detalizationOptionsList !== undefined) {
    const value = detalizationOptionsList.map((detail) => detail.value).indexOf(detalizationValue);

    return detalizationOptionsList[value];
  }

  return false;
};

export const getCurrentDetalizationOptions = (timeRangeStart: number, timeRangeEnd: number) => {
  const unixStart = moment.unix(timeRangeStart).tz(DEFAULT_TIMEZONE);
  const unixEnd = moment.unix(timeRangeEnd).tz(DEFAULT_TIMEZONE);
  const unixDiffMilliseconds = Math.floor(moment.duration(unixEnd.diff(unixStart)).asMilliseconds());

  if (unixDiffMilliseconds <= ONE_HOUR) {
    return [
      { name: '5m', value: 300 },
      { name: '10m', value: 600 },
    ];
  }

  if (unixDiffMilliseconds > ONE_HOUR && unixDiffMilliseconds <= SIX_HOURS) {
    return [
      { name: '30m', value: 1800 },
      { name: '1h', value: 3600 },
    ];
  }

  if (unixDiffMilliseconds > SIX_HOURS && unixDiffMilliseconds <= TWELVE_HOURS) {
    return [
      { name: '1h', value: 3600 },
      { name: '2h', value: 7200 },
    ];
  }

  if (unixDiffMilliseconds > TWELVE_HOURS && unixDiffMilliseconds <= ONE_DAY) {
    return [
      { name: '2h', value: 7200 },
      { name: '4h', value: 14400 },
    ];
  }

  if (unixDiffMilliseconds > ONE_DAY && unixDiffMilliseconds <= THREE_DAYS) {
    return [
      { name: '6h', value: 21600 },
      { name: '12h', value: 43200 },
    ];
  }

  if (unixDiffMilliseconds > THREE_DAYS && unixDiffMilliseconds <= ONE_WEEK) {
    return [
      { name: '12h', value: 50400 },
      { name: '1d', value: 86400 },
    ];
  }

  if (unixDiffMilliseconds > ONE_WEEK && unixDiffMilliseconds <= ONE_MONTH) {
    return [
      { name: '1d', value: 86400 },
      { name: '2d', value: 172800 },
    ];
  }

  return [
    { name: 'relative', value: Math.floor(unixDiffMilliseconds / 1000 / 12) },
  ];

  // return [
  //   { name: 'weeks', value: 604800 },
  //   { name: 'months', value: 2592000 }
  // ];
};

export const getDateRange = (timeslice: string = '1d') => {
  const startAt = Math.round((Date.now() - ms(timeslice)) / 1000);
  const endAt = Math.round(Date.now() / 1000);

  return { dateFrom: startAt, dateTo: endAt };
};

export const prepareGetQuery = (input) => encodeURI(input);

// @ts-nocheck

import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, createStyles, Dialog, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import api from 'app/api';
import { parseFiltersToArray, parseFiltersToRequest } from 'utils/helpers';
import { BaseWidgetType, FilterWidgetProps, WidgetFilters } from 'pages/dashboard/types';
import { Filter } from 'pages/appearance/types';
import SelectInputWrapper from 'components/SelectInput';
import { TextInputWrapper } from 'components/TextInput';
import { DIALOG_IMAGES, DIALOG_TYPES } from 'utils/dashboardDialog';
import { WIDGET_TYPES } from 'utils/constants';
import { validationCheck } from 'v2/utils/functions';
import { FIELD_RULES } from 'v2/constants/fieldValidators';
import OutputTextFields from './OutputTextFields';
import CustomTabs from './CustomTabs';
import { parseStringFilters } from '../DashboardChart';
import { FIELD_WIDGET_TYPES, mapFields } from '../../../description/FiltersCategories';
import { WIDGET_TO_TYPE } from '../../../utils/constants';

const useStyles = makeStyles((theme) => createStyles({
  img: {
    // marginRight: `${vars.spacing}px`,
    // backgroundColor: vars.dark8,
    // borderRadius: vars.primaryBorderRadius,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }
}),);

export type DialogType =
  | 'value'
  | 'face_feed'
  | 'pie_chart'
  | 'vehicle_feed'
  | 'person_feed'
  | 'hist_chart'
  | 'line_chart'

type SelectedTab = 'line' | 'bar';

type SettingsDialogType = {
  widgetId: string | number,
  isOpen: boolean,
  onClose: () => void,
  updateLayout: () => void,
  type: DialogType,
  title: string,
  filters: WidgetFilters,
  filtersObject:any,
  setFiltersObject:any,
};

export type CurrentValuesType = {
  [key in BaseWidgetType]: Filter;
};

const SettingsDialog: React.FC<SettingsDialogType> = ({
  widgetId,
  type,
  title,
  filters,
  isOpen,
  onClose,
  updateLayout,
  filtersObject,
  selectedIds,
  setSelectedTypeChart,
}) => {
  const [widgetTitle, setWidgetTitle] = useState<string>(title);
  const [widgetTitleValid, setWidgetTitleValid] = useState(true);
  const [widgetTitleErrorText, setWidgetTitleErrorText] = useState('');
  const [widgetType, setWidgetType] = useState<BaseWidgetType>(parseStringFilters(filters).object_type);
  const [fieldsWidget, setFieldsWidget] = useState<FilterWidgetProps>();
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(type);
  const [currentValues, setCurrentValues] = useState<CurrentValuesType>(parseStringFilters(filters))
  const [typeWidget, setTypeWidget] = useState<DialogType>(type);
  const [waitCreating, setWaitCreating] = useState(false);
  const [errors, setErrors] = useState({});
  const widgetRootType = WIDGET_TO_TYPE[typeWidget];
  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    setTypeWidget(tab);
  };

  const handleSelectBaseChange = (event) => {
    setWidgetType(event.target.value);
    setCurrentValues({
      object_type: event.target.value,
      ...selectedIds,
    });
  };

  const selectWidget = (widgetType, filed) => {
    const type = WIDGET_TYPES[widgetType];
    setSelectedTypeChart(filed);
    return typeof type === 'object' ? type[filed] : type;
  };

  useEffect(() => {
    setErrors({})
  }, [widgetType]);
  useEffect(() => {
    if (widgetType) {
      api.get(`/object-manager/search/filters/${widgetType}?filters=${parseFiltersToRequest(parseFiltersToArray(currentValues))}`)
        .then((response: any) => {
          setFieldsWidget(response.data) });
    }
  }, [widgetType, filters, currentValues]);

  const handleFilterValueChange = (newFilter: Filter) => {
    const newFilters = {
      ...currentValues,
      ...newFilter,
    };

    setCurrentValues(newFilters);
  };

  const handleChangeSettingWidget = () => {
    const outFilters = {
      object_type: widgetType
    }

    fieldsWidget
      ?.filters
      .forEach(({ name, default_value }) => {
        if (default_value) {
          outFilters[name] = default_value
        }
      })

    Object
      .entries(currentValues)
      .forEach(([field, value]) => {
        outFilters[field] = value;
      });

    const outArrayFilters = Object
      .entries(outFilters)
      .map(([field, value]) => (Array.isArray(value) ? [field, value.join('-')] : [field, value]))
      .map(([field, value]) => `${field}:${value}`);

    setWaitCreating(true);
    api.patch(`/layout-manager/widgets/${widgetId}`, {
      filters: outArrayFilters,
      type: selectWidget(widgetRootType, typeWidget),
      name: widgetTitle,
    }).then(() => {
      onClose();
      updateLayout();
    }).finally(() => {
      setWaitCreating(false);
    })
  };

  const onTitleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setWidgetTitle(event.target.value.trimLeft())
    const { error, success } = validationCheck(event.target.value.trimLeft(), [FIELD_RULES.REQUIRED, FIELD_RULES.MAXLENGTH_100])
    setWidgetTitleValid(success)
    setWidgetTitleErrorText(error)
  }, []);

  const Image = DIALOG_IMAGES[typeWidget];

  const defaultFilters = fieldsWidget?.filters.filter(({ name }) => name !== 'matched' && name !== 'is_reference');
  const pieFaceFilters = defaultFilters?.filter(({ name }) => name !== 'person_gender');
  const pieVehicleFilters = defaultFilters?.filter(({ name }) => name !== 'vehicle_type' && name !== 'vehicle_manufacturer' && name !== 'vehicle_model')

  const chartFilters = (widgetType, objectType) => {
    if (widgetType === 'pie_chart') {
      switch (objectType) {
        case 'face':
          return pieFaceFilters;
        case 'vehicle':
          return pieVehicleFilters;
        default:
          return defaultFilters
      }
    } else {
      return defaultFilters
    }
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={onClose}
    >
      <Paper variant="outlined">
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Typography align="left">
            Widget settings
          </Typography>
        </Box>
        <Divider />
        <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
          <Grid container spacing={2}>
            <Grid item>
              <Grid container spacing={2} direction="column">
                {DIALOG_TYPES[type] === 'pagination'
                && (
                <Grid item>
                  <CustomTabs
                    typeWidget={selectedTab}
                    handleClick={handleSelectTab}
                  />
                </Grid>
                )}
                <Grid item>
                  <Box width="196px" p={2} display="flex" alignItems="center" justifyContent="center">
                    <Image />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Box>
                <Grid container spacing={2} direction="column">
                  <Grid item xs>
                    <TextInputWrapper
                      label="Widget title"
                      name="widget-title"
                      value={widgetTitle}
                      onChange={onTitleChange}
                      error={!widgetTitleValid}
                      helperText={widgetTitleErrorText}
                    />
                  </Grid>
                  <Grid item xs>
                    <SelectInputWrapper
                      value={widgetType}
                      onChange={handleSelectBaseChange}
                      label="Select base"
                      items={mapFields[widgetRootType][FIELD_WIDGET_TYPES]}
                      name={FIELD_WIDGET_TYPES}
                    />
                  </Grid>
                  {typeWidget && chartFilters(typeWidget, widgetType)?.map((element, index) => (
                    <Grid item xs key={index}>
                      <OutputTextFields
                        textField={element}
                        handleFilterValueChange={handleFilterValueChange}
                        value={currentValues[element.name]}
                        object_type={fieldsWidget.object_type}
                        typeWidget={widgetRootType}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={waitCreating || !widgetTitleValid || Object.keys(errors).length > 0}
            onClick={handleChangeSettingWidget}
          >
            Change widget
          </Button>
        </Box>
      </Paper>
    </Dialog>

  // <Dialog
  //   open={isOpen}
  //   onClose={onClose}
  //   aria-labelledby="select-location-dialog"
  //   classes={{ paper: classes.root }}
  // >
  //   <div className={classes.header}>
  //     <div className={classes.blockHeaderLeft}>
  //       <Typography>Widget settings</Typography>
  //     </div>
  //     <div className={classes.headerRight}>
  //       <IconButton
  //         aria-label="delete"
  //         color="primary"
  //         onClick={onClose}
  //         className={classes.headerIcons}
  //       >
  //         <CloseIcon />
  //       </IconButton>
  //     </div>
  //   </div>
  //   <DialogContent classes={{ root: classes.dialogContent }}>
  //     <CustomTabs
  //       typeDrawer={DIALOG_TYPES[typeWidget]}
  //       typesDiagram={selectedTab}
  //       handleLineClick={handleSelectTab('line')}
  //       handleBarClick={handleSelectTab('bar')}
  //       name={DIALOG_NAMES[typeWidget]}
  //     />
  //     <Grid
  //       container
  //       classes={{
  //         root: classes.rooContainer,
  //       }}
  //     >
  //       <Grid item>
  //         <img className={classes.img} alt="test" src={DIALOG_IMAGES[typeWidget]} />
  //       </Grid>
  //       <Grid item>
  //         <TextInputWrapper
  //           label="Widget title"
  //           name="widget-title"
  //           value={widgetTitle}
  //           onChange={onTitleChange}
  //           error={!widgetTitle.trim()}
  //           helperText={!widgetTitle.trim() && 'field cannot be empty'}
  //
  //         />
  //         <SelectInputWrapper
  //           value={widgetType}
  //           onChange={handleSelectBaseChange}
  //           label="Select base"
  //           items={mapFields[widgetRootType][FIELD_WIDGET_TYPES]}
  //           name={FIELD_WIDGET_TYPES}
  //         />
  //         {typeWidget !== 'pie_chart' && fieldsWidget?.filters.map((element, index) => (
  //           <OutputTextFields
  //             key={index}
  //             textField={element}
  //             handleFilterValueChange={handleFilterValueChange}
  //             value={currentValues[element.name]}
  //           />
  //         ),)}
  //       </Grid>
  //     </Grid>
  //   </DialogContent>
  //   <Divider className={classes.divider} />
  //   <DialogActions className={classes.bottomContainer}>
  //     <Button
  //       variant="contained"
  //       color="primary"
  //       className={classes.button}
  //       disabled={waitCreating || widgetTitle.length === 0}
  //       onClick={handleChangeSettingWidget}
  //     >
  //       Change widget
  //     </Button>
  //   </DialogActions>
  // </Dialog>
  )
}

export default SettingsDialog;

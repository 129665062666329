// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { useSnackbar } from 'notistack';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import useUserCompanyQuery from 'v2/hooks/queries/useUserCompanyQuery';
import useCompaniesTypesQuery from 'v2/hooks/queries/useCompaniesTypesQuery';
import useCompaniesQuery from 'v2/hooks/queries/useCompaniesQuery';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';

const AddCompany = () => {
  const [formScheme, setFormScheme] = useState();
  const form = useUIForm(formScheme);
  const { addCompany } = useCompaniesQuery();
  const { companiesTypes } = useCompaniesTypesQuery();
  const { userCompany } = useUserCompanyQuery();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog, showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const isShowContent = companiesTypes && userCompany && formScheme;
  const { formState: { isValid } } = form;

  useEffect(() => {
    if (companiesTypes && userCompany) {
      setFormScheme(FORM_SCHEMES.ADD_COMPANY({
        companiesTypes: companiesTypes.filter(({ rank }) => rank > userCompany.rank),
      }));
    }
  }, [companiesTypes, userCompany]);

  return isShowContent && (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>Add New Company</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <UIForm methods={form} scheme={formScheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          Cancel
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            showBackdrop('Creating company');
            addCompany.mutateAsync({
              ...form.getValues(),
              parent_company_id: userCompany.id,
            }).then((data) => {
              hideDialog();
              enqueueSnackbar('Company added successfully', { variant: 'success' });
              showDialog(DIALOGS.ADD_USER, { company: data, addAdmin: true });
            }).finally(() => {
              hideBackdrop();
            });
          }}
        >
          Add
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default AddCompany;

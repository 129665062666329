// @ts-nocheck

import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import Values from 'v2/widgets/Values/Values';
import { WIDGET_STATE } from '../utils/constants';

import DashboardWidget from '../../components/Dashboard/DashboardWidget';
import DashboardChart from '../../components/Dashboard/DashboardChart';
import DetectedObjectsFeed from './WidgetFeed';
import LastDetectedObjects from '../../components/Dashboard/LastDetectedObjects';
import Statistics from '../../components/Dashboard/Statistics';

const componentMapping = {
  hist_chart: DashboardChart,
  line_chart: DashboardChart,
  pie_chart: DashboardChart,
  value: Values,
  face_feed: DetectedObjectsFeed,
  vehicle_feed: DetectedObjectsFeed,
  person_feed: DetectedObjectsFeed,
  last_objects: LastDetectedObjects,
  nxstats: Statistics,
};

const WidgetWrapper = ({
  editable, widget, scenarios, removeWidget, updateLayout
}) => {
  const [widgetState, setWidgetState] = useState(WIDGET_STATE.FETCHING);

  const mappingWidgetData = ({
    id,
    type,
    name,
    filters,
    online,
    websocket_url,
    timeslice,
    isResizable,
    state,
  }) => (
    <DashboardWidget
      editable={editable}
      key={id}
      size={state.size}
      id={id}
      type={type}
      title={name}
      filters={filters}
      index={id}
      showComponent
      resizeWidget={() => {}}
      removeComponent={removeWidget}
      Component={componentMapping[type]}
      isRealtime={online}
      websocket_url={websocket_url}
      timeslice={timeslice}
      isResizable={isResizable}
      updateLayout={updateLayout}
      state={state}
    />
  )

  const fetchingRender = () => (
    <Box zIndex={1} position="absolute" left={0} top={0} p={2} width={1} height={1} bgcolor="background.main" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <CircularProgress size={64} />
    </Box>
  )

  const errorRender = () => (
    <Box zIndex={1} position="absolute" left={0} top={0} p={2} width={1} height={1} bgcolor="background.main" display="flex" flexDirection="column" alignItems="center" justifyContent="center" onClick={() => setWidgetState(WIDGET_STATE.FETCHING)}>
      <Box mb={1}>
        <CloudOffIcon color="primary" style={{ fontSize: 64 }} />
      </Box>
      <Typography>Error loading</Typography>
      <Typography>Tap to retry</Typography>
    </Box>
  )

  const selectRender = () => {
    switch (widgetState) {
      case WIDGET_STATE.FETCHING:
        return fetchingRender();
      case WIDGET_STATE.ERROR:
        return errorRender();
      default:
        return null;
    }
  }

  return (
    <Box width={1} height={1} position="relative" display="flex" flexDirection="column" alignItems="center" justifyContent="center" overflow="hidden">
      {
        mappingWidgetData(widget)
      }
    </Box>
  )
}

export default WidgetWrapper;

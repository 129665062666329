// @ts-nocheck
import api from 'app/api';
import useBasicQuery from './useBasicQuery';

const LOCATIONS_QUERY_KEYS = ['locations'];
const CAMERAS_QUERY_KEYS = ['cameras'];

const useDevicesQuery = () => {
  const locationsQuery = useBasicQuery({
    queryKey: LOCATIONS_QUERY_KEYS,
    queryFn: async () => {
      const result = await api.get('/device-manager/locations/');
      return result.data;
    },
    initialData: [],
  });

  const camerasQuery = useBasicQuery({
    queryKey: CAMERAS_QUERY_KEYS,
    queryFn: async () => {
      const result = await api.get('/device-manager/cameras/');
      return result.data;
    },
    initialData: [],
  });

  return {
    locations: locationsQuery.data,
    locationsQuery,
    cameras: camerasQuery.data,
    camerasQuery,
  };
};

export default useDevicesQuery;

// @ts-nocheck

import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core';
import { vars } from '../../Theme';

type HeaderProps = {
  title: string;
  handleButtonAction?: () => void;
  buttonText?: string;
  buttonIcon?: React.ComponentType<SvgIconProps>;
};

const useStyles = makeStyles(() => createStyles({
  header: {
    background: vars.backgroundMain,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '65px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
    alignItems: 'center',
    padding: '0 32px',
  },
  button: {
    height: '40px',
    minWidth: '44px',
    padding: '0 8px',
    marginLeft: '.75rem',
  },
  buttonIcon: {
    fontSize: '19px',
    margin: '0 6px',
  },
  buttonText: {
    margin: '0 6px',
  },
}),);

const Header = ({
  title, handleButtonAction, buttonText, buttonIcon
}: HeaderProps) => {
  const styles = useStyles();
  const Icon = buttonIcon;
  return (
    <div className={styles.header}>
      <h3>{title}</h3>
      {(buttonText || buttonIcon) && (
        <Button
          aria-label="add"
          variant="contained"
          color="primary"
          onClick={() => {
            handleButtonAction && handleButtonAction();
          }}
          classes={{
            root: styles.button,
          }}
        >
          {Icon && <Icon className={styles.buttonIcon} />}
          {buttonText && <span className={styles.buttonText}>{buttonText}</span>}
        </Button>
      )}
    </div>
  );
};

export default Header;

// @ts-nocheck

import React from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
  useClick,
  useHover,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
} from '@floating-ui/react';

export const TooltipContext = React.createContext(null);

const useTooltip = ({
  initialOpen,
  placement = 'bottom',
  byClick,
  byHover,
  subOffset = 0,
  open: controlledOpen,
  setOpen: setControlledOpen,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const arrowRef = React.useRef(null);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: (...args) => autoUpdate(...args, { animationFrame: true }),
    middleware: [
      offset(8 + subOffset),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const { context } = data;

  const click = useClick(context, {
    enabled: Boolean(byClick && controlledOpen == null),
  });

  const hover = useHover(context, {
    enabled: Boolean(byHover && controlledOpen == null),
  });

  const dismiss = useDismiss(context);

  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([click, hover, dismiss, role]);

  const hidePopover = () => setOpen();

  return React.useMemo(
    () => ({
      open,
      setOpen,
      hidePopover,
      ...interactions,
      ...data,
      arrowRef,
    }),
    [open, setOpen, interactions, data, arrowRef],
  );
}

const useDraggableTooltip = ({
  initialOpen,
  placement = 'top',
  open: controlledOpen,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = uncontrolledOpen;
  const setOpen = setUncontrolledOpen;

  const arrowRef = React.useRef(null);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: (...args) => autoUpdate(...args, { animationFrame: true }),
    middleware: [
      offset(8),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const { context } = data;

  const click = useClick(context, {
    event: 'mousedown',
    enabled: Boolean(!open),
  });

  const hover = useHover(context, {
    enabled: Boolean(!controlledOpen),
  });

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mouseup',
    outsidePress: (event) => {
      setOpen(false);
      return true
    },
  });

  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([click, hover, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      arrowRef,
    }),
    [open, setOpen, interactions, data, arrowRef],
  );
}

export function Tooltip({
  children,
  ...options
}) {
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export function DraggableTooltip({
  children,
  ...options
}) {
  const tooltip = useDraggableTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export const TooltipTrigger = ({ children, ...props }) => {
  const context = React.useContext(TooltipContext);

  if (React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref: context.refs.setReference,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }),
    );
  }

  return (
    <span
      ref={context.refs.setReference}
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps()}
    >
      {children}
    </span>
  );
};

export const TooltipContent = (props) => {
  const context = React.useContext(TooltipContext);

  if (!context.open || !props.children) return null;

  return (
    <FloatingPortal>
      <div
        className="UITooltip"
        ref={context.refs.setFloating}
        style={context.floatingStyles}
        {...context.getFloatingProps()}
      >
        {props.arrow && (
          <FloatingArrow
            className="UITooltipArrow"
            ref={context.arrowRef}
            context={context}
          />
        )}
        {props.children}
      </div>
    </FloatingPortal>
  );
};

// @ts-nocheck

import { WIDGET_TYPES } from 'v2/utils/constants';

export const OBJECT_TYPES = {
  FACE: 'face',
  VEHICLE: 'vehicle',
  PERSON: 'person',
};

export const OBJECT_BASE = {
  [OBJECT_TYPES.FACE]: { value: OBJECT_TYPES.FACE, name: 'Face' },
  [OBJECT_TYPES.VEHICLE]: { value: OBJECT_TYPES.VEHICLE, name: 'Vehicle' },
  [OBJECT_TYPES.PERSON]: { value: OBJECT_TYPES.PERSON, name: 'Person' },
};

export const WIDGET_BASE = {
  [WIDGET_TYPES.FEED]: [OBJECT_BASE[OBJECT_TYPES.FACE], OBJECT_BASE[OBJECT_TYPES.VEHICLE], OBJECT_BASE[OBJECT_TYPES.PERSON]],
  [WIDGET_TYPES.BAR]: [OBJECT_BASE[OBJECT_TYPES.FACE], OBJECT_BASE[OBJECT_TYPES.VEHICLE], OBJECT_BASE[OBJECT_TYPES.PERSON]],
  [WIDGET_TYPES.LINE]: [OBJECT_BASE[OBJECT_TYPES.FACE], OBJECT_BASE[OBJECT_TYPES.VEHICLE], OBJECT_BASE[OBJECT_TYPES.PERSON]],
  [WIDGET_TYPES.PIE]: [OBJECT_BASE[OBJECT_TYPES.FACE], OBJECT_BASE[OBJECT_TYPES.VEHICLE]],
  [WIDGET_TYPES.NUMERIC]: [OBJECT_BASE[OBJECT_TYPES.FACE], OBJECT_BASE[OBJECT_TYPES.VEHICLE], OBJECT_BASE[OBJECT_TYPES.PERSON], OBJECT_BASE[OBJECT_TYPES.OTHER]],
};

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import {
  Box, Button, FormControl, Paper, Typography,
} from '@material-ui/core/';
import { NavLink } from 'react-router-dom';
import { CLOUD } from 'v2/utils/brands';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import FormGenerator, { useFormGenerator } from 'v2/ui-kit/UIForm/FormGenerator';
import useLogin from 'v2/hooks/useLogin';
import useAutoFill from 'v2/hooks/useAutoFill';
import { useDialog } from 'v2/providers/DialogProvider';
import FiberNewIcon from '@material-ui/icons/FiberNew';

const Login = (props) => {
  const form = useFormGenerator();
  const { autoFill, autoFillCheck } = useAutoFill();
  const { hideDialog } = useDialog();

  const {
    DialogTitle,
    DialogBody,
    DialogActions,
    params,
    redirectUrl,
    companyId,
  } = props;

  const { cloudAuth, signIn } = useLogin({ params, redirectUrl, companyId });

  const { formState: { isValid } } = form;

  const [formScheme] = useState(FORM_SCHEMES.LOGIN());

  useEffect(() => {
    autoFillCheck();
  }, []);

  const Content = (<FormGenerator methods={form} scheme={formScheme} />);

  const Actions = (
    <>
      <FormControl size="small" variant="outlined" fullWidth>
        <Button
          variant="contained"
          color="primary"
          disabled={!(isValid || autoFill)}
          onClick={() => signIn(form)}
        >
          Log In
        </Button>
      </FormControl>
      <Box p={1}>
        <Typography variant="subtitle2" align="center">
          or Log In with
        </Typography>
      </Box>
      <FormControl size="small" variant="outlined" fullWidth>
        <Button
          variant="contained"
          color="primary"
          onClick={cloudAuth}
        >
          {CLOUD.buttonText}
        </Button>
      </FormControl>
    </>
  );

  const AdditionalActions = (
    <Box mb={2} width={1} display="flex" alignItems="center" justifyContent="center">
      <Typography variant="subtitle2">
        Forgot password?
      </Typography>
      <Box ml={1}>
        <NavLink onClick={hideDialog} className="UINavLink" to="/reset-password">
          Reset Password
        </NavLink>
      </Box>
    </Box>
  );

  const Info = (
    <Box width={1} display="flex" alignItems="center" justifyContent="center">
      <NavLink color="primary" onClick={hideDialog} to="/terms-of-use" className="UINavLink">
        Terms of Use
      </NavLink>
      <Box mx={1}>
        <Typography variant="subtitle2">|</Typography>
      </Box>
      <NavLink color="primary" onClick={hideDialog} to="/privacy-policy" className="UINavLink">
        Privacy Policy
      </NavLink>
    </Box>
  );

  const Logo = () => (
    <Box width={1} display="flex" alignItems="center" justifyContent="center">
      <div className="UILogo" />
    </Box>
  );

  return (
    <>
      <Paper variant="outlined">
        <DialogTitle child={Logo} />
        <DialogBody className="UIPaddingLess">
          {Content}
          <Box p={2} width={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {Actions}
          </Box>
        </DialogBody>
        <DialogActions>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width={1}>
            {AdditionalActions}
            {Info}
          </Box>
        </DialogActions>
        {process.env.REACT_APP_BETA_URL && (
          <DialogActions>
            <Box p={2} width={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <FormControl size="small" variant="outlined">
                <Button
                  className="blob"
                  startIcon={<FiberNewIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() => document.location = `/${process.env.REACT_APP_BETA_URL}`}
                >
                  Switch to new version
                </Button>
              </FormControl>
            </Box>
          </DialogActions>
        )}
      </Paper>
    </>
  );
};

export default Login;

// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tagsDelete = DataReducer({
  name: 'tagsDelete',
  dataProvider: async (tagID) => {
    const result = await api.delete(`/object-manager/v1/tags/${tagID}`)
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Deleting tags',
    [FETCH_STATE.REJECTED]: 'Error deleting tags',
  }
});
export default tagsDelete;

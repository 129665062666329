import React from 'react';
import clsx from 'clsx';
import { UIButtonProps, Size, Variant } from './interface';

import './UIButton.scss';

const DefaultSize = 'xs';
const DefaultVariant = 'contained';

const UIButton: React.FC<UIButtonProps> = ({
  children,
  size = DefaultSize,
  variant = DefaultVariant,
  disabled = false,
  fullWidth = false,
  onClick,
}) => {
  const sizes: Size[] = ['xs', 'sm', 'md', 'lg'];
  const variants: Variant[] = ['outlined', 'contained'];

  const className = clsx(
    'UIButton',
    size && sizes.includes(size) ? size : DefaultSize,
    variant && variants.includes(variant) ? variant : DefaultVariant,
    disabled && 'disabled',
    fullWidth && 'w-full'
  );

  return (
    <button
      className={className}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {
        children
      }
    </button>
  );
};

export default UIButton;

// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  sidebarVisibility: false,
}

const appSettings = createSlice({
  name: 'appSettings',
  initialState: {
    data: initialData
  },
  reducers: {
    setSidebarVisibility(state, action) {
      state.data.sidebarVisibility = action.payload;
    }
  },
});

export default appSettings;

// @ts-nocheck

import moment from 'moment-timezone';
import { debounce } from 'lodash/fp';
import { Filter } from 'pages/appearance/types';
import capitalize from 'lodash/capitalize';
import { FIELD_RULES, FIELD_VALIDATORS } from 'v2/constants/fieldValidators';
import {
  UNIX_ONE_HOUR,
  UNIX_ONE_DAY,
  UNIX_ONE_MONTH,
  UNIX_ONE_YEAR, EXCLUDED_PARSE_FILTERS,
} from './constants';

export const getDefaultTimezone = () => String(process.env.REACT_APP_DEFAULT_TIMEZONE) || 'America/Los_Angeles';

export const getDateFormat = (dateStart, dateEnd) => {
  const diff = (dateEnd - dateStart) / UNIX_ONE_HOUR;

  const unixStart = moment.unix(dateStart);
  const unixEnd = moment.unix(dateEnd);

  let dateFormat;
  let dateFormatIsYearly = false;

  if (diff <= 1) {
    dateFormat = 'hh:mm\u00a0A';
  } else if (UNIX_ONE_DAY >= diff) {
    dateFormat = 'hh:mm\u00a0A';
  } else if (UNIX_ONE_MONTH >= diff) {
    dateFormat = 'MM/DD hh:mm\u00a0A';
  } else if (UNIX_ONE_YEAR >= diff) {
    dateFormat = 'MM/DD';
  } else {
    dateFormat = 'YY/MM';
    dateFormatIsYearly = true;
  }

  if (unixEnd.year() > unixStart.year() && !dateFormatIsYearly) dateFormat = 'YY/MM/DD';

  return dateFormat;
};

export const parseFiltersToRequest = (filters) => filters.filter((filter) => {
  const filterItem = filter.trim().split(':');
  return !!filterItem[1] && !EXCLUDED_PARSE_FILTERS.includes(filterItem[0]);
}).join('&filters=');

export const parseFiltersToArray = (filters) => Object
  .entries(filters)
  .filter(([key, value]) => !!value && !EXCLUDED_PARSE_FILTERS.includes(key))
  .map(([field, value]) => `${field}:${encodeURIComponent(value)}`);

export const triggerOnFilterValueChange = debounce(400, (val: Filter, fn: (filter: Filter) => void) => fn(val));

export const parseObjectTypeToObject = (objectType) => (
  objectType.map((string) => {
    const newString = string.replace('_', ' ');

    return {
      value: string,
      name: newString[0].toUpperCase() + newString.slice(1),
    };
  })
);

export const fromUTCToLocal = (date) => moment.utc(date);

export const utilsDeleteMsecs = (number) => Math.trunc(Number(number) / 1000);

export const utilsAddMsecs = (number) => Math.trunc(Number(number) * 1000);

export const getTitleFromObject = (object) => {
  switch (object.type) {
    case ('face'):
      return `${object.meta.gender ? capitalize(object.meta.gender) : 'Unidentified gender'}, ${object.meta.age ? object.meta.age : 'age'}`;
    case ('vehicle'):
      return `${object.meta.type ? capitalize(object.meta.type) : 'Unidentified type'}`;
    default:
      return 'Unknown';
  }
};

export const userValid = (valueInput) => (valueInput.match(/[A-Za-z]/g)
  && !valueInput.match(/[0-9]/g)
  && !valueInput.match(/[^a-zA-Z\d\s-]/g)
  && valueInput.length >= 2);
// export const userValid = (valueInput:string) => (valueInput.match(/^[A-Za-z_\-\s]{2,120}$/g))

export const passwordValid = (input) => input
  && FIELD_VALIDATORS[FIELD_RULES.PASSWORD].pattern.value.test(input)
  && FIELD_VALIDATORS[FIELD_RULES.MINLENGTH_8].minLength.value <= input.length;

export const passwordError = (input) => {
  switch (true) {
    case (input.length < 8):
      return FIELD_VALIDATORS[FIELD_RULES.MINLENGTH_8].minLength.message;

    case (!FIELD_VALIDATORS[FIELD_RULES.PASSWORD].pattern.value.test(input)):
      return FIELD_VALIDATORS[FIELD_RULES.PASSWORD].pattern.message;

    default:
      return '';
  }
};

export const checkValidEmail = (valueInput) => valueInput
  && valueInput.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,12}$/);

export const CompanyNameValid = (valueInput) => valueInput && valueInput.match(/^[A-Za-z_\-\s]{1,120}$/g);

export const timeConverter = (UNIX_timestamp) => new Date(UNIX_timestamp).toLocaleDateString('en-US');

export const locationNameValid = (valueInput) => (valueInput.match(/^[\w|\W]{2,99}$/g))

export const objectNameValid = (valueInput) => (valueInput?.match(/^[\w|\W]{0,99}$/g))

export const objectNotesValid = (valueInput) => (valueInput?.match(/^[\w|\W]{0,499}$/g))

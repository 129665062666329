// @ts-nocheck

import axios from 'axios';
import DataReducer from '../DataReducer';

const privacy = DataReducer({
  name: 'privacy',
  dataProvider: async () => {
    const result = await axios.get('/content/privacy.json');
    return result.data;
  },
});

export default privacy;

// @ts-nocheck

import React from 'react';
import CloudOffTwoToneIcon from '@material-ui/icons/CloudOffTwoTone';
import { UIWidgetOverlap, UIWidgetPlaceholderContent } from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';

const ErrorRequest = ({ onRetry }) => (
  <UIWidgetOverlap>
    <UIWidgetPlaceholderContent
      icon={CloudOffTwoToneIcon}
      title="Oops, Something went wrong"
      onRetry={onRetry}
    />
  </UIWidgetOverlap>
);

export default ErrorRequest;

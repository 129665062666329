// @ts-nocheck

/**
 * @typedef {Object} ISharedLayoutsBehavior
 * @property {function} init
 * @property {function} update
 */

/**
 * Кейсы сценариев расшаренного рабочего пространства
 * @param {{layouts: ILayoutsAdapter}} source Адаптеры данных
 * @param {Object} externalHandlers - Внешние хэндлеры
 * @return {ISharedLayoutsBehavior}
 */

export default ({ layouts }, { setLayout }) => {
  let layout;

  const loadDetails = async (id) => {
    layout = await layouts.fetchOne(id);
  }

  const updateState = () => {
    setLayout(layout);
  }

  return {
    init: async (layout) => {
      await loadDetails(layout.params.id);
      updateState();
    },
    update: async (payload) => {
      await layouts.update(layout.id, { ...payload });

      await loadDetails(layout.id);
      updateState();
    },
  };
};

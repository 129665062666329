// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';

const companyUsers = DataReducer({
  name: 'companyUsers',
  dataProvider: async (id) => {
    const result = await api.post(`/auth-manager/v1/company/${id}/users/`, {
      pagination: {
        pgoffset: 0,
        pgsize: -1
      }
    });
    return result.data.items;
  },
});

export default companyUsers;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { OBJECT_BASE } from 'v2/constants/objectTypes';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';

const ObjectTypeSelector = ({
  initialValue, value, items, onChange,
}) => {
  const [_value, _setValue] = useState(initialValue);

  const onTabChange = (event, value) => {
    onChange && onChange(value);
    _setValue(value);
  };

  return (
    <div className="px-2">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        value={value || _value}
        onChange={onTabChange}
      >
        {
          items.map((item, index) => <Tab key={index} value={item.value} label={item.name} />)
        }
      </Tabs>
    </div>
  );
};

const MenuSubHeader = ({ children }) => (
  <div className="p-2 py-4">
    <span className="UIContentHeader">{children}</span>
  </div>
);

const CommonForm = ({ sectionTitle, methods, scheme }) => scheme && scheme.length > 0 && (
  <>
    <MenuSubHeader className="UIContentHeader">{sectionTitle}</MenuSubHeader>
    <UIForm methods={methods} scheme={scheme} />
  </>
);

const AdvancedSearchFilters = (props) => {
  const [orderingsScheme, setOrderingsScheme] = useState([]);
  const [camerasScheme, setCamerasScheme] = useState([]);
  const [timestampScheme, setTimestampScheme] = useState([]);
  const [objectTypeScheme, setObjectTypeScheme] = useState([]);
  const [commonScheme, setCommonScheme] = useState([]);

  const onChange = () => props.setFilters({
    ...orderingsForm.getValues(),
    camera_filters: camerasForm.getValues()?.devices,
    timestamp_filters: timestampForm.getValues(),
    object_type_filters: objectTypeForm.getValues(),
    common_filters: commonForm.getValues(),
  });

  const onChangeObjectType = (value) => {
    props.setObjectType(value);
  };

  const orderingsForm = useUIForm(orderingsScheme, onChange);
  const camerasForm = useUIForm(camerasScheme, onChange);
  const timestampForm = useUIForm(timestampScheme, onChange);
  const objectTypeForm = useUIForm(objectTypeScheme, onChange);
  const commonForm = useUIForm(commonScheme, onChange);

  useEffect(() => {
    setOrderingsScheme(FORM_SCHEMES.SEARCH_ORDERING(props.filters?.orderings));
    setCamerasScheme(FORM_SCHEMES.SEARCH_CAMERAS(props.filters?.camera_filters));
    setTimestampScheme(FORM_SCHEMES.SEARCH_DATES(props.filters?.timestamp_filters));
    setObjectTypeScheme(FORM_SCHEMES.SEARCH_OBJECT_TYPE_FILTERS[props.objectType](props.filters?.object_type_filters));
    setCommonScheme(FORM_SCHEMES.SEARCH_COMMON_FILTERS(props.filters?.common_filters));
  }, [props.objectType, props.filters]);

  return (
    <>
      <ObjectTypeSelector
        value={props.objectType}
        items={Object.values(OBJECT_BASE)}
        onChange={onChangeObjectType}
      />
      <CommonForm sectionTitle="Result ordering" methods={orderingsForm} scheme={orderingsScheme} />
      <CommonForm sectionTitle="Cameras and locations" methods={camerasForm} scheme={camerasScheme} />
      <CommonForm sectionTitle="Time interval" methods={timestampForm} scheme={timestampScheme} />
      <CommonForm sectionTitle="Object filters" methods={objectTypeForm} scheme={objectTypeScheme} />
      <CommonForm sectionTitle="Common filters" methods={commonForm} scheme={commonScheme} />
    </>
  );
};
export default AdvancedSearchFilters;

// @ts-nocheck

import React from 'react';
import { Box, SvgIcon } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { WifiOff } from '@material-ui/icons';

export const ToastsProvider = (props) => (
  <SnackbarProvider
    maxSnack={3}
    iconVariant={{
      offline: (
        <Box mr={2} display="flex" alignItems="center" justifyContent="center">
          <SvgIcon
            htmlColor="white"
            fontSize="small"
          >
            <WifiOff />
          </SvgIcon>
        </Box>
      ),
    }}
  >
    {props.children}
  </SnackbarProvider>
);

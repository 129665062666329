// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Button, IconButton, Input, Menu, MenuItem, SvgIcon
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { ClearSharp } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { handlers } from 'v2/redux/store';

const TagsComboInput = ({ tags, field, ...rest }) => {
  const allTags = useSelector((state) => state.tags.data) || [];
  const container = useRef(null)
  const [selectedTags, setSelectedTags] = useState(tags);
  const [unselectedTags, setUnselectedTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const selectedTagNames = selectedTags.reduce((arr, tag) => [...arr, tag.name.toLowerCase()], [])
  const filtred = unselectedTags.filter((tag) => tag.name.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1)

  const showMenu = () => (unselectedTags?.length > 0 || inputValue) && !rest['aria-invalid'];
  const showAddTag = () => inputValue && filtred?.[0]?.name.toLowerCase() !== inputValue.toLowerCase() && !selectedTagNames.includes(inputValue.toLowerCase())

  useEffect(() => {
    handlers.tags.fetch()
  }, [])

  useEffect(() => {
    setUnselectedTags(allTags.filter((tag) => !selectedTagNames.includes(tag.name.toLowerCase())))
    field.onChange(selectedTags?.length > 0 ? selectedTags : []);
  }, [allTags, selectedTags])

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // TODO: внутри происходит мутация типов: прокидывали массив объектов(тегов), на изменении превращаем в строчку (для валидации новых тегов как строчек), на блуре обратно в массив объектов
  const onInputChange = ({ value }) => {
    setOpen(true)
    setInputValue(value);
    field.onChange(value);
  }

  const handleChangeSelect = (tag) => {
    setOpen(false)
    setInputValue('')
    setSelectedTags((prev) => [...prev, tag]);
  }

  return (
    <Box py={1} px={3} width={1} ref={container} gridGap={5} display="flex" style={{ flexFlow: 'wrap' }} justifyContent="start" alignItems="center" position="relative">
      {
        selectedTags.map((tag) => (
          <Button
            color="primary"
            style={{
              padding: 5, flexShrink: 0, maxWidth: '100%', textTransform: 'none'
            }}
            variant="outlined"
            key={tag.name}
            onClick={() => {
              setSelectedTags(selectedTags.filter((item) => item.name !== tag.name));
            }}
          >
            {tag.name}
            <SvgIcon style={{ fontSize: 16 }}><ClearSharp /></SvgIcon>
          </Button>
        ))
      }
      <Input
        disableUnderline
        value={inputValue}
        style={{ flexGrow: 1 }}
        onChange={(e) => onInputChange(e.target)}
        onFocus={() => handleMenuOpen()}
        onBlur={() => { field.onChange(selectedTags); }}
      />
      { showMenu() && (
        <>
          <Box ml={2} position="absolute" top={8} right={5}>
            <IconButton
              onClick={handleMenuOpen}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          <Menu
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            variant="menu"
            anchorEl={container.current}
            autoFocus={false}
            getContentAnchorEl={null}
            open={open && !!container.current}
            onClose={handleClose}
            disableAutoFocusItem
            PaperProps={{
              style: {
                maxWidth: container.current?.clientWidth,
                width: '100%'
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {showAddTag() && (
            <MenuItem
              key="add_new"
              style={{ textTransform: 'none' }}
              onClick={(e) => {
                handleChangeSelect({ name: inputValue });
                handleClose();
              }}
            >
              Add "
              {inputValue}
              "
            </MenuItem>
            )}
            {
            filtred.map((tag) => (
              <MenuItem
                key={tag.id}
                style={{ textTransform: 'none' }}
                onClick={(e) => {
                  handleChangeSelect(tag);
                  handleClose();
                }}
              >
                {tag.name}
              </MenuItem>
            ))
          }
          </Menu>
        </>
      )}
    </Box>
  )
}

export default TagsComboInput

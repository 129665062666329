// @ts-nocheck

import { AxiosInstance } from 'axios';

/**
 * @typedef {Object} ILayoutsAdapter
 * @property {function} fetch
 * @property {function} create
 * @property {function} update
 * @property {function} delete
 * @property {function} copy
 */

/**
 * @param {AxiosInstance} api - Экземпляр Axios
 * @return {ILayoutsAdapter}
 */

export default (api) => ({
  fetchAll: () => api.get('/layout-manager/v1/layouts').then(({ data }) => data),
  fetchOne: (id) => api.get(`/layout-manager/v3/layouts/${id}`).then(({ data }) => data),
  create: (payload) => api.post('/layout-manager/v3/layouts', payload).then(({ data }) => data),
  update: (id, payload) => api.patch(`/layout-manager/v3/layouts/${id}`, payload),
  delete: (id) => api.delete(`/layout-manager/v1/layouts/${id}`),
  copy: (id, payload) => api.post('/layout-manager/v3/copy-layouts/', { id, ...payload }).then(({ data }) => data),
  share: (id) => api.post(`/layout-manager/v1/layouts/${id}/share/`).then(({ data }) => data),
});

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import useWatchListPredicatesQuery from '../hooks/queries/useWatchListPredicatesQuery';
import { useBackdrop } from '../providers/BackdropProvider';
import { clearEmptyValues, isEmpty } from '../utils/functions';
import UISectionMessage from '../ui-kit/UISectionMessage/UISectionMessage';

const AddWatchListPredicate = (props) => {
  const { watchList, fromCreated } = props;

  const { addWatchListPredicate } = useWatchListPredicatesQuery(watchList.id);
  const [formScheme, setFormScheme] = useState();
  const [formValues, setFormValues] = useState({});
  const form = useUIForm(formScheme, (data) => setFormValues(data));
  const { showDialog, hideDialog } = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { formState: { isValid, isDirty } } = form;

  useEffect(() => {
    setFormScheme(FORM_SCHEMES.WATCH_LIST_OBJECT_TYPE_FILTERS[watchList.object_type]());
  }, [watchList]);

  const showInformation = (variant) => {
    switch (variant) {
      case ('createdFrom'):
        showDialog(DIALOGS.CONFIRMATION, {
          hasHeader: true,
          content: (
            <div className="p-2 pb-8">
              <UISectionMessage type="success">
                Watch List has been created. Please proceed to the Video Management Platform to set a new Analytics Event
              </UISectionMessage>
            </div>
          ),
          hasButtons: false,
          onConfirmation: (hideDialog) => {
            hideDialog();
          },
        });
        break;
      case ('createdSelf'):
        showDialog(DIALOGS.CONFIRMATION, {
          hasHeader: true,
          content: (
            <div className="p-2 pb-8">
              <UISectionMessage type="success">
                Filters have been added
              </UISectionMessage>
            </div>
          ),
          hasButtons: false,
          onConfirmation: (hideDialog) => {
            hideDialog();
          },
        });
        break;
    }
  }

  const onCloseHeandler = () => (fromCreated ? showInformation('createdFrom') : hideDialog());

  return (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>{`Add Filter for "${watchList.name}"`}</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={onCloseHeandler} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <UIForm methods={form} scheme={formScheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={onCloseHeandler}
        >
          Cancel
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid || !isDirty || isEmpty(clearEmptyValues(formValues))}
          onClick={() => {
            showBackdrop();
            addWatchListPredicate.mutateAsync([watchList.id, formValues]).then(() => {
              fromCreated ? showInformation('createdFrom') : showInformation('createdSelf');
            }).finally(() => {
              hideBackdrop();
            });
          }}
        >
          Submit
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default AddWatchListPredicate;

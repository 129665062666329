// @ts-nocheck

import { AxiosInstance } from 'axios';

/**
 * @typedef {Object} IAuthAdapter
 * @property {function} refreshToken
 * @property {function} getCurrentUser
 * @property {function} getUser
 * @property {function} logoutUser
 */

/**
 * @param {AxiosInstance} api - Экземпляр Axios
 * @return {IAuthAdapter}
 */

export default (api) => ({
  refreshToken: () => api.post('/auth-manager/auth/refresh-token/', {}, {
    headers: {
      'access-token': localStorage.getItem('access-token'),
      'refresh-token': localStorage.getItem('refresh-token')
    }
  }).then(({ data }) => {
    localStorage.setItem('access-token', data.access_token);
    localStorage.setItem('refresh-token', data.refresh_token);
  }),
  getCurrentUser: () => api.get('/auth-manager/me/').then(({ data }) => data),
  getUser: (id) => api.get(`/auth-manager/users/${id}`).then(({ data }) => data),
  logoutUser: () => {
    localStorage.removeItem('user-company');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
  },
  login: ({ login, password }) => api.post('/auth-manager/auth/', { login, password }).then(({ data }) => {
    localStorage.setItem('access-token', data.access_token);
    localStorage.setItem('refresh-token', data.refresh_token);
    return data;
  }),
  nxLoginInternal: (scope, access_token) => api.post(`/auth-manager/v1/auth/${scope}`, { access_token }).then(({ data }) => {
    localStorage.setItem('access-token', data.access_token);
    localStorage.setItem('refresh-token', data.refresh_token);
    return data;
  }),
  companies: () => api.get('/auth-manager/context/available-companies/').then(({ data }) => data),
});

// @ts-nocheck

import network from '../reducers/network';

const networkHandlers = (store) => ({
  checkStatus: () => {
    window.addEventListener('online', () => store.dispatch(network.actions.checkStatus(true)));
    window.addEventListener('offline', () => store.dispatch(network.actions.checkStatus(false)));
  }
});

export default networkHandlers;

// @ts-nocheck
import { matchPath } from 'react-router-dom';
import { ROUTES } from 'v2/utils/routes';
import { handlers } from 'v2/redux/store';
import { ACCEPTED_FILES } from 'v2/utils/constants';
import { FIELD_VALIDATORS } from 'v2/constants/fieldValidators';
import { filetypemime } from 'magic-bytes.js';
import jwtDecode from 'jwt-decode';

export const checkAuth = () => {
  const jwt = localStorage.getItem('access-token');

  if (jwt) {
    try {
      const authData = jwtDecode(jwt);
      const expiration = new Date(Number(authData.exp) * 1000);

      if (new Date() > expiration) {
        localStorage.removeItem('user-company');
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        return false;
      }
    } catch (e) {
      localStorage.removeItem('user-company');
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      return false;
    }
    return true;
  }
  return false;
};

export const checkCredentials = () => localStorage.getItem('access-token')
  && localStorage.getItem('refresh-token')
  && localStorage.getItem('user-company')
  && checkAuth();

function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

export const isEmpty = (value) => isNullOrUndefined(value)
  || (value?.hasOwnProperty('length') && value.length === 0)
  || (value?.constructor === Object && Object.keys(value).length === 0);

export const FormSchemeToValues = (scheme = []) => Object.assign({}, ...scheme.map((item) => ({ [item.name]: isNullOrUndefined(item.value) ? item.defaultValue : item.value })));

export const clearEmptyValues = (values) => Object.fromEntries(Object.entries(values || {}).filter(([, value]) => !isEmpty(value)));

export const clearFilters = (filters) => clearEmptyValues({
  timestamp_filters: clearEmptyValues(filters.timestamp_filters),
  common_filters: clearEmptyValues(filters.common_filters),
  object_type_filters: clearEmptyValues(filters.object_type_filters),
  camera_filters: clearEmptyValues(filters.camera_filters),
  orderings: clearEmptyValues(filters.orderings),
});

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const checkFileMIME = (file, successCallback, errorCallback) => {
  const fileReader = new FileReader();

  fileReader.onloadend = (f) => {
    const results = filetypemime(new Uint8Array(f.target.result));
    if (ACCEPTED_FILES.some((item) => results.includes(item))) {
      successCallback && successCallback();
    } else {
      errorCallback && errorCallback(results);
    }
  }

  fileReader.readAsArrayBuffer(file);
}

function sharedRestoringSession(scenarios) {
  return scenarios.auth.init()
    .then(() => Promise.all([
      scenarios.widgets.init(),
    ]))
    .catch(() => {
      scenarios.auth.clear();
    })
}

function defaultRestoringSession(scenarios) {
  return scenarios.auth.init()
    .then(() => Promise.all([
      scenarios.layouts.init(),
      scenarios.widgets.init(),
      handlers.help.fetch(),
      handlers.user.fetch(),
      handlers.user.types(),
      handlers.user.company(),
      handlers.companies.types(),
      handlers.companies.fetch(),
    ]))
    // .catch(() => {
    //   console.log('CATCH');
    //   scenarios.auth.clear();
    // })
}

const match = (targetURL, currentURL) => matchPath(currentURL, {
  path: targetURL,
  exact: true,
  strict: false
});

export const routeLoadingData = (scenarios, currentURL) => {
  const layout = match(ROUTES.SHARED_LAYOUT.path, currentURL);

  switch (true) {
    case (!!layout):
      return sharedRestoringSession(scenarios, layout);
    default:
      return defaultRestoringSession(scenarios);
  }
}
export const validationCheck = (value, rules) => {
  const result = { success: true, error: '' };
  [...rules].forEach((rule) => {
    const currentRule = FIELD_VALIDATORS[rule];

    if (!result.success) return result;

    switch (true) {
      case rule === 'required' && isEmpty(value):
        result.success = false;
        result.error = currentRule.required?.message;
        break;
      case rule.match(/minLength/gm) && value.length < currentRule.minLength?.value:
        result.success = false;
        result.error = currentRule.minLength?.message;
        break;
      case rule.match(/maxLength/gm) && value.length > currentRule.maxLength?.value:
        result.success = false;
        result.error = currentRule.maxLength?.message;
        break;
      default:
        if (!value.match(currentRule.pattern?.value)) {
          result.success = false;
          result.error = currentRule.pattern?.message;
        }
        break;
    }
  })
  return result
}

export const mergeRules = (rules) => Object.assign({}, ...(rules || []).map((rule) => (typeof rule === 'object' ? rule : FIELD_VALIDATORS[rule])));

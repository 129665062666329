// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

const userActions = createSlice({
  name: 'userActions',
  initialState: {
    data: {}
  },
  reducers: {
    setActions(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export default userActions;

// @ts-nocheck
import React from 'react';
import {
  List,
  ListItemIcon,
  ListItemText,
  MenuItem, MenuList,
} from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import LockIcon from '@material-ui/icons/Lock';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { handlers } from 'v2/redux/store';
import { useSelector } from 'react-redux';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'v2/utils/routes';
import { TooltipContext } from 'v2/components/basic/BasicPopoverNew';

const AvatarPopover = () => {
  const context = React.useContext(TooltipContext);
  const { hidePopover } = context;

  const history = useHistory();
  const dialog = useDialog();

  const userActions = useSelector((state) => state.userActions.data);

  const onListOfCompanies = () => {
    hidePopover && hidePopover();
    history.push(ROUTES.SETTINGS.path);
  }

  const onSwitchCompany = () => {
    hidePopover && hidePopover();
    dialog.showDialog(DIALOGS.SWITCH_COMPANY, { showClose: true });
  }

  const onLogOut = () => {
    dialog.showDialog(DIALOGS.CONFIRMATION, {
      title: 'Log out',
      text: 'Do you really want to log out?',
      confirmText: 'Log out',
      onConfirmation: (hideDialog) => {
        handlers.user.logout()
          .then(() => {
            hideDialog();
          })
      }
    })
  }

  return (
    <MenuList disablePadding className="UIRoundedMenu">
      {userActions.canSwitchCompany
          && (
            <MenuItem dense onClick={onSwitchCompany}>
              <ListItemIcon>
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary="Choose company" />
            </MenuItem>
          )}
      <MenuItem dense onClick={onListOfCompanies}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="User Settings" />
      </MenuItem>
      <MenuItem dense onClick={onLogOut}>
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </MenuItem>
    </MenuList>
  )
}
export default AvatarPopover;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import { useHistory } from 'react-router';
import jwtDecode from 'jwt-decode';
import { ROUTES } from '../utils/routes';
import useUserQuery from '../hooks/queries/useUserQuery';
import Loader from '../placeHolders/Loader';
import DataNotFound from '../placeHolders/WidgetDataNotFound';
import { useBackdrop } from '../providers/BackdropProvider';
import Success from '../placeHolders/Success';
import Warning from '../placeHolders/Warning';

const TOKEN_ERROR = 'TOKEN_ERROR';
const USER_NOT_FOUND = 'USER_NOT_FOUND';
const LOADING = 'LOADING';
const USER_NOT_REGISTERED = 'USER_NOT_REGISTERED';
const USER_REGISTERED = 'USER_REGISTERED';
const USER_REGISTER = 'USER_REGISTER';

const RegistrationDialog = (props) => {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [error, setError] = useState();
  const [renderState, setRenderState] = useState(LOADING);
  const { userCheckRegister, userRegister } = useUserQuery();
  const history = useHistory();
  const [formScheme, setFormScheme] = useState();
  const form = useUIForm(formScheme);

  const { params } = props;
  const { formState: { isValid } } = form;

  useEffect(() => {
    try {
      const jwtData = jwtDecode(params.token);
      userCheckRegister.mutateAsync({ email: jwtData.email })
        .then((response) => {
          if (response.data) {
            setRenderState(USER_REGISTERED);
          } else {
            setFormScheme(FORM_SCHEMES.REGISTRATION_USER(jwtData.email));
            setRenderState(USER_NOT_REGISTERED);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setRenderState(USER_NOT_FOUND);
          }
        })
    } catch (e) {
      setRenderState(TOKEN_ERROR);
      setError(e.message)
    }
  }, [params]);

  const userRegisterHandle = () => {
    setRenderState(USER_REGISTER);
    showBackdrop();
    userRegister.mutateAsync({ ...form.getValues(), email_token: params.token }).then(() => {
      setRenderState(USER_REGISTER);
      hideBackdrop();
    })
  }

  switch (renderState) {
    case (LOADING):
      return (
        <Loader />
      )
    case (USER_NOT_FOUND): {
      return (
        <DataNotFound title="Error" subtitle="User not found or link is broken" onRetryTitle="Log in" onRetry={() => history.push(ROUTES.LOGIN.path)} />
      )
    }
    case (TOKEN_ERROR): {
      return (
        <DataNotFound title="Error" subtitle={error} onRetryTitle="Log in" onRetry={() => history.push(ROUTES.LOGIN.path)} />
      )
    }
    case (USER_REGISTER):
      return (
        <Success title="Success" subtitle="User registered succesfilly" onRetryTitle="Log in" onRetry={() => history.push(ROUTES.LOGIN.path)} />
      )
    case (USER_REGISTERED):
      return (
        <Warning title="Warning" subtitle="User already registered" onRetryTitle="Log in" onRetry={() => history.push(ROUTES.LOGIN.path)} />
      )
    case (USER_NOT_REGISTERED):
      return (
        <UIWidgetContainer className="UIDialog">
          <UIWidgetHeader>
            <UIWidgetTitle>Registration</UIWidgetTitle>
          </UIWidgetHeader>
          <UIWidgetBody>
            <UIForm methods={form} scheme={formScheme} />
          </UIWidgetBody>
          <UIWidgetFooter className="justify-end">
            <UIButton
              variant="contained"
              disabled={!isValid}
              onClick={userRegisterHandle}
            >
              Submit
            </UIButton>
          </UIWidgetFooter>
        </UIWidgetContainer>
      )
  }
};

export default RegistrationDialog;

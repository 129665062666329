// @ts-nocheck
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { routeLoadingData } from 'v2/utils/functions';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { useModel } from 'v2/providers/ModelProvider';
import { CLOUD } from 'v2/utils/brands';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { ROUTES } from 'v2/utils/routes';
import { handlers } from '../redux/store';

const useLogin = ({ params, redirectUrl, companyId }) => {
  const history = useHistory();
  const location = useLocation();

  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog, showDialog } = useDialog();
  const { scenarios } = useModel();

  const urlParams = new URLSearchParams(location.search);
  const nxCode = urlParams.get('code');

  useEffect(() => {
    handlers.user.clear();

    if (nxCode) {
      showBackdrop();
      scenarios.auth.nxLogin(nxCode).then(companyStep).finally(hideBackdrop);
    }
  }, []);

  const signIn = (form) => {
    showBackdrop();
    scenarios.auth.login({ ...form.getValues() }).then(companyStep).finally(hideBackdrop);
  };

  const cloudAuth = () => {
    showBackdrop();
    window.location = CLOUD.url(window.location.href);
  };

  const choseCompany = (redirectURL, companyId, companies) => {
    const companyExists = companies.find((item) => item.id === companyId);

    if (companyExists) {
      hideDialog();
      return handlers.user.switchCompany(companyId, redirectURL).catch(console.log);
    }

    return history.push(ROUTES.NO_COMPANY.path);
  }

  const companyStep = ({ companies }) => {
    hideDialog();

    if (!companies.length) {
      return history.push(ROUTES.NO_COMPANIES.path);
    }

    if (companyId) {
      choseCompany(redirectUrl, companyId, companies);
    } else {
      if (companies.length > 1) {
        showDialog(DIALOGS.SWITCH_COMPANY, { login: true, redirectURL: redirectUrl });
      } else {
        // choseCompany(redirectUrl, companies[0].id, companies).then(() => routeLoadingData(scenarios, redirectUrl)).finally(() => history.push(redirectUrl));
        choseCompany(redirectUrl, companies[0].id, companies).finally(() => history.push(redirectUrl));
      }
    }
  };

  return {
    companyStep, signIn, cloudAuth,
  };
};

export default useLogin;

// @ts-nocheck
// import { useEffect, useRef, useState } from 'react';
// import moment from 'moment-timezone';
//
// const reconnectCodes = [1001, 1006, 1011];
//
// const useNotifications = (
//   isRealtime,
//   url,
//   onChange,
// ) => {
//   const socket = useRef();
//   const [online, setOnline] = useState(true);
//
//   const writeLog = (action, e) => {
//     console.group(action);
//     console.log(`${moment()}`);
//     console.log(e);
//     console.groupEnd();
//   }
//
//   const createSocketConnection = () => {
//     socket.current = new WebSocket(url);
//     socket.current.onopen = (e) => writeLog('OPEN', e);
//     socket.current.onerror = (e) => writeLog('ERROR', e);
//     socket.current.onmessage = (event) => {
//       onChange(JSON.parse(event.data));
//     }
//     socket.current.onclose = (e) => {
//       writeLog('CLOSE', e);
//       if (reconnectCodes.includes(e.code)) {
//         writeLog('RETRY RECONNECT', e);
//         setTimeout(createSocketConnection, 5000);
//       }
//     }
//   };
//
//   const closeSocket = () => {
//     if (socket.current) {
//       if (socket.current.readyState === 1) {
//         socket.current.close();
//       } else {
//         socket.current.onopen = () => {
//           socket.current.close();
//         }
//       }
//     }
//   }
//
//   useEffect(() => {
//     const onLine = (e) => {
//       writeLog('ONLINE', e);
//       setOnline(true);
//     }
//
//     const offLine = (e) => {
//       writeLog('OFFLINE', e);
//       setOnline(false);
//     }
//
//     window.addEventListener('online', onLine);
//     window.addEventListener('offline', offLine);
//
//     return () => {
//       closeSocket();
//       window.removeEventListener('online', onLine);
//       window.removeEventListener('offline', offLine);
//     }
//   }, [])
//
//   useEffect(() => {
//     if (isRealtime && url && online) {
//       createSocketConnection();
//     } else {
//       closeSocket();
//     }
//
//     return () => closeSocket();
//   }, [isRealtime, url, online]);
// };
//
// export default useNotifications;

import { useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { v4 } from 'uuid';
import { FEED_LENGTH } from '../utils/constants';

const useNotifications = (
  isRealtime,
  url,
  accumulator,
  onChange
) => {
  const _socket = useRef();
  const _timerInsert = useRef();
  const _timerUpdate = useRef();

  const writeLog = (action, e) => {
    console.group(action.toUpperCase());
    console.log(`${moment()}`);
    console.log(e);
    console.groupEnd();
  };

  const clearTimers = () => {
    _timerInsert.current && clearInterval(_timerInsert.current);
    _timerUpdate.current && clearInterval(_timerUpdate.current);
  }

  const closeSocket = () => {
    _socket.current && _socket.current.close();
  };

  const insertObject = (objects) => {
    accumulator.current.unshift(...objects);
    accumulator.current = accumulator.current.slice(0, FEED_LENGTH);
  }

  const createSocketConnection = (url) => {
    const socket = new ReconnectingWebSocket(url, [], { debug: false });

    socket.addEventListener('open', (e) => {
      writeLog('open', e);

      // FOR MOCK OBJECT SENDING
      // _timerInsert.current = setInterval(() => {
      //   const id = v4();
      //
      //   const object = [{
      //     label: 0,
      //     type: 'face',
      //     track_id: '2da1b671-d8f4-4c42-83c7-560a3da3cba0',
      //     camera_id: '47ab9637-a03e-4679-a7c4-7f1166182e6f',
      //     camera_name: 'Spammer test camera #0',
      //     timestamp: Date.now() / 1000,
      //     meta: { age: 22, gender: 'male', bad_quality: 'good' },
      //     roi: {
      //       x1: 0.2, y1: 0.2, x2: 0.8, y2: 0.8
      //     },
      //     image_path: '6e2866c8-e08a-4746-bf43-0e01658e1b82.jpg',
      //     // image_url: 'https://dummyimage.com/300x400/000/fff&text=TEST',
      //     image_url: `https://dummyimage.com/300x400/000/fff&text=${id}`,
      //     id,
      //   }];
      //
      //   insertObject(object);
      // }, 1000);

      _timerUpdate.current = setInterval(() => {
        onChange(accumulator.current);
      }, 1000);
    });

    socket.addEventListener('message', (e) => {
      const objects = JSON.parse(e.data);
      const preparedObjects = objects.map((item) => ({ ...item, key: v4() }));

      insertObject(preparedObjects);
    });

    socket.addEventListener('error', (e) => {
      clearTimers();
    });

    socket.addEventListener('close', (e) => {
      writeLog('close', e);
      clearTimers();
    });

    _socket.current = socket;
  };

  useEffect(() => {
    if (isRealtime && url) {
      closeSocket();
      createSocketConnection(url);
    } else {
      closeSocket();
    }

    return () => closeSocket();
  }, [isRealtime, url, onChange]);
};

export default useNotifications;

// @ts-nocheck

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Layout from 'partials/Layout';
import AccountSettings from 'v2/components/AccountSettings';
import UserSettingsAvatar from 'v2/components/UserSettingsAvatar';

const UserSettings = () => (
  <Layout onlyRoot>
    <Box p={2} width={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box width={1} borderRadius="borderRadius" bgcolor="background.main" overflow="hidden">
            <UserSettingsAvatar />
            <Box m={2} mt={0} borderRadius="borderRadius" bgcolor="background.secondary">
              <AccountSettings />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Layout>
);

export default UserSettings;

// @ts-nocheck
import {
  Box, IconButton, Typography,
} from '@material-ui/core';
import React from 'react';
import BasicOnlineAvatar from 'v2/components/basic/BasicOnlineAvatar';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AvatarPopover from 'v2/popovers/AvatarPopover';
import { Tooltip, TooltipContent, TooltipTrigger } from 'v2/components/basic/BasicPopoverNew';

const FullVariant = ({
  user, online, userCompany, userCompanyShortname
}) => (
  <Box display="flex" alignItems="center" justifyContent="center">
    <BasicOnlineAvatar online={online} src={user.photo_url} />
    <Box display="flex" flexDirection="column" mx={2} maxWidth={200}>
      <Typography align="left" className="UIAvatarName">
        {`${user.first_name} ${user.last_name}`}
      </Typography>
      <Typography align="left" className="UIAvatarCompany">
        {`${userCompany.name}, ${userCompanyShortname.short}`}
      </Typography>
    </Box>
    <IconButton>
      <Tooltip byClick>
        <TooltipTrigger>
          <ExpandMore />
        </TooltipTrigger>
        <TooltipContent arrow>
          <AvatarPopover />
        </TooltipContent>
      </Tooltip>
    </IconButton>
  </Box>
)

export default FullVariant

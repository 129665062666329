// @ts-nocheck

import React, {
  ChangeEvent, useRef, useEffect
} from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import {
  Box, TextField,
} from '@material-ui/core';

import { locationNameValid } from '../../utils/helpers'

const AddLocation = ({
  handleLocationNameInput,
  visible,
  locationName,
  addLocationClick,
  handleClose,
  newLocationId,
  locationId,
  rootLocation,
}) => {
  const widthInput = useRef(null)

  useEffect(() => {
    const element = widthInput?.current;
    if (!element) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize[0].inlineSize > 140) {
          entry.target.firstChild.style.opacity = 1
        } else {
          entry.target.firstChild.style.opacity = 0
        }
      }
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [visible])
  if (!visible) return null;

  return rootLocation || (locationId && locationId === newLocationId) ? (
    <Box
      mb={1}
      p={2}
      borderRadius="borderRadius"
      border={1}
      display="flex"
      alignItems="center"
      bgcolor="background.input"
      borderColor="background.primaryBorder"
    >
      <Box mr={1} display="flex" width={1} alignItems="center">
        <TextField
          fullWidth
          placeholder="Write location name"
          value={locationName}
          label="New location name"
          onChange={handleLocationNameInput}
          error={!locationNameValid(locationName)}
          helperText={!locationNameValid(locationName)
          && 'The Location Name field should be between 2-99 symbols.'}
          ref={widthInput}
        />
      </Box>
      <Box mr={1} display="flex" alignItems="center">
        <IconButton
          color="primary"
          disabled={!locationName || !locationNameValid(locationName)}
          onClick={addLocationClick}
        >
          <CheckIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  ) : null;
};

export default AddLocation;

// @ts-nocheck

import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, IconButton, TextField, Tooltip
} from '@material-ui/core';
import { FIELD_RULES, FIELD_VALIDATORS } from 'v2/constants/fieldValidators';
import { mergeRules } from 'v2/utils/functions';
import { withTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';

const TextInput = (props) => {
  const { t } = props;

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ ...FIELD_VALIDATORS[FIELD_RULES.MAXLENGTH_100], ...mergeRules(props.rules) }}
      defaultValue={props.value || ''}
      render={(controller) => (
        <Tooltip arrow title={props.tooltip || ''} enterDelay={1000} enterNextDelay={500}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label={props.label}
            error={!!controller.formState?.errors[props.name]?.message}
            helperText={t(controller.formState?.errors[props.name]?.message)}
            InputLabelProps={{ shrink: true }}
            disabled={props.disabled}
            InputProps={{
              endAdornment: controller.field.value !== props.defaultValue && !props.disabled && props.showClear && (
              <Box right={4} position="absolute">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    controller.field.onChange(props.defaultValue);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
              ),
            }}
            {...controller.field}
          />
        </Tooltip>
      )}
    />
  )
}

export default withTranslation(['validation'])(TextInput);

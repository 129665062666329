// @ts-nocheck
import React from 'react';
import moment from 'moment-timezone';
import UITagIcon from 'v2/icons/UITagIcon';
import UIMetaList from 'v2/ui-kit/UIMetaList/UIMetaList';
import UIPinIcon from 'v2/icons/UIPinIcon';
import UIPatientListIcon from 'v2/icons/UIPatientListIcon';
import UIVideocamIcon from 'v2/icons/UIVideocamIcon';
import UIScheduleIcon from 'v2/icons/UIScheduleIcon';
import UIDirectionsCarIcon from 'v2/icons/UIDirectionsCarIcon';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import clsx from 'clsx';
import { MONTH_DAY_YEAR_TIME_A_FORMAT } from '../../../utils/timeFormats';

const UITooltipHeader = ({ className, children }) => (
  <div className={clsx('text-white/50', className)}>{children}</div>
);

const getMetaTime = (object) => {
  const { last_seen, timestamp } = object;
  const time = last_seen || timestamp;
  return moment.unix(time).format(MONTH_DAY_YEAR_TIME_A_FORMAT);
};

const objectId = ({ object }) => {
  const Tooltip = (
    <>
      <UITooltipHeader>Object Identification Number</UITooltipHeader>
      <div>{object.id}</div>
    </>
  );

  return (
    <div className="UIMetaItem">
      <UITagIcon />
      <UIWidgetAction
        byHover
        tooltip={Tooltip}
      >
        {object.id}
      </UIWidgetAction>
    </div>
  );
};

const licensePlate = ({ object }) => {
  const data = object.meta.license_plate ? object.meta.license_plate.toUpperCase() : 'N/A';

  const Tooltip = (
    <>
      <UITooltipHeader>License Plate</UITooltipHeader>
      <div>{data}</div>
    </>
  );

  return (
    <div className="UIMetaItem">
      <UIPinIcon />
      <UIWidgetAction
        byHover
        tooltip={Tooltip}
      >
        {data}
      </UIWidgetAction>
    </div>
  );
};

const camera = ({ object }) => {
  const data = object.camera_name || 'N/A';

  const Tooltip = (
    <>
      <UITooltipHeader>Detected Camera</UITooltipHeader>
      <div>{data}</div>
    </>
  );

  return (
    <div className="UIMetaItem">
      <UIVideocamIcon />
      <UIWidgetAction
        byHover
        tooltip={Tooltip}
      >
        {data}
      </UIWidgetAction>
    </div>
  );
};

const time = ({ object }) => {
  const data = getMetaTime(object);

  const Tooltip = (
    <>
      <UITooltipHeader>Object Detection Time</UITooltipHeader>
      <div>{data}</div>
    </>
  );

  return (
    <div className="UIMetaItem">
      <UIScheduleIcon />
      <UIWidgetAction
        byHover
        tooltip={Tooltip}
      >
        {data}
      </UIWidgetAction>
    </div>
  );
};

const faceName = ({ object }) => {
  const data = [
    [
      object.meta.gender ? object.meta.gender.toUpperCase() : 'N/A',
      object.meta.age || 'N/A',
    ].join(' '),
    object.meta.name,
  ].filter((item) => item);

  const Tooltip = (
    <>
      <UITooltipHeader>Face Information</UITooltipHeader>
      <div className="whitespace-break-spaces">{data.join('\n')}</div>
      {object.meta.notes && (
        <>
          <UITooltipHeader className="mt-4">Description</UITooltipHeader>
          <div className="whitespace-break-spaces">{object.meta.notes}</div>
        </>
      )}
    </>
  );

  return (
    <div className="UIMetaItem">
      <UIPatientListIcon />
      <UIWidgetAction
        byHover
        tooltip={Tooltip}
      >
        {data.join(', ')}
      </UIWidgetAction>
    </div>
  );
};

const vehicleName = ({ object }) => {
  const data = [
    object.meta.type ? object.meta.type.toUpperCase() : 'N/A',
    object.meta.name,
  ].filter((item) => item);

  const Tooltip = (
    <>
      <UITooltipHeader>Vehicle Information</UITooltipHeader>
      <div className="whitespace-break-spaces">{data.join('\n')}</div>
      {object.meta.notes && (
        <>
          <UITooltipHeader className="mt-4">Description</UITooltipHeader>
          <div className="whitespace-break-spaces">{object.meta.notes}</div>
        </>
      )}
    </>
  );

  return (
    <div className="UIMetaItem">
      <UIDirectionsCarIcon />
      <UIWidgetAction
        byHover
        tooltip={Tooltip}
      >
        {data.join(', ')}
      </UIWidgetAction>
    </div>
  );
};

const personName = ({ object }) => object.meta.name && (
  <div className="UIMetaItem">
    <UIPatientListIcon />
    <UIWidgetAction
      byHover
      tooltip={object?.meta?.name ? `Person Information: ${object.meta.name}` : 'Person Information'}
    >
      {object.meta.name}
    </UIWidgetAction>
  </div>
);

export const META_REGULAR = {
  vehicle: {
    objectId,
    licensePlate,
    name: vehicleName,
    camera,
    time,
  },
  face: {
    objectId,
    name: faceName,
    camera,
    time,
  },
  person: {
    objectId,
    name: personName,
    camera,
    time,
  },
};

const makeComponent = (key, Component) => Component && React.cloneElement(Component, { key });

const UIMetaData = ({ object, meta = META_REGULAR }) => {
  const metaObjectType = meta[object?.type];

  if (!metaObjectType) return null;

  return (
    <UIMetaList>
      {Object.values(metaObjectType).map((fn, index) => makeComponent(index, fn({ object })))}
    </UIMetaList>
  );
};

export default UIMetaData;

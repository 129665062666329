// @ts-nocheck
import React, { useState } from 'react';
import {
  Box, Button, createStyles, makeStyles, TextField
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DashboardMenu from 'components/Dashboard/Menu/DashboardMenu';
import Dialog from 'components/common/BasicDialog';
import { useSnackbar } from 'notistack';
import useUserCompanyQuery from 'v2/hooks/queries/useUserCompanyQuery';
import usePermissionsQuery, { PERMISSIONS } from '../../../v2/hooks/queries/usePermissionsQuery';

const useStyles = makeStyles(() => createStyles({
  /* formRowButton: {
    whiteSpace: 'nowrap',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    width: '32rem',
    textAlign: 'left',
  },
  formRowInput: {
    width: '100%',
  } */
}));

const LayoutMenu = ({
  layout, actionCreate, actionUpdate, actionDelete, actionCopy, actionShare,
}) => {
  const classes = useStyles();

  const [dialogOptions, setDialogOptions] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { userCompany } = useUserCompanyQuery();
  const { permissions } = usePermissionsQuery();

  const InputForm = ({ initialValue, onChange }) => (
    <TextField
      fullWidth
      size="small"
      label="Layout name"
      variant="outlined"
      value={initialValue}
      onChange={(e) => onChange(e.target.value.trimLeft())}
      error={initialValue.length === 0}
      helperText={initialValue.length === 0 && 'Field cannot be empty'}
    />
  );

  const shareForm = ({ initialValue }) => (
    <Box display="flex" flexDirection="column" width="450px">
      <TextField
        className={classes.formRowInput}
        size="small"
        label="Link"
        variant="outlined"
        value={initialValue}
        readOnly
        fullWidth
        multiline
      />
      <Box mt={2} display="flex" justifyContent="flex-end">
        {
          permissions[PERMISSIONS.CLIPBOARD_WRITE] ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                enqueueSnackbar('Link copied', { variant: 'success' });
                navigator.clipboard.writeText(initialValue);
                closeDialog && closeDialog();
              }}
            >
              Copy link
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                closeDialog && closeDialog();
              }}
            >
              Close
            </Button>
          )
        }
      </Box>
    </Box>
  );

  const closeDialog = () => {
    setDialogOptions();
  };

  const menuItems = [
    {
      text: 'Add',
      visible: true,
      Icon: AddIcon,
      onClick: () => {
        setDialogOptions({
          title: 'Add layout',
          Component: InputForm,
          initialValue: 'New layout',
          closeDialog,
          onChange: (name) => {
            actionCreate({ name: name?.trim() });
          },
          fromValidator: (value) => value?.trim()?.length !== 0,
        });
      },
    },
    {
      text: 'Copy',
      visible: true,
      Icon: FileCopyIcon,
      onClick: () => {
        setDialogOptions({
          title: 'Copy layout',
          Component: InputForm,
          initialValue: layout.name,
          closeDialog,
          onChange: (name) => {
            actionCopy({ name: name?.trim() });
          },
          fromValidator: (value) => value?.trim()?.length !== 0,
        });
      },
    },
    {
      text: 'Rename',
      visible: true,
      Icon: CreateIcon,
      onClick: () => {
        setDialogOptions({
          title: 'Rename layout',
          Component: InputForm,
          initialValue: layout.name,
          closeDialog,
          onChange: (name) => {
            actionUpdate({ name: name?.trim() });
          },
          fromValidator: (value) => value?.trim()?.length !== 0,
        });
      },
    },
    {
      text: 'Delete',
      visible: true,
      Icon: DeleteIcon,
      onClick: () => {
        setDialogOptions({
          title: 'Delete layout',
          Component: () => 'Do you really want to delete this layout?',
          closeDialog,
          onChange: () => {
            actionDelete();
          },
        });
      },
    },
    {
      text: 'Share',
      visible: true,
      Icon: ShareIcon,
      onClick: () => {
        actionShare(userCompany.id).then((link) => {
          setDialogOptions({
            title: 'Sharable link',
            initialValue: link,
            hideButtons: true,
            Component: shareForm,
            closeDialog,
          });
        });
      },
    },
  ];

  return (
    <>
      <DashboardMenu title="More" menuItems={menuItems} />
      {dialogOptions && (
        <Dialog
          isOpen
          {...dialogOptions}
        />
      )}
    </>
  );
};

export default LayoutMenu;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { useDialog } from 'v2/providers/DialogProvider';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import useUserTypesQuery from 'v2/hooks/queries/useUserTypesQuery';
import useUserQuery from 'v2/hooks/queries/useUserQuery';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import UISectionMessage from 'v2/ui-kit/UISectionMessage/UISectionMessage';

const AddUser = (props) => {
  const [formScheme, setFormScheme] = useState();
  const form = useUIForm(formScheme);
  const { usersTypes } = useUserTypesQuery();
  const { user, userCreate } = useUserQuery();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const company = props.params || props.company;
  const isShowContent = usersTypes && user && formScheme;
  const { formState: { isValid } } = form;

  useEffect(() => {
    if (user && usersTypes) {
      setFormScheme(FORM_SCHEMES.ADD_USER({
        usersTypes: usersTypes.filter(({ rank }) => (rank >= user.rank)),
        addAdmin: props.addAdmin,
      }));
    }
  }, [usersTypes, user]);

  return isShowContent && (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>Add New User</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        {
          props.addAdmin && (
            <div className="p-2 pt-0">
              <UISectionMessage type="success">
                You have already been added as an Administrator to the new company. If you want, you can add another
                Administrator
              </UISectionMessage>
            </div>
          )
        }
        <UIForm methods={form} scheme={formScheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          {props.addAdmin ? 'Skip' : 'Cancel'}
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            const selectedRole = usersTypes.find((item) => item.value === form.getValues().role);

            showBackdrop(`Creating ${selectedRole.name}`);
            userCreate.mutateAsync({ ...form.getValues(), company_id: company.id }).then(() => {
              hideDialog();
              props.onSuccess && props.onSuccess();
              enqueueSnackbar(`${selectedRole.name} added successfully`, { variant: 'success' });
            }).finally(() => {
              hideBackdrop();
            });
          }}
        >
          Add
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default AddUser;

// @ts-nocheck

import { AxiosInstance } from 'axios';

/**
 * @typedef {Object} IHelpAdapter
 * @property {function} fetch
 */

/**
 * @param {AxiosInstance} api - Экземпляр Axios
 * @return {IHelpAdapter}
 */

export default (api) => ({
  fetch: () => api.get('/help.json').then((response) => response.data)
});

// @ts-nocheck
import React from 'react';

export default function Track({ source, target, getTrackProps }) {
  return (
    <div
      className="track"
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  )
}

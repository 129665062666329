// @ts-nocheck

import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import { BUTTON_TYPES } from 'v2/utils/constants';
import { handlers } from 'v2/redux/store';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({ columns, rowActions }) {
  const namedCols = columns.filter((item) => item.label);

  return namedCols.length > 0 && (
    <TableHead>
      <TableRow>
        {columns.map(({
          id, label, align,
        }) => (
          <TableCell
            key={id}
            align={align}
          >
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableToolbar = (props) => {
  const {
    title, toolbarActions, titleActions, subTitle,
  } = props;

  const designSelector = (action) => {
    switch (action.type) {
      case BUTTON_TYPES.ICON:
        return (
          <IconButton
            component="span"
            onClick={action.onClick}
          >
            <action.Icon />
          </IconButton>
        );
      case BUTTON_TYPES.COMBO:
        return (
          <Button
            color="primary"
            variant={action.variant || 'outlined'}
            onClick={action.onClick}
            endIcon={<action.Icon />}
          >
            {action.title}
          </Button>
        );
      default:
        return (
          <Button
            color="primary"
            variant={action.variant || 'outlined'}
            onClick={action.onClick}
          >
            {action.title}
          </Button>
        );
    }
  };

  return (
    <Box p={2} width={1} display="flex" flexDirection="column">
      <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Box width={1} overflow="hidden">
          <Box display="flex" alignItems="center">
            {
              titleActions && titleActions.filter(({ visible }) => visible).map((action, index) => (
                <Box key={index} mr={2} whiteSpace="nowrap">
                  {
                    designSelector(action)
                  }
                </Box>
              ))
            }
            <Box display="flex" flexDirection="column">
              <Typography>
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml={2} display="flex">
          {
            toolbarActions && toolbarActions.filter(({ visible }) => visible).map((action, index) => (
              <Box key={index} ml={2} whiteSpace="nowrap">
                {
                  designSelector(action)
                }
              </Box>
            ))
          }
        </Box>
      </Box>
      {subTitle}
    </Box>
  );
};

const TableContent = (props) => {
  const {
    rows, columns, onRowClick, rowClassName, rowActions, rowsPerPage, page, showEmpty,
  } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  return (
    <TableContainer>
      <Box border={1} borderRadius="borderRadius" borderColor="background.primaryBorder">
        <Table stickyHeader style={{ tableLayout: 'auto' }}>
          <EnhancedTableHead
            {...props}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  hover
                  onClick={(event) => {
                    onRowClick && onRowClick(event, row);
                  }}
                  key={row.id}
                  className={rowClassName && rowClassName(row)}
                >
                  {
                    columns.map(({
                      id, formatter, align, width,
                    }) => ((row.hasOwnProperty(id) || showEmpty) && id !== 'rowAction'
                      ? (
                        <TableCell
                          key={id}
                          align={align}
                          className="UITableCell"
                        >
                          {formatter ? formatter(row) : row[id]}
                        </TableCell>
                      ) : null)).filter((item) => !!item)
                  }
                  {
                    rowActions && (
                      <TableCell key="rowActions" align="center">
                        {rowActions(row)}
                      </TableCell>
                    )
                  }
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default function EnhancedTable(props) {
  const {
    title, rows, columns, onRowClick, rowClassName, toolbarActions, titleActions, storageKey, rowActions, subTitle, EmptyTableComponent, showEmpty,
  } = props;
  const defaultRowsPerPage = 10;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(Number(localStorage.getItem(storageKey) || defaultRowsPerPage));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsCount = parseInt(event.target.value, defaultRowsPerPage);
    setRowsPerPage(newRowsCount);
    localStorage.setItem(storageKey, newRowsCount);
    setPage(0);
  };

  return (
    <div>
      {title
        && (
        <TableToolbar
          title={title}
          titleActions={titleActions}
          toolbarActions={toolbarActions}
          subTitle={subTitle}
        />
        )}
      {(rows.length === 0 && EmptyTableComponent)
        ? (
          <EmptyTableComponent />
        ) : (
          <>
            <TableContent
              rows={rows}
              columns={columns}
              onRowClick={onRowClick}
              rowClassName={rowClassName}
              rowActions={rowActions}
              rowsPerPage={rowsPerPage}
              page={page}
              showEmpty={showEmpty}
            />
            <TablePagination
              rowsPerPageOptions={[10, 50, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}

    </div>
  );
}

// @ts-nocheck

import help from './help';
import privacy from './privacy';
import terms from './terms';
import companiesTypes from './companiesTypes';
import companyUsers from './companyUsers';
import userCompany from './userCompany';
import userActions from './userActions';
import companies from './companies';
import user from './user';
import licenses from './licenses';
import usersTypes from './usersTypes';
import layouts from './layouts';
import clusterNotifications from './clusterNotifications';
import suggestions from './suggestions';
import userPatch from './userPatch';
import requests from './requests';
import networkStatus from './network';
import googleIcons from '../googleIcons/fetch';

const reducer = {
  help: help.reducer,
  privacy: privacy.reducer,
  terms: terms.reducer,

  user: user.reducer,
  userCompany: userCompany.reducer,
  userPatch: userPatch.reducer,
  userActions: userActions.reducer,

  licenses: licenses.reducer,

  usersTypes: usersTypes.reducer,

  companies: companies.reducer,
  companiesTypes: companiesTypes.reducer,

  companyUsers: companyUsers.reducer,

  layouts: layouts.reducer,

  clusterNotifications: clusterNotifications.reducer,

  suggestions: suggestions.reducer,

  networkStatus: networkStatus.reducer,

  requests: requests.reducer,

  googleIcons: googleIcons.reducer,
}

export default reducer;

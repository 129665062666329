// @ts-nocheck

import { useEffect } from 'react';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { useLocation, useParams } from 'react-router';

const parseQueryString = (search: string) => {
  const query = search.split('?');
  if (query.length < 2) return null;
  const params = query[1].split('&');
  const result = {};
  params.forEach((param) => {
    const [key, value] = param.split('=');
    result[key] = value ? decodeURIComponent(value) : null;
  });
  return result;
}

const getCompanyId = (path: string) => {
  if (!path) return null;
  const match = path.match(/\/companies\/(\d+)/);
  return match ? Number(match[1]) : null;
};

const getCompanyIdFromSharedWidget = (path: string) => {
  if (!path) return null;
  const match = path.match(/\/shared-widget\/(\d+)\//);
  return match ? Number(match[1]) : null;
}

const getCompanyIdFromSharedLayout = (path: string) => {
  if (!path) return null;
  const match = path.match(/\/shared-layout\/(\d+)\//);
  return match ? Number(match[1]) : null;
};

const getCompanyIdFromAppearance = (path: string) => {
  if (!path) return null;
  const match = path.match(/\/appearance\/(\d+)\//);
  return match ? Number(match[1]) : null;
}

const LogIn = () => {
  const dialog = useDialog();
  const params = useParams();
  const location = useLocation();
  const query = parseQueryString(location.search || location.pathname);
  const redirectUrl = query?.redirect_url || '/';
  const companyId = getCompanyId(redirectUrl) || getCompanyIdFromSharedWidget(redirectUrl) || getCompanyIdFromSharedLayout(redirectUrl) || getCompanyIdFromAppearance(redirectUrl) || null;

  useEffect(() => {
    dialog.showDialog(DIALOGS.LOGIN, { params, redirectUrl, companyId });
  }, []);
};

export default LogIn;

// @ts-nocheck

import React from 'react';
import AdvancedSearchFilters from 'v2/pages/AdvancedSearch/AdvancedSearchFilters';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetLeftActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import LeftPanelClose from '../../widgetActions/LeftPanelClose';

const AdvancedSearchPanel = ({
  onSubmit,
  isFetching,
  onClear,
  onClickOpenPanel,
  filters,
  setFilters,
  objectType,
  setObjectType,
}) => (
  <UIWidgetContainer className="h-full mr-2 w-96 shrink-0">
    <UIWidgetHeader>
      <UIWidgetLeftActions>
        <LeftPanelClose onClick={onClickOpenPanel} tooltip="HIDE APPLIED FILTERS" />
      </UIWidgetLeftActions>
      <UIWidgetTitle>Search filters</UIWidgetTitle>
    </UIWidgetHeader>
    <UIWidgetBody>
      <AdvancedSearchFilters
        filters={filters}
        objectType={objectType}
        setFilters={setFilters}
        setObjectType={setObjectType}
      />
    </UIWidgetBody>
    <UIWidgetFooter className="justify-between">
      <UIButton
        variant="outlined"
        onClick={onClear}
        disabled={isFetching}
      >
        Clear Filters
      </UIButton>
      <UIButton
        variant="contained"
        disabled={isFetching}
        onClick={onSubmit}
      >
        Search
      </UIButton>
    </UIWidgetFooter>
  </UIWidgetContainer>
);

export default AdvancedSearchPanel;

// @ts-nocheck
import { useEffect, useState } from 'react';
import { MODE_DEVELOPMENT } from 'v2/utils/constants';
import useUserQuery from './useUserQuery';
import useUserCompanyQuery from './useUserCompanyQuery';
import useCompaniesTypesQuery from './useCompaniesTypesQuery';
import useCompaniesQuery from './useCompaniesQuery';
import useLicensesQuery from './useLicensesQuery';

const canAddCompany = (companiesTypes, userCompany) => (companiesTypes || []).filter(({ rank }) => rank > userCompany?.rank).length > 0 && userCompany?.role === 'admin';

const canSwitchCompany = (companies) => (companies || []).filter(({ role }) => !!role).length > 1;

const canStorageAccess = (userCompany, user) => user?.id && userCompany?.id && true;

const canAddDemoLicense = (licenses) => !licenses?.items?.find((item) => item.trial);

const useUserActions = () => {
  const { user } = useUserQuery();
  const { userCompany } = useUserCompanyQuery();
  const { companiesTypes } = useCompaniesTypesQuery();
  const { companies } = useCompaniesQuery();
  const { licenses } = useLicensesQuery();

  const [result, setResult] = useState({})

  useEffect(() => {
    setResult({
      canAddCompany: canAddCompany(companiesTypes, userCompany),
      canSwitchCompany: canSwitchCompany(companies),
      canStorageAccess: canStorageAccess(userCompany, user),
      canAddDemoLicense: canAddDemoLicense(licenses),
      canExperimentalFeatures: MODE_DEVELOPMENT
    })
  }, [user, userCompany, companiesTypes, companies, licenses])

  return result
};

export default useUserActions;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import {
  Box, Button, Divider, Typography
} from '@material-ui/core';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import { useSnackbar } from 'notistack';
import SubscriptionButton from 'v2/components/SubscriptionButton';
import { handlers } from 'v2/redux/store';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { useDebounce } from '@uidotdev/usehooks';
import useCompleterNames from '../hooks/useCompleterNames';

const AdditionalInformation = ({
  id, parentId, type, name, notes, fetchObject, tags,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [nameField, setNameField] = useState(name || '');
  const debouncedName = useDebounce(nameField, 200);
  const { completerNames } = useCompleterNames(debouncedName, type, debouncedName);

  const isShowSubscriptionButton = type === 'face';

  const onFormChange = (data, { name, type }) => {
    if (name === 'name' && type === 'change') {
      setNameField(data.name);
    }
  };

  const [formScheme, setFormScheme] = useState(FORM_SCHEMES.ADDITIONAL_INFORMATION([], name, notes, tags));
  const form = useUIForm(formScheme, onFormChange, { keepDirty: true, keepErrors: true });
  const { formState: { isValid, isDirty } } = form;

  useEffect(() => {
    if (Array.isArray(completerNames)) {
      setFormScheme(FORM_SCHEMES.ADDITIONAL_INFORMATION(completerNames, form.getValues().name, form.getValues().notes, tags));
    }
  }, [completerNames]);

  const onSubmitClick = () => {
    const { tags = [], ...metadata } = form.getValues();

    showBackdrop('Saving new information');

    handlers.object.patch(id, { tags, metadata }).then(() => fetchObject())
      .then(() => enqueueSnackbar(
        'Saved',
        { variant: 'success', autoHideDuration: 3000 },
      ))
      .catch((err) => {
        console.error('some error occurred');
        console.error(err);
      })
      .finally(hideBackdrop);
  };

  return (
    <Box
      bgcolor="background.main"
      border={1}
      width={1}
      height={1}
      borderRadius="borderRadius"
      display="flex"
      flexDirection="column"
      borderColor="background.primaryBorder"
    >
      <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Typography>
          Additional information
        </Typography>
      </Box>
      <Divider />
      <Box
        width={1}
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        position="relative"
      >
        <UIForm methods={form} scheme={formScheme} />
      </Box>
      {/* <Box p={2} py={0}>
        {isShowSubscriptionButton && <SubscriptionButton cluster_id={parentId} name={name} />}
      </Box> */}
      <Box p={2} display="flex" alignItems="flex-end" height={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmitClick}
          disabled={!isValid || !isDirty}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default AdditionalInformation;

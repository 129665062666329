// @ts-nocheck

import React, { createContext, useContext } from 'react';

import { useGrid } from 'v2/providers/GridProvider';
import BasicDialog from 'v2/components/basic/BasicDialog';

import TestDialog from 'v2/dialogs/TestDialog';
import ExtendedPhoto from 'v2/dialogs/ExtendedPhoto';
import AddCompany from 'v2/dialogs/AddCompany';
import AddLicense from 'v2/dialogs/AddLicense';
import AddToken from 'v2/dialogs/AddToken';
import AddTag from 'v2/dialogs/AddTag';
import ShowToken from 'v2/dialogs/ShowToken';
import AddUser from 'v2/dialogs/AddUser';
import AddAvatar from 'v2/dialogs/AddAvatar';
import Login from 'v2/dialogs/Login';
import Confirmation from 'v2/dialogs/Confirmation';
import EditPassword from 'v2/dialogs/EditPassword';
import EditUser from 'v2/dialogs/EditUser';
import SwitchCompany from 'v2/dialogs/SwitchCompany';
import ClusterSubscription from 'v2/dialogs/ClusterSubscription';
import EditTimeZone from 'v2/dialogs/EditTimeZone';
import AddWatchListPredicate from 'v2/dialogs/AddWatchListPredicate';
import EditWatchListPredicate from 'v2/dialogs/EditWatchListPredicate';
import AddWatchLists from '../dialogs/AddWatchLists';
import EditWatchLists from '../dialogs/EditWatchLists';
import RegistrationDialog from '../dialogs/RegistrationDialog';

const Context = createContext({});

export const DIALOGS = {
  TEST_DIALOG: 'TEST_DIALOG',
  EXTENDED_PHOTO: 'EXTENDED_PHOTO',
  ADD_COMPANY: 'ADD_COMPANY',
  ADD_LICENSE: 'ADD_LICENSE',
  ADD_TOKEN: 'ADD_TOKEN',
  SHOW_TOKEN: 'SHOW_TOKEN',
  ADD_USER: 'ADD_USER',
  ADD_AVATAR: 'ADD_AVATAR',
  SWITCH_COMPANY: 'SWITCH_COMPANY',
  CONFIRMATION: 'CONFIRMATION',
  EDIT_USER: 'EDIT_USER',
  EDIT_PASSWORD: 'EDIT_PASSWORD',
  EDIT_TIMEZONE: 'EDIT_TIMEZONE',
  LOGIN: 'LOGIN',
  ADD_TAG: 'ADD_TAG',
  CLUSTER_SUBSCRIPTION: 'CLUSTER_SUBSCRIPTION',
  ADD_WATCH_LISTS_PREDICATE: 'ADD_WATCH_LISTS_PREDICATE',
  EDIT_WATCH_LISTS_PREDICATE: 'EDIT_WATCH_LISTS_PREDICATE',
  ADD_WATCH_LISTS: 'ADD_WATCH_LISTS',
  EDIT_WATCH_LISTS: 'EDIT_WATCH_LISTS',
  REGISTRATION_DIALOG: 'REGISTRATION_DIALOG',
};

export const COMPONENTS = {
  TEST_DIALOG: TestDialog,
  EXTENDED_PHOTO: ExtendedPhoto,
  ADD_COMPANY: AddCompany,
  ADD_LICENSE: AddLicense,
  ADD_TOKEN: AddToken,
  SHOW_TOKEN: ShowToken,
  ADD_USER: AddUser,
  ADD_AVATAR: AddAvatar,
  SWITCH_COMPANY: SwitchCompany,
  CONFIRMATION: Confirmation,
  ADD_TAG: AddTag,
  EDIT_USER: EditUser,
  EDIT_PASSWORD: EditPassword,
  EDIT_TIMEZONE: EditTimeZone,
  LOGIN: Login,
  CLUSTER_SUBSCRIPTION: ClusterSubscription,
  ADD_WATCH_LISTS_PREDICATE: AddWatchListPredicate,
  EDIT_WATCH_LISTS_PREDICATE: EditWatchListPredicate,
  ADD_WATCH_LISTS: AddWatchLists,
  EDIT_WATCH_LISTS: EditWatchLists,
  REGISTRATION_DIALOG: RegistrationDialog,
};

export const DIALOG_PROPS = {
  [DIALOGS.MATERIAL_ICONS]: {
    maxWidth: 'xl',
  },
  [DIALOGS.EXTENDED_PHOTO]: {
    V2: true,
  },
  [DIALOGS.ADD_LICENSE]: {
    V2: true,
  },
  [DIALOGS.ADD_COMPANY]: {
    V2: true,
  },
  [DIALOGS.ADD_TOKEN]: {
    V2: true,
  },
  [DIALOGS.SHOW_TOKEN]: {
    V2: true,
  },
  [DIALOGS.ADD_USER]: {
    V2: true,
  },
  [DIALOGS.CLUSTER_SUBSCRIPTION]: {
    V2: true,
  },
  [DIALOGS.CONFIRMATION]: {
    V2: true,
  },
  [DIALOGS.ADD_WATCH_LISTS_PREDICATE]: {
    V2: true,
  },
  [DIALOGS.EDIT_WATCH_LISTS_PREDICATE]: {
    V2: true,
  },
  [DIALOGS.ADD_WATCH_LISTS]: {
    V2: true,
  },
  [DIALOGS.EDIT_WATCH_LISTS]: {
    V2: true,
  },
  [DIALOGS.REGISTRATION_DIALOG]: {
    V2: true,
  }
};

export const DialogProvider = (props) => {
  const { lockGrid, unlockGrid } = useGrid();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();

  const showDialog = (DialogType, initialData) => {
    lockGrid();
    setData({
      Component: COMPONENTS[DialogType],
      dialogProps: DIALOG_PROPS[DialogType] || {},
      initialData,
    });
    setOpen(true);
  };

  const hideDialog = () => {
    setOpen(false);
    unlockGrid();
  };

  const { Component, initialData, dialogProps } = data || {};

  return (
    <Context.Provider value={{
      open,
      showDialog,
      hideDialog,
    }}
    >
      {props.children}
      {Component && <BasicDialog Component={Component} initialData={initialData} dialogProps={dialogProps} />}
    </Context.Provider>
  );
};

export const useDialog = () => useContext(Context);

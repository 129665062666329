// @ts-nocheck
import React, { forwardRef } from 'react';
import {
  DraggableTooltip, TooltipContent, TooltipTrigger
} from 'v2/components/basic/BasicPopoverNew';

const Handler = forwardRef(({
  min, max, handle, getHandleProps
}, ref) => (
  <div
    ref={ref}
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={handle.value}
    className="handle"
    style={{
      left: `${handle.percent}%`,
    }}
    {...getHandleProps(handle.id)}
  />
))

const Handle = ({
  range, handle, getHandleProps, isDragging, showTooltip
}) => {
  const [min, max] = range;

  return showTooltip ? (
    <DraggableTooltip open={isDragging}>
      <TooltipTrigger>
        <Handler min={min} max={max} handle={handle} getHandleProps={getHandleProps} />
      </TooltipTrigger>
      <TooltipContent arrow>
        <div className="p-2">
          {Math.trunc(handle.value)}
        </div>
      </TooltipContent>
    </DraggableTooltip>
  ) : (
    <Handler min={min} max={max} handle={handle} getHandleProps={getHandleProps} />
  )
}

export default Handle;

// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import api from 'app/api';
import { CHART_REFRESH_TIMEOUT, EXCLUDED_KEYS } from 'utils/constants';
import moment from 'moment-timezone';
import {
  getCurrentDetalizationOptions,
  getDateRange,
  getDefaultActiveDetalization,
  getDetalizationByValue,
} from 'utils/chart';
import ms from 'ms';
import ToggleDatePicker from '../../../components/Dashboard/ToggleDatePicker';
import { parseStringFilters } from '../../../components/Dashboard/DashboardChart';
import { yellowToDateRangeMap } from '../../../utils/yelloMapping';

import './Values.scss';
import UITextWidget from '../../ui-kit/UITextWidget/UITextWidget';

const ValuesWidget = ({
  selectedIds,
  filtersObject,
  setFiltersObject,
  id,
  onChangeRealtime,
  updateLayout,
  state,
  setIsFetching,
  setLastUpdateTime,
}) => {
  const [content, setContent] = useState();
  const showTimeSlice = state.hasOwnProperty('showTimeSlice') ? state.showTimeSlice : true;

  const [detalizationOptionsList, setDetalizationOptionsList] = useState([]);
  const [activeDetalization, setActiveDetalization] = useState<number>();

  const getDetalizationOptions = (filtersObject) => {
    if (filtersObject.timeslice !== undefined && filtersObject.timeslice !== '') {
      const startAt = moment(moment().valueOf() - ms(filtersObject.timeslice as string)).unix();
      const endAt = moment().unix();

      setFilter({
        ...filter,
        dateRange: { dateFrom: startAt, dateTo: endAt },
      });

      return getCurrentDetalizationOptions(startAt, endAt);
    }
    if (filtersObject.dateRange !== undefined) {
      if (filtersObject.dateRange.dateFrom === undefined && filtersObject.dateRange.dateTo === undefined) {
        filtersObject.dateRange = JSON.parse(filtersObject.dateRange);
      }

      return getCurrentDetalizationOptions(filtersObject.dateRange.dateFrom, filtersObject.dateRange.dateTo);
    }

    const startAt = moment().add(-12, 'hours').unix();
    const endAt = moment().unix();
    setFilter({
      ...filter,
      dateRange: { dateFrom: startAt, dateTo: endAt },
    });

    return getCurrentDetalizationOptions(startAt, endAt);
  };

  const type = filtersObject.object_type;
  const defaultLocations = filtersObject.selectedIds ? filtersObject.selectedIds.locations : [];
  const defaultCameras = filtersObject.selectedIds ? filtersObject.selectedIds.cameras : [];
  const [filter, setFilter] = useState({
    selectedIds:
      {
        locations: defaultLocations,
        cameras: defaultCameras,
      },
    timeslice: filtersObject.timeslice !== undefined ? filtersObject.timeslice : '12h',
    detalizationOptionsList: filtersObject.detalizationOptionsList,
    detalization: filtersObject.detalization,
    dateRange: typeof (filtersObject.dateRange) === 'string' ? JSON.parse(filtersObject.dateRange) : filtersObject.dateRange,
  });

  useEffect(() => {
    const detalizationsList = getDetalizationOptions(filtersObject);
    if (detalizationsList) {
      const activeValue = getDefaultActiveDetalization(detalizationsList, filtersObject);
      setActiveDetalization(activeValue);
      setDetalizationOptionsList(detalizationsList);
    }
  }, []);

  const parseFilter = ({ selectedIds, timeslice, dateRange }: ChartFilter) => {
    let selectedIdsFilter = '';
    Array.isArray(selectedIds.locations) && (selectedIdsFilter += selectedIds.locations.reduce((prev, next) => `${prev}&filters=location:${next}`, ''));
    Array.isArray(selectedIds.cameras) && (selectedIdsFilter += selectedIds.cameras.reduce((prev, next) => `${prev}&filters=camera:${next}`, ''));
    let startAt;
    let endAt;
    let timestampsFilter;

    if (dateRange) {
      startAt = dateRange.dateFrom;
      endAt = dateRange.dateTo;
      timestampsFilter = `&filters=timestamp_start:${startAt}&filters=timestamp_end:${endAt}`;
    } else if (timeslice !== undefined && timeslice !== '') {
      startAt = Math.round((Date.now() - ms(timeslice)) / 1000);
      endAt = Math.round(Date.now() / 1000);
      timestampsFilter = `&filters=timestamp_start:${startAt}&filters=timestamp_end:${endAt}`;
    }

    const filtersObjectString = Object.entries(filtersObject).reduce((acc, [key, value]) => {
      if (value && !EXCLUDED_KEYS.includes(key)) {
        if (key === 'person_age' && value === '1-99') {
          return acc;
        }
        return `${acc}&filters=${key}:${String(value)}`;
      }

      return acc;
    }, '');

    return selectedIdsFilter + timestampsFilter + filtersObjectString;
  };

  const updateWidgetFilters = (
    ids,
    dateRange,
    detalizationValue,
    detalizationList,
    chartDateRange,
  ) => {
    const detalizations = detalizationList && Object.keys(detalizationList).map((i) => JSON.stringify(detalizationList[parseInt(i, 10)])).join('|');

    const filtersValue = [`object_type:${type}`,
      `locations: ${ids?.locations?.join(',')}`,
      `cameras: ${ids?.cameras?.join(',')}`,
      `timeslice:${dateRange}`,
      `detalization:${detalizationValue}`,
      `detalizationOptionsList:${detalizations}`,
      `dateRange:${JSON.stringify(chartDateRange)}`,
    ];

    Object.entries(filtersObject).reduce((acc, [key, value]) => {
      if (value && !EXCLUDED_KEYS.includes(key)) {
        acc.push(`${key}:${String(value)}`);
      }

      return acc;
    }, filtersValue);

    api.patch(`/layout-manager/widgets/${id}`, {
      filters: filtersValue,
    });

    setFiltersObject && setFiltersObject(parseStringFilters(filtersValue));
    updateLayout && updateLayout();
  };

  useEffect(() => {
    if (detalizationOptionsList !== undefined && detalizationOptionsList.length > 0) {
      const activeValue = getDefaultActiveDetalization(detalizationOptionsList, filtersObject);

      setActiveDetalization(activeValue);
      filter.detalization = activeValue;
      filter.detalizationOptionsList = detalizationOptionsList;
    }
  }, [detalizationOptionsList, filtersObject]);

  useEffect(() => {
    if (Array.isArray(filter.detalizationOptionsList) && filter.dateRange) {
      const newFilter = {
        ...filter,
        selectedIds,
      };

      setFilter(newFilter);
      updateWidgetFilters(
        selectedIds,
        filter.timeslice,
        filter.detalization,
        filter.detalizationOptionsList,
        filter.dateRange,
      );
    }
  }, [selectedIds]);

  const handleTimeRangeChange = (dateRange: string) => {
    const newFilter = {
      ...filter,
      timeslice: dateRange,
    };
    const startAt = Math.round((Date.now() - ms(dateRange as string)) / 1000);
    const endAt = Math.round(Date.now() / 1000);
    const detalizationsList = getDetalizationOptions(newFilter);
    let activeValue;

    if (detalizationsList) {
      setDetalizationOptionsList(detalizationsList);
      activeValue = getDefaultActiveDetalization(detalizationsList, filtersObject);

      newFilter.dateRange = { dateFrom: startAt, dateTo: endAt };
      newFilter.detalizationOptionsList = detalizationsList;
      newFilter.detalization = activeValue;
    }
    setFilter(newFilter);
    setActiveDetalization(activeValue);

    // getChartData(type, parseFilter(newFilter), newFilter.detalization);
    if (detalizationsList && activeValue !== undefined) {
      updateWidgetFilters(
        filter.selectedIds,
        dateRange,
        activeValue,
        detalizationsList,
        { dateFrom: startAt, dateTo: endAt },
      );
    } else {
      updateWidgetFilters(
        filter.selectedIds,
        dateRange,
        filter.detalization,
        filter.detalizationOptionsList,
        filter.dateRange,
      );
    }
  };

  const handleDateRangeChange = (dateRangeIn: [any, any]) => {
    const dateRange: ChartDateRange = yellowToDateRangeMap(dateRangeIn);

    if (dateRange.dateTo !== filter.dateRange.dateTo && onChangeRealtime) {
      onChangeRealtime(false);
    }

    let newFilter = {
      ...filter,
      timeslice: '',
      detalization: null,
      dateRange: { dateFrom: dateRange.dateFrom, dateTo: dateRange.dateTo },
    };
    var detalizationsList = getDetalizationOptions(newFilter);
    if (detalizationsList) {
      setDetalizationOptionsList(detalizationsList);
      newFilter = {
        ...newFilter,
        detalizationOptionsList: detalizationsList,
      };
    }
    setFilter(newFilter);

    updateWidgetFilters(
      newFilter.selectedIds,
      '',
      newFilter.detalization,
      newFilter.detalizationOptionsList,
      newFilter.dateRange,
    );
  };

  const handleDetalizationChange = (detalizationValue: number) => {
    if (!detalizationValue) return;

    const newFilter = {
      ...filter,
      detalization: detalizationValue,
    };
    setFilter(newFilter);

    const newDetalization = getDetalizationByValue(detalizationValue, detalizationOptionsList);
    const detalizationsList = getDetalizationOptions(newFilter);

    if (newDetalization && detalizationsList) {
      setActiveDetalization(newDetalization.value);
      updateWidgetFilters(
        filter.selectedIds,
        filter.timeslice,
        newDetalization.value,
        detalizationsList,
        filter.dateRange,
      );
    }
  };

  const updateValueWidget = () => {
    setIsFetching && setIsFetching(true);
    const url = `/chartilla/charts?chart_type=numeric&filters=object_type:${type}${parseFilter(filter)}&pgsize=0`;
    api.get(url).then((response) => {
      setContent(response.data?.series?.toLocaleString('en'));
      setLastUpdateTime && setLastUpdateTime(moment(new Date().getTime()).format('MM/DD/YYYY HH:mm:ss'));
    }).finally(() => {
      setIsFetching && setIsFetching();
    })
  };

  const stateRef = useRef();
  stateRef.current = filter;

  useEffect(() => {
    updateValueWidget();
    const intervalId = setInterval(
      () => {
        const filter = stateRef.current;
        if (filter.timeslice) {
          filter.dateRange = getDateRange(filter.timeslice);
        }
        updateValueWidget();
      },
      CHART_REFRESH_TIMEOUT,
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [filter, filtersObject, activeDetalization]);

  // const nodeRef = useRef();

  // useEffect(() => {
  //   if (nodeRef?.current) {
  //     const bbox = nodeRef.current.getBBox();
  //     nodeRef.current.setAttribute('viewBox', `0 0 ${bbox.width} ${bbox.height}`)
  //   }
  // }, [nodeRef, content]);

  if (!content) {
    return (
      <Box
        p={2}
        width={1}
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        p={2}
        width={1}
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <UITextWidget content={content} />
      </Box>
      {showTimeSlice && (
        <ToggleDatePicker
          onTimeRangeChange={handleTimeRangeChange}
          timeRangeProp={filter.timeslice}
          dateRangeProp={filter.dateRange}
          onDateRangeChange={handleDateRangeChange}
          detalizationOptionsList={detalizationOptionsList}
          activeDetalization={activeDetalization}
          onDetalizationChange={handleDetalizationChange}
        />
      )}
    </>
  );
};

export default ValuesWidget;

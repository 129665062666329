// @ts-nocheck

import React from 'react';
import {
  Box, Button, IconButton, Link, Typography,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import {
  Add, ArrowForwardIos, Delete, Edit,
} from '@material-ui/icons';
import BasicTable from 'v2/components/basic/BasicTable';
import BasicWidget from 'v2/components/basic/BasicWidget';
import { ROUTES } from 'v2/utils/routes';
import { TABLE } from 'v2/utils/constants';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import useWatchListsQuery from '../../hooks/queries/useWatchListsQuery';

const headCells = () => [
  {
    id: 'name',
    label: 'Watch List Name',
  },
  {
    id: 'object_type',
    label: 'Object Type',
    align: 'center',
  },
  {
    id: 'action',
    label: '',
    align: 'center',
  },
];

const WatchListsPage = () => {
  const dialog = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { watchListsQuery, deleteWatchLists } = useWatchListsQuery();

  const rightActions = [{
    title: 'Add Watch List',
    type: TABLE.TOOLBAR.BUTTONS.TYPE,
    Icon: Add,
    visible: watchListsQuery.data?.length,
    onClick: () => dialog.showDialog(DIALOGS.ADD_WATCH_LISTS),
  }];

  const rowActions = (item) => (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box>
        <IconButton onClick={() => dialog.showDialog(DIALOGS.EDIT_WATCH_LISTS, { item })}>
          <Edit />
        </IconButton>
      </Box>
      <Box ml={4}>
        <IconButton onClick={() => dialog.showDialog(DIALOGS.CONFIRMATION, {
          title: 'Delete Watch List',
          text: `Do you really want to delete watch list "${item.name}" ?`,
          onConfirmation: (hideDialog) => {
            showBackdrop();
            deleteWatchLists.mutateAsync(item.id).then(() => {
              hideDialog();
            }).finally(() => {
              hideBackdrop();
            });
          },
        })}
        >
          <Delete />
        </IconButton>
      </Box>
      <Box ml={4}>
        <Link component={NavLink} to={ROUTES.NOTIFICATIONS.ROUTES.WATCH_LISTS_DETAILS.url(item.id)}>
          <IconButton>
            <ArrowForwardIos />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );

  const EmptyTableComponent = () => (
    <Box p={6}>
      <Typography align="center" variant="h6">
        Currently you do not have added any Watch Lists
      </Typography>
      <Typography align="center" variant="h6">
        Watch Lists are used to monitor and track specific objects with predefined filters
      </Typography>
      <Box mt={4} textAlign="center">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => dialog.showDialog(DIALOGS.ADD_WATCH_LISTS)}
        >
          Add Watch List
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box p={2} width={1}>
      <BasicWidget
        title="Watch Lists"
        rightActions={rightActions}
      >
        {
          watchListsQuery.isFetched && (
            <BasicTable
              storageKey="watchListsPageSize"
              columns={headCells()}
              rows={watchListsQuery.data}
              rowActions={rowActions}
              EmptyTableComponent={EmptyTableComponent}
            />
          )
        }
      </BasicWidget>
    </Box>
  );
};

export default WatchListsPage;

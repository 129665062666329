// @ts-nocheck

import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Tooltip } from '@material-ui/core';
import { mergeRules } from 'v2/utils/functions';
import TagsComboInput from './TagsComboInput';

const TagsAutocomplete = (props) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={mergeRules(props.rules)}
    defaultValue={props.tags || []}
    render={(controller) => (
      <>
        <Tooltip arrow {...props} title={props.tooltip ? props.tooltip : ''} enterDelay={1000} enterNextDelay={500}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label={props.label}
            error={!!controller.formState?.errors[props.name]?.message}
            helperText={controller.formState?.errors[props.name]?.message}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              items: props.options,
              className: 'UIOutlinedTextCombo',
              inputProps: {
                ...props,
                ...controller,
              },
              inputComponent: TagsComboInput
            }}
          />
        </Tooltip>
      </>
    )}
  />
)

export default TagsAutocomplete

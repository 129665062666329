// @ts-nocheck

import React, { useState } from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  withStyles, createStyles, Box,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { v4 } from 'uuid';
import clsx from 'clsx'

import { vars } from 'Theme';
import UIHighLightText from 'v2/ui-kit/UIHighLightText/UIHighLightText';
import useLocationInput from './useLocationInputHook';
import {
  emptyElement, hideElems, locationDragHandler, setLocationElName, showElems
} from './draggableUtils';
import Camera from './Camera';
import AddLocation from './AddLocation';
import BlankItem from './BlankItem';

const styles = (theme) => createStyles({
  root: {
    '& .MuiTreeItem-iconContainer': {
      width: 'unset',
      marginRight: 'unset',
      '& svg': {
        // fontSize: 24,
      },
    },
    '& .MuiTreeItem-label': {
      padding: 0,
      overflow: 'hidden',
      backgroundColor: 'unset',
    },
  },
  treeItem: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.input,
    borderRadius: theme.shape.borderRadius,
    marginBottom: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(2)}px`,
    width: '100%',
    border: `1px solid ${theme.palette.background.inputBorder}`
    // '&:hover': {
    //   background: 'rgb(55 55 55 / 20%)',
    // },
  },
  treeItemDragOver: {
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      color: vars.white,
    },
  },
  treeItemLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  treeItemRight: {
    display: 'flex',
    alignItems: 'center',
  },
  treeItemIcon: {
    // margin: '19px',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    // height: '56px',
    // paddingRight: '20px',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    // paddingLeft: '1rem',
    pointerEvents: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  treeItemLabel: {
    '&:hover': {
      background: 'none',
    },
  },
  treeItemSelected: {
    background: 'unset',
  },
  iconButton: {
    // color: vars.primary,
    // padding: '.5rem',
  },
  icon: {
    // color: vars.primary,
    // padding: '0',
  },
  wrapperName: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
});

const Location = ({
  handleItemSelect,
  dropEmitter,
  selectedLocationId,
  model,
  locations,
  addItem,
  deleteItem,
  toggleItem,
  isRootNode,
  classes,
  newLocationId,
  setNewLocationId,
  setMobileModalOpen,
  handleArchiveCamera,
  search,
}) => {
  const { name, cameras } = model;

  const {
    isNewLocationVisible,
    toggleNewLocation,
    closeNewLocation,
    locationName,
    setLocationName,
  } = useLocationInput();

  const isChild = (child, locations) => {
    if (locations.length === 0) {
      return false;
    }
    for (const loc of locations) {
      if (child.id === loc.id) {
        return true;
      } if (isChild(child, loc.child_locations)) {
        return true;
      }
    }
    return false;
  };

  const min600 = useMediaQuery('(max-width:960px)');

  const handleDrop = (e) => {
    setItemOver(false);
    hideElems();
    e.stopPropagation();
    e.preventDefault();
    const { type, dragItem, isAllocated } = JSON.parse(e.dataTransfer.getData('application/json'));
    if (type === 'location' && dragItem.id === model.id) {
      return;
    }
    if (type === 'location' && isChild(model, dragItem.child_locations)) {
      return;
    }
    if (type === 'camera' && cameras.find((cam) => cam.id === dragItem.id)) {
      return;
    }
    // dropEmitter.emit('item-drop', type, dragItem, isAllocated);
    dropEmitter.emit('item-drop', type, dragItem);

    addItem(model.id, dragItem.name, dragItem, type);
  };

  const handleDragStart = (e) => {
    e.stopPropagation();
    setLocationElName(model.name);
    showElems();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(emptyElement, 0, 0);
    e.dataTransfer.setData('application/json', JSON.stringify({ type: 'location', dragItem: model }));
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleAddLocation = () => {
    addItem(model.id, locationName, undefined, 'location');
    setLocationName('');
    closeNewLocation();
  };

  const handleToggleAddLocation = (e) => {
    e.stopPropagation();
    setNewLocationId(model.id);
    toggleItem(model.id);
    toggleNewLocation();
  };

  const handleMobileModalOpen = (e) => {
    e.stopPropagation();
    setMobileModalOpen(true);
  };

  const handleTreeItemClick = () => {
    handleItemSelect(model)
  };

  dropEmitter.once('item-drop', (type, dragItem, isAllocated) => {
    setItemOver(false);
    switch (type) {
      case 'camera':
        if (!model.cameras.find((cam) => cam.id === dragItem.id)) {
          return;
        }
        if (isAllocated) {
          deleteItem(dragItem.id, type, model.id);
        }
        break;
      case 'location':
        // deleteItem(dragItem.id, type, '', false);
        // закоментировал, чтобы не удалял верхнеуровневую локацию при попытке перемещения в другую локацию
        break;
    }
  });

  const [itemOver, setItemOver] = useState(false);

  const handleDragEnter = () => {
    if (!itemOver) {
      dropEmitter.emit('item-enter', model.id);
    }
  };

  const handleDragLeave = () => {
    if (itemOver) {
      dropEmitter.emit('item-leave', model.id);
    }
  };

  dropEmitter.on('item-enter', (id) => {
    if (model.id === id) {
      setItemOver(true);
    }
  });

  dropEmitter.on('item-leave', (id) => {
    if (itemOver && model.id === id) {
      setItemOver(false);
    }
  });

  return (
    <TreeItem
      nodeId={model.id}
      draggable={isRootNode}
      // draggable={!isRootNode}
      onDragStart={handleDragStart}
      onDrag={locationDragHandler}
      onDragEnd={hideElems}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      label={(
        <Box width={1} display="flex" alignItems="center">
          <Box mr={1} display="flex" alignItems="center">
            <DragIndicatorIcon color="primary" />
          </Box>
          <Box mr={1} flexGrow={1} display="flex" alignItems="center" overflow="hidden">
            <UIHighLightText searchText={search} text={name} />
          </Box>
          <Box mr={1} display="flex" alignItems="center">
            <IconButton color="primary" onClick={handleToggleAddLocation}>
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      onClick={handleTreeItemClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      classes={{
        root: classes.root,
        content: clsx(classes.treeItem, { [classes.treeItemDragOver]: itemOver }),
        label: classes.treeItemLabel,
        selected: classes.treeItemSelected,
      }}
    >
      <AddLocation
        locationId={model.id}
        newLocationId={newLocationId}
        visible={isNewLocationVisible}
        locationName={locationName}
        handleLocationNameInput={(e) => setLocationName(e.target.value)}
        addLocationClick={handleAddLocation}
        handleClose={closeNewLocation}
      />
      {locations
        && locations.map((location) => (
          <Location
            newLocationId={newLocationId}
            setNewLocationId={setNewLocationId}
            selectedLocationId={selectedLocationId}
            model={location}
            addItem={addItem}
            deleteItem={deleteItem}
            handleItemSelect={handleItemSelect}
            dropEmitter={dropEmitter}
            locations={location.child_locations}
            toggleItem={toggleItem}
            classes={classes}
            isRootNode
            key={location.id || v4()}
            setMobileModalOpen={setMobileModalOpen}
            handleArchiveCamera={handleArchiveCamera}
            search={search}
          />
        ))}
      {cameras
        && cameras.map((camera) => (
          <Camera
            key={camera.id}
            model={camera}
            parentId={model.id}
            handleCameraSelect={handleItemSelect}
            handleCameraDelete={deleteItem}
            setMobileModalOpen={setMobileModalOpen}
            handleArchiveCamera={handleArchiveCamera}
            search={search}
          />
        ))}
      {itemOver && <BlankItem />}
    </TreeItem>
  );
};

export default withStyles(styles)(Location);

// @ts-nocheck
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getProperty, setProperty } from 'dot-prop';
import api from 'app/api';
import { QUERY_STALE_TIME } from 'v2/constants/constQueries';
import useBasicQuery from './useBasicQuery';
import useUserQuery from './useUserQuery';

export const QUERY_KEYS = ['user_state'];

const useUserStateQuery = () => {
  const queryClient = useQueryClient();
  const { user } = useUserQuery();

  const userStateQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.get('/auth-manager/v1/users/state');
      return result.data;
    },
    enabled: Boolean(user),
    staleTime: QUERY_STALE_TIME,
  });

  const userStateUpdate = useMutation({
    mutationFn: (state) => api.post('/auth-manager/v1/users/state', {
      state: {
        ...userStateQuery.data,
        ...state,
      },
    }),
    onMutate: async (next) => {
      await queryClient.cancelQueries(QUERY_KEYS);
      const previousData = queryClient.getQueryData(QUERY_KEYS);

      queryClient.setQueryData(QUERY_KEYS, (prev) => ({ ...prev, ...next }));

      return { previousData };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(QUERY_KEYS, context.previousData);
    },
  });

  return {
    settings: userStateQuery.data,
    getUserState: (path, defaultValue) => getProperty(userStateQuery.data, path, defaultValue),
    setUserState: (path, value) => {
      setProperty(userStateQuery.data, path, value);
      return userStateUpdate.mutateAsync(getProperty(userStateQuery.data, path));
    },
    toggleUserState: (path, defaultValue) => {
      setProperty(userStateQuery.data, path, !getProperty(userStateQuery.data, path, defaultValue));
      return userStateUpdate.mutateAsync(getProperty(userStateQuery.data, path));
    },
    userStateQuery,
  };
};

export default useUserStateQuery;

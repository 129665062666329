// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { handlers } from 'v2/redux/store';
import SearchField from 'v2/components/SearchField';
import CompaniesList from 'v2/components/CompaniesList';

export default function SwitchCompany(props) {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [valueSearch, setValueSearch] = useState('');

  const companiesTypes = useSelector((state) => state.companiesTypes.data);
  const usersTypes = useSelector((state) => state.usersTypes.data);
  const userCompany = useSelector((state) => state.userCompany.data);
  const companies = useSelector((state) => state.companies.data);
  const user = useSelector((state) => state.user.data);

  const {
    DialogTitle,
    DialogBody,
    login,
    redirectURL,
    showClose,
  } = props;

  const availableCompanies = companies
    .filter((company) => company.role !== null && company.id !== userCompany.id)
    .map((company) => ({
      ...company,
      type: companiesTypes.find((item) => item.value === company.company_type),
      role: usersTypes.find((item) => item.id === company.role),
    }))
    .filter((company) => (valueSearch.length > 0
      ? company.name.toLocaleLowerCase().includes(valueSearch.toLocaleLowerCase())
          || company.type.name.toLocaleLowerCase().includes(valueSearch.toLocaleLowerCase())
          || company.role.name.toLocaleLowerCase().includes(valueSearch.toLocaleLowerCase())
      : true))

  const activeCompany = companies
    .filter((company) => company.id === userCompany.id)
    .map((company) => ({
      ...company,
      type: companiesTypes.find((item) => item.value === userCompany.company_type),
      role: usersTypes.find((item) => item.id === user.role),
    }));

  const onSwitchCompany = ({ id, name }) => {
    showBackdrop(`Switching to ${name}`);
    handlers.user.switchCompany(id, redirectURL).catch(hideBackdrop);
  }

  useEffect(() => {
    showBackdrop('Loading companies');

    if (login) {
      Promise.all([
        handlers.companies.types(),
        handlers.user.types(),
        handlers.companies.fetch(),
      ]).then(hideBackdrop)
    } else {
      Promise.all([
        handlers.companies.types(),
        handlers.user.types(),
        handlers.user.company(),
        handlers.companies.fetch(),
        handlers.user.fetch(),
      ]).then(hideBackdrop)
    }
  }, [])

  const Content = (
    <Box width={1} height={1} display="flex" flexDirection="column" overflow="hidden">
      {activeCompany.length > 0 && (
        <>
          <Box pl={2} pr={2} height={1} width={1} display="flex" flexDirection="column">
            <CompaniesList
              title="Current company"
              items={activeCompany}
              selected
            />
          </Box>
          <Divider />
        </>
      )}
      <Box pl={2} pr={2} height={1} width={1} display="flex" flexDirection="column">
        <CompaniesList
          title="Available companies"
          items={availableCompanies}
          onClick={onSwitchCompany}
          button
        />
      </Box>
    </Box>
  );

  const Logo = () => <div className="UILogo" />

  return (
    <>
      <DialogTitle title="Choose company" child={!showClose && Logo} hasCloseIcon={showClose}>
        <Divider />
        <Box p={2}>
          <SearchField onChange={setValueSearch} />
        </Box>
      </DialogTitle>
      <DialogBody className="UIPaddingLess">
        {Content}
      </DialogBody>
    </>
  )
}

// @ts-nocheck

import ReportProblemTwoToneIcon from '@material-ui/icons/ReportProblemTwoTone';
import React from 'react';
import { UIWidgetPlaceholder, UIWidgetPlaceholderContent } from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';

const DataNotFound = (props) => (
  <UIWidgetPlaceholder>
    <UIWidgetPlaceholderContent
      icon={ReportProblemTwoToneIcon}
      title={props.title || 'No results found'}
      subTitle={props.subtitle || 'We could not find what you searched for.\nPlease adjust the filters and try again.'}
      {...props}
    />
  </UIWidgetPlaceholder>
);

export default DataNotFound;

// @ts-nocheck

const SCHEME = {
  name: 'New widget',
}

/**
 * @typedef {Object} IFiltersBehavior
 * @property {function} fetchForWidget
 */

/**
 * Кейсы сценариев виджетов
 * @param {{widgets: IWidgetsAdapter}} source Адаптеры данных
 * @param {Object} externalHandlers - Внешние хэндлеры
 * @return {IFiltersBehavior}
 */

export default ({ filters }, externalHandlers) => ({
  fetchForWidget: (objectType, widgetType, payload) => filters.fetchForWidget(objectType, widgetType, payload),
});

// @ts-nocheck

import React from 'react';

function UIHelpImage({ src }) {
  return (
    <img src={src} />
  )
}

export default UIHelpImage;

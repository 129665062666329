// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import './UIText.scss';

const UITextWidget = ({ content, className }) => {
  const nodeRef = useRef();

  useEffect(() => {
    if (nodeRef?.current) {
      const bbox = nodeRef.current.getBBox();
      nodeRef.current.setAttribute('viewBox', `0 0 ${bbox.width} ${bbox.height}`)
    }
  }, [nodeRef, content]);

  return (
    <svg className={clsx('UITextWidget', className)} ref={nodeRef}>
      <text>
        {content}
      </text>
    </svg>
  )
}

export default UITextWidget;

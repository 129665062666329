// @ts-nocheck

import React, { useEffect, useState } from 'react';
import useAdvancedSearchResultsQuery from 'v2/hooks/queries/useAdvancedSearchResultsQuery';
import useMobile from 'v2/hooks/useMobile';
import { SETTINGS } from 'v2/constants/userState';
import { getSearchDefaultFilters } from 'v2/hooks/queries/useAdvancedSearchFiltersQuery';
import Layout from '../../../partials/Layout';
import useUserSettingsQuery from '../../hooks/queries/useUserStateQuery';
import AdvancedSearchResult from '../../widgets/AdvancedSearchResult';
import AdvancedSearchPanel from './AdvancedSearchPanel';

const AdvancedSearchPage = () => {
  const {
    setFilters,
    setObjectType,
    filtersQuery,
    objectTypeQuery,
    resultsQuery,
  } = useAdvancedSearchResultsQuery();

  const [actualFilters, setActualFilters] = useState();
  const [actualObjectType, setActualObjectType] = useState();
  const { isMobile } = useMobile();
  const { getUserState, toggleUserState } = useUserSettingsQuery();

  const isPanelOpen = getUserState(SETTINGS.LEFT_PANEL_OPEN, true);
  const isShowSearchFiltersPanel = isPanelOpen && !isMobile && actualObjectType;

  const onSearchClick = () => {
    resultsQuery.remove();
    setFilters([actualObjectType, actualFilters]);
    setObjectType(actualObjectType);
  };

  const onClearClick = () => {
    setActualFilters(getSearchDefaultFilters(actualObjectType));
  };

  const onFetchNextPageClick = () => {
    !resultsQuery.isFetching && resultsQuery.fetchNextPage();
  };

  const onClickOpenPanel = () => {
    toggleUserState(SETTINGS.LEFT_PANEL_OPEN, true).catch();
  };

  useEffect(() => {
    objectTypeQuery.isFetched && setActualObjectType(objectTypeQuery.data);
  }, [objectTypeQuery.data]);

  useEffect(() => {
    actualObjectType && filtersQuery.isFetched && setActualFilters(filtersQuery.data[actualObjectType]);
  }, [actualObjectType]);

  useEffect(() => {
    actualObjectType && filtersQuery.isFetched && setActualFilters(filtersQuery.data[actualObjectType]);
  }, [actualObjectType, filtersQuery.data]);

  return (
    <Layout hasSearchButton>
      <div className="h-full p-2 flex">
        {isShowSearchFiltersPanel && (
          <AdvancedSearchPanel
            filters={actualFilters}
            setFilters={setActualFilters}
            objectType={actualObjectType}
            setObjectType={setActualObjectType}
            onSubmit={onSearchClick}
            onClear={onClearClick}
            isFetching={resultsQuery.isFetching}
            onClickOpenPanel={onClickOpenPanel}
          />
        )}
        <AdvancedSearchResult
          items={resultsQuery.data}
          error={resultsQuery.error}
          isError={resultsQuery.isError}
          isFetching={resultsQuery.isFetching}
          isHasNextPage={resultsQuery.hasNextPage}
          fetchNextPage={onFetchNextPageClick}
          isPanelOpen={isPanelOpen}
          onClickOpenPanel={onClickOpenPanel}
        />
      </div>
    </Layout>
  );
};

export default AdvancedSearchPage;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import { useBackdrop } from '../providers/BackdropProvider';
import useWatchListsQuery from '../hooks/queries/useWatchListsQuery';
import UISectionMessage from '../ui-kit/UISectionMessage/UISectionMessage';

const EditWatchLists = (props) => {
  const { item } = props;
  const { editWatchLists } = useWatchListsQuery();
  const [formScheme, setFormScheme] = useState();
  const form = useUIForm(formScheme);
  const { showDialog, hideDialog } = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { formState: { isValid, isDirty } } = form;

  useEffect(() => {
    setFormScheme(FORM_SCHEMES.WATCH_LISTS_EDIT(item));
  }, [item]);

  const showInformation = () => {
    showDialog(DIALOGS.CONFIRMATION, {
      hasHeader: true,
      content: (
        <div className="p-2 pb-8">
          <UISectionMessage type="success">
            Watch List name has been updated
          </UISectionMessage>
        </div>
      ),
      hasButtons: false,
      onConfirmation: (hideDialog) => {
        hideDialog();
      },
    });
  }

  return (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>{`Edit Watch List "${item.name}"`}</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <UIForm methods={form} scheme={formScheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          Cancel
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid || !isDirty}
          onClick={() => {
            showBackdrop();
            editWatchLists.mutateAsync([item.id, form.getValues()]).then(() => {
              showInformation();
            }).finally(() => {
              hideBackdrop();
            });
          }}
        >
          Submit
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default EditWatchLists;

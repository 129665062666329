// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';
import { FETCH_STATE } from '../../utils/constants';

const licenses = DataReducer({
  name: 'licenses',
  dataProvider: async () => {
    const result = await api.post('/auth-manager/v1/licenses/', {
      pagination: {
        pgoffset: 0,
        pgsize: -1
      }
    });
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching licenses',
    [FETCH_STATE.REJECTED]: 'Error fetch licenses',
  }
});
export default licenses;

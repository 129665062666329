// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Grid, Paper, makeStyles, createStyles, Typography, ButtonBase, Button, Box,
} from '@material-ui/core';

import { defaultRestoringSession, routeLoadingData } from 'v2/utils/functions';
import { useModel } from 'v2/providers/ModelProvider';
import api from '../../app/api';
import { useAppStore } from '../../AppStore';

const useStyles = makeStyles(() => createStyles({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '480px',
    // background: vars.primaryBackground,
    paddingTop: '20px',
    paddingBottom: '2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  logo: {
    width: '185px',
    marginBottom: '2rem',
  },
  title: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
    fontSize: '20px',
  },
  header: {
    // color: vars.textGray,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  companyButtonBase: {
    width: '100%',
    marginBottom: '.5rem',
  },
  companyButton: {
    // background: vars.dark4,
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1.5rem',
    fontWeight: 500,
    fontSize: '1rem',
  },
}),);

type ICompany = {
  id: string;
  name: string;
};

const CompanySelect: React.FunctionComponent = () => {
  const location = useLocation();
  const { scenarios } = useModel();
  const classes = useStyles();
  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [VALUES, HANDLERS] = useAppStore();

  const history = useHistory();

  useEffect(() => {
    api.get<ICompany[]>('/auth-manager/context/available-companies/').then((response: any) => {
      setCompanyList(response.data);
    });
  }, []);

  useEffect(() => {
    if (companyList.length === 1) {
      history.push('/');
    }
  }, [companyList, history]);

  const handleChangeCompany = (company: string) => {
    api.patch('/auth-manager/context/active-company/', { company }).then(() => {
      routeLoadingData(scenarios, location.state?.redirectUrl).finally(() => {
        const locationState = location.state;
        HANDLERS.setIsAuthenticated(true);

        if (locationState.redirectUrl) {
          history.push(locationState.redirectUrl);
        } else {
          history.push('/');
        }
      });
    });
  };

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.form}>
        <div className={classes.header}>
          <div className="UILogo" />
          <Typography className={classes.title} variant="h4" align="left">
            Select company
          </Typography>
        </div>
        {companyList
          && companyList.map((company) => (
            <Button
              key={company.id}
              focusRipple
              className={classes.companyButtonBase}
              variant="contained"
              color="primary"
              onClick={() => handleChangeCompany(company.id)}
            >
              <Box width={1} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {company.name}
              </Box>
            </Button>
          ))}
      </Paper>
    </Grid>
  );
};

export default CompanySelect;

// @ts-nocheck
import { useQuery } from '@tanstack/react-query';

const useBasicQuery = ({ queryKey, queryFn, ...args }) => useQuery({
  refetchOnWindowFocus: false,
  queryKey,
  queryFn,
  ...args
});

export default useBasicQuery;

import LiveFeedImage from 'img/live-feed';
import PieChartWidgetImage from 'img/pie-chart-widget';
import ValueImage from 'img/value';
import BarImage from 'img/widget-bar';
import LineImage from 'img/widget-line';
import { DialogType } from 'components/Dashboard/Dialogs/SettingsDialog';

export const DIALOG_IMAGES = {
  value: ValueImage,
  face_feed: LiveFeedImage,
  vehicle_feed: LiveFeedImage,
  person_feed: LiveFeedImage,
  pie_chart: PieChartWidgetImage,
  hist_chart: BarImage,
  line_chart: LineImage,
};

export const DIALOG_NAMES = {
  value: 'Value',
  face_feed: 'Live feed',
  vehicle_feed: 'Live feed',
  person_feed: 'Live feed',
  pie_chart: 'Pie Chart widget',
  hist_chart: 'Bar / Line Chart widget',
  line_chart: 'Bar / Line Chart widget',
};

export const DIALOG_TYPES = {
  value: 'list',
  face_feed: 'list',
  pie_chart: 'normal',
  vehicle_feed: 'list',
  person_feed: 'list',
  hist_chart: 'pagination',
  line_chart: 'pagination',
};

export const INITIAL_FILTERS = {
  face: {
    name: '',
    notes: '',
    person_age: '',
    person_gender: '',
    person_glasses: '',
    person_mask: '',
    image_quality: '',
  },
  vehicle: {
    name: '',
    notes: '',
    vehicle_color: '',
    vehicle_license_plate: '',
    vehicle_manufacturer: '',
    vehicle_model: '',
    vehicle_type: '',
    image_quality: '',
  },
  person: {
    name: '',
    notes: '',
    image_quality: '',
  },
  group_of_people: {
    name: '',
    notes: '',
    image_quality: '',
  },
  unclassified: {
    name: '',
    notes: '',
    image_quality: '',
  },
};

export const getCurrentWidgetType = (type: any, filters: any): DialogType => {
  if (type === 'hist_chart') {
    return filters.includes('chart_type:pie')
      ? 'pie_chart'
      : 'hist_chart'
  }

  return type as DialogType;
};

// @ts-nocheck
import React, { useState, useEffect } from 'react'
import {
  Grid, Paper, makeStyles, createStyles, TextField, Button, Box,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import { useHistory, NavLink } from 'react-router-dom';
import api from 'app/api';
import { handlers } from 'v2/redux/store';
import { checkValidEmail } from '../../utils/helpers';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    height: '100vh',
    display: 'flex',
    background: 'url(img/login-bg.png) no-repeat center center fixed',
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '480px',
    // background: vars.primaryBackground,
    paddingTop: '20px',
    paddingBottom: '2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 9,
  },
  logo: {
    width: '170px',
  },
  header: {
    // color: vars.textGray,
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    marginBottom: '1rem',
    '& fieldset': {
      // borderRadius: vars.primaryBorderRadius,
      // border: `1px solid ${vars.primaryBorderColor}`,
    },
  },
  labelInput: {
    // color: vars.primaryBorderColor,
  },
  btn: {
    marginTop: '1rem',
    // textTransform: 'none',
  },
  info: {
    marginBottom: '1rem',
    display: 'flex',
    textAlign: 'center',
  }
}),);

export default function ResetPassword() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [checkEmail, setCheckEmail] = useState({
    login: '',
  })

  useEffect(() => {
    handlers.user.clear();
  }, [])

  const handleCheckEmail = (e) => {
    setCheckEmail({ ...checkEmail, [e.target.name]: e.target.value });
  };

  const checkInput = (value) => Object.values(value).every((emailValue) => emailValue.length > 0) && checkValidEmail(checkEmail.login)

  const SendCode = () => {
    api.post('/auth-manager/auth/forgot-password/', checkEmail)
      .then((response) => {
        if (response.status === 200) {
          history.push('/change-password/', { email: checkEmail.login })
        }
      })
      .then(() => enqueueSnackbar('A confirmation code has been sent to your email', { variant: 'success' }))
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      SendCode();
    }
  };

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.form}>
        <div className={classes.header}>
          <div className="UILogo" />
        </div>
        <span className={classes.info}>If you need to reset your password, please enter your email address used in our System and proceed to the next step.</span>
        <TextField
          size="small"
          name="login"
          className={classes.input}
          label="E-Mail"
          InputLabelProps={{
            className: classes.labelInput,
          }}
          variant="outlined"
          value={checkEmail.login}
          onChange={handleCheckEmail}
          onKeyPress={handleKeyPress}
          error={!checkValidEmail(checkEmail.login) && checkEmail.login !== ''}
          helperText={!checkValidEmail(checkEmail.login) && checkEmail.login !== '' && 'Invalid format email'}
        />
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          disabled={!checkInput(checkEmail)}
          onClick={SendCode}
        >
          Send a code
        </Button>
        <Box mt={2} display="flex" justifyContent="center">
          <NavLink className="UINavLink" to="/log-in">
            Back to Log In
          </NavLink>
        </Box>
      </Paper>
    </Grid>
  )
}

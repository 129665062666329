// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import UIBasicCropper from 'v2/ui-kit/UIBasicCropper/UIBasicCropper';
import { useDialog } from 'v2/providers/DialogProvider';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { checkFileMIME } from '../utils/functions';
import { ACCEPTED_FILES } from '../utils/constants';
import useUserQuery from '../hooks/queries/useUserQuery';

const AddAvatar = (props) => {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { userUpdateAvatar } = useUserQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { hideDialog } = useDialog();
  const [imageURL, setImageURL] = useState();
  const cropperRef = useRef(null);

  const {
    DialogTitle,
    DialogBody,
    DialogActions,
    file,
    imageUrl,
  } = props;

  useEffect(() => {
    file && handleSelectedFiles(file);
  }, [file]);

  useEffect(() => {
    imageUrl && setImageURL(imageUrl);
  }, [imageUrl]);

  const handleSelectedFiles = (file) => {
    checkFileMIME(file, () => {
      const objectUrl = URL.createObjectURL(file);
      setImageURL(objectUrl);
    }, (results) => {
      enqueueSnackbar(results[0] ? `Unsupported file format: ${results[0]}` : 'Unsupported file format', {
        variant: 'error',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    })
  };

  const handleFileClick = (event) => {
    handleSelectedFiles(event.target.files[0]);
  };

  const handleUploadClick = () => {
    const formData = new FormData();

    showBackdrop('Uploading Avatar');
    cropperRef.current.getCanvas()?.toBlob((blob) => {
      formData.append('file', blob as Blob);
      userUpdateAvatar.mutateAsync(formData)
        .then(() => enqueueSnackbar('File uploaded successfully', { variant: 'success' }))
        .finally(() => {
          hideBackdrop();
          hideDialog();
        });
    }, 'image/jpeg');
  };

  const Content = (
    <Box
      width={1}
      height={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      position="relative"
    >
      <Box
        overflow="hidden"
        width={1}
        height="auto"
        alignItems="center"
        justifyContent="center"
        position="relative"
        display="flex"
      >
        <UIBasicCropper
          src={imageURL}
          showControls
          circle
          restrictionType="stencil"
          restrictions={{
            minWidth: 64,
            minHeight: 64,
          }}
          ref={cropperRef}
        />
      </Box>
    </Box>
  );

  const Actions = (
    <Box width={1} display="flex" justifyContent="space-between">
      <label htmlFor="upload-photo">
        <input
          style={{ display: 'none' }}
          accept={ACCEPTED_FILES.join(',')}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={handleFileClick}
        />
        <Button
          aria-label="upload"
          variant="outlined"
          color="primary"
          component="span"
        >
          {imageURL ? 'Reselect file' : 'Select file'}
        </Button>
      </label>
      <Button
        color="primary"
        variant="contained"
        onClick={handleUploadClick}
      >
        Upload
      </Button>
    </Box>
  );

  return (
    <>
      {imageURL && (
        <>
          <DialogTitle title="Select User Avatar" hasCloseIcon />
          <DialogBody className="UIPaddingLess">
            {Content}
          </DialogBody>
          <DialogActions>
            {Actions}
          </DialogActions>
        </>
      )}
    </>
  );
};

export default AddAvatar;

// @ts-nocheck
import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const SelectInput = ({
  name,
  value,
  defaultValue,
  onChange,
  items,
  label,
}) => (
  <FormControl size="small" variant={'outlined' || variant} fullWidth>
    {label && <InputLabel htmlFor="role-select">{label}</InputLabel>}
    <Select
      name={name}
      labelId={name}
      value={value}
      label={label}
      onChange={onChange}
      defaultValue={defaultValue || null}
      IconComponent={KeyboardArrowDownIcon}
    >
      {items && items.map((item) => (
        <MenuItem
          value={item.value}
          key={item.value}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectInput;

// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import * as Highcharts from 'highcharts';
import { Chart as ChartType, SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import { getDateFormat } from 'utils/helpers';
import {
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Box, Divider,
} from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { vars } from 'Theme';
import { SelectedIds } from 'components/LocationSelector';
import { ChartDateRange, DetalizationType } from 'pages/dashboard/types';

import api from 'app/api';
import {
  getChartLabels, getChartOptions, getCurrentDetalizationOptions, getPieChartLabels, parseStoredChartLabels,
} from 'utils/chart';
import { yellowFromDateRangeMap } from 'utils/yelloMapping';
import CustomLabel from './CustomLabel';
import BasicDialog from '../common/BasicDialog';
import ChartDatePicker from './ChartDatePicker';
import UITextWidget from '../../v2/ui-kit/UITextWidget/UITextWidget';
import { DEFAULT_TEXT } from '../../v2/constants/defaultText';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: `${vars.spacing}px`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  toggleButton: {
    color: vars.white,
    padding: `${vars.spacing}px`,
    // border: `1px solid ${vars.primaryBorderColor}`,
    '&.MuiButtonBase-root.Mui-selected': {
      // backgroundColor: vars.primary,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 .75rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 .4rem',
    },
  },
  toggleGroup: {
    width: '100%',
    display: 'flex',
  },
  legendContainer: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  pieCHartLegendContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // margin: '25px 25px 15px 0',
    flexWrap: 'wrap',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  labelGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    // justifyContent: 'center',
    // textAlign: 'right',
  },
  labelGroupName: {
    minWidth: '60px',
    whiteSpace: 'nowrap',
    fontSize: '9px',
    textTransform: 'uppercase',
    textAlign: 'end'
  },
  preloader: {
    height: '60%',
  },
  chartHead: {
    marginBottom: '18px',
  },
  chartContent: {
    height: '100%',
    overflow: 'hidden',
  },
  chartBottom: {
    marginTop: `${vars.spacing}px`,
    maxHeight: '230px',
    textAlign: 'center',
  },
  inputDetalization: {
    width: '20%',
    marginLeft: 'auto',
  },
  chartFiltersContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    borderTop: `1px solid ${vars.primaryBorderColor}`,
    padding: `${vars.spacing}px`,
    alignItems: 'center',
  },
  toogleButtonsContainerAppearances: {
    width: '100%',
    marginLeft: '18px',
  },
  selectActiveDetalization: {
    '& svg': {
      // color: vars.primary,
    },
    '& fieldset': {
      // border: `1px solid ${vars.primaryBorderColor}`,
    },
  },
  checkbox: {
    padding: '0px',
    marginRight: '8px',
    '& svg': {
      // fill: vars.dark7,
    },
  },
  checkboxChecked: {
    '& svg': {
      fill: vars.primary,
    },
  },
  icon: {
    fill: '#737D80',
  },
}));

type TimeSlice = {
  id: string;
  name: string;
  value: number;
  default: boolean;
  custom?: boolean;
}

type ChartProps = {
  isSharedWidget: boolean,
  timeRangeProp?: string;
  isPieChart?: boolean;
  selectedChartType?: string;
  dateRangeProp?: ChartDateRange;
  onDateRangeChange?: (dateRange: any) => void;
  onTimeRangeChange: (dateRange: string) => void;
  onDetalizationChange?: (detalizationValue: number) => void;
  detalizationOptionsList?: DetalizationType[];
  onChangeRealtime?: (realtime: boolean) => void;
  isRealtime?: boolean;
  height?: string;
  activeDetalization?: number;
  dashBoardChart: boolean;
  background?: string;
  categories: string[];
  series: SeriesOptionsType[];
  defaultLabels?: boolean;
  locationSelector?: boolean;
  defaultSelectedIds?: SelectedIds;
  onLocationChange?: (ids: SelectedIds) => void;
  toggleGroupMargin?: string;
  chartDataLoading?: boolean;
  widgetId?: string;
  chartHeadMargin?: string;
  setOpenCamerasSelector: any;
  openCamerasSelector: any;
  setLegend?: (legend: []) => void;
  legend: any,
};

export const CUSTOM_DATE_RANGE = 'Custom';

type CustomLabelType = { color: string; group?: string; name: string; handler: () => void };

const debouncedHandler = (handler: (v: string) => void) => debounce(
  (_: any, newDateRange: string | null) => {
    if (newDateRange === null || newDateRange === CUSTOM_DATE_RANGE) {
      return;
    }
    handler(newDateRange);
  },
  50,
);

const Chart = ({
  isSharedWidget,
  timeRangeProp,
  isPieChart,
  isRealtime,
  background,
  selectedChartType,
  dateRangeProp,
  onDateRangeChange,
  onTimeRangeChange,
  onDetalizationChange,
  detalizationOptionsList,
  activeDetalization,
  dashBoardChart,
  categories,
  series,
  defaultLabels = false,
  locationSelector = false,
  defaultSelectedIds,
  onLocationChange,
  toggleGroupMargin,
  onChangeRealtime,
  chartDataLoading,
  widgetId,
  chartHeadMargin,
  setOpenCamerasSelector,
  openCamerasSelector,
  setLegend,
  legend,
  showStackLabels,
  showLegend,
  showTimeSlice,
  debouncedPatch,
}: ChartProps) => {
  const classes = useStyles();
  const chartRef = useRef<ChartType>();
  const { pathname } = useLocation();
  const [timeSlices, setTimeSlices] = useState<TimeSlice[]>([]);
  const [timeRange, setTimeRange] = useState<string>(timeRangeProp || '');
  const [customLabels, setCustomLabels] = useState<CustomLabelType[]>([]);
  const [labelGroups, setLabelGroups] = useState<string[]>([]);

  useEffect(() => {
    api.get<TimeSlice[]>('/layout-manager/report-timeslices/').then((response) => {
      response.data.push({
        id: '00000000-0000-0000-0000-000000000000',
        name: CUSTOM_DATE_RANGE,
        value: Infinity,
        default: false,
      });
      setTimeSlices(response.data);

      if (timeRangeProp) {
        setTimeRange(timeRangeProp);
      } else {
        setTimeRange(CUSTOM_DATE_RANGE);
      }
    });
  }, []);

  useEffect(() => {
    if (!chartRef.current || chartDataLoading) {
      return;
    }

    const groups: string[] = [];

    setCustomLabels(isPieChart
      ? getPieChartLabels(chartRef.current.series && chartRef.current.series[0], legend || [], groups)
      : getChartLabels(chartRef.current.series, legend || [], groups));

    setLabelGroups(groups);
  }, [chartDataLoading, isPieChart, pathname, legend, widgetId, series, categories]);

  // if (categories?.length > 0 && !isNaN(Number(categories[0]))) {
  //   const dateFormat = getDateFormat(Number(categories[0]), Number(categories[categories.length - 1]));
  //
  //   console.log('dateFormat', dateFormat);
  //
  //   // for (let i = 0; i < categories.length; i++) {
  //   //   categories[i] = moment.unix(Number(categories[i])).tz(DEFAULT_TIMEZONE).format(dateFormat);
  //   // }
  // }

  const chartOptions = getChartOptions({
    series,
    categories,
    selectedChartType,
    isPieChart,
    showStackLabels,
  });

  const handleLabel = (name: string) => () => {
    if (legend.find((label) => label === name)) {
      const filterLabel = legend.filter((label) => label !== name);
      setLegend(filterLabel);
      debouncedPatch.current(filterLabel);
    } else {
      const prevState = [...legend, name];
      setLegend(prevState);
      debouncedPatch.current(prevState);
    }
  };

  NoDataToDisplay(Highcharts);

  const handleDetalizationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as number;

    if (newValue && onDetalizationChange !== undefined) onDetalizationChange(newValue);
  };

  const onTimeButtonClick = (value: TimeSlice) => () => {
    setTimeRange(value.name);
  };

  const [showDataTimePicker, setShowDataTimePicker] = useState<boolean>(false);
  const handleDataTimePicker = () => {
    setShowDataTimePicker((prevState) => !prevState);
  };

  return (
    <Box height={1} display="flex" flexDirection="column">
      {showDataTimePicker && (
        <BasicDialog
          title="Select custom date range"
          isOpen={showDataTimePicker}
          initialValue={yellowFromDateRangeMap(dateRangeProp, timeRange)}
          closeDialog={handleDataTimePicker}
          onChange={(value) => {
            const [start, stop] = value;
            const optionsList = getCurrentDetalizationOptions(start.valueOf() / 1000, stop.valueOf() / 1000);

            onDetalizationChange(optionsList[0].value);
            onDateRangeChange && onDateRangeChange(value);
            setTimeRange('Custom');
          }}
          Component={ChartDatePicker}
        />
      )}
      { series?.length > 0 && series[0]?.data.length > 0 ? (
        <Box className="UIUnDraggable" width={1} height={1} overflow="hidden">
          <HighchartsReact
            containerProps={{ style: { height: '100%' } }}
            highcharts={Highcharts}
            options={chartOptions}
            callback={(chart: ChartType) => {
              chartRef.current = chart;
            }}
          />
        </Box>
      ) : <UITextWidget content={DEFAULT_TEXT.NO_DATA} className="UITextWidgetNoData" />}
      {showLegend && customLabels?.length > 0 && (
        <Box className="UIUnDraggable" p={1} display="flex" overflow="auto" flexShrink={0}>
          <Box display="flex" flexDirection="column" mx="auto">
            {!defaultLabels && labelGroups.length
              ? labelGroups.map((lg) => (
                <div key={lg} className={classes.labelGroup}>
                  {!isPieChart && (
                    <span className={classes.labelGroupName}>
                      {capitalize(lg)}
                      :
                      {' '}
                    </span>
                  )}
                  {customLabels
                    .filter((label) => label.group === lg)
                    .map(({
                      name, group, color, handler,
                    }) => (
                      <CustomLabel
                        key={name}
                        name={name}
                        color={color}
                        handler={handler}
                        isDisabled={legend ? legend.includes(`${name}-${group}`) : false}
                        handleLabel={handleLabel(`${name}-${group}`)}
                      />
                    ))}
                </div>
              ))
              : (
                <div className={classes.labelGroup}>
                  {customLabels.map(({ name, color, handler }) => (
                    <CustomLabel
                      key={name}
                      name={name}
                      color={color}
                      handler={handler}
                      isDisabled={legend && legend.includes(name)}
                      handleLabel={handleLabel(name)}
                    />
                  ))}
                </div>
              )}
          </Box>
        </Box>
      )}
      {showTimeSlice && (
        <Box p={1} className="UIUnDraggable" display="flex" overflow="auto" flexShrink={0}>
          <ToggleButtonGroup
            size="small"
            value={timeRange}
            exclusive
            onChange={debouncedHandler(onTimeRangeChange)}
            style={{ marginTop: toggleGroupMargin || '' }}
            className="UIButtonGroupChart"
          >
            {timeSlices
              .sort((a, b) => a.value - b.value)
              .map((item) => (
                <ToggleButton
                  key={item.id}
                  value={item.name}
                  onClick={item.name === CUSTOM_DATE_RANGE ? handleDataTimePicker : onTimeButtonClick(item)}
                  selected={timeRange === item.name}
                  defaultChecked={item.default}
                >
                  {item.name}
                </ToggleButton>
              ))}
            {(detalizationOptionsList && activeDetalization && !isPieChart && timeRange !== CUSTOM_DATE_RANGE) && (
              <ToggleButton value={CUSTOM_DATE_RANGE}>
                <FormControl className="UIUnDraggable" size="small" variant="outlined">
                  <Select
                    value={activeDetalization}
                    onChange={handleDetalizationChange}
                    variant="outlined"
                    IconComponent={KeyboardArrowDownIcon}
                    className="UISelectChart"
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                      name: 'Show by',
                      id: 'outlined-show-by-selector',
                    }}
                  >
                    {detalizationOptionsList?.map((detalizationOption) => (
                      <MenuItem
                        className="UISelectItemChart"
                        key={detalizationOption.value}
                        value={detalizationOption.value}
                      >
                        {detalizationOption.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(Chart);

// @ts-nocheck

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import Domain from '../model';

const Context = createContext({});

export const ModelProvider = (props) => {
  const [scenarios, setScenarios] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [layout, setLayout] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [user, setUser] = useState(null);
  const [help, setHelp] = useState(null);

  useEffect(() => {
    const domain = Domain({
      setLayouts,
      setLayout,
      setWidgets,
      setCompanies,
      setUser,
      setHelp
    });

    setScenarios(domain.scenarios);
  }, []);

  return (
    <Context.Provider value={{
      scenarios,
      layouts,
      layout,
      widgets,
      companies,
      user,
      help,
    }}
    >
      {scenarios && props.children}
    </Context.Provider>
  );
};

export const useModel = () => useContext(Context);

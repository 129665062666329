// @ts-nocheck

import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, IconButton, MenuItem, TextField, Tooltip
} from '@material-ui/core';
import { mergeRules } from 'v2/utils/functions';
import ClearIcon from '@material-ui/icons/Clear';

const SelectSingle = (props) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={mergeRules(props.rules)}
    defaultValue={props.defaultValue}
    render={(controller) => (
      <Tooltip arrow title={props.tooltip || ''} enterDelay={1000} enterNextDelay={500}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          select
          label={props.label}
          disabled={props.disabled}
          error={!!controller.formState?.errors[props.name]?.message}
          helperText={controller.formState?.errors[props.name]?.message}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: controller.field.value !== props.defaultValue && !props.disabled && props.showClear && (
              <Box right={24} position="absolute">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    controller.field.onChange(props.defaultValue);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            ),
          }}
          SelectProps={{
            displayEmpty: props.hasOwnProperty('displayEmpty') ? props.displayEmpty : true,
          }}
          {...controller.field}
        >
          {props.items.map((item, index) => (
            <MenuItem
              value={item.value}
              key={index}
            >
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>
    )}
  />
);

export default SelectSingle;

// @ts-nocheck
import React from 'react';
import {
  Box, Card, Divider, makeStyles, Typography
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import VideocamIcon from '@material-ui/icons/Videocam';
import moment from 'moment-timezone';
import { NavLink, useHistory } from 'react-router-dom';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import { vars } from 'Theme';
import { MONTH_DAY_YEAR_TIME_A_FORMAT } from 'utils/timeFormats';
import clsx from 'clsx';
import { useDialog } from 'v2/providers/DialogProvider';
import UIMetaData from 'v2/ui-kit/UIMetaData/UIMetaData';
import UICardOverlay from 'v2/ui-kit/UICardOverlay/UICardOverlay';
import { MODE_PRODUCTION } from 'v2/utils/constants';

const iconFontSize = 0.75;

const useStyles = makeStyles({
  root: {
    // backgroundColor: vars.primaryBackground,
    // border: `1px solid ${vars.primaryBorderColor}`,
    // borderRadius: vars.primaryBorderRadius,
    // borderRadius: vars.primaryBorderRadius,
  },
  imageContainer: {
    position: 'relative',
    height: 158,
    overflow: 'hidden',
  },
  imageContainerBig: {
    position: 'relative',
    height: 255,
    minHeight: 255,
    overflow: 'hidden',
  },
  imageLink: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    // borderBottom: `1px solid ${vars.primaryBorderColor}`,
  },
  image: {
    height: '100%',
    position: 'absolute',
    maxWidth: '80%',
    objectFit: 'cover',
    display: 'flex',
  },
  imageBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(10px)',
  },
  imagePerson: {
    objectPosition: 'top',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${vars.spacing}px`,
  },
  cardContentBlock: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
    fontSize: `${iconFontSize}rem`,
  },
  cardContentItem: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      // color: vars.primary,
    },
    '&:last-child': {},
  },
  cardContentItemText: {
    marginLeft: 4,
    display: 'flex',
    textAlign: 'start',
    margin: 1,
    whiteSpace: 'nowrap',
  },
  mainCard: {
    height: '100%',
    // background: vars.primaryBackground,
    // border: `1px solid ${vars.primaryBorderColor}`,
    // borderRadius: vars.primaryBorderRadius,
  },
  date: {
    color: vars.white,
    marginBottom: '.5rem',
    fontSize: '12px',
  },
  countText: {
    color: vars.white,
    marginTop: '.5rem',
    fontSize: '12px',
  },
  extensionIcon: {
    width: 24,
    height: 24,
    color: vars.white,
  },
  appearancesIcon: {
    color: vars.white,
    width: 20,
    height: 20,
  },
  counter: {
    marginLeft: '4px',
    color: vars.white,
    fontSize: 14,
  },
  iconLeftBottom: {
    position: 'absolute',
    left: '4px',
    bottom: '4px',
  },
  iconRightBottom: {
    position: 'absolute',
    right: '4px',
    bottom: '4px',
  },
  iconBackground: {
    background: 'rgb(13,14,15, 0.5)',
    display: 'flex',
    padding: '0px 4px',
    alignItems: 'center',
    height: 28,
    minWidth: 'unset',
    borderRadius: 4,
  },
});

type MainPhotoCardProps = {
  object: any,
  bigPhoto?: boolean;
  fullInfo?: boolean;
  allowDetails?: boolean;
};

const getColor = (type: string) => {
  switch (type?.toLowerCase()) {
    case 'van':
      return '#E012A9';
    case 'others':
      return '#B7860B';
    case 'minivan':
      return '#13E588';
    case 'truck':
      return '#FF9828';
    case 'wagon':
      return '#A5CEFC';
    case 'suv':
      return '#941CEE';
    case 'sedan':
      return '#E5D02E';
    case 'convertible':
      return '#92B70B';
    case 'male':
      return '#0B92B7';
    case 'female':
      return '#E54B21';
    case 'hatchback':
      return '#00ADFB';
    default:
      return '#611602';
  }
};

const MainPhotoCard = ({
  object, bigPhoto, fullInfo, allowDetails, cameras = [], liveFeed,
}: MainPhotoCardProps) => {
  const { showDialog } = useDialog();
  const classes = useStyles();
  const history = useHistory();

  const onCardClick = (object: any) => {
    if (object.id) {
      history.push({ pathname: `/appearance/${object.id}` });
    }
  };
  const getObjectTimeString = (object) => (object.last_seen ? moment.unix(object.last_seen).format(MONTH_DAY_YEAR_TIME_A_FORMAT) : moment.unix(object.timestamp).format(MONTH_DAY_YEAR_TIME_A_FORMAT));

  function showCamera() {
    return cameras.length !== 1
      ? (
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <VideocamIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
          </Box>
          <span className={classes.cardContentItemText}>{object.camera_name || 'N/A'}</span>
        </div>
      ) : null;
  }

  function showMask() {
    return (
      <div className={classes.cardContentItem}>
        <Box width={20} display="flex" alignItems="center" justifyContent="center">
          <FormatColorResetIcon fontSize="small" />
        </Box>
        <span className={classes.cardContentItemText}>{object.meta.mask || 'N/A'}</span>
      </div>
    );
  }

  const ObjectID = ({ object }) => (
    <Box width={1} display="flex" alignItems="center">
      <Box width={20} display="flex" alignItems="center" justifyContent="center" overflow="hidden">
        <Typography style={{ fontSize: `${iconFontSize * 1.10}rem` }}>
          ID
        </Typography>
      </Box>
      <Box ml={1}>
        {object.id}
      </Box>
    </Box>
  );

  const ObjectColorDot = ({ object }) => (
    <FiberManualRecordIcon
      fontSize="small"
      style={{ color: getColor(object.meta.gender || object.meta.type), fontSize: `${iconFontSize * 1.25}rem` }}
    />
  );

  function faceType(full: boolean) {
    if (full) {
      return (
        <div className={classes.cardContentBlock}>
          <div className={classes.cardContentItem}>
            <ObjectID object={object} />
          </div>
          <div className={classes.cardContentItem}>
            <Box width={20} display="flex" alignItems="center" justifyContent="center">
              <ObjectColorDot object={object} />
            </Box>
            <span className={classes.cardContentItemText} title={object.meta.name}>
              {object.meta.gender ? object.meta.gender.toUpperCase() : 'N/A'}
              {' '}
              {object.meta.age || 'N/A'}
              {' '}
              {object.meta.name ? object.meta.name.toUpperCase() : null}
            </span>
          </div>
          <div className={classes.cardContentItem}>
            <Box width={20} display="flex" alignItems="center" justifyContent="center">
              <VideocamIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
            </Box>
            <span className={classes.cardContentItemText}>{object.camera_name || 'N/A'}</span>
          </div>
          <div className={classes.cardContentItem}>
            <Box width={20} display="flex" alignItems="center" justifyContent="center">
              <QueryBuilderIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
            </Box>
            <span className={classes.cardContentItemText}>{getObjectTimeString(object)}</span>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.cardContentBlock}>
        <div className={classes.cardContentItem}>
          <ObjectID object={object} />
        </div>
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <ObjectColorDot object={object} />
          </Box>
          <span className={classes.cardContentItemText} title={object.meta.name}>
            {object.meta.gender ? object.meta.gender.toUpperCase() : 'N/A'}
            {' '}
            {object.meta.age || 'N/A'}
            {' '}
            {object.meta.name ? object.meta.name.toUpperCase() : null}
          </span>
        </div>
        {showCamera()}
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <QueryBuilderIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
          </Box>
          <span className={classes.cardContentItemText}>{getObjectTimeString(object)}</span>
        </div>
      </div>
    );
  }

  function vehicleType(full: boolean) {
    return (
      <div className={classes.cardContentBlock}>
        <div className={classes.cardContentItem}>
          <ObjectID object={object} />
        </div>
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <DriveEtaIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
          </Box>
          <span
            className={classes.cardContentItemText}
          >
            {object.meta.license_plate ? object.meta.license_plate.toUpperCase() : 'N/A'}
          </span>
        </div>
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <ObjectColorDot object={object} />
          </Box>
          <span
            className={classes.cardContentItemText}
            title={object.meta.name}
          >
            {object.meta.type ? object.meta.type.toUpperCase() : 'N/A'}
            {' '}
            {object.meta.name ? object.meta.name.toUpperCase() : null}
          </span>
        </div>
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <VideocamIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
          </Box>
          <span className={classes.cardContentItemText}>{object.camera_name || 'N/A'}</span>
        </div>
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <QueryBuilderIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
          </Box>
          <span className={classes.cardContentItemText}>{getObjectTimeString(object)}</span>
        </div>
      </div>
    );
  }

  function defaultType(full: boolean) {
    if (full) {
      return (
        <div className={classes.cardContentBlock}>
          <div className={classes.cardContentItem}>
            <ObjectID object={object} />
          </div>
          {object.meta.name && (
          <div className={classes.cardContentItem}>
            <span
              className={classes.cardContentItemText}
              title={object.meta.name}
            >
              {object.meta.name ? object.meta.name.toUpperCase() : null}
            </span>
          </div>
          )}
          <div className={classes.cardContentItem}>
            <Box width={20} display="flex" alignItems="center" justifyContent="center">
              <VideocamIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
            </Box>
            <span className={classes.cardContentItemText}>{object.camera_name || 'N/A'}</span>
          </div>
          <div className={classes.cardContentItem}>
            <Box width={20} display="flex" alignItems="center" justifyContent="center">
              <QueryBuilderIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
            </Box>
            <span className={classes.cardContentItemText}>{getObjectTimeString(object)}</span>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.cardContentBlock}>
        <div className={classes.cardContentItem}>
          <ObjectID object={object} />
        </div>
        {object.meta.name && (
          <div className={classes.cardContentItem}>
            <span
              className={classes.cardContentItemText}
              title={object.meta.name}
            >
              {object.meta.name ? object.meta.name.toUpperCase() : null}
            </span>
          </div>
        )}
        {showCamera()}
        <div className={classes.cardContentItem}>
          <Box width={20} display="flex" alignItems="center" justifyContent="center">
            <QueryBuilderIcon fontSize="small" style={{ fontSize: `${iconFontSize * 1.25}rem` }} />
          </Box>
          <span className={classes.cardContentItemText}>{getObjectTimeString(object)}</span>
        </div>
      </div>
    );
  }

  function getType(objectType: any, full?: boolean) {
    switch (objectType) {
      case 'face':
        return faceType(full);
      case 'vehicle':
        return vehicleType(full);
      default:
        return defaultType(full);
    }
  }

  function getImageClass(objectType: any) {
    return clsx(classes.image, {
      [classes.imagePerson]: objectType === 'person',
    });
  }

  const showIconEye = !(object.type === 'vehicle' && MODE_PRODUCTION);

  return (
    <Card variant="outlined">
      <Box
        height={1}
        width={1}
        display="flex"
        flexDirection="column"
        style={{ cursor: !allowDetails ? 'pointer' : 'default' }}
      >
        <div className={bigPhoto ? classes.imageContainerBig : classes.imageContainer}>
          <NavLink
            className="UINavLink UIFullSize"
            to={!allowDetails && `/appearance/${object.id}`}
            style={{ position: 'relative', cursor: !allowDetails ? 'pointer' : 'default' }}
          >
            <Box width={1} height={1} display="flex" justifyContent="center" position="relative" alignItems="center">
              <div className={classes.imageBackground} style={{ backgroundImage: `url(${object.image_url})` }} />
              <img
                className={getImageClass(object.type)}
                src={object.image_url}
              />
            </Box>
          </NavLink>
          <UICardOverlay object={object} />
          {/* <Box className={classes.iconRightBottom}> */}
          {/*  <Box className={classes.iconBackground}> */}
          {/*    <IconButton */}
          {/*      onClick={() => { */}
          {/*        showDialog(DIALOGS.EXTENDED_PHOTO, { object }); */}
          {/*      }} */}
          {/*    > */}
          {/*      <FullscreenIcon className={classes.extensionIcon} /> */}
          {/*    </IconButton> */}
          {/*  </Box> */}
          {/* </Box> */}
          {/* {liveFeed ? null : object.cluster_size > 1 && showIconEye && ( */}
          {/*  <Box className={classes.iconLeftBottom} display="flex"> */}
          {/*    <Tooltip title={object.is_reference ? 'Main Grouped Object' : 'Grouped Object'}> */}
          {/*      <Box */}
          {/*        className={classes.iconBackground} */}
          {/*      > */}
          {/*        <VisibilityIcon */}
          {/*          className={classes.appearancesIcon} */}
          {/*          style={object.is_reference ? { color: 'yellow' } : {}} */}
          {/*          onClick={() => !allowDetails && onCardClick(object)} */}
          {/*        /> */}
          {/*        <div className={classes.counter}>{object.cluster_size}</div> */}
          {/*      </Box> */}
          {/*    </Tooltip> */}
          {/*  </Box> */}
          {/* )} */}

        </div>
        <Divider />
        <Box p={1} height={bigPhoto ? '100%' : 'unset'}>
          <UIMetaData object={object} />
        </Box>
      </Box>
    </Card>
  );
};

export default MainPhotoCard;

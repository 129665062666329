// @ts-nocheck

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

const Context = createContext({});

export const GridProvider = (props) => {
  const [isDraggable, setDraggable] = useState(true);
  const [isResizable, setResizable] = useState(true);

  const lockGrid = () => {
    setDraggable(false);
    setResizable(false);
  }

  const unlockGrid = () => {
    setDraggable(true);
    setResizable(true);
  }

  return (
    <Context.Provider value={{
      isDraggable,
      isResizable,
      lockGrid,
      unlockGrid
    }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useGrid = () => useContext(Context);

// @ts-nocheck

import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, Grid, IconButton, TextField, Tooltip, Typography
} from '@material-ui/core';
import { mergeRules } from 'v2/utils/functions';
import ClearIcon from '@material-ui/icons/Clear';
import useDevicesQuery from 'v2/hooks/queries/useDevicesQuery';
import LocationSelector from '../../../../components/LocationSelector';

const CameraPickerWrapper = ({ field }) => {
  const { cameras } = useDevicesQuery();

  const formatter = (camera) => {
    let name = 'Filtered by: ';
    name = `${name + camera?.length} Camera`;
    name += (camera.length > 1 ? 's' : '');

    return name;
  };

  const selectorName = ({ camera }) => (camera && camera.length > 0 ? formatter(camera) : formatter(cameras));

  return (
    <Box p={2} pl={4} width={1} display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
      <Typography>
        {selectorName(field.value || {})}
      </Typography>
    </Box>
  );
};

const SelectCameras = (props) => {
  const [open, setOpen] = useState(false);
  const { camerasQuery } = useDevicesQuery();

  return camerasQuery.isFetched && (
    <Controller
      name={props.name}
      control={props.control}
      rules={mergeRules(props.rules)}
      defaultValue={props.value || {}}
      render={(controller) => !props.hidden && (
        <Grid item xs>
          <Tooltip arrow title={props.tooltip || ''} enterDelay={500} enterNextDelay={500}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label={props.label}
              error={!!controller.formState?.errors[props.name]?.message}
              helperText={controller.formState?.errors[props.name]?.message}
              InputLabelProps={{ shrink: true }}
              onClick={() => setOpen(true)}
              InputProps={{
                inputProps: {
                  ...controller,
                },
                inputComponent: CameraPickerWrapper,
                endAdornment: (controller.field.value || {})?.camera?.length > 0 && (
                  <Box mr={2}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        controller.field.onChange([]);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                ),
              }}
            />
          </Tooltip>
          <LocationSelector
            defaultSelectedIds={{
              locations: controller.field.value?.location || [],
              cameras: controller.field.value?.camera || [],
            }}
            onSelect={(ids) => {
              controller.field.onChange({ camera: ids.cameras, location: ids.locations });
              setOpen(false);
            }}
            handleClose={() => setOpen(false)}
            open={open}
          />
        </Grid>
      )}
    />
  );
};

export default SelectCameras;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdvancedSearchFilters from 'v2/pages/AdvancedSearch/AdvancedSearchFilters';
import { ROUTES } from 'v2/utils/routes';
import useAdvancedSearchFiltersQuery, { getSearchDefaultFilters } from 'v2/hooks/queries/useAdvancedSearchFiltersQuery';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import { useDrawer } from '../../providers/DrawerProvider';
import CloseAction from '../../widgetActions/CloseAction';

const AdvancedSearchDrawer = () => {
  const history = useHistory();
  const { hideDrawer } = useDrawer();

  const {
    setFilters,
    setObjectType,
    filtersQuery,
    objectTypeQuery,
  } = useAdvancedSearchFiltersQuery();

  const [actualFilters, setActualFilters] = useState();
  const [actualObjectType, setActualObjectType] = useState();
  const isShowSearchFiltersPanel = actualObjectType;

  const onSubmitForm = () => {
    setFilters([actualObjectType, actualFilters]);
    setObjectType(actualObjectType);
    hideDrawer();
    history.push(ROUTES.OBJECTS_SEARCH.path);
  };

  const onClearClick = () => {
    setActualFilters(getSearchDefaultFilters(actualObjectType));
  }

  useEffect(() => {
    objectTypeQuery.isFetched && setActualObjectType(objectTypeQuery.data);
  }, [objectTypeQuery.data]);

  useEffect(() => {
    actualObjectType && filtersQuery.isFetched && setActualFilters(filtersQuery.data[actualObjectType]);
  }, [actualObjectType]);

  return isShowSearchFiltersPanel && (
    <UIWidgetContainer className="h-full w-full">
      <UIWidgetHeader>
        <UIWidgetTitle>Search filters</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDrawer} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <AdvancedSearchFilters
          filters={actualFilters}
          setFilters={setActualFilters}
          objectType={actualObjectType}
          setObjectType={setActualObjectType}
        />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="contained"
          onClick={onSubmitForm}
        >
          Search
        </UIButton>
        <div className="flex gap-2">
          <UIButton
            variant="outlined"
            onClick={onClearClick}
          >
            Clear Filters
          </UIButton>
        </div>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default AdvancedSearchDrawer;

import React from 'react';

const emptyElement = document.createElement('div');
emptyElement.style.width = '0px';
emptyElement.style.height = '0px';

const draggableCameraEl = document.createElement('div');
const draggableLocationEl = document.createElement('div');

const configureDraggableElement = (el: HTMLElement) => {
  el.style.width = '25em';
  el.style.height = '50px';
  el.style.display = 'flex';
  el.style.position = 'absolute';
  el.style.backgroundColor = 'white';
  el.style.pointerEvents = 'none';
  el.style.backgroundColor = 'rgb(55 55 55 / 75%)';
  el.style.alignItems = 'center';
  el.style.justifyContent = 'center';
  el.style.display = 'none';
};

export const hideElems = () => {
  draggableCameraEl.style.display = 'none';
  setElAtPosition(draggableCameraEl, 0, 0);
  draggableLocationEl.style.display = 'none';
  setElAtPosition(draggableLocationEl, 0, 0);
};

export const showElems = () => {
  draggableCameraEl.style.display = 'flex';
  draggableLocationEl.style.display = 'flex';
};

export const setElAtPosition = (el: HTMLElement, x: number, y: number) => {
  el.style.top = `${y - 15}px`;
  el.style.left = `${x - 15}px`;
};

export const cameraDragHandler = (e: DragEvent) => {
  setElAtPosition(draggableCameraEl, e.clientX + window.scrollX, e.clientY + window.scrollY);
};

export const locationDragHandler = (e: React.DragEvent) => {
  setElAtPosition(draggableLocationEl, e.clientX + window.scrollX, e.clientY + window.scrollY);
};

export const setCameraElName = (name: string) => draggableCameraEl.innerText = name;
export const setLocationElName = (name: string) => draggableLocationEl.innerText = name;

const initDraggableElements = () => {
  configureDraggableElement(draggableCameraEl);
  configureDraggableElement(draggableLocationEl);
  document.body.appendChild(emptyElement);
  document.body.appendChild(draggableCameraEl);
  document.body.appendChild(draggableLocationEl);
};

initDraggableElements();

export {
  emptyElement,
};

// @ts-nocheck
import { useEffect, useState } from 'react';

const useChangedState = (props) => {
  const [state, setState] = useState(props);

  useEffect(() => {
    props && setState(props);
  }, [props]);

  return [state, setState]
}
export default useChangedState;

// @ts-nocheck

import React from 'react';
import clsx from 'clsx';

import './UIMetaList.scss';

const UIMetaList = ({
  children,
  title
}) => {
  const className = clsx('UIMetaList');

  return (
    <div className={className} title={title}>
      {children}
    </div>
  );
};

export default UIMetaList;

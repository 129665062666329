// @ts-nocheck

import { FETCH_STATE } from '../../utils/constants';
import DataReducer from '../DataReducer';
import api from '../../../app/api';

const userPatch = DataReducer({
  name: 'userPatch',
  dataProvider: async ([id, payload]) => {
    const result = await api.patch(`/auth-manager/users/${id}`, payload);
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Update user',
    [FETCH_STATE.REJECTED]: 'Error update user',
  }
});

export default userPatch;

// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIInfo600Icon from 'v2/icons/UIInfo600Icon';

import './AutoRefreshInfo.scss';

const AutoRefreshInfo = ({
  lastUpdateTime,
}) => (
  <UIWidgetAction
    byHover
    tooltip={`Updated: ${lastUpdateTime}`}
    className="UIAutoRefreshInfo"
  >
    <UIInfo600Icon />
  </UIWidgetAction>
);

export default AutoRefreshInfo;

// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

const network = createSlice({
  name: 'network',
  initialState: { data: { status: true } },
  // #1167 Online/offline indicator issues - убрали индикацию состояния наличия интернета
  // initialState: { data: { status: navigator.onLine } },
  reducers: {
    checkStatus(state) {
      state.data = { status: true };
      // #1167 Online/offline indicator issues - убрали индикацию состояния наличия интернета
      // state.data = { status: action.payload };
    },
  },
});

export default network;

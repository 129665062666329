// @ts-nocheck

import React, { useEffect } from 'react';
import { useModel } from 'v2/providers/ModelProvider';
import WidgetsGrid from 'v2/components/WidgetsGrid';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { handlers } from 'v2/redux/store';
import LayoutError from './LayoutError';

const SharedBoard = () => {
  const { widgets, scenarios } = useModel();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { entityID } = useParams();
  const sharedLayout = useSelector((state) => state.layouts);
  const layout = useSelector((state) => state.layouts.data);

  useEffect(() => {
    showBackdrop('Loading Layout');
    Promise.all([
      handlers.layouts.fetchOne({ id: entityID }),
    ]).finally(hideBackdrop);
  }, []);

  const updateLayout = (grid) => handlers.layouts.update({ grid }, layout.id).then(scenarios.widgets.init).finally(hideBackdrop)

  const addWidget = (index, component, widgetPostData) => {
    showBackdrop('Adding widget');
    return scenarios.widgets.create(widgetPostData).then((data) => updateLayout([...layout.grid, {
      id: data.id, x: 0, y: 0, w: 1, h: 1
    }]))
  }

  const removeWidget = (data) => {
    showBackdrop('Removing widget');
    return updateLayout(layout.grid.filter((item) => item.id !== data.id)).then(() => scenarios.widgets.delete(data.id));
  }

  return (
    <LayoutError requests={sharedLayout} showLoader>
      <WidgetsGrid
        layout={layout}
        widgets={widgets}
        scenarios={scenarios}
        updateLayout={updateLayout}
        removeWidget={removeWidget}
        addWidget={addWidget}
      />
    </LayoutError>
  )
};

export default SharedBoard;

// @ts-nocheck

import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Tooltip } from '@material-ui/core';
import { FIELD_RULES, FIELD_VALIDATORS } from 'v2/constants/fieldValidators';
import { mergeRules } from 'v2/utils/functions';

const TextArea = (props) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={{ ...FIELD_VALIDATORS[FIELD_RULES.MAXLENGTH_100], ...mergeRules(props.rules) }}
    defaultValue={props.value || ''}
    render={(controller) => (
      <Tooltip arrow title={props.tooltip || ''} enterDelay={1000} enterNextDelay={500}>
        <TextField
          fullWidth
          size={props.size || 'small'}
          variant="outlined"
          label={props.label}
          error={!!controller.formState?.errors[props.name]?.message}
          helperText={controller.formState?.errors[props.name]?.message}
          InputLabelProps={{ shrink: true }}
          multiline
          minRows={props.rows || 4}
          {...controller.field}
        />
      </Tooltip>
    )}
  />
)

export default TextArea

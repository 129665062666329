// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import { DIALOGS } from 'v2/providers/DialogProvider';
import UICropFreeIcon from 'v2/icons/UICropFreeIcon';

const ExtendedPhoto = ({ object, showDialog }) => (
  <UIWidgetAction
    byHover
    tooltip="Extend Photo"
    onClick={() => {
      showDialog(DIALOGS.EXTENDED_PHOTO, { object });
    }}
  >
    <UICropFreeIcon />
  </UIWidgetAction>
)

export default ExtendedPhoto;

// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tagsCreate = DataReducer({
  name: 'tagsCreate',
  dataProvider: async (names) => {
    const result = await api.post('/object-manager/v1/tags/', { names })
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Adding token',
    [FETCH_STATE.REJECTED]: 'Error adding token',
  }
});
export default tagsCreate;

import React, { ChangeEvent, FunctionComponent } from 'react';
import { OutlinedInputProps, TextField, TextFieldProps } from '@material-ui/core';

type changeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

type TextInputProps = {
  name?: string;
  label?: string | undefined;
  value?: string;
  type?: string;
  defaultValue?: string;
  className?: string;
  variant?: string;
  placeholder?: string;
  onChange: changeHandler;
  InputProps?: OutlinedInputProps,
} & TextFieldProps;

const TextInput: FunctionComponent<TextInputProps> = ({
  name,
  label,
  placeholder,
  value,
  defaultValue,
  onChange,
  type,
  InputProps,
  className,
  variant,
  ...props
}) => (
  <TextField
    fullWidth
    name={name}
    size="small"
    value={value || ''}
    onChange={onChange}
    type={type || 'text'}
    variant="outlined"
    InputProps={InputProps}
    label={label}
    placeholder={placeholder}
    {...props}
  />
);

export const TextInputWrapper = TextInput;

export default TextInput;

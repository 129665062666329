// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UILeftPanelCloseIcon from 'v2/icons/UILeftPanelCloseIcon';

const LeftPanelClose = ({ onClick, tooltip = 'Close left panel' }) => (
  <UIWidgetAction byHover tooltip={tooltip} onClick={onClick}>
    <UILeftPanelCloseIcon />
  </UIWidgetAction>
);

export default LeftPanelClose;

// @ts-nocheck
import { useQueryClient, useMutation } from '@tanstack/react-query';
import api from 'app/api';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['licenses'];

const useLicensesQuery = () => {
  const queryClient = useQueryClient();

  const licensesQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.post('/auth-manager/v1/licenses/');

      const active = result.data.items.filter((license) => !license.is_expired);
      const expired = result.data.items.filter((license) => license.is_expired);

      active.sort((l1, l2) => Date.parse(l1.expired_at) - Date.parse(l2.expired_at));
      expired.sort((l1, l2) => Date.parse(l2.expired_at) - Date.parse(l1.expired_at));

      result.data.items = [...active, ...expired];

      return result.data;
    },
  });

  const addLicense = useMutation({
    mutationFn: ({ key }) => api.patch(`/auth-manager/licenses/${key}`),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  const activateDemoLicense = useMutation({
    mutationFn: () => api.get('/auth-manager/demo-licenses/'),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  return {
    addLicense,
    activateDemoLicense,
    licenses: licensesQuery.data,
    licensesQuery
  }
}

export default useLicensesQuery;

// @ts-nocheck

import React, { useEffect } from 'react';
import {
  Avatar, Box, IconButton, Typography
} from '@material-ui/core';
import BasicTable from 'v2/components/basic/BasicTable';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Add, ArrowBack, Delete } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { handlers } from 'v2/redux/store';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { BUTTON_TYPES, TABLE } from 'v2/utils/constants';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import useCompanyUsersQuery from 'v2/hooks/queries/useCompanyUsersQuery';
import useUserQuery from 'v2/hooks/queries/useUserQuery';
import { ROUTES } from '../utils/routes';
import BasicWidget from './basic/BasicWidget';

const headCells = (usersTypes) => [
  {
    id: 'first_name',
    label: 'Name',
    formatter: ({ first_name, last_name, photo_url }) => {
      const name = first_name && last_name ? `${first_name} ${last_name}` : 'Invited User';

      return (
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Avatar className="UITableAvatar" src={photo_url} />
          </Box>
          <Typography>{name}</Typography>
        </Box>
      )
    },
  },
  {
    id: 'role',
    label: 'Role',
    formatter: ({ role }) => usersTypes.find(({ id }) => id === role)?.name,
  },
  {
    id: 'email',
    label: 'E-Mail',
  },
  {
    id: 'action',
    align: 'center',
  },
];
const CompanyUsers = () => {
  const history = useHistory();
  const params = useParams();
  const dialog = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { enqueueSnackbar } = useSnackbar();

  const companies = useSelector((state) => state.companies.data);
  const usersTypes = useSelector((state) => state.usersTypes.data);
  const { user, userDelete } = useUserQuery();
  const { companyUsers, companyUsersQuery } = useCompanyUsersQuery(params.id);

  const company = companies?.find(({ id }) => id === Number(params.id));

  const actions = {
    canAddUsers: company?.role === 'admin',
  };

  const rightActions = [{
    title: 'Add user',
    type: TABLE.TOOLBAR.BUTTONS.TYPE,
    Icon: Add,
    visible: actions.canAddUsers,
    onClick: () => {
      dialog.showDialog(DIALOGS.ADD_USER, { params, onSuccess: companyUsersQuery.remove });
    },
  }];

  const leftActions = [{
    type: BUTTON_TYPES.ICON,
    Icon: ArrowBack,
    visible: true,
    onClick: () => history.push(ROUTES.SETTINGS.ROUTES.COMPANIES.path),
  }];

  const rowActions = (row) => actions.canAddUsers && row.id !== user?.id && (
    <IconButton onClick={() => {
      dialog.showDialog(DIALOGS.CONFIRMATION, {
        title: 'Delete User',
        text: row.first_name && row.last_name
          ? `Do you really want to delete "${row.first_name} ${row.last_name}" ?`
          : `Do you really want to delete "${row.email}" ?`,
        confirmText: 'YES',
        onConfirmation: (hideDialog) => {
          showBackdrop('Delete User');
          userDelete.mutateAsync([params.id, row.id]).then(() => {
            hideDialog();
            companyUsersQuery.remove();
            enqueueSnackbar(row.first_name && row.last_name ? `${row.first_name} ${row.last_name} deleted successfully` : `${row.email} deleted successfully`, { variant: 'success' });
          }).finally(() => {
            hideBackdrop();
          });
        },
      });
    }}
    >
      <Delete />
    </IconButton>
  );

  useEffect(() => {
    showBackdrop('Loading users');
    Promise.all([
      handlers.companies.fetch(),
      handlers.companies.companyUsers(params.id),
    ]).then(hideBackdrop);
  }, []);

  return (
    <Box p={2} width={1}>
      <BasicWidget title={`Users List in "${company?.name}"`} leftActions={leftActions} rightActions={rightActions}>
        <BasicTable
          storageKey="companyUsersPerPage"
          columns={headCells(usersTypes)}
          rows={companyUsers}
          rowActions={rowActions}
        />
      </BasicWidget>
    </Box>
  );
};

export default CompanyUsers;

// @ts-nocheck

import React, { useEffect } from 'react';
import {
  Box, Button, IconButton, Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
  Add, ArrowBack, Delete, Edit
} from '@material-ui/icons';
import BasicTable from 'v2/components/basic/BasicTable';
import BasicWidget from 'v2/components/basic/BasicWidget';
import { ROUTES } from 'v2/utils/routes';
import { BUTTON_TYPES, TABLE } from 'v2/utils/constants';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import useWatchListsQuery from '../../hooks/queries/useWatchListsQuery';
import useWatchListPredicatesQuery from '../../hooks/queries/useWatchListPredicatesQuery';
import { OBJECT_TYPES } from '../../constants/objectTypes';

const headCells = {
  [OBJECT_TYPES.FACE]: () => [
    {
      id: 'name',
      label: 'Name',
      formatter: (item) => (item.filter.hasOwnProperty('name') ? item.filter.name : 'All names'),
    },
    {
      id: 'person_age',
      label: 'Age',
      align: 'center',
      formatter: (item) => (item.filter.hasOwnProperty('person_age') ? item.filter.person_age.join('-') : 'All ages'),
    },
    {
      id: 'person_gender',
      label: 'Gender',
      align: 'center',
      formatter: (item) => (item.filter.hasOwnProperty('person_gender') ? ['Female', 'Male'][item.filter.person_gender] : 'All genders'),
    },
    {
      id: 'rowAction',
      label: '',
      align: 'center',
    },
  ],
  [OBJECT_TYPES.VEHICLE]: () => [
    {
      id: 'vehicle_type',
      label: 'Vehicle Type',
      formatter: (item) => (item.filter.hasOwnProperty('vehicle_type') ? item.filter.vehicle_type : 'All vehicles types'),
    },
    {
      id: 'vehicle_license_plate',
      label: 'Vehicle License Plate',
      align: 'center',
      formatter: (item) => (item.filter.hasOwnProperty('vehicle_license_plate') ? item.filter.vehicle_license_plate : 'All license plates'),
    },
    {
      id: 'rowAction',
      label: '',
      align: 'center',
    },
  ],
};

const WatchListsPredicatesPage = () => {
  const history = useHistory();
  const params = useParams();
  const dialog = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const { watchListsQuery } = useWatchListsQuery();
  const { watchListPredicatesQuery, deleteWatchListPredicate } = useWatchListPredicatesQuery(params.id);

  const isFetched = watchListPredicatesQuery.isFetched && watchListsQuery.isFetched;
  const isError = watchListPredicatesQuery.failureReason || watchListPredicatesQuery.failureReason;
  const isSuccess = isFetched && !isError;
  const watchList = ((watchListsQuery.data || []).find(({ id }) => id === parseInt(params.id, 10)) || {});
  const objectType = watchList.object_type;

  useEffect(() => {
    if (isFetched) {
      hideBackdrop();
    } else {
      showBackdrop();
    }
  }, [isFetched]);

  const leftActions = [{
    type: BUTTON_TYPES.ICON,
    Icon: ArrowBack,
    visible: true,
    onClick: () => history.push(ROUTES.NOTIFICATIONS.ROUTES.WATCH_LISTS.path),
  }];

  const rightActions = [{
    title: 'Add Filter',
    type: TABLE.TOOLBAR.BUTTONS.TYPE,
    Icon: Add,
    visible: watchListPredicatesQuery.data?.length,
    onClick: () => dialog.showDialog(DIALOGS.ADD_WATCH_LISTS_PREDICATE, { watchList }),
  }];

  const rowActions = (item) => (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box>
        <IconButton onClick={() => dialog.showDialog(DIALOGS.EDIT_WATCH_LISTS_PREDICATE, { watchList, item })}>
          <Edit />
        </IconButton>
      </Box>
      <Box ml={4}>
        <IconButton onClick={() => dialog.showDialog(DIALOGS.CONFIRMATION, {
          title: 'Delete Filter',
          text: 'Do you really want to delete selected filter?',
          onConfirmation: (hideDialog) => {
            showBackdrop();
            deleteWatchListPredicate.mutateAsync(item.id).then(() => {
              hideDialog();
            }).finally(() => {
              hideBackdrop();
            });
          },
        })}
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );

  const EmptyTableComponent = () => (
    <Box p={6}>
      <Typography align="center" variant="h6">
        Currently you do not have added any filters
      </Typography>
      <Box mt={4} textAlign="center">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => dialog.showDialog(DIALOGS.ADD_WATCH_LISTS_PREDICATE, { watchList })}
        >
          Add Filter
        </Button>
      </Box>
    </Box>
  );

  const NotFoundComponent = () => (
    <Box p={6}>
      <Typography align="center" variant="h6">
        Watch list not found
      </Typography>
    </Box>
  );

  return (
    <Box p={2} width={1} height={1}>
      <BasicWidget
        title={watchList?.name ? `Watch Filters in ${watchList.name}` : 'Watch Filters'}
        leftActions={leftActions}
        rightActions={rightActions}
      >
        {
          isSuccess && (
            <BasicTable
              storageKey="watchListsPredicatesPageSize"
              columns={headCells[objectType]()}
              rows={watchListPredicatesQuery.data}
              rowActions={rowActions}
              EmptyTableComponent={EmptyTableComponent}
              showEmpty
            />
          )
         }
        {
          isError && <NotFoundComponent />
        }
      </BasicWidget>
    </Box>
  );
};

export default WatchListsPredicatesPage;

// @ts-nocheck

import React, { useEffect } from 'react';
import { Box, IconButton, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Add, Group } from '@material-ui/icons';
import BasicTable from 'v2/components/basic/BasicTable';
import BasicWidget from 'v2/components/basic/BasicWidget';
import { ROUTES } from 'v2/utils/routes';
import { TABLE } from 'v2/utils/constants';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { handlers } from 'v2/redux/store';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import useCompaniesQuery from 'v2/hooks/queries/useCompaniesQuery';

const COMPANY_SPC = 100;

const headCells = (usersTypes, companiesTypes, companies, user, userCompany) => [
  {
    id: 'name',
    label: 'Company Name',
  },
  {
    id: 'contact_email',
    label: 'Contact E-mail',
    align: 'center',
  },
  {
    id: 'contact_address',
    label: 'Address',
    align: 'center',
  },
  {
    id: 'company_type',
    label: 'Company Type',
    align: 'center',
    formatter: ({ company_type }) => companiesTypes.find(({ value }) => value === company_type)?.short,
  },
  {
    id: 'parent_company_id',
    label: 'Created By',
    align: 'center',
    formatter: ({ parent_company_id }) => {
      const parent = companies?.find(({ id }) => id === parent_company_id);
      if (!parent) return '';
      const type = companiesTypes?.find(({ value }) => value === parent.company_type)?.short;
      if (!type) return '';
      return `${parent.name}, ${type}`;
    },
    admin_scp: true,
  },
  {
    id: 'role',
    label: 'Role',
    align: 'center',
    formatter: ({ role }) => usersTypes.find(({ id }) => id === role)?.name,
  },
  {
    id: 'action',
    label: 'Users',
    align: 'center',
  },
].filter((item) => (user.role !== 'admin' || userCompany.rank !== COMPANY_SPC ? !item.admin_scp : true));

const Companies = () => {
  const dialog = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const companiesTypes = useSelector((state) => state.companiesTypes.data);
  const usersTypes = useSelector((state) => state.usersTypes.data);
  const userCompany = useSelector((state) => state.userCompany.data);
  const userActions = useSelector((state) => state.userActions.data);
  const user = useSelector((state) => state.user.data);
  const { companies } = useCompaniesQuery();

  const rows = [
    (companies || []).find((item) => item.id === userCompany.id),
    ...(companies || []).filter((item) => item.id !== userCompany.id),
  ].filter((item) => !!item);

  const toolbarActions = [{
    title: 'Add company',
    type: TABLE.TOOLBAR.BUTTONS.TYPE,
    Icon: Add,
    visible: userActions.canAddCompany,
    onClick: () => {
      dialog.showDialog(DIALOGS.ADD_COMPANY);
    }
  }]

  const rowClassName = (row) => (userCompany?.id === row.id ? 'UIRowCurrentCompany' : 'UIRow')

  useEffect(() => {
    showBackdrop('Loading companies');
    Promise.all([
      handlers.companies.types(),
      handlers.user.types(),
      handlers.user.company(),
      handlers.companies.fetch(),
    ]).then(hideBackdrop);
  }, [])

  const rowActions = (row) => row.role && (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box>
        <Link component={NavLink} to={ROUTES.SETTINGS.ROUTES.COMPANY_USERS.url(row.id)}>
          <IconButton>
            <Group />
          </IconButton>
        </Link>
      </Box>
    </Box>
  )

  return (
    <Box p={2} width={1}>
      <BasicWidget title="List of Companies" rightActions={toolbarActions}>
        <BasicTable
          storageKey="companiesPerPage"
          columns={headCells(usersTypes, companiesTypes, companies, user, userCompany)}
          rows={rows}
          rowActions={rowActions}
          rowClassName={rowClassName}
        />
      </BasicWidget>
    </Box>
  );
}

export default Companies;

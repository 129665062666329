// @ts-nocheck
// TODO REMOVE MUI
import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useGrid } from 'v2/providers/GridProvider';
import { BACKDROP_STATE } from 'v2/utils/constants';
import { useTranslation } from 'react-i18next';

const Context = createContext({});

const Loader = (props) => {
  const { t } = useTranslation('backdrop');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box mb={2}>
        <CircularProgress size={64} />
      </Box>
      <Typography variant="h5">{t(props.text)}</Typography>
    </Box>
  );
};

export const BackdropProvider = (props) => {
  const { lockGrid, unlockGrid } = useGrid();
  const [content, setContent] = React.useState();
  const [state, setState] = useState(BACKDROP_STATE.HIDDEN);

  const hideBackdrop = () => {
    setState(BACKDROP_STATE.HIDDEN);
  };

  const showBackdrop = (text) => {
    setContent({ text });
    setState(BACKDROP_STATE.LOADING);
  };

  useEffect(() => {
    state !== BACKDROP_STATE.HIDDEN ? lockGrid() : unlockGrid();
  }, [state]);

  return (
    <Context.Provider value={{ showBackdrop, hideBackdrop }}>
      {props.children}
      <Backdrop className="UIBackdrop" open={state !== BACKDROP_STATE.HIDDEN}>
        {state === BACKDROP_STATE.LOADING && <Loader {...content} />}
      </Backdrop>
    </Context.Provider>
  );
};

export const useBackdrop = () => useContext(Context);

// @ts-nocheck

import tagsFetch from '../reducers/fetch';
import tagsCreate from '../reducers/create';
import tagsDelete from '../reducers/delete';
import tagsPatch from '../reducers/patch';
import tagsGetById from '../reducers/getById';
import tagsGetObjects from '../reducers/tagsGetObjects';

const tagsHandlers = (store) => ({
  fetch: () => store.dispatch(tagsFetch.dataFetch()),
  create: (names) => store.dispatch(tagsCreate.dataFetch(names)).then((response) => {
    store.dispatch(tagsFetch.dataFetch())
    return response.payload;
  }),
  delete: (id) => store.dispatch(tagsDelete.dataFetch(id)).then((response) => {
    store.dispatch(tagsFetch.dataFetch())
    return response.payload;
  }),
  patch: (id, params) => store.dispatch(tagsPatch.dataFetch([id, params])),
  getById: (id) => store.dispatch(tagsGetById.dataFetch(id)).then((response) => response.payload),
  tagsGetObjects: (id, type) => store.dispatch(tagsGetObjects.dataFetch([id, type])).then((response) => response.payload),
});

export default tagsHandlers;

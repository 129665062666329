// @ts-nocheck

import React from 'react';
import { Provider } from 'react-redux';

import store from 'v2/redux/store';

export default (props) => (
  <Provider store={store}>
    {props.children}
  </Provider>
)

// @ts-nocheck

import React, {
  MouseEvent, useRef, useEffect, useMemo, useState
} from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import VideocamIcon from '@material-ui/icons/Videocam';
import {
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArchiveIcon from '@material-ui/icons/Archive';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DeleteModal from 'pages/locations/DeleteModal';
import { ICamera, ILocationCamera, ItemType } from 'pages/locations/types';
import {
  cameraDragHandler, emptyElement, hideElems, setCameraElName, showElems
} from 'pages/locations/draggableUtils';
import UIHighLightText from 'v2/ui-kit/UIHighLightText/UIHighLightText';

type CameraPageProps = {
  handleCameraSelect: (camera: ILocationCamera) => void;
  handleCameraDelete: (id: string, type: ItemType, parentId?: string) => void;
  setMobileModalOpen: (open: boolean) => void;
  handleArchiveCamera: (camera: ILocationCamera) => void;
  model: ILocationCamera;
  parentId: string;
};

const Camera = ({
  handleCameraDelete, handleCameraSelect, handleArchiveCamera, model, parentId, setMobileModalOpen, search
}: CameraPageProps) => {
  const { name, archived } = model;
  const isActive = useMemo(
    () => (model as ICamera).active,
    [model],
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleModalClick = (value: boolean) => () => {
    setIsDeleteModalOpen(value)
  };

  const handleDragStart = (e: React.DragEvent<HTMLElement>) => {
    e.stopPropagation();
    setCameraElName(name);
    showElems();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(emptyElement, 0, 0);
    e.dataTransfer.setData('application/json', JSON.stringify({ type: 'camera', dragItem: model, isAllocated: true }));
  };

  const deleteCamera = () => {
    handleCameraDelete(model.id, 'camera', parentId);
  };

  const divElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divElementRef.current) {
      divElementRef.current.addEventListener('drag', cameraDragHandler, true);
    }
  }, []);

  const handleMobileModalOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMobileModalOpen(true);
  };

  const handleArchive = () => {
    handleArchiveCamera(model);
    setIsDeleteModalOpen(false)
  };

  return (
    <TreeItem
      nodeId={`${model.id}_${model.nodeId}`}
      onClick={() => {
        handleCameraSelect(model);
      }}
      label={!archived && (
        <Box
          mb={1}
          borderRadius="borderRadius"
          border={1}
          display="flex"
          alignItems="center"
          draggable
          ref={divElementRef}
          onDragStart={handleDragStart}
          onDragEnd={hideElems}
          bgcolor="background.input"
          borderColor="background.primaryBorder"
        >
          <Box p={2} width={1} display="flex" alignItems="center">
            <Box mr={1} display="flex" alignItems="center">
              <DragIndicatorIcon color="primary" />
            </Box>
            <Box mr={1} display="flex" alignItems="center">
              <VideocamIcon color={isActive ? 'primary' : 'secondary'} />
            </Box>
            <Box mr={1} flexGrow={1} alignItems="center" overflow="hidden" whiteSpace="noWrap" textOverflow="ellipsis">
              <UIHighLightText searchText={search} text={name.toUpperCase()} />
            </Box>
            <Box mr={1} display="flex" alignItems="center">
              {archived
                ? (
                  <IconButton onClick={handleModalClick(true)}>
                    <UnarchiveIcon color="primary" />
                  </IconButton>
                )
                : (
                  <IconButton onClick={handleModalClick(true)}>
                    <ArchiveIcon color="primary" />
                  </IconButton>
                )}
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton onClick={deleteCamera}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DeleteModal
            name={name}
            isOpen={isDeleteModalOpen}
            type="camera"
            handleClose={handleModalClick(false)}
            handleDelete={handleArchive}
            isArchiveCamera={archived}
          />
        </Box>
      )}
    />
  );
};

export default React.memo(Camera);

// @ts-nocheck

import { CLOUD } from 'v2/utils/brands';
import { TOKEN_REFRESH_INTERVAL_TIMER } from 'v2/utils/constants';
import moment from 'moment-timezone';
import { checkCredentials } from 'v2/utils/functions';

/**
 * @typedef {Object} IAuthScenario
 * @property {function} init
 * @property {function} clear
 */

/**
 * Кейсы сценариев аутентификации
 * @param {{auth: IAuthAdapter}} source Адаптеры данных
 * @param {Object} externalHandlers - Внешние хэндлеры
 * @return {IAuthScenario}
 */

export default ({ auth }, { setUser, setCompanies }) => {
  let tokenTimer = null;

  const refreshTokenTimer = () => {
    if (localStorage.getItem('access-token')) {
      return auth.refreshToken();
    }

    return Promise.resolve();
  };

  const startTimer = () => {
    clearInterval(tokenTimer);
    tokenTimer = setInterval(() => refreshTokenTimer().catch(() => {}), TOKEN_REFRESH_INTERVAL_TIMER)
  }

  const clearSession = () => {
    clearInterval(tokenTimer);
    auth.logoutUser();
  }

  const authSimilar = async (data) => {
    if (data.user_id) {
      const companies = await auth.companies();

      await startTimer();
      setCompanies(companies);

      return { companies }
    }

    throw Error('Auth failed');
  }

  return ({
    clear: async () => {
      clearSession();
    },
    init: async () => {
      if (checkCredentials()) {
        const { user_id } = await auth.getCurrentUser();
        const user = await auth.getUser(user_id);
        moment.tz.setDefault(user.timezone);

        await startTimer();
        setUser(user);
      } else {
        throw Error('Not credentials for access');
      }
    },
    login: async ({ login, password }) => {
      const data = await auth.login({ login, password });

      return authSimilar(data);
    },
    nxLogin: async (code) => {
      const data = await auth.nxLoginInternal(CLOUD.scope, code);

      if (data.access_token) {
        return authSimilar(data);
      }

      throw Error('NXAuth failed');
    }
  });
}

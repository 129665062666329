// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import { useHistory } from 'react-router-dom';
import { useBackdrop } from '../providers/BackdropProvider';
import useWatchListsQuery from '../hooks/queries/useWatchListsQuery';
import { ROUTES } from '../utils/routes';

const AddWatchLists = () => {
  const history = useHistory();
  const { addWatchLists } = useWatchListsQuery();
  const [formScheme, setFormScheme] = useState();
  const form = useUIForm(formScheme);
  const { showDialog, hideDialog } = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { formState: { isValid, isDirty } } = form;

  useEffect(() => {
    setFormScheme(FORM_SCHEMES.WATCH_LISTS_ADD());
  }, []);

  return (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>Add Watch List</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <UIForm methods={form} scheme={formScheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          Cancel
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid || !isDirty}
          onClick={() => {
            showBackdrop();
            addWatchLists.mutateAsync(form.getValues()).then((response) => {
              history.push(ROUTES.NOTIFICATIONS.ROUTES.WATCH_LISTS_DETAILS.url(response.id));
              showDialog(DIALOGS.ADD_WATCH_LISTS_PREDICATE, { watchList: response, fromCreated: true })
            }).finally(() => {
              hideBackdrop();
            })
          }}
        >
          Submit
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default AddWatchLists;

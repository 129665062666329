// @ts-nocheck

import React, {
  createContext,
  useContext,
} from 'react';
import BasicPopover from 'v2/components/basic/BasicPopover';
import AvatarPopover from 'v2/popovers/AvatarPopover';

const Context = createContext({});

export const POPOVERS = {
  AVATAR_POPOVER: 'AVATAR_POPOVER',

}

export const COMPONENTS = {
  AVATAR_POPOVER: AvatarPopover,
}

export const POPOVERS_PROPS = {
  [POPOVERS.AVATAR_POPOVER]: {}
};

export const PopoverProvider = (props) => {
  const [data, setData] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState();

  const showPopover = (anchorEl, PopoverType, initialData) => {
    setData({
      Component: COMPONENTS[PopoverType],
      initialData,
      popoverProps: POPOVERS_PROPS[PopoverType] || {},
    });
    setAnchorEl(anchorEl);
  }

  const hidePopover = () => {
    setAnchorEl(null)
  }

  const { Component, initialData } = data || {};

  return (
    <Context.Provider value={{
      anchorEl,
      showPopover,
      hidePopover,
    }}
    >
      {props.children}
      {Component && (
      <BasicPopover
        Component={Component}
        anchorEl={anchorEl}
        initialData={initialData}
      />
      )}
    </Context.Provider>
  );
};

export const usePopover = () => useContext(Context);

// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIFitScreenIcon from 'v2/icons/UIFitScreenIcon';

const ButtonROI = ({ isDisabled, onClick }) => (
  <UIWidgetAction byHover tooltip={isDisabled ? 'Detection area on' : 'Detection area off'} isDisabled={isDisabled} onClick={onClick}>
    <UIFitScreenIcon />
  </UIWidgetAction>
);

export default ButtonROI;

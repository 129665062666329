// @ts-nocheck

import React from 'react';
import {
  Box, Grid,
  withStyles, Theme, StyleRules, createStyles, WithStyles
} from '@material-ui/core';

const styles: (theme: Theme) => StyleRules<string> = () => createStyles({
  header: {
    fontSize: '24px',
  },
});

type HomePageProps = {} & WithStyles<typeof styles>;
const HomePage = ({ classes }: HomePageProps) => (
  <Box p={2}>
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid item>
        <div>
          <h2 className={classes.header}>Error 404. Page not found.</h2>
        </div>
      </Grid>
    </Grid>
  </Box>
);

export default withStyles(styles)(HomePage);

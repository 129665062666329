// @ts-nocheck

import axios from 'axios';
import Api from '../../app/api';
import LayoutScenarios from './scenarios/layouts';
import SharedLayouts from './scenarios/sharedLayouts';
import WidgetsScenarios from './scenarios/widgets';
import AuthScenarios from './scenarios/auth';
import HelpScenarios from './scenarios/help';
import FiltersScenarios from './scenarios/filters';
import LayoutAdapter from './adapters/api/layouts';
import WidgetsAdapter from './adapters/api/widgets';
import AuthAdapter from './adapters/api/auth';
import HelpAdapter from './adapters/api/help';
import FiltersAdapter from './adapters/api/filters';

export default (externalHandlers) => {
  const sources = {
    layouts: LayoutAdapter(Api),
    widgets: WidgetsAdapter(Api),
    auth: AuthAdapter(Api),
    filters: FiltersAdapter(Api),
    help: HelpAdapter(axios),
  };

  const scenarios = {
    layouts: LayoutScenarios(sources, externalHandlers),
    sharedLayouts: SharedLayouts(sources, externalHandlers),
    widgets: WidgetsScenarios(sources, externalHandlers),
    auth: AuthScenarios(sources, externalHandlers),
    help: HelpScenarios(sources, externalHandlers),
    filters: FiltersScenarios(sources, externalHandlers),
  };

  return {
    sources,
    scenarios,
  };
}

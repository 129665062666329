// @ts-nocheck
import React, {
  Fragment, FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { Grid } from '@material-ui/core';
import flatMap from 'lodash/flatMap';

import api from 'app/api';
import DetectedObjectsFeed from 'v2/components/WidgetFeed';
import Values from 'v2/widgets/Values/Values';
import Statistics from 'components/Dashboard/Statistics';
import DashboardChart from 'components/Dashboard/DashboardChart';
import LastDetectedObjects from 'components/Dashboard/LastDetectedObjects';
import DashboardWidget from 'components/Dashboard/DashboardWidget';
import AddWidget from 'components/Dashboard/AddWidget';
import {
  IBlocksType, LayoutData, WidgetData, WidgetPostData, WidgetType,
} from './types';

let emptyWidgetId = 0;

const emptyWidget = (): IBlocksType => ({
  component: undefined,
  id: `fake-widget-id-${emptyWidgetId++}`,
  size: 6,
  isResizable: false,
  title: 'Statistic',
  isRealtime: false,
  websocket_url: '',
  type: 'nxstats',
  filters: [],
  timeslice: '',
  state: {},
});

export const componentMapping = {
  hist_chart: DashboardChart,
  line_chart: DashboardChart,
  pie_chart: DashboardChart,
  value: Values,
  face_feed: (props: any) => (
    <DetectedObjectsFeed {...props} />),
  vehicle_feed: (props: any) => (
    <DetectedObjectsFeed {...props} />),
  person_feed: (props: any) => (
    <DetectedObjectsFeed {...props} />),
  last_objects: LastDetectedObjects,
  nxstats: Statistics,
};

const checkResizable = (type: WidgetType): boolean => type !== 'nxstats';

const createElement = (data: WidgetData): IBlocksType => ({
  component: componentMapping[data.type],
  id: data.id,
  // size: data.size[1] * 6,
  isResizable: checkResizable(data.type),
  title: data.name,
  isRealtime: data.online,
  timeslice: data.timeslice,
  filters: data.filters,
  websocket_url: data.websocket_url,
  type: data.type,
  state: data.state,
});

const Dashboard = ({ layout, setLayout }) => {
  const [blocks, setBlocks] = useState<IBlocksType[]>([]);
  const [showComponent, setShowComponent] = useState(true);
  const [cameras, setCameras] = useState(null);

  const getCameras = () => api.get('/device-manager/cameras/').then(({ data }) => data);

  useEffect(() => {
    getCameras().then(setCameras);
  }, []);

  const updateLayout = useCallback(() => {
    Promise.all(
      flatMap(layout.grid)
        .map((widgetId, i) => (widgetId
          ? api.get<WidgetData>(`/layout-manager/widgets/${widgetId}`)
            .then(({ data }) => createElement(data))
          : Promise.resolve(emptyWidget()))),
    ).then((fetchedBlocks) => {
      setBlocks(fetchedBlocks);
    });
  }, [layout]);

  useEffect(() => {
    updateLayout();
  }, [layout]);

  const removeComponent = useCallback((widgetToRemove: string, type: string, size: number) => () => {
    const isWide = size === 12;
    const newLayout: LayoutData = { ...layout, grid: [] };

    if (!layout) return;

    layout.grid.forEach((row) => {
      if (row.includes(widgetToRemove)) {
        newLayout.grid.push(isWide && type !== 'nxstats' ? [null, null] : row.map((widgetId) => (widgetId === widgetToRemove ? null : widgetId)));
      } else {
        newLayout.grid.push(row);
      }
    });

    setLayout(newLayout);
  }, [layout]);

  const addComponent = useCallback((i: any, component: FunctionComponent<any>, widgetPostData: WidgetPostData) => {
    api.post<{ id: string }>('/layout-manager/widgets/', widgetPostData)
      .then(({ data }) => api.get<WidgetData>(`/layout-manager/widgets/${data.id}`))
      .then(({ data }) => {
        let count = 0;
        let row = 0;
        let
          col = 0;
        while (count < i) {
          if (i === 1 && layout.grid[row].length === 2) {
            row = 0;
            col = 1;
            break;
          }
          if (layout.grid[row].length === 2) {
            if (count + 2 === i) {
              col = 0;
              row++;
              break;
            } else if (count + 2 > i) {
              col = 1;
              break;
            } else {
              count += 2;
              row++;
            }
          } else {
            if (count + 1 === i) {
              col = 0;
              row++;
              break;
            } else {
              count++;
              row++;
            }
          }
        }
        layout.grid[row][col] = data.id;
        blocks[i] = createElement(data);

        return layout;
      })
      .then((newLayout) => {
        setLayout({ ...newLayout });
      });
  }, [blocks, layout]);

  const resize = useCallback((i: any) => {
    setShowComponent(false);
    const updatedArray = blocks.slice();
    const widget = updatedArray[i];
    api.patch(`/layout-manager/widgets/${widget.id}`, {
      size: [1, widget.size === 12 ? 1 : 2],
    })
      .then(() => {
        if (updatedArray[i].size === 6) {
          // if this block on the left size - remove right container, if the block is right - conversaly
          if (i % 2 !== 0) {
            updatedArray[i].size = 12;
            updatedArray[i - 1].size = 0;
          } else {
            updatedArray[i].size = 12;
            updatedArray[i + 1] && (updatedArray[i + 1].size = 0);
          }
        } else {
          if (i % 2 !== 0) {
            updatedArray[i].size = 6;
            updatedArray[i - 1].size = 6;
          } else {
            updatedArray[i].size = 6;
            updatedArray[i + 1] && (updatedArray[i + 1].size = 6);
          }
        }
        setBlocks(updatedArray);
        setTimeout(() => {
          updateLayout();
          setShowComponent(true);
        }, 1);
      });
  }, [blocks, updateLayout]);

  const isResizable = useCallback((id: string, type: string, size: number): boolean => {
    if (type === 'nxstats') {
      return false;
    }
    const row = layout.grid.find((row) => row.includes(id));

    if (row && row.includes(null)) {
      return true;
    }
    return row?.length === 1 && size === 12;
  }, [layout.grid]);

  const resizeWidget = useCallback((
    id: string,
    type: string,
    size: number,
    isWidgetResizable: boolean,
    index: number,
  ) => () => {
    if (isResizable(id, type, size) && isWidgetResizable) {
      resize(index);
    }
  }, [isResizable, resize]);

  return (
    <Grid container spacing={1}>
      {blocks.map(
        ({
          id,
          size,
          type,
          title,
          filters,
          component,
          isRealtime,
          websocket_url,
          timeslice,
          isResizable,
          state,
        }, index) => size !== 0 && (
        <Fragment key={`${index}-${filters}`}>
          {!component && (
          <AddWidget
            parentSize={size}
            addComponent={addComponent}
            index={index}
          />
          )}
          {component && (
          <DashboardWidget
            size={size}
            id={id}
            type={type}
            title={title}
            filters={filters}
            index={index}
            showComponent={showComponent && cameras && cameras.length > 0}
            resizeWidget={resizeWidget(id, type, size, isResizable, index)}
            removeComponent={removeComponent(id, type, size)}
            Component={component}
            isRealtime={isRealtime}
            websocket_url={websocket_url}
            timeslice={timeslice}
            isResizable={isResizable}
            updateLayout={updateLayout}
            state={state}
          />
          )}
        </Fragment>
        ),
      )}
    </Grid>
  );
};

export default React.memo(Dashboard);

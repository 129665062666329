// @ts-nocheck

import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const RealtimeSwitch = ({
  isRealtime,
  isDisabled,
  changeRealtime,
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        size="small"
        disabled={isDisabled}
        checked={isRealtime}
        onChange={() => {
          if (changeRealtime !== undefined) {
            changeRealtime(!isRealtime);
          }
        }}
        name="realtime"
        color="primary"
      />
      )}
    label="Auto refresh"
  />
);

export default RealtimeSwitch;

// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

const requests = createSlice({
  name: 'requests',
  initialState: {
    data: {
      pending: {},
      errors: {},
    }
  },
  reducers: {
    setRequests(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export default requests;

// @ts-nocheck

export const FIELD_RULES = {
  REQUIRED: 'required',
  MINLENGTH_2: 'minLength2',
  MINLENGTH_8: 'minLength8',
  MAXLENGTH_100: 'maxLength100',
  MAXLENGTH_500: 'maxLength500',
  ALPHABET: 'alphabet',
  ALPHABET_SPACE_HYPHEN: 'alphabetSpaceHyphen',
  SPACES: 'SPACES',
  UNDERSCORE: 'UNDERSCORE',
  TAG_NAME: 'tagName',
  USER_NAME: 'userName',
  COMPANY_NAME: 'companyName',
  OBJECT_NAME: 'objectName',
  OBJECT_NOTE: 'objectNote',
  LOCATION_NAME: 'locationName',
  LOCATION_NOTE: 'locationNote',
  EMAIL: 'email',
  PASSWORD: 'password',
  LICENSE: 'license',
  CLUSTER_NAME: 'CLUSTER_NAME',
  SUBSCRIPTION_NAME: 'SUBSCRIPTION_NAME',
  CLUSTER_NOTIFICATION_DUPLICATE: 'CLUSTER_NOTIFICATION_DUPLICATE',
};

export const FIELD_VALIDATORS = {
  [FIELD_RULES.REQUIRED]: { required: { value: true, message: 'Field cannot be empty' } },
  [FIELD_RULES.MINLENGTH_2]: { minLength: { value: 2, message: 'Field should be from 2 symbols' } },
  [FIELD_RULES.MINLENGTH_8]: { minLength: { value: 8, message: 'Field should be from  8 symbols' } },
  [FIELD_RULES.MAXLENGTH_100]: { maxLength: { value: 100, message: 'Field should be up to 100 symbols' } },
  [FIELD_RULES.MAXLENGTH_500]: { maxLength: { value: 500, message: 'Field should be up to 500 symbols' } },
  [FIELD_RULES.ALPHABET]: {
    pattern: {
      value: /^[A-Za-z]+$/,
      message: 'Field should contain alphabet characters only',
    },
  },
  [FIELD_RULES.ALPHABET_SPACE_HYPHEN]: {
    pattern: {
      value: /^[A-Za-z\-\s]+$/,
      message: 'Field should contain alphabet characters only',
    },
  },
  [FIELD_RULES.CLUSTER_NAME]: {
    pattern: {
      value: /^[a-zA-Z_0-9]+(?:\s+[a-zA-Z_0-9]+)*$/,
      message: 'The Name field cannot contain special characters and cannot start or end with a space.',
    },
  },
  [FIELD_RULES.TAG_NAME]: {
    pattern: {
      value: /^[0-9a-zA-Z_ ().-]+$/,
      message: 'Field should not contain special characters',
    },
  },
  [FIELD_RULES.COMPANY_NAME]: {
    pattern: {
      value: /^[^ ]+(?:\s[^ ]+)*$/,
      message: 'Field cannot start or end with a whitespace or have more than 1 whitespace in a row',
    },
  },
  [FIELD_RULES.EMAIL]: { pattern: { value: /^[\w\-.]+@([\w-]+\.)+[\w-]{2,}$/, message: 'Invalid format E-Mail' } },
  [FIELD_RULES.PASSWORD]: {
    pattern: {
      value: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).+$/,
      message: 'The field must include at least one number, one uppercase letter, one lowercase letter, and one of the following special characters: !, @, #, $, %, ^, &, *',
    },
  },
  [FIELD_RULES.LICENSE]: {
    pattern: {
      value: /^(([0-9a-fA-F]{8})[-]([0-9a-fA-F]{4})[-]([0-9a-fA-F]{4})[-]([0-9a-fA-F]{4})[-]([0-9a-fA-F]{12}))$/gm,
      message: 'Invalid license key',
    },
  },
  [FIELD_RULES.USER_NAME]: {
    pattern: {
      value: /^[a-zA-Z-]+(?:\s+[a-zA-Z-]+)*$/,
      message: 'The Name field cannot contain less than two letters, as well as special characters and numbers. The name cannot start or end with a space.',
    },
  },
  [FIELD_RULES.SUBSCRIPTION_NAME]: {
    pattern: {
      value: /^[a-zA-Z_0-9]+(?:\s+[a-zA-Z_0-9]+)*$/,
      message: 'The Name field cannot contain less than two letters, as well as special characters and numbers. The name cannot start or end with a space.',
    },
  },
  [FIELD_RULES.OBJECT_NAME]: { pattern: { value: /^[\w|\W]{2,99}$/, message: 'Invalid object name' } },
  [FIELD_RULES.OBJECT_NOTE]: { pattern: { value: /^[\w|\W]{2,99}$/, message: 'Invalid object note' } },
  [FIELD_RULES.LOCATION_NAME]: { pattern: { value: /^[\w|\W]{2,99}$/, message: 'Invalid location name' } },
  [FIELD_RULES.LOCATION_NOTE]: { pattern: { value: /^[\w|\W]{2,99}$/, message: 'Invalid location note' } },
  [FIELD_RULES.CLUSTER_NOTIFICATION_DUPLICATE]: (subscriptions) => ({
    validate: {
      required: (value) => (subscriptions.map((item) => item.name).includes(value) ? 'The name is already in use. Please set a new one' : null),
    },
  }),
};

// @ts-nocheck
import { getProperty, setProperty } from 'dot-prop';

const STORAGE_KEY = 'localData';

const accessStore = (store) => {
  const state = store.getState();
  const { canStorageAccess } = state.userActions.data;

  const data = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {}
  const userCompany = state.userCompany.data;
  const user = state.user.data;

  const makePath = (key) => [user.id, userCompany.id, key].join('.');

  return {
    canStorageAccess,
    data,
    makePath,
  }
}

const storageHandlers = (store) => ({
  get: (key, defaultValue) => {
    const { canStorageAccess, data, makePath } = accessStore(store);

    return canStorageAccess && key ? getProperty(data, makePath(key), defaultValue) : defaultValue;
  },
  set: (key, value) => {
    const { canStorageAccess, data, makePath } = accessStore(store);
    if (canStorageAccess && key) {
      setProperty(data, makePath(key), value);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
    }
  }
});

export default storageHandlers;

// @ts-nocheck

import React, { useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { useDialog } from 'v2/providers/DialogProvider';
import useLicensesQuery from 'v2/hooks/queries/useLicensesQuery';
import { useSnackbar } from 'notistack';
import FormGenerator, { useFormGenerator } from 'v2/ui-kit/UIForm/FormGenerator';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from '../widgetActions/CloseAction';

const AddLicense = () => {
  const form = useFormGenerator();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { addLicense } = useLicensesQuery();
  const { formState: { isValid } } = form;
  const [formScheme] = useState(FORM_SCHEMES.ADD_LICENSE());

  return (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>Activate New License</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <FormGenerator methods={form} scheme={formScheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          Cancel
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            showBackdrop('Activating license');
            addLicense.mutateAsync(form.getValues())
              .then(() => enqueueSnackbar('License has been activated', { variant: 'success' }))
              .finally(() => {
                hideBackdrop();
                hideDialog();
              });
          }}
        >
          Activate
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default AddLicense;

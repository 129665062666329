// @ts-nocheck

import React from 'react';
import { Box, Typography } from '@material-ui/core';

const SmallDisplayAlert = () => (
  <Box width={1} height={1} alignItems="center" justifyContent="center" display="flex" flexDirection="column">
    <Box mb={2}>
      <Typography variant="h3" align="center">Please increase widget size</Typography>
    </Box>
    <Typography variant="h4" align="center">Min container size is 320x240 px</Typography>
  </Box>
);

export default SmallDisplayAlert;

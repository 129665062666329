// @ts-nocheck

import api from 'app/api';
import advancedSearch from '../reducers/advancedSearchFiltersReducer';

const advancedSearchFiltersHandlers = (store) => ({
  fetch: (objectType, data) => api.post(`/object-manager/v2/filters/${objectType}`, data).then((response) => response.data),
  setFilters: (filters) => {
    store.dispatch(advancedSearch.actions.setFilters(filters));
  },
  setObjectType: (objectType) => {
    store.dispatch(advancedSearch.actions.setObjectType(objectType));
  },
});

export default advancedSearchFiltersHandlers;

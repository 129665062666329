// @ts-nocheck

import React, { useRef } from 'react';
import {
  Button,
  Box,
} from '@material-ui/core';

import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { checkFileMIME } from 'v2/utils/functions';
import { ACCEPTED_FILES } from 'v2/utils/constants';
import { useSnackbar } from 'notistack';
import BasicAvatar from './basic/BasicAvatar';
import useUserQuery from '../hooks/queries/useUserQuery';
import { useBackdrop } from '../providers/BackdropProvider';

const UserSettingsAvatar = () => {
  const filePicker = useRef();
  const dialog = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { user, userRemoveAvatar } = useUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  const pickFile = async () => {
    const files = filePicker?.current.files;

    checkFileMIME(files[0], () => {
      dialog.showDialog(DIALOGS.ADD_AVATAR, { file: files[0] });
    }, (results) => {
      enqueueSnackbar(results[0] ? `Unsupported file format: ${results[0]}` : 'Unsupported file format', {
        variant: 'error',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    })
  };

  const pickFileHandler = () => {
    if (filePicker?.current.value) {
      filePicker.current.value = null;
    }
    filePicker?.current.click();
  }

  const handleDeleteAvatar = (hideDialog) => {
    showBackdrop('Removing user avatar');
    userRemoveAvatar.mutateAsync().finally(() => {
      hideDialog();
      hideBackdrop();
    });
  };

  return user && (
    <Box
      width={1}
      height={1}
      position="relative"
    >
      <Box
        width={1}
        p={2}
        top="0"
        position="absolute"
        bgcolor="background.secondary"
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <input
          type="file"
          accept={ACCEPTED_FILES.join(',')}
          ref={filePicker}
          onChange={pickFile}
          hidden
        />
        <Button
          variant="outlined"
          onClick={pickFileHandler}
        >
          {user.photo_url ? 'Replace Photo' : 'Upload Photo'}
        </Button>
        {user.photo_url
          && (
            <Box ml={2}>
              <Button
                variant="outlined"
                onClick={() => dialog.showDialog(DIALOGS.CONFIRMATION, {
                  title: 'Delete User Avatar',
                  text: 'Do you really want to delete this avatar?',
                  onConfirmation: handleDeleteAvatar,
                  confirmText: 'Delete',
                })}
              >
                Remove Photo
              </Button>
            </Box>
          )}
      </Box>
      <Box
        height={1}
        width={1}
        p={2}
        pb={0}
        display="flex"
      >
        <Box
          height={1}
          flex={0}
          borderRadius="50%"
          border={8}
          zIndex={1}
          borderColor="background.main"
          bgcolor="background.secondary"
        >
          <BasicAvatar variant="large" src={user.photo_url} />
        </Box>
      </Box>
    </Box>
  );
}
export default UserSettingsAvatar;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { useDialog } from 'v2/providers/DialogProvider';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import useUserQuery from 'v2/hooks/queries/useUserQuery';

const EditUser = (props) => {
  const { user, userUpdate, userQuery } = useUserQuery();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const [formScheme, setFormScheme] = useState();
  const form = useUIForm(formScheme);

  const { DialogTitle, DialogBody, DialogActions } = props;
  const { formState } = form;

  useEffect(() => {
    user && setFormScheme(FORM_SCHEMES.EDIT_USER(user.first_name, user.last_name))
  }, [user]);

  return (
    <>
      <DialogTitle title="Edit user data" hasCloseIcon />
      <DialogBody className="UIPaddingLess">
        <Box
          width={1}
          height={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          position="relative"
        >
          <UIForm methods={form} scheme={formScheme} />
        </Box>
      </DialogBody>
      <DialogActions>
        <Box width={1} display="flex" justifyContent="space-between">
          <Button
            color="primary"
            variant="outlined"
            onClick={hideDialog}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!formState.isValid || !formState.isDirty}
            onClick={() => {
              showBackdrop('Updating user');
              userUpdate.mutateAsync([user.id, form.getValues()]).then(() => {
                hideDialog();
                enqueueSnackbar('User has been changed', { variant: 'success' });
              }).finally(hideBackdrop);
            }}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </>
  )
};

export default EditUser;

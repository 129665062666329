// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Box, Button, createStyles, Grid, makeStyles, Paper, TextField
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import api from 'app/api';
import CustumField from 'v2/ui-kit/UIForm/fields/CustumField';
import { useModel } from 'v2/providers/ModelProvider';
import { passwordValid, passwordError } from '../../utils/helpers';
import { handlers } from '../../v2/redux/store';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    height: '100vh',
    display: 'flex',
    background: 'url(../img/login-bg.png) no-repeat center center fixed',
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '480px',
    // background: vars.primaryBackground,
    paddingTop: '20px',
    paddingBottom: '2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 9,
  },
  logo: {
    width: '170px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    marginBottom: '1rem',
    '& fieldset': {
      // borderRadius: vars.primaryBorderRadius,
      // border: `1px solid ${vars.primaryBorderColor}`,
    },
  },
  labelInput: {
    // color: vars.primaryBorderColor,
  },
  btn: {
    marginTop: '1rem',
    // textTransform: 'none',
  },
  info: {
    // marginTop: '1rem',
    margin: '1rem auto',
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
}),);

export default function ChangePassword() {
  const history = useHistory();
  const location = useLocation()
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { scenarios } = useModel();
  const [checkData, setCheckData] = useState({
    login: '',
    code: '',
    new_password: '',
  })

  const [checkLogin, setCheckLogin] = useState({
    login: '',
  })

  useEffect(() => {
    handlers.user.clear();
    if (location?.state?.email) {
      setCheckData((prev) => ({ ...prev, login: location.state.email }))
      setCheckLogin((prev) => ({ ...prev, login: location.state.email }))
    } else {
      history.push('/reset-password')
    }
  }, [])

  const {
    login, code, new_password
  } = checkData

  const handleCheckData = (e) => {
    setCheckData({ ...checkData, [e.target.name]: e.target.value });
  };

  const checkInputCode = (valueInput) => (valueInput.match(/^\d+$/g)
  && valueInput.length === 6)

  const ChangePassword = () => {
    api.post('/auth-manager/auth/confirm-forgot-password/', checkData)
      .then((response) => {
        if (response.status === 200) {
          history.push('/log-in/')
        }
      })
      .then(() => enqueueSnackbar('Password changed successfully', { variant: 'success' }))
  }

  const SendCode = () => {
    api.post('/auth-manager/auth/forgot-password/', checkLogin)
      .then(() => enqueueSnackbar('A confirmation code has been sent to your email', { variant: 'success' }))
  }

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.form}>
        <div className={classes.header}>
          <div className="UILogo" />
        </div>
        <span className={classes.info}>We have sent a confirmation code to your email address. Please enter it in the field below to set a new password.</span>
        <TextField
          size="small"
          name="login"
          className={classes.input}
          label="E-Mail"
          InputLabelProps={{
            className: classes.labelInput,
          }}
          variant="outlined"
          value={login}
          onChange={handleCheckData}
          disabled={Boolean(!checkLogin.login || login)}
        />
        <TextField
          size="small"
          name="code"
          className={classes.input}
          label="Confirmation code"
          InputLabelProps={{
            className: classes.labelInput,
          }}
          variant="outlined"
          value={code}
          onChange={handleCheckData}
          error={!checkInputCode(code) && code !== ''}
          helperText={!checkInputCode(code) && code !== '' && 'Incorrect code'}
          disabled={Boolean(!checkLogin.login)}
        />
        <CustumField
          name="new_password"
          label="New password"
          className={classes.input}
          value={new_password}
          onChange={handleCheckData}
          type="password"
          error={!passwordValid(new_password) && new_password !== ''}
          helperText={!passwordValid(new_password) && new_password !== ''
          && passwordError(new_password)}
        />
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          disabled={!checkInputCode(code) || !passwordValid(new_password)}
          onClick={ChangePassword}
        >
          Change password
        </Button>
        {checkLogin.login ? (
          <Box mt={2} width={1}>
            <Button fullWidth variant="contained" color="primary" onClick={SendCode}>
              Send code one more time
            </Button>
          </Box>
        ) : null}
        <Box mt={2} width={1} textAlign="center">
          <NavLink className="UINavLink" to="/log-in">
            Back to Log In
          </NavLink>
        </Box>
      </Paper>
    </Grid>
  )
}

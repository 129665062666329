// @ts-nocheck

import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import userActions from '../reducers/userActions';
import userCompany from '../reducers/userCompany';
import companiesTypes from '../reducers/companiesTypes';
import companies from '../reducers/companies';
import user from '../reducers/user';

const canExperimentalFeatures = () => {
  const branch = process.env.REACT_APP_BRANCH;

  return !branch.match(/^rc-.+/);
}

const canAddCompany = (state) => {
  const {
    companiesTypes: { data: companiesTypes = [] },
    userCompany: { data: userCompany = [] },
  } = state;

  return companiesTypes.filter(({ rank }) => rank > userCompany.rank).length > 0 && userCompany.role === 'admin'
}

const canSwitchCompany = (state) => {
  const {
    companies: { data: companies = [] },
  } = state;

  return companies.filter(({ role }) => !!role).length > 1;
}

const canStorageAccess = (state) => {
  const {
    userCompany: { data: userCompany = [] },
    user: { data: user = {} },
  } = state;

  return user?.id && userCompany?.id && true;
}

const ActionsMiddleware = () => {
  const middleware = createListenerMiddleware()

  middleware.startListening({
    matcher: isAnyOf(
      companiesTypes.dataFetch.fulfilled,
      userCompany.dataFetch.fulfilled,
      companies.dataFetch.fulfilled,
      user.dataFetch.fulfilled,
    ),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState();

      const result = {
        canAddCompany: canAddCompany(state),
        canSwitchCompany: canSwitchCompany(state),
        canStorageAccess: canStorageAccess(state),
        canExperimentalFeatures: canExperimentalFeatures()
      };

      listenerApi.dispatch(userActions.actions.setActions(result))
    },
  });

  return middleware;
}

export default ActionsMiddleware;

// @ts-nocheck
import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const Image = ({ theme }) => (
  <svg width="100%" height="100%" viewBox="0 0 112 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 3C0 1.34315 1.34315 0 3 0H29C30.6569 0 32 1.34315 32 3V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V3Z" fill={theme.palette.primary.main} />
    <path d="M0 43C0 41.3431 1.34315 40 3 40H29C30.6569 40 32 41.3431 32 43V69C32 70.6569 30.6569 72 29 72H3C1.34315 72 0 70.6569 0 69V43Z" fill="#dadada" />
    <path d="M40 3C40 1.34315 41.3431 0 43 0H69C70.6569 0 72 1.34315 72 3V29C72 30.6569 70.6569 32 69 32H43C41.3431 32 40 30.6569 40 29V3Z" fill="#595959" />
    <path d="M40 43C40 41.3431 41.3431 40 43 40H69C70.6569 40 72 41.3431 72 43V69C72 70.6569 70.6569 72 69 72H43C41.3431 72 40 70.6569 40 69V43Z" fill={theme.palette.primary.main} />
    <path d="M80 3C80 1.34315 81.3431 0 83 0H109C110.657 0 112 1.34315 112 3V29C112 30.6569 110.657 32 109 32H83C81.3431 32 80 30.6569 80 29V3Z" fill="#dadada" />
    <path d="M80 43C80 41.3431 81.3431 40 83 40H109C110.657 40 112 41.3431 112 43V69C112 70.6569 110.657 72 109 72H83C81.3431 72 80 70.6569 80 69V43Z" fill="#595959" />
  </svg>
);

export default withTheme(Image);

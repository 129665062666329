// @ts-nocheck

import React from 'react';
import UIAutorenew600Icon from 'v2/icons/UIAutorenew600Icon';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import clsx from 'clsx';

import './AutoRefresh.scss';

const AutoRefresh = ({
  isRealtime,
  isFetching,
  handleRealtimeChange,
  lastUpdateTime,
}) => {
  const className = clsx(
    'UIAutoRefresh',
    isRealtime && 'isRealTime',
    isRealtime && isFetching && 'isFetching',
  );

  return (
    <UIWidgetAction
      byHover
      tooltip={isRealtime ? `Updated: ${lastUpdateTime}` : 'Enable auto refresh'}
      onClick={() => {
        handleRealtimeChange(!isRealtime);
      }}
      className={className}
    >
      <UIAutorenew600Icon />
    </UIWidgetAction>
  );
};

export default AutoRefresh;

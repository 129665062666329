// @ts-nocheck

import React, { useEffect, useState } from 'react';
import {
  Box, IconButton, Input, Menu, MenuItem
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

const TextComboInput = (props) => {
  const customItem = props.items.find(({ value }) => value === '');
  const { isDirty, isTouched } = props.fieldState;

  const [anchorEl, setAnchorEl] = useState();
  const [preset, setPreset] = useState();

  useEffect(() => {
    if ((!isDirty && !isTouched) || !props.field.value) {
      const selectedPreset = props.items.find(({ value }) => value === props.field.value);
      if (selectedPreset && selectedPreset.value) {
        setPreset(selectedPreset)
      } else {
        setPreset();
      }
    }
  }, [props])

  const handleFilterClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSelect = (item) => {
    props.field.onChange(item.value);
    props.field.onBlur();

    if (item.value) {
      setPreset(item);
    } else {
      setPreset();
    }
  };

  return (
    <Box py={1} px={3} width={1} display="flex" justifyContent="center" alignItems="center">
      <Box mr={2}>
        <IconButton
          onClick={handleFilterClick}
        >
          <FilterList />
        </IconButton>
      </Box>
      {
        preset ? (
          <Input
            fullWidth
            disableUnderline
            value={preset.name}
            readOnly
          />
        ) : (
          <Input
            fullWidth
            disableUnderline
            value={props.field.value}
            onBlur={props.field.onBlur}
            onChange={(e) => {
              props.field.onChange(e.target.value);
            }}
            placeholder={customItem.name}
          />
        )
      }
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {
          props.items.map((item, index) => (
            <MenuItem
              key={index}
              selected={item.value === props.field.value}
              onClick={() => {
                handleChangeSelect(item);
                handleClose();
              }}
            >
              {item.name}
            </MenuItem>
          ))
        }
      </Menu>
    </Box>
  );
};

export default TextComboInput;

// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  TransformComponent, TransformWrapper, useControls, useTransformEffect
} from 'react-zoom-pan-pinch';
import clsx from 'clsx';
import ZoomSlider from 'v2/widgetActions/ZoomSlider';
import { OBJECT_TYPES } from 'v2/constants/objectTypes';
import { capitalizeFirstLetter } from 'v2/utils/functions';
import { useDialog } from 'v2/providers/DialogProvider';
import { ROUTES } from 'v2/utils/routes';
import ZoomReset from 'v2/widgetActions/ZoomReset';

import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import UIMetaData from 'v2/ui-kit/UIMetaData/UIMetaData';
import CloseAction from '../widgetActions/CloseAction';
import LinkAction from '../widgetActions/LinkAction';
import ButtonROI from '../widgetActions/ButtonROI';

const Content = ({ object, showRoi, imageBounds }) => (
  <>
    <img
      src={object.image_url}
      style={{
        width: imageBounds.naturalWidth,
        height: imageBounds.naturalHeight,
      }}
    />
    <div
      className={clsx('roi-box', showRoi && 'active')}
      style={{
        left: `${object.roi.x1 * 100}%`,
        top: `${object.roi.y1 * 100}%`,
        right: `${100 - object.roi.x2 * 100}%`,
        bottom: `${100 - object.roi.y2 * 100}%`,
      }}
    />
    {object.meta.license_plate_roi && (
    <div
      className={clsx('roi-box', showRoi && 'active')}
      style={{
        left: `${object.meta.license_plate_roi.x1 * 100}%`,
        top: `${object.meta.license_plate_roi.y1 * 100}%`,
        right: `${100 - object.meta.license_plate_roi.x2 * 100}%`,
        bottom: `${100 - object.meta.license_plate_roi.y2 * 100}%`,
      }}
    />
    )}
  </>
)

const ZoomControls = ({
  minScale, maxScale, stepScale, initialScale
}) => {
  const [stateScale, setStateScale] = useState({});

  const {
    zoomIn, zoomOut, centerView, resetTransform
  } = useControls();

  useTransformEffect(({ state }) => {
    setStateScale({ ...state });
  });

  return (
    <div className="absolute w-64 flex gap-1 items-center p-0.5 rounded text-xs bg-neutral-800/50 bottom-1 cursor-default">
      <ZoomSlider
        minScale={minScale}
        maxScale={maxScale}
        stepScale={stepScale}
        initialScale={initialScale}
        value={stateScale.scale}
        onZoomOut={() => zoomOut(stepScale, 0)}
        onZoomIn={() => zoomIn(stepScale, 0)}
        onUpdate={(value) => centerView(value, 0)}
        onChange={(value) => centerView(value, 0)}
        tooltipFormat={() => `Scale ${Math.floor(stateScale.scale * 100)}%`}
        className="w-full"
      />
      <ZoomReset
        onChange={() => resetTransform(0)}
        disabled={initialScale === stateScale.scale}
      />
    </div>
  )
}

const ExtendedPhoto = (props) => {
  const { hideDialog } = useDialog();
  const container = useRef();
  const [showRoi, setShowRoi] = useState(true);
  const [imageBounds, setImageBounds] = useState();

  const initialScale = 1.0;
  const minScale = 0.1;
  const maxScale = 2.0;
  const stepScale = 0.05;

  const getFaceTitle = (object) => {
    const meta = [];
    if (object.meta.gender) {
      meta.push(capitalizeFirstLetter(object.meta.gender));
    }
    if (object.meta.age) {
      meta.push(object.meta.age);
    }
    if (meta.length) {
      return `Face - ${meta.join(', ')}`;
    }
    return 'Face';
  };
  const getCarTitle = (object) => {
    const meta = [];
    if (object.meta.type) {
      meta.push(capitalizeFirstLetter(object.meta.type));
    }
    if (object.meta.license_plate) {
      meta.push(object.meta.license_plate.toUpperCase());
    }
    if (meta.length) {
      return `Vehicle - ${meta.join(', ')}`;
    }
    return 'Vehicle';
  };

  const getTitleFromObject = (object) => {
    switch (object.type) {
      case OBJECT_TYPES.FACE:
        return getFaceTitle(object);
      case OBJECT_TYPES.VEHICLE:
        return getCarTitle(object);
      case OBJECT_TYPES.PERSON:
        return 'Person';
      default:
        return 'Unknown';
    }
  };

  const toggleRoi = () => setShowRoi((prev) => !prev);

  useEffect(() => {
    if (container.current) {
      const image = new Image();

      image.onload = () => {
        const rect = container.current.getBoundingClientRect();

        const scaleWidth = rect.width / image.naturalWidth;
        const scaleHeight = rect.height / image.naturalHeight;

        const minScale = Math.min(scaleWidth, scaleHeight);

        const zoomFactor = 0.95;
        const scaleFactor = minScale * zoomFactor;

        setImageBounds({
          naturalWidth: image.naturalWidth * scaleFactor,
          naturalHeight: image.naturalHeight * scaleFactor
        });
      };

      image.src = props.object.image_url;
    }
  }, [container]);

  return (
    <UIWidgetContainer className="UIDialog dialog-extended-photo">
      <UIWidgetHeader>
        <UIWidgetTitle>{getTitleFromObject(props.object)}</UIWidgetTitle>
        <UIWidgetRightActions>
          <NavLink to={ROUTES.OBJECT_DETAILS.url(props.object.id)} onClick={hideDialog}>
            <LinkAction />
          </NavLink>
          <ButtonROI onClick={toggleRoi} isDisabled={!showRoi} />
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody
        ref={container}
        className="relative items-center bg-neutral-900 cursor-grab"
      >
        {imageBounds && (
          <TransformWrapper
            centerOnInit
            alignmentAnimation={{ disabled: true }}
            initialScale={initialScale}
            minScale={minScale}
            maxScale={maxScale}
            wheel={{ step: stepScale }}
            doubleClick={{ mode: 'reset' }}
            disablePadding
            smooth={false}
          >
            <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
              <Content object={props.object} showRoi={showRoi} imageBounds={imageBounds} />
            </TransformComponent>
            <ZoomControls minScale={minScale} maxScale={maxScale} stepScale={stepScale} initialScale={initialScale} />
          </TransformWrapper>
        )}
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIMetaData object={props.object} />
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default ExtendedPhoto;

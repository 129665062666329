// @ts-nocheck

import React, {
  useEffect,
  useState,
} from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { createTheme, StylesProvider } from '@material-ui/core/styles';
import { templateTheme, palettes } from 'Theme';

import 'v2/styles/github-markdown-dark.css';

export const ThemeProvider = (props) => {
  const vars = palettes();
  const [palette] = useState(vars);
  const [theme, setTheme] = useState(createTheme(templateTheme(vars)));

  useEffect(() => {
    setTheme(createTheme(templateTheme(palette)));
  }, [palette])

  return (
    <MuiThemeProvider theme={{ ...theme, vars }}>
      <StylesProvider injectFirst>
        <CssBaseline />
      </StylesProvider>
      {props.children}
    </MuiThemeProvider>
  );
};

import { ObjectPropertyType } from 'pages/dashboard/types';
import {
  WIDGET_CHART,
  WIDGET_FEED,
  WIDGET_PIE,
  WIDGET_STATISTICS,
  WIDGET_VALUE,
} from '../description/FiltersCategories';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const SIX_HOURS = 6 * ONE_HOUR;
export const TWELVE_HOURS = 12 * ONE_HOUR;
export const ONE_DAY = 24 * ONE_HOUR;
export const THREE_DAYS = 3 * ONE_DAY;
export const ONE_WEEK = 7 * ONE_DAY;
export const TWO_WEEKS = 14 * ONE_DAY;
export const ONE_MONTH = 31 * ONE_DAY;
export const ONE_YEAR = 12 * ONE_MONTH;

export const UNIX_ONE_MINUTE = 60;
export const UNIX_ONE_HOUR = 60 * UNIX_ONE_MINUTE;
export const UNIX_ONE_DAY = 24;
export const UNIX_ONE_MONTH = 31 * UNIX_ONE_DAY;
export const UNIX_ONE_YEAR = 12 * UNIX_ONE_MONTH;

export const WIDGET_TYPES: ObjectPropertyType = {
  [WIDGET_PIE]: 'pie_chart',
  [WIDGET_VALUE]: 'value',
  [WIDGET_STATISTICS]: 'nxstats',
  [WIDGET_CHART]: {
    line_chart: 'line_chart',
    hist_chart: 'hist_chart',
    column: 'hist_chart',
    line: 'line',
  },
  [WIDGET_FEED]: {
    face: 'face_feed',
    vehicle: 'vehicle_feed',
    person: 'person_feed',
  },
};

export const WIDGET_TO_TYPE = {
  pie_chart: WIDGET_PIE,
  value: WIDGET_VALUE,
  nxstats: WIDGET_STATISTICS,
  line_chart: WIDGET_CHART,
  hist_chart: WIDGET_CHART,
  face_feed: WIDGET_FEED,
  vehicle_feed: WIDGET_FEED,
  person_feed: WIDGET_FEED,
};

export const CHART_REFRESH_TIMEOUT = Number.parseInt(process.env.REACT_APP_CHART_REFRESH_TIMEOUT || '30000', 10);

export const DEFAULT_TIMEZONE = process.env.REACT_APP_DEFAULT_TIMEZONE || 'America/Los_Angeles';

export const EXCLUDED_KEYS = [
  'object_type',
  'timeslice',
  'detalization',
  'detalizationOptionsList',
  'selectedIds',
  'dateRange',
];

export const EXCLUDED_PARSE_FILTERS = ['detalizationOptionsList', 'dateRange', 'selectedIds'];

// @ts-nocheck

import appSettingsReducer from '../reducers/appSettingsReducer';

const appSettingsHandlers = (store) => ({
  setSidebarVisibility: (visible) => {
    store.dispatch(appSettingsReducer.actions.setSidebarVisibility(visible));
  },
});

export default appSettingsHandlers;

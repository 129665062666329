import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => createStyles({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    userSelect: 'none',
    textTransform: 'uppercase',
    fontSize: '9px',
  },
  labelDot: {
    borderRadius: '100%',
    height: '9px',
    width: '9px',
    marginRight: '.5rem',
    marginLeft: '0.5rem',
  },
  disabledLabel: {
    opacity: 0.5,
    textDecoration: 'line-through',
  },
}),);

export type CustomLabelType = {
  color: string;
  name: string;
  isDisabled: boolean,
  handler: () => void,
  handleLabel: () => void,
};

const CustomLabel: React.FC<CustomLabelType> = ({
  name,
  color,
  handler,
  isDisabled,
  handleLabel,
}) => {
  const classes = useStyles();

  return (
  // <div key={name} onClick={handleLabel}>
    <div
      className={clsx(classes.labelContainer, { [classes.disabledLabel]: isDisabled })}
      onClick={() => {
        handler();
        handleLabel();
      }}
    >
      <span className={classes.labelDot} style={{ background: isDisabled ? '#737373' : color }} />
      {name}
    </div>
  // </div>
  );
};

export default CustomLabel;

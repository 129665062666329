// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';
import { FETCH_STATE } from '../../utils/constants';

const clusterNotifications = DataReducer({
  name: 'clusterNotifications',
  dataProvider: async () => {
    const result = await api.post('/notification-manager/v1/watchlists/', {
      pagination: {
        pgsize: Number.MAX_SAFE_INTEGER
      }
    });
    return result.data.items;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching clusterNotifications',
    [FETCH_STATE.REJECTED]: 'Error fetch clusterNotifications',
  }
});
export default clusterNotifications;

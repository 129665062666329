// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Grid } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import { pickBy } from 'lodash/fp';
import moment from 'moment-timezone';
import clsx from 'clsx';

import api from 'app/api';
import { vars } from 'Theme';
import { NXStats } from 'pages/dashboard/types';

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    // backgroundColor: vars.primaryBackground,
    border: `1px solid ${vars.primaryBorderColor}`,
    // borderRadius: vars.primaryBorderRadius,
    height: '182px',
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
  },
  value: {
    fontSize: 40,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '50px',
    },
  },
  circle: {
    width: '80px',
    height: '80px',
  },
  circleContainer: {
    width: '100px',
  },
  ulCircle: {
    height: '25px',
    width: '25px',
    marginRight: '.5rem',
  },
  listContainer: {
    padding: '0 1rem',
  },
  list: {
    maxHeight: '335px',
    overflowY: 'scroll',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 0',
    // borderBottom: `1px solid ${vars.dark4}`,
    fontSize: '1rem',
  },
  itemRight: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    width: '75px',
  },
  storageTitle: {
    textAlign: 'start',
    margin: '1rem 0',
  },
  uptime: {
    width: '80px',
    height: '80px',
    color: vars.primary,
  },
  downtime: {
    width: '80px',
    height: '80px',
    color: vars.primary,
  },
  statContent: {
    width: 'auto',
    padding: 12,
    margin: 0,
  },
  wrapperColumn: {
    [theme.breakpoints.only('lg')]: {
      minWidth: '100%',
    },
  },
  lastColumn: {
    [theme.breakpoints.only('lg')]: {
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  lastElement: {
    marginLeft: 24,
  },
  lastPaper: {
    [theme.breakpoints.only('lg')]: {
      width: '100%',
    },
  },
}));

const Statistics = () => {
  const classes = useStyles();

  const [stats, setStats] = useState<NXStats>();

  useEffect(() => {
    api.get<NXStats>('/stats/statistics/nx/')
      .then((response) => {
        setStats(response.data);
      });

    const statsCalls = setInterval(() => {
      api.get<NXStats>('/stats/statistics/nx/')
        .then((response) => {
          setStats(response.data);
        });
    }, 1000);

    return () => {
      clearInterval(statsCalls);
    };
  }, []);

  return (
    !stats ? null : (
      <Grid container spacing={1} className={classes.statContent}>
        <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
          <div className={classes.paper}>
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
              <span className={classes.title}>CPU</span>
              <span className={classes.value}>
                {Math.round(stats.cpu * 100)}
                %
              </span>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              className={classes.circleContainer}
            >
              <CircularProgressbar
                strokeWidth={12}
                className={classes.circle}
                value={stats.cpu * 100}
                text=""
                styles={buildStyles({
                  pathColor: vars.primary,
                  trailColor: vars.secondary,
                  pathTransitionDuration: 1,
                })}
              />
            </Grid>
          </div>
          <div className={classes.paper}>
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
              <span className={classes.title}>Network</span>
              <span className={classes.value}>
                {Math.round(stats.network * 100)}
                %
              </span>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              className={classes.circleContainer}
            >
              <CircularProgressbar
                strokeWidth={12}
                className={classes.circle}
                value={stats.network * 100}
                text=""
                styles={buildStyles({
                  pathColor: vars.primary,
                  trailColor: vars.secondary,
                  pathTransitionDuration: 1,
                })}
              />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
          <div className={classes.paper}>
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
              <span className={classes.title}>Memory</span>
              <span className={classes.value}>
                {Math.round(stats.mem * 100)}
                %
              </span>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              className={classes.circleContainer}
            >
              <CircularProgressbar
                strokeWidth={12}
                className={classes.circle}
                value={stats.mem * 100}
                text=""
                styles={buildStyles({
                  pathColor: vars.primary,
                  trailColor: vars.secondary,
                  pathTransitionDuration: 1,
                })}
              />
            </Grid>
          </div>
          <div className={classes.paper}>
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
              <span className={classes.title}>Uptime</span>
              <span className={classes.value}>{moment(stats.uptime * 10).format('HH:MM:SS')}</span>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              className={classes.circleContainer}
            >
              {stats.uptime ? <CallMadeIcon className={classes.uptime} />
                : <CallReceivedIcon className={classes.downtime} />}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={6} xl={4} className={classes.wrapperColumn}>
          <Grid container direction="column" className={classes.lastColumn}>
            {stats.storage.map((item, index) => {
              let name: string = '';
              let value: number = 0;
              pickBy((v, k) => {
                name = k;
                value = v;
              }, item);
              return (
                <div
                  key={name}
                  className={clsx(
                    classes.paper,
                    classes.lastPaper,
                    {
                      [classes.lastElement]: stats.storage.length - 1 === index,
                    },
                  )}
                >
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <span className={classes.title}>
                      {name}
                      {' '}
                      Storage
                    </span>
                    <span className={classes.value}>
                      {Math.round(stats.mem * 100)}
                      %
                    </span>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-end"
                    className={classes.circleContainer}
                  >
                    <CircularProgressbar
                      strokeWidth={12}
                      className={classes.circle}
                      value={value * 100}
                      text=""
                      styles={buildStyles({
                        pathColor: vars.primary,
                        trailColor: vars.secondary,
                        pathTransitionDuration: 1,
                      })}
                    />
                  </Grid>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default Statistics;

// @ts-nocheck

/**
 * @typedef {Object} IHelpScenario
 * @property {function} init
 */

/**
 * Кейсы сценариев аутентификации
 * @param {{Help: IHelpAdapter}} source Адаптеры данных
 * @param {Object} externalHandlers - Внешние хэндлеры
 * @return {IHelpScenario}
 */

export default ({ help }, { setHelp }) => ({
  init: async () => {
    try {
      setHelp(await help.fetch());

      return help;
    } catch (e) {
      console.log('Failed Help loading');
    }
  },
})

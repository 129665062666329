// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Card, CardActionArea, CardContent, CardMedia, Hidden, Typography, CircularProgress, Grid
} from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { vars } from 'Theme';
import LocationSelector, { SelectedIds } from 'components/LocationSelector';
import api from 'app/api';
import { CHART_REFRESH_TIMEOUT, DEFAULT_TIMEZONE } from 'utils/constants';
import { MONTH_DAY_YEAR_WITH_SECONDS } from 'utils/timeFormats';
import { parseStringFilters } from './DashboardChart';

type ObjectData = {
  img: string;
  date: string;
  object_id?: string;
};

type LastObjectsFilter = {
  selectedIds: SelectedIds;
}

type Props = {
  id: string;
  filters: string[];
  height?: string;
  isRealtime: boolean;
};

const OBJECTS_SIZE = Number.parseInt(process.env.REACT_APP_OBJECTS_SIZE || '30', 10);

const LastDetectedObjects: React.FunctionComponent<Props> = ({
  id, filters, height, isRealtime
}) => {
  const useStyles = makeStyles({
    root: {
      margin: 10,
      // backgroundColor: vars.primaryBackground,
    },
    cuttedContainer: {
      height: height || '440px',
      overflow: 'auto',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(173px, 1fr))',
      gridAutoRows: 'minmax(150px, auto)',
    },
    media: {
      width: '100%',
      height: '158px',
    },
    title: {
      textAlign: 'left',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: 1.334,
      textTransform: 'uppercase',
    },
    cardContent: {
      padding: `${vars.spacing}px`,
    },
    chartButton: {
      textTransform: 'initial',
      fontSize: '0.975rem',
    },
    chartButtonIcon: {
      marginRight: '.25rem',
    },
    selectorBtnRow: {
      textAlign: 'left',
    },
    feedContent: {
      padding: '1rem',
    },
    preloader: {
      height: '72%',
    },
  });

  const filtersObject = parseStringFilters(filters);

  const type = filtersObject.object_type;

  const defaultLocations = filtersObject.selectedIds ? filtersObject.selectedIds.locations : [];
  const defaultCameras = filtersObject.selectedIds ? filtersObject.selectedIds.cameras : [];
  const [lastObjectsDataLoading, setLastObjectsDataLoading] = useState<boolean>(false);

  const classes = useStyles();

  const [objects, setObjects] = useState<ObjectData[]>([]);
  const [filter, setFilter] = useState<LastObjectsFilter>({
    selectedIds:
      {
        locations: defaultLocations,
        cameras: defaultCameras,
      },
  });
  const [openSelector, setOpenSelector] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<SelectedIds>(filter.selectedIds || {
    locations: [],
    cameras: [],
  });

  const stateRef = useRef<LastObjectsFilter>();
  stateRef.current = filter;

  const parseFilter = ({ selectedIds }: LastObjectsFilter) => {
    let selectedIdsFilter = '';
    Array.isArray(selectedIds.locations) && (selectedIdsFilter += selectedIds.locations.reduce((prev, next) => `${prev}&filters=location:${next}`, ''));
    Array.isArray(selectedIds.cameras) && (selectedIdsFilter += selectedIds.cameras.reduce((prev, next) => `${prev}&filters=camera:${next}`, ''));

    return selectedIdsFilter;
  };

  const prepareLastObjects = (objects_data: any) => {
    const items: ObjectData[] = [];

    objects_data.items.map((item: any) => {
      items.push({
        img: item.image_url,
        date: item.timestamp,
        object_id: item.id,
      });
    });

    return items;
  };

  const getLastObjectsData = (filter = '') => {
    setLastObjectsDataLoading(true);
    const url = `/object-manager/search/?filters=object_type:${type}${filter}&pgsize=${OBJECTS_SIZE}`;

    api.get<any>(url).then((response) => {
      setObjects(prepareLastObjects(response.data));
      setLastObjectsDataLoading(false);
    });
  };

  const updateWidgetFilters = (ids: SelectedIds) => {
    api.patch(`/layout-manager/widgets/${id}`, {
      filters: [
        `object_type:${type}`,
        `locations: ${ids.locations.join(',')}`,
        `cameras: ${ids.cameras.join(',')}`,
      ],
    });
  };

  const handleLocationSelect = (ids: SelectedIds) => {
    const newFilter = {
      selectedIds: ids,
    };
    setFilter(newFilter);
    setSelectedIds({
      ...ids,
    });
    getLastObjectsData(parseFilter(newFilter));
    updateWidgetFilters(ids);
    setOpenSelector(false);
  };

  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout>();

  useEffect(() => {
    getLastObjectsData(parseFilter(filter));
  }, []);

  useEffect(() => {
    if (!isRealtime) {
      intervalRef && clearInterval(intervalRef);
      setIntervalRef(undefined);
    } else {
      intervalRef && clearInterval(intervalRef);
      setIntervalRef(undefined);
      const ref = setInterval(
        () => {
          const filter = stateRef.current || {
            selectedIds: { cameras: [], locations: [] },
          };
          getLastObjectsData(parseFilter(filter));
        },
        CHART_REFRESH_TIMEOUT,
      );
      setIntervalRef(ref);
    }

    return () => {
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
  }, [isRealtime]);

  const history = useHistory();

  return (
    <div className={classes.feedContent}>
      <div className={classes.selectorBtnRow}>
        <Button
          className={classes.chartButton}
          color="primary"
          variant="text"
          onClick={() => setOpenSelector(true)}
        >
          <VideocamIcon color="primary" fontSize="small" className={classes.chartButtonIcon} />
          <Hidden xsDown>
            {selectedIds.cameras.length || selectedIds.locations.length
              ? `Filtered by: ${selectedIds.locations.length} Location${selectedIds.locations.length > 0 ? 's' : ''}, ${selectedIds.cameras.length} Camera${selectedIds.cameras.length > 0 ? 's' : ''}`
              : 'Filter by Cameras/Locations'}
          </Hidden>
        </Button>
        <LocationSelector
          onSelect={handleLocationSelect}
          defaultSelectedIds={selectedIds}
          handleClose={() => setOpenSelector(false)}
          open={openSelector}
        />
      </div>
      {lastObjectsDataLoading
        ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.preloader}
          >
            <Grid item><CircularProgress /></Grid>
          </Grid>
        )
        : (
          <>
            <div className={classes.cuttedContainer}>
              <div className={classes.container}>
                {objects.map((card, i) => {
                  const lastSeen = moment.utc(card.date);

                  return (
                    <Card
                      className={classes.root}
                      key={i}
                      onClick={
                      card.object_id
                        ? () => history.push(`/appearance/${card.object_id}`)
                        : () => {}
                    }
                    >
                      <CardActionArea style={{ cursor: card.object_id ? 'pointer' : 'default' }}>
                        <CardMedia className={classes.media} image={card.img} />
                        <CardContent className={classes.cardContent}>
                          <Typography className={classes.title}>
                            {moment(lastSeen).format(MONTH_DAY_YEAR_WITH_SECONDS)}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                },)}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default LastDetectedObjects;

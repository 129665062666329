// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, createStyles, Grid, makeStyles, TextField, Typography
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';

import api from 'app/api';
import { vars } from 'Theme';
import { useSnackbar } from 'notistack';
import { useAppStore } from 'AppStore';
import CustumField from 'v2/ui-kit/UIForm/fields/CustumField';
import { handlers } from 'v2/redux/store';
import { passwordValid, userValid } from '../../utils/helpers';
import { IUserPatch } from './Profile.interface';

const useStyles = makeStyles(() => createStyles({
  input: {
    marginRight: `${vars.spacing}px`,
    marginBottom: `${vars.spacing}px`,
    width: '100%',
    // textTransform: 'capitalize',
    // borderRadius: vars.primaryBorderRadius,
    // background: vars.backgroundInput,
    '& .MuiFormHelperText-root': {
      textTransform: 'initial',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderRadius: vars.primaryBorderRadius,
        // border: `1px solid ${vars.primaryBorderColor}`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        // borderColor: `${vars.primaryBorderColor}`,
      },
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  inputsContainer: {
    display: 'flex',
  },
  container: {
    // width: '100%',
    // height: '100%',
    // padding: `${vars.spacing}px`,
    // textAlign: 'start',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  saveButton: {
    // textTransform: 'none',
    maxWidth: 'fit-content',
  },
}),);

interface Passwords {
  old_password: string;
  new_password: string;
}

const AccountSettings = ({ refreshUsers }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [VALUES, HANDLERS] = useAppStore();

  const [user, setUser] = useState<any>(null);

  const [oldUserState, setOldUserState] = useState<any>(null);

  const [passwords, setPasswords] = useState<Passwords>({
    old_password: '',
    new_password: '',
  });
  const [disable, setDisable] = React.useState(true);

  const jwt = localStorage.getItem('access-token');

  const history = useHistory();

  useEffect(() => {
    if (jwt) {
      const userId = jwtDecode(jwt).sub || '';
      api.get(`/auth-manager/users/${userId}`).then((response: any) => {
        setOldUserState(response.data)
        setUser(response.data);
      });
    } else {
      history.push('/log-in');
    }
  }, []);

  const {
    old_password, new_password, showPassword, showNewPassword
  } = passwords

  const checkPassword = old_password.length === 0 && new_password.length === 0;

  const callValidPassword = passwordValid(old_password);
  const callValidNewPassword = passwordValid(new_password);

  const nameComparison = user?.first_name !== oldUserState?.first_name;
  const lastNameComparison = user?.last_name !== oldUserState?.last_name;

  const checkValidation = () => {
    if (nameComparison && userValid(user.first_name) && checkPassword) {
      return true
    }
    if (lastNameComparison && userValid(user.last_name) && checkPassword) {
      return true
    }
    return !!(userValid(user.first_name) && userValid(user.last_name) && callValidPassword && callValidNewPassword);
  }

  const handleUpdate = () => {
    const updatedUser: any = {};
    const updatedUserOld: any = {};
    const updatedPassword: any = {};
    const promises = [];
    const userId = jwtDecode(jwt).sub || '';
    setDisable(true)

    if (new_password && old_password) {
      updatedPassword.old_password = old_password;
      updatedPassword.new_password = new_password;
      promises.push(api.post('/auth-manager/auth/change-password/', updatedPassword).then(() => {
        enqueueSnackbar('Password changed successfully', { variant: 'success' });
        clearFields();
      }))
    }

    if (user.first_name && user.last_name) {
      updatedUser.first_name = user.first_name;
      updatedUser.last_name = user.last_name;
      updatedUserOld.first_name = oldUserState.first_name;
      updatedUserOld.last_name = oldUserState.last_name;
      if (user?.first_name === oldUserState?.first_name && user?.last_name === oldUserState?.last_name) {
        return
      }
      promises.push(api.patch<IUserPatch>(`/auth-manager/users/${userId}`, updatedUser).then((userResponse) => {
        enqueueSnackbar('User data changed successfully', { variant: 'success' });
        setUser(userResponse.data)
        setOldUserState(userResponse.data)
      }))
    }

    if (promises.length > 0) {
      if (user?.first_name === oldUserState?.first_name && user?.last_name === oldUserState?.last_name) {
        return
      }
      Promise.all(promises).then(() => {
        HANDLERS.setIsImageChange(!VALUES.isImageChange);
        handlers.user.fetch()
        refreshUsers && refreshUsers();
      }).then(() => setDisable(true)).catch(() => setDisable(true))
    }
  };

  const handlePasswordInput = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    setDisable(false);
  };

  const handleUserInput = (e: any) => {
    e.persist();
    setUser((prevState: any) => ({ ...prevState, [e?.target?.name]: e?.target?.value }));
    setDisable(false);
  };

  const clearFields = () => {
    setPasswords({
      old_password: '',
      new_password: '',
    });
  };

  return user ? (
    <Box p={2} width={1} display="flex" flexDirection="column">
      <Box mb={2}><Typography mb={2}>Account data</Typography></Box>
      <Box mb={2} width={1} display="flex">
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              size="small"
              label="First name"
              variant="outlined"
              name="first_name"
              value={user.first_name}
              onChange={handleUserInput}
              className={classes.input}
              error={!userValid(user.first_name)}
              helperText={!userValid(user.first_name) && 'The First Name field cannot contain less than two letters, as well as special characters and numbers.'}
            />
          </Grid>
          <Grid item xs>
            <TextField
              size="small"
              label="Last name"
              variant="outlined"
              name="last_name"
              value={user.last_name}
              onChange={handleUserInput}
              className={classes.input}
              error={!userValid(user.last_name)}
              helperText={!userValid(user.last_name) && 'The Last Name field cannot contain less than two letters, as well as special characters and numbers.'}
            />
          </Grid>
          <Grid item xs>
            <TextField
              size="small"
              label="E-Mail"
              disabled
              variant="outlined"
              value={user ? user.email : ''}
              className={classes.input}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}><Typography>Change Password</Typography></Box>
      <Box mb={2} width={1} display="flex">
        <Grid container spacing={2}>
          <Grid item xs>
            <CustumField
              label="Old password"
              className={classes.input}
              name="old_password"
              type="password"
              value={old_password}
              onChange={handlePasswordInput}
              error={!passwordValid(old_password) && old_password !== ''}
              helperText={!passwordValid(old_password) && old_password !== ''
           && 'Password should be between 8-99 symbols, contain numbers, uppercase, lowercase and special characters'}
            />
          </Grid>
          <Grid item xs>
            <CustumField
              label="New password"
              className={classes.input}
              name="new_password"
              type="password"
              value={new_password}
              onChange={handlePasswordInput}
              error={!passwordValid(new_password) && new_password !== ''}
              helperText={!passwordValid(new_password) && new_password !== ''
           && 'Password should be between 8-99 symbols, contain numbers, uppercase, lowercase and special characters'}
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        className={classes.saveButton}
        disabled={disable || !checkValidation() || !userValid(user.first_name)
        || !userValid(user.last_name)}
        color="primary"
        variant="contained"
        onClick={handleUpdate}
      >
        Save Changes
      </Button>
    </Box>
  ) : null;
};

export default AccountSettings;

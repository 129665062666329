// @ts-nocheck

import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, CircularProgress,
} from '@material-ui/core';

// TODO добавить возможность прокидывать массив запросов, кнопку для попытки повторить запросы при ошибке, вынести логику в хук
const LayoutError = ({
  requests, showLoader, loaderprops, ErrorComponent, ...props
}) => {
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState('pending');

  const checkErrorsAndStatuses = (requests) => {
    const requestsArray = Array.isArray(requests) ? requests : [requests];
    const currentErrors = [];
    let currentStatus = 'fulfilled';

    requestsArray.forEach((request) => {
      const { error, timestamp, status } = request;

      error && currentErrors.push(error);
      switch (true) {
        case status === 'fulfilled':
          break;
        default:
          currentStatus = 'pending';
          break;
      }
    });
    setErrors(currentErrors);
    setStatus(currentStatus);
  };
  useEffect(() => {
    checkErrorsAndStatuses(requests);
  }, [requests]);

  const DefaultErrorComponent = ({ errors }) => (
    <>
      {errors.map((error) => {
        const { code, message } = error;
        return (
          <Box p={6}>
            {code && (
              <Typography align="center" variant="h5">{`Error ${code}`}</Typography>
            )}
            {message && (
              <Grid container alignItems="center" justifyContent="center" spacing={1}>
                <Box fontSize={20}>
                  {message}
                </Box>
              </Grid>
            )}
          </Box>
        );
      })}
    </>

  );

  const Loader = ({ loaderprops = {} }) => {
    const { text, ...props } = loaderprops;
    return (
      <Box display="flex" height={1} flexDirection="column" alignItems="center" justifyContent="center">
        <Box mb={2}>
          <CircularProgress size={64} {...props} />
        </Box>
        {text && <Typography variant="h5">{text}</Typography>}
      </Box>
    );
  };

  switch (true) {
    case errors.length > 0:
      return ErrorComponent ? <ErrorComponent errors={errors} /> : <DefaultErrorComponent errors={errors} />;
    case status === 'pending' && showLoader:
      return <Loader loaderprops={loaderprops} />;
    case status === 'fulfilled':
      return <>{props.children}</>;
    default:
      return <></>;
  }
};

export default LayoutError;

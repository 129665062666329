// @ts-nocheck

import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment-timezone';
import { vars } from 'Theme';
import { makeStyles } from '@material-ui/core/styles';
import DateTimePicker from 'components/common/DateTimePicker';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px',
    // backgroundColor: vars.backgroundInput,
    // borderRadius: vars.primaryBorderRadius,
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    // color: vars.dark5,
    fontSize: '1rem',
  },
  date: {
    fontSize: '1rem',
  },
  pickersContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  pickerContainer: {
    height: '300px',
    width: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginRight: '16px',
  },
}),);

type ChartDatePickerType = {
  initialValue: [Moment, Moment];
  onChange: (date: [Moment, Moment]) => void;
  date: Moment;
};

const ChartDatePicker = ({ onChange, initialValue: [start, end] }: ChartDatePickerType) => {
  const classes = useStyles();

  const [dateMin, setDateMin] = useState(start);
  const [dateMax, setDateMax] = useState(end);

  function validator(a, b, cb) {
    const min = a ? a.unix() : null;
    const max = b ? b.unix() : null;

    if ((max - min) < 300) {
      cb(min, max);
    }

    if (min >= max) {
      cb(min, max);
    }
  }

  useEffect(() => {
    validator(dateMin, dateMax, (min) => {
      setDateMax(moment.unix(min + 300));
    })

    dateMin && dateMax && onChange([dateMin, dateMax])
  }, [dateMin, onChange]);

  useEffect(() => {
    validator(dateMin, dateMax, (min, max) => {
      setDateMin(moment.unix(max - 300));
    })

    dateMin && dateMax && onChange([dateMin, dateMax])
  }, [dateMax, onChange]);

  return (
    <>
      <div className={classes.pickersContainer}>
        <DateTimePicker initialValue={dateMin} onChange={setDateMin} disableFuture />
        <div className={classes.divider} />
        <DateTimePicker initialValue={dateMax} onChange={setDateMax} disableFuture />
      </div>
    </>
  );
};

export default ChartDatePicker;

// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tagsFetch = DataReducer({
  name: 'tags',
  dataProvider: async () => {
    const result = await api.post('/object-manager/v1/tags-list/', {
      orderings: [
        'name'
      ],
      pagination: {
        pgoffset: 0,
        pgsize: -1
      }
    })
    return result.data.items;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching tags',
    [FETCH_STATE.REJECTED]: 'Error fetch tags',
  }
});
export default tagsFetch;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import CustomSlider from 'components/common/CustomSlider';
import SelectInputWrapper from 'components/SelectInput';
import { triggerOnFilterValueChange } from 'utils/helpers';
import CustumField from 'v2/ui-kit/UIForm/fields/CustumField';
import { Grid } from '@material-ui/core';

const OutputTextFields = ({
  textField,
  handleFilterValueChange,
  value,
  object_type,
  typeWidget,
  errors,
  setErrors
}) => {
  const [age, setAge] = useState([0, 100]);
  const [valid, setValid] = useState(true);
  const maxLen = textField.rendered_name === "Object's details" ? 499 : 99;
  const errorMessage = `The ${textField.rendered_name} field should be between 1-${maxLen} symbols.`;

  useEffect(() => {
    if (textField.type === 'range' && value) {
      setAge(value.split('-').map(Number));
    }
  }, [value])
  useEffect(() => {
    if (textField.type === 'text') {
      if (value?.length > maxLen) {
        setValid(false);
        setErrors({ ...errors, [textField.rendered_name]: true });
      } else {
        setValid(true);
        delete errors[textField.rendered_name]
        setErrors(errors)
      }

      // setValid(() => (value?.length ? value.length <= maxLen : true))
    }
  }, [object_type, value])
  const handleInputChange = (event: any) => {
    handleFilterValueChange({ [textField.name]: event.target.value })
  };
  const handleSliderInputChange = (event, value) => {
    const [from, to] = value as number[];
    setAge(value as number[]);
    triggerOnFilterValueChange({ person_age: `${from}${to ? `-${to}` : ''}` }, handleFilterValueChange);
  }

  const filterTextControl = (textField) => (
    <Grid item key={textField.name}>
      <CustumField
        name={textField.name}
        onChange={(value) => handleFilterValueChange({ [textField.name]: value })}
        handleFilterValueChange={handleInputChange}
        label={textField.rendered_name}
        value={value || ''}
        type="text"
        error={!valid}
        helperText={!valid && errorMessage}
        textFieldName={textField.name === 'name' || textField.name === 'notes' || textField.name === 'vehicle_license_plate'}
      />
    </Grid>
  )

  switch (textField.type) {
    case 'range': {
      return (
        <CustomSlider
          key={textField.name}
          name={textField.name}
          label={textField.rendered_name}
          onChange={handleSliderInputChange}
          value={age}
          type="inputLabel"
        />
      );
    }
    case 'single': {
      const items = textField.values.map((string) => {
        const newString = string.replace('_', ' ');

        return {
          value: string,
          name: newString[0].toUpperCase() + newString.slice(1),
        };
      });

      return (
        <SelectInputWrapper
          defaultValue={textField.default_value}
          key={textField.name}
          name={textField.name}
          onChange={handleInputChange}
          label={textField.rendered_name}
          items={items}
          value={value || textField.default_value}
          emptyItem
        />
      );
    }
    case 'text':
      return filterTextControl(textField)
      // return (
      //   <TextInputWrapper
      //     key={textField.name}
      //     name={textField.name}
      //     onChange={handleInputChange}
      //     label={textField.rendered_name}
      //     value={value}
      //   />
      // )
  }
  // default: {
  //   return null;
  // }
  // }
};

export default OutputTextFields;

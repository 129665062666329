// @ts-nocheck

import React from 'react';
import {
  Typography,
  Button,
  Box, Grid, Slider,
} from '@material-ui/core';
import { BUTTON_TYPES } from 'v2/utils/constants';
import IconButton from '@material-ui/core/IconButton';

const buttonSelector = (action) => {
  switch (action.type) {
    case BUTTON_TYPES.ICON:
      return (
        <IconButton
          component="span"
          onClick={action.onClick}
        >
          <action.Icon />
        </IconButton>
      );
    case BUTTON_TYPES.ICON_START:
    case BUTTON_TYPES.ICON_END:
      return (
        <Button
          color={action.color || 'primary'}
          variant={action.variant || 'outlined'}
          onClick={action.onClick}
          startIcon={action.type === BUTTON_TYPES.ICON_START && <action.Icon />}
          endIcon={action.type === BUTTON_TYPES.ICON_END && <action.Icon />}
        >
          {action.title}
        </Button>
      );
    default:
      return (
        <Button
          color={action.color || 'primary'}
          variant={action.variant || 'outlined'}
          onClick={action.onClick}
        >
          {action.title}
        </Button>
      );
  }
};

const ZoomSlider = (props) => (
  <Box width={50} mx={2}>
    <Slider
      value={props.value}
      onChange={(e, value) => props.onChange(value)}
      step={0.05}
      min={0.5}
      max={1}
    />
  </Box>
);

const BasicWidget = (props) => {
  const gridProps = { xs: 12, ...(props.grid || {}) };

  const style = {
    zoom: props.zoom || 1,
  };

  const padding = props.hasOwnProperty('p') ? props.p : 2;

  const renderActions = (actions, renderProps) => (
    actions.map((action, index) => {
      if (action.hasOwnProperty('visible') ? action.visible : true) {
        return (
          <Box item key={index} {...renderProps} whiteSpace="nowrap">
            {
              buttonSelector(action)
            }
          </Box>
        );
      }

      return null;
    })
  );

  const Header = () => (
    <Box
      p={2}
      width={1}
      bgcolor="background.secondary"
      display="flex"
      alignItems="center"
      style={style}
      minHeight={49}
    >
      {
        props.leftActions && (
          <Box display="flex" alignItems="center">
            {
              renderActions(props.leftActions, { mr: 2 })
            }
          </Box>
        )
      }
      {
        props.title && (
          <Typography className="UIContentHeader" noWrap>
            {props.title}
          </Typography>
        )
      }
      {
        props.rightActions && (
          <Box display="flex" justifyContent="flex-end" alignItems="center" flexGrow={1}>
            {
              renderActions(props.rightActions, { ml: 2 })
            }
          </Box>
        )
      }
    </Box>
  );

  const Footer = () => (props.footerContent ? (
    <Box
      p={2}
      width={1}
      bgcolor="background.secondary"
      display="flex"
      alignItems="center"
      style={style}
    >
      <props.footerContent />
    </Box>
  ) : null);

  const Content = (
    <Box
      position="relative"
      height={props.height}
      borderRadius="borderRadius"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Header />
      <Box p={padding} bgcolor="background.main" height={1} style={style} overflow="auto">
        {props.children}
      </Box>
      <Footer />
    </Box>
  );

  return props.grid ? <Grid item {...gridProps}>{Content}</Grid> : Content;
};

export default BasicWidget;

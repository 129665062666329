import moment from 'moment-timezone';
import ms from 'ms';
import { CUSTOM_DATE_RANGE } from '../components/chart/Chart';

export const yellowFromDateRangeMap = (range = [], timeRange) => {
  if (range?.dateFrom && CUSTOM_DATE_RANGE === timeRange) {
    return [
      moment.unix(Number(range?.dateFrom)),
      moment.unix(Number(range?.dateTo)),
    ];
  }

  return [
    moment().subtract(12, 'h'),
    moment(),
  ];
};

export const yellowToDateRangeMap = (range) => ({
  dateFrom: Number(range[0].unix()),
  dateTo: Number(range[1].unix()),
});

// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIVisibilityIcon from 'v2/icons/UIVisibilityIcon';
import UIVisibilityFilledIcon from 'v2/icons/UIVisibilityFilledIcon';

const ClusterSize = ({ object }) => {
  const {
    cluster_size,
    is_reference,
    parent_id,
    type
  } = object;

  const isRootObject = cluster_size > 1 && is_reference && !parent_id;
  const visible = cluster_size > 1 && type === 'face';

  return visible && (
    <UIWidgetAction
      byHover
      tooltip={isRootObject ? 'Main Grouped Object' : 'Grouped Objects'}
    >
      {isRootObject ? <UIVisibilityFilledIcon /> : <UIVisibilityIcon />}
      {cluster_size}
    </UIWidgetAction>
  )
}

export default ClusterSize;

// @ts-nocheck

import React from 'react';
import SubMenu from 'v2/components/SubMenu';
import { ROUTES } from 'v2/utils/routes';

export default function Settings() {
  return (
    <SubMenu MenuListTitle={ROUTES.SETTINGS.title} MenuListRoutes={ROUTES.SETTINGS.ROUTES} />
  );
}

// @ts-nocheck

import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

import GraphsImage from 'img/graphs';
import LiveFeedImage from 'img/live-feed';
import PieChartWidgetImage from 'img/pie-chart-widget';
import ValueImage from 'img/value';
import BarImage from 'img/widget-bar';
import LineImage from 'img/widget-line';

import DetectedObjectsFeed from 'v2/components/WidgetFeed';
import useNotifications from 'v2/hooks/useNotifications';

import {
  WIDGET_FEED, WIDGET_CHART, WIDGET_PIE, WIDGET_VALUE
} from 'description/FiltersCategories';
import { useDialog, DIALOGS } from 'v2/providers/DialogProvider';
import { WIDGET_TYPES } from 'v2/utils/constants';
import Values from 'v2/widgets/Values/Values';
import DashboardChart from './DashboardChart';
import SelectDialog from './Dialogs/SelectDialog';

const widgets = [
  {
    component: DashboardChart,
    imgUrl: GraphsImage,
    col: 4,
    typeDialog: 'pagination',
    typeWidget: WIDGET_CHART,
    data: {
      size: [1, 1],
      type: 'hist_chart',
      name: 'Bar / Line Chart',
      filters: ['timeslice:12h'],
      timeslice: '',
      state: {
        legend: [],
      },
    },
    images: {
      lineImgUrl: LineImage,
      barImgUrl: BarImage,
    },
  },
  {
    component: DashboardChart,
    imgUrl: PieChartWidgetImage,
    col: 4,
    typeDialog: 'normal',
    typeWidget: WIDGET_PIE,
    data: {
      size: [1, 1],
      type: 'pie_chart',
      name: 'Pie Chart',
      filters: ['chart_type:pie', 'timeslice:12h'],
      timeslice: '',
      state: {
        legend: [],
      },
    },
  },
  {
    component: (props: any) => (<Values filters={props.filters} />),
    imgUrl: ValueImage,
    col: 4,
    typeDialog: 'list',
    typeWidget: WIDGET_VALUE,
    data: {
      size: [1, 1],
      type: 'value',
      name: 'Numeric Value',
      filters: [],
      timeslice: '',
    },
    extended: {
      type: WIDGET_TYPES.NUMERIC,
      name: 'Numeric Value',
    }
  },
  {
    component: (props: any) => (<DetectedObjectsFeed useWebsocket={useNotifications} {...(props as any)} />),
    imgUrl: LiveFeedImage,
    col: 4,
    typeDialog: 'list',
    typeWidget: WIDGET_FEED,
    data: {
      size: [1, 1],
      type: 'vehicle_feed',
      name: 'Live Feed',
      filters: [],
      timeslice: '',
      online: true,
    },
  }
];

const AddWidget = ({ submitHandler }) => {
  const { showDialog } = useDialog();

  const [isFirstState, setIsFirstState] = useState(true);

  const handleAddClick = () => {
    setIsFirstState(false);
    // TODO Ждем правок от бека для перехода на POST
    // showDialog(DIALOGS.WIDGET_TYPES, { widgets, setIsFirstState });
  };

  return (
    <Box width={1} height={1} display="flex" alignItems="center" justifyContent="center" borderRadius="borderRadius" border={1} bgcolor="background.main" borderColor="background.primaryBorder">
      <div>
        <Button className="UIUnDraggable" aria-label="add" onClick={handleAddClick}>
          <AddIcon color="primary" />
          <Typography>Add widget</Typography>
        </Button>
      </div>
      {
        !isFirstState && (
        <SelectDialog
          setIsFirstState={setIsFirstState}
          widgetsStock={widgets}
          submitHandler={submitHandler}
        />
        )
      }
    </Box>
  );
};

export default AddWidget;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import api from 'app/api';
import { Box, Tooltip, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { validationCheck } from 'v2/utils/functions';
import { FIELD_RULES } from 'v2/constants/fieldValidators';
import ContentEditable from './ContentEditable.tsx';

export default function ChangeWidgetTitle({ title, id, updateLayout }) {
  const [widgetTitle, setWidgetTitle] = useState(title);
  const [showTitleEditor, setShowTitleEditor] = useState(false);
  const [refreshEditable, setRefreshEditable] = useState(false);
  const [valid, setValid] = useState(true);
  const [tooltipText, setTooltipText] = useState(title);
  const [openTooltip, setOpenTooltip] = useState(false);

  const onTitleChange = (text) => {
    setWidgetTitle(text.trimLeft())
    const { error, success } = validationCheck(text.trimLeft(), [FIELD_RULES.REQUIRED, FIELD_RULES.MAXLENGTH_100])
    setTooltipText(error)
    setOpenTooltip(!success)
    setValid(success)
  };

  useEffect(() => {
    if (refreshEditable) {
      setRefreshEditable(false)
    }
  }, [refreshEditable])

  useEffect(() => {
    setWidgetTitle(title)
    setTooltipText(title)
  }, [title])

  const handleChangeWidgetTitle = () => {
    // TODO V2 Strong refactor to data flow
    api.patch(`/layout-manager/widgets/${id}`, {
      name: widgetTitle.trimRight(),
    }).then(() => {
      updateLayout();
      setShowTitleEditor(false)
      setTooltipText(widgetTitle)
    })
  };

  const handleClickWidgetTitle = (e) => {
    setShowTitleEditor(true)
    setOpenTooltip(false)
  };

  const handleCancel = () => {
    setOpenTooltip(false);
    setWidgetTitle(title);
    setValid(true);
    setTooltipText(title);
    setShowTitleEditor(false);
    widgetTitle !== title && setRefreshEditable(true);
  }

  return (
    <Box width={1} overflow="hidden" display="flex" alignItems="center">
      <Tooltip open={openTooltip} classes={{ tooltip: valid ? 'UIWidgetTooltip' : 'UIWidgetTooltip Error' }} disableFocusListener disableTouchListener disableHoverListener title={<Box><Typography>{tooltipText}</Typography></Box>}>
        <Box display="flex" mr={2} overflow="hidden">
          <ContentEditable
            variant="h6"
            onClick={handleClickWidgetTitle}
            value={widgetTitle}
            cancel={handleCancel}
            refresh={refreshEditable}
            onChange={onTitleChange}
            onMouseEnter={() => !showTitleEditor && setOpenTooltip(true)}
            onMouseLeave={() => setOpenTooltip(false)}
            showtEditor={showTitleEditor}
          />
        </Box>
      </Tooltip>
      {showTitleEditor && (
      <Box display="flex" alignItems="center" justifyContent="center" mr={2}>
        <IconButton
          className="UIApplyButton"
          mx={2}
          disabled={!valid || widgetTitle.trim() === title}
          onClick={() => {
            handleChangeWidgetTitle()
          }}
        >
          <DoneIcon />
        </IconButton>
        <IconButton
          className="UICancelButton"
          mx={2}
          onClick={() => {
            handleCancel()
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      )}
    </Box>
  )
}

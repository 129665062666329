// @ts-nocheck
import React, { useState } from 'react';

import LocationSelector, { SelectedIds } from 'components/LocationSelector';
import { difference } from 'lodash';
import { parseStringFilters } from './Dashboard/DashboardChart';
import api from '../app/api';

type LiveFeedSettingsProps = {
  id: string | number,
  filters: string[],
  selected?: SelectedIds;
  onChange?: (ids: SelectedIds) => void;
  handleClose: any;
  open: any;
};

const WidgetLocationSelector = ({
  id,
  filters,
  selected,
  onChange,
  handleClose,
  open,
  updateLayout,
}: LiveFeedSettingsProps) => {
  const updateWidgetFilters = (ids: SelectedIds) => {
    const data = [...filters.filter((item) => !item.includes('locations') && !item.includes('cameras'))];

    if (ids?.locations?.length > 0) data.push(`locations:${ids.locations.join(',')}`)
    if (ids?.cameras?.length > 0) data.push(`cameras:${ids.cameras.join(',')}`)

    api.patch(`/layout-manager/widgets/${id}`, {
      filters: data,
    });
  };

  const handleLocationSelect = (ids: SelectedIds) => {
    updateWidgetFilters(ids);
    onChange && onChange(ids);
    updateLayout();
    handleClose();
  };

  return (
    <LocationSelector
      onSelect={handleLocationSelect}
      handleClose={handleClose}
      defaultSelectedIds={selected}
      open={open}
    />
  );
};

export default React.memo(WidgetLocationSelector);

// @ts-nocheck

import handler from './handlers/tagsHandlers';
import tagsCreate from './reducers/create';
import tagsDelete from './reducers/delete';
import tagsFetch from './reducers/fetch';
import tagsPatch from './reducers/patch';
import tagsGetById from './reducers/getById';
import tagsGetObjects from './reducers/tagsGetObjects';

export default {
  handler,
  slices: [tagsCreate, tagsDelete, tagsFetch, tagsPatch, tagsGetObjects, tagsGetById]
}

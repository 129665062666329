// @ts-nocheck

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppStoreProvider } from 'AppStore';
import { PopoverProvider } from 'v2/providers/PopoverProvider';
import { ModelProvider } from 'v2/providers/ModelProvider';
import { ThemeProvider } from 'v2/providers/ThemeProvider';
import { BackdropProvider } from 'v2/providers/BackdropProvider';
import { GridProvider } from 'v2/providers/GridProvider';
import { DialogProvider } from 'v2/providers/DialogProvider';
import { DrawerProvider } from 'v2/providers/DrawerProvider';
import { ToastsProvider } from 'v2/providers/ToastsProvider';
import RestoreSession from 'v2/components/RestoreSession';
import Navigation from 'partials/Navigation';
import ReduxStore from 'v2/providers/ReduxStore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ThemeProvider>
        <ModelProvider>
          <ToastsProvider>
            <GridProvider>
              <ReduxStore>
                <AppStoreProvider>
                  <BackdropProvider>
                    <RestoreSession>
                      <DrawerProvider>
                        <DialogProvider>
                          <PopoverProvider>
                            <Navigation />
                          </PopoverProvider>
                        </DialogProvider>
                      </DrawerProvider>
                    </RestoreSession>
                  </BackdropProvider>
                </AppStoreProvider>
              </ReduxStore>
            </GridProvider>
          </ToastsProvider>
        </ModelProvider>
      </ThemeProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

export default App;

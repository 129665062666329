// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';

const userCompany = DataReducer({
  name: 'userCompany',
  dataProvider: async () => {
    const result = await api.get('/auth-manager/v1/user/company');
    return result.data;
  },
});

export default userCompany;

// @ts-nocheck

import React, {
  createContext, useContext, useEffect, useRef, useState
} from 'react';
import BasicMotion from 'v2/components/basic/BasicMotion';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useDebounce } from '@uidotdev/usehooks';
import useMobile from 'v2/hooks/useMobile';
import { SETTINGS } from 'v2/constants/userState';
import useUserStateQuery from '../hooks/queries/useUserStateQuery';
import AdvancedSearchDrawer from '../pages/AdvancedSearch/AdvancedSearchDrawer';

export const DRAWERS = {
  ADVANCED_SEARCH: 'ADVANCED_SEARCH',
};

export const COMPONENTS = {
  ADVANCED_SEARCH: AdvancedSearchDrawer,
};

const usePanelState = (ComponentID, defaultPanelSize = 30) => {
  const { getUserState, setUserState, userStateQuery } = useUserStateQuery();
  const [initialSize, setInitialSize] = useState(defaultPanelSize);
  const [savedSize, setSavedSize] = useState(defaultPanelSize);
  const debouncedSize = useDebounce(savedSize, 300);

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  useEffect(() => {
    if (userStateQuery.isFetched && ComponentID) {
      const safeSize = getUserState(SETTINGS.DRAWERS[ComponentID], defaultPanelSize);
      setInitialSize(clamp(safeSize, 0, 100));
    } else {
      setInitialSize();
    }
  }, [userStateQuery, ComponentID]);

  useEffect(() => {
    if (ComponentID && initialSize !== debouncedSize) {
      setUserState(SETTINGS.DRAWERS[ComponentID], Math.floor(debouncedSize)).catch();
    }
  }, [debouncedSize]);

  return [initialSize, setSavedSize];
};

const Context = createContext({});

export const DrawerProvider = (props) => {
  const { isMobile } = useMobile();
  const [Component, setComponent] = useState();
  const [savedSize, setDrawerSize] = usePanelState(Component?.id);
  const [isOpen, setOpen] = useState(false);
  const panelRef = useRef();

  const animationDuration = 0.2;

  const hideDrawer = () => {
    setOpen(false);
    setTimeout(setComponent, animationDuration * 1000);
  };

  const showDrawer = (ComponentID) => {
    setOpen(true);
    setComponent({
      Component: COMPONENTS[ComponentID],
      id: ComponentID,
    });
  };

  const minWidth = Math.round((300 * 100) / window.innerWidth);

  return (
    <Context.Provider value={{ showDrawer, hideDrawer }}>
      {props.children}
      {savedSize && Component && (
        <div className="absolute top-0 left-0 bottom-0 right-0 z-10 overflow-hidden">
          <BasicMotion
            key="backdrop"
            isShow={isOpen}
            initial={{ backgroundColor: 'rgba(0,0,0,0)' }}
            animate={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
            transition={{ duration: animationDuration, ease: 'easeIn' }}
            exit={{ backgroundColor: 'rgba(0,0,0,0)' }}
            className="absolute top-0 left-0 bottom-0 right-0"
          />
          <PanelGroup direction="horizontal" className="relative">
            <Panel collapsible>
              <div className="w-full h-full" onClick={hideDrawer} />
            </Panel>
            <Panel
              defaultSize={isMobile ? 100 : savedSize}
              maxSize={isMobile ? 100 : 80}
              onResize={(value) => !isMobile && setDrawerSize(value)}
              ref={panelRef}
              minSize={minWidth}
            >
              <BasicMotion
                key="component"
                isShow={isOpen}
                initial={{ translateX: '100%' }}
                animate={{ translateX: 0 }}
                transition={{ duration: animationDuration, ease: 'easeIn' }}
                exit={{ translateX: '100%' }}
                className="flex h-full"
              >
                {!isMobile && (
                  <PanelResizeHandle className="react-resizable-handler" />
                )}
                <Component.Component />
              </BasicMotion>
            </Panel>
          </PanelGroup>
        </div>
      )}
    </Context.Provider>
  );
};

export const useDrawer = () => useContext(Context);

// @ts-nocheck

import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { Button, Divider, Grid } from '@material-ui/core';

const DefaultDialog = ({
  defaultTimeSlice,
  widgetsStock,
  setIsFirstState,
  setTypeDrawer,
  setDrawerNumber
}) => {
  const handleButtonClick = (widget, index) => () => {
    widget.data.timeslice = defaultTimeSlice;
    setTypeDrawer(widget.typeDialog);
    setDrawerNumber(index);
  }

  return (
    <Dialog
      open
    >
      <Paper variant="outlined">
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Typography align="left">
            Choose widget type
          </Typography>
        </Box>
        <Divider />
        <Box p={2} width={1}>
          <Grid container spacing={2}>
            {widgetsStock.map((widget, index) => (
              <Grid key={widget.data.name} item xs={12} md={6}>
                <Button onClick={handleButtonClick(widget, index)} fullWidth>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box mb={2} width={96} height={96}>
                      <widget.imgUrl />
                    </Box>
                    <Typography>{widget.data.name}</Typography>
                  </Box>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="flex-start">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsFirstState(true)}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default DefaultDialog;

import { useState } from 'react';

const useLocationInput = () => {
  const [locationName, setLocationName] = useState<string>('');
  const [isNewLocationVisible, setIsNewLocationVisible] = useState(false);

  const toggleNewLocation = () => setIsNewLocationVisible(true);
  const closeNewLocation = () => {
    setLocationName('');
    setIsNewLocationVisible(false);
  };

  return {
    locationName,
    setLocationName,
    isNewLocationVisible,
    toggleNewLocation,
    closeNewLocation,
  };
};

export default useLocationInput;

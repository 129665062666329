// @ts-nocheck
import React from 'react'
import clsx from 'clsx';
import UIWarningFilled700Icon from 'v2/icons/UIWarningFilled700Icon';
import UICheckCircleFilled700Icon from 'v2/icons/UICheckCircleFilled700Icon';
import UIInfoFilled700Icon from 'v2/icons/UIInfoFilled700Icon';
import UIDescription700Icon from 'v2/icons/UIDescription700Icon';

import './UISectionMessage.scss'

const variants = {
  success: {
    icon: <UICheckCircleFilled700Icon />,
    class: 'success',
    title: 'Success',
  },
  info: {
    icon: <UIInfoFilled700Icon />,
    class: 'info',
    title: 'Information',
  },
  warning: {
    icon: <UIWarningFilled700Icon />,
    class: 'warning',
    title: 'Warning',
  },
  error: {
    icon: <UIWarningFilled700Icon />,
    class: 'error',
    title: 'Error',
  },
  note: {
    icon: <UIDescription700Icon />,
    class: 'note',
    title: '',
  },
  empty: {
    icon: <UIInfoFilled700Icon />,
    class: 'empty',
    title: 'Information',
  },
}
const UISectionMessage = ({
  title, type, children, className
}) => {
  const variant = variants[type] || variants.empty;

  return (
    <div className={clsx('UISectionMessage', variant.class, className)}>
      {variant.icon}
      <div>
        <div className="title">{title || variant.title}</div>
        <div>{children}</div>
      </div>
    </div>
  )
}
export default UISectionMessage

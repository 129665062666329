// @ts-nocheck

import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { FETCH_STATE } from 'v2/utils/constants';

export const revertAll = createAction('REVERT_ALL')

export default ({ name, dataProvider, messages }) => {
  const dataFetch = createAsyncThunk(
    name,
    async (param, { getState, requestId, rejectWithValue }) => {
      const state = getState()[name];
      if (state.status === FETCH_STATE.PENDING && state.requestId === requestId) {
        try {
          return await dataProvider(param);
        } catch (err) {
          return rejectWithValue(err?.response?.data ? err.response?.data : err)
        }
      }
    },
    {
      condition: (param, { getState }) => {
        const { timestamp, arg } = getState()[name];
        return Date.now() - timestamp > 1000 || param !== arg;
      },
      getPendingMeta: () => ({ messages }),
    },
  );

  dataFetch.messages = messages;

  const initialState = {
    data: [],
    status: FETCH_STATE.IDLE,
    requestId: null,
    error: null,
    arg: null,
    timestamp: 0,
  };

  const dataSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => builder
      .addCase(dataFetch.pending, (state, action) => {
        if (state.status !== FETCH_STATE.PENDING) {
          state.status = FETCH_STATE.PENDING;
          state.requestId = action.meta.requestId;
          state.arg = action.meta.arg;
          // delete state.data;
          delete state.error;
        }
      })
      .addCase(dataFetch.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.status === FETCH_STATE.PENDING && state.requestId === requestId
        ) {
          state.status = FETCH_STATE.FULFILLED;
          // TODO potential problem with periodic loaded data
          state.data = action.payload;
          state.requestId = null;
          state.timestamp = Date.now();
        }
      })
      .addCase(dataFetch.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.status === FETCH_STATE.PENDING && state.requestId === requestId
        ) {
          state.status = FETCH_STATE.REJECTED;
          state.error = action.payload;
          state.requestId = null;
          state.timestamp = Date.now();
        }
      })
      .addCase(revertAll, () => initialState),
  });

  return {
    ...dataSlice,
    dataFetch,
  };
}

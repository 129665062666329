// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tokensCreate = DataReducer({
  name: 'tokensCreate',
  dataProvider: async (name) => {
    const result = await api.post('/auth-manager/v1/gateway_token/', { token_name: name })
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Adding token',
    [FETCH_STATE.REJECTED]: 'Error adding token',
  }
});
export default tokensCreate;

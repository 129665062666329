// @ts-nocheck

import React, { useState, useEffect } from 'react';

import api from 'app/api';
import { parseObjectTypeToObject } from 'utils/helpers'
import DefaultDialog from './DefaultDialog';
import ListDialog from './ListDialog';

const componentSelection = ({
  typeDrawer,
  setIsFirstState,
  widgetsStock,
  defaultTimeSlice,
  parentSize,
  setTypeDrawer,
  drawerNumber,
  setDrawerNumber,
  objectType,
  submitHandler,
  indexWidget,
}) => {
  switch (typeDrawer) {
    case 'default': {
      return (
        <DefaultDialog
          typeDrawer={typeDrawer}
          widgetsStock={widgetsStock}
          defaultTimeSlice={defaultTimeSlice}
          parentSize={parentSize}
          setIsFirstState={setIsFirstState}
          setTypeDrawer={setTypeDrawer}
          setDrawerNumber={setDrawerNumber}
          indexWidget={indexWidget}
          submitHandler={submitHandler}
        />
      );
    }
    default: {
      return (
        <ListDialog
          objectType={objectType}
          drawerNumber={drawerNumber}
          widgetsStock={widgetsStock}
          typeDrawer={typeDrawer}
          setTypeDrawer={setTypeDrawer}
          setIsFirstState={setIsFirstState}
          submitHandler={submitHandler}
          indexWidget={indexWidget}
        />
      );
    }
  }
};

const SelectDrawer = ({
  setIsFirstState, widgetsStock, defaultTimeSlice, parentSize, submitHandler, indexWidget
}) => {
  const [typeDrawer, setTypeDrawer] = useState('default');
  const [drawerNumber, setDrawerNumber] = useState(0);
  const [objectType, setObjectType] = useState([]);

  useEffect(() => {
    api.get('/object-manager/object-types/')
      .then((response) => {
        const objectType = parseObjectTypeToObject(response.data);
        setObjectType(objectType);
      })
  }, []);

  return componentSelection({
    typeDrawer,
    setTypeDrawer,
    setIsFirstState,
    widgetsStock,
    defaultTimeSlice,
    parentSize,
    drawerNumber,
    setDrawerNumber,
    objectType,
    submitHandler,
    indexWidget,
  });
};

export default SelectDrawer;

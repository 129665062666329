// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import EventEmitter from 'eventemitter3';
import {
  createStyles,
  Grid,
  Paper,
  makeStyles,
  Typography,
  Avatar,
  Button,
  Box, Divider,
} from '@material-ui/core';

import Notification from 'components/notification/Notification';
import api from 'app/api';
import { vars } from 'Theme';
import { useAppStore } from 'AppStore';
import Layout from 'partials/Layout';
import UserList from './UserList';
import Licenses from './Licenses';
import ActivateKeyModal from './ActivateKeyModal';
import AddUserModal from './AddUserModal';
import AccountSettings from './AccountSettings';
import { ILicense } from './Profile.interface';
import SelectAvatarDialog from './SelectAvatarDialog';
import DeleteAvatarDialog from './DeleteAvatarDialog';
import AddTokenModal from './AddTokenModal';
import ListOfAvailableCompanies from './ListOfAvailableCompanies';
import TokensList from './TokensList';

const useStyles = makeStyles((theme) => createStyles({
  avatar: {
    height: '120px',
    width: '120px',
    margin: '1rem 0',
  },
  userBio: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${vars.spacing}px`,
  },
  paper: {
    // border: `1px solid ${vars.primaryBorderColor}`,
    // borderRadius: vars.primaryBorderRadius,
    // background: vars.primaryBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%',
  },
  paperWithTable: {
    // border: `1px solid ${vars.primaryBorderColor}`,
    // borderRadius: vars.primaryBorderRadius,
    // background: vars.primaryBackground,
  },
  paperWithTableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${vars.spacing}px`,
    alignItems: 'center',
  },
  paperHeader: {
    // width: '100%',
    // display: 'flex',
    // justifyContent: 'space-between',
    // padding: `${vars.spacing}px`,
  },
  paperText: {
    textTransform: 'capitalize',
    fontSize: 18,
  },
  textBtn: {
    // textTransform: 'capitalize',
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'flex-end',
  },
  textBtnDelete: {
    // textTransform: 'capitalize',
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'flex-end',
  },
  textBtnInvisible: {
    display: 'none',
  },
  buttonIcon: {
    marginRight: '.25rem',
  },
  headerRight: {
    // width: '350px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  divider: {
    width: '100%',
    backgroundColor: vars.primaryBorderColor,
  },
  role: {
    // color: vars.blueGray,
    // marginBottom: `${vars.spacing}px`,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
  },
}),);

const Profile = () => {
  const classes = useStyles();

  const [isOpenSelectAvatar, setIsOpenSelectAvatar] = useState<boolean>(false);
  const [isOpenDeleteAvatar, setIsOpenDeleteAvatar] = useState<boolean>(false);
  const [openKeyModal, setOpenKeyModal] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [hasDemoLicense, setHasDemoLicense] = useState(false);
  const [activeNewLicense, setActiveNewLicense] = useState(false)
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false)
  const [VALUES] = useAppStore()

  const getDemoLicenseState = () => {
    api.get<ILicense[]>('/auth-manager/licenses/')
      .then(
        ({ data }) => {
          const dl = data.find((l) => l.trial);
          setActiveNewLicense(true)
          setHasDemoLicense(!!dl);
        },
      );
  };

  useEffect(() => {
    getDemoLicenseState();
  }, []);

  const handleKeyModalOpen = () => {
    setOpenKeyModal(true);
  };

  const handleKeyModalClose = () => {
    setOpenKeyModal(false);
  };

  const handleAddUserModalOpen = () => {
    setOpenAddUserModal(true);
  };

  const handleAddUserModalClose = () => {
    setOpenAddUserModal(false);
  };

  const usersRefreshEmitter = new EventEmitter();
  const licensesRefreshEmitter = new EventEmitter();
  const tokensRefreshEmitter = new EventEmitter();

  const refreshTokens = () => tokensRefreshEmitter.emit('tokens-refresh');
  const refreshUsers = () => usersRefreshEmitter.emit('users-refresh');
  const refreshLicenses = () => {
    getDemoLicenseState();
    licensesRefreshEmitter.emit('licenses-refresh');
  };

  const notificationRef = useRef<any>();

  const activateDemoLicense = () => {
    api.get('/auth-manager/demo-licenses/')
      .then(() => {
        refreshLicenses();
        if (notificationRef.current) {
          notificationRef.current.openNotification();
        }
      })
  };

  const handleSelectAvatar = () => {
    setIsOpenSelectAvatar((prevState) => !prevState);
  }

  const handleDeleteAvatar = () => {
    setIsOpenDeleteAvatar((prevState) => !prevState);
  }

  return (
    <Layout title="Settings" onlyRoot>
      <Box p={2} width={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.paper} variant="outlined">
              <Box p={2} width={1} height={1} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
                <Box height={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Box p={2}>
                    <Avatar
                      className="MuiAvatar-profile"
                      alt="User image"
                      src={VALUES.currentUser?.photo_url}
                    />
                  </Box>
                  <div>
                    <Typography>
                      {VALUES.currentUser?.first_name}
                      {' '}
                      {VALUES.currentUser?.last_name}
                    </Typography>
                    <Typography className={classes.role}>{VALUES.currentUser?.role === 'admin' ? 'Administrator' : 'Regular User'}</Typography>
                  </div>
                </Box>
                <Grid spacing={2} container justifyContent="center">
                  <Grid item>
                    <Button
                      color="primary"
                      className={classes.textBtn}
                      onClick={handleSelectAvatar}
                    >
                      {VALUES.currentUser?.photo_url ? 'Replace Photo' : 'Upload Photo'}
                    </Button>
                  </Grid>
                  {VALUES.currentUser?.photo_url
                  && (
                  <Grid item>
                    <Button
                      onClick={handleDeleteAvatar}
                    >
                      Remove
                    </Button>
                  </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Paper className={classes.paper} variant="outlined">
              <Box p={2} width={1} display="flex">
                <Typography className={classes.paperText} align="left" variant="h5">
                  General information
                </Typography>
              </Box>
              <Box width={1}>
                <Divider variant="fullWidth" />
              </Box>
              <AccountSettings refreshUsers={refreshUsers} />
            </Paper>
          </Grid>
          {/* <Grid item xs={12}> */}
          {/*  <Box> */}
          {/*    <Paper className={classes.paperWithTable} variant="outlined"> */}
          {/*      <Box p={2} width={1} display="flex" justifyContent="space-between" alignItems="center"> */}
          {/*        <Typography className={classes.paperText} align="left" variant="h5"> */}
          {/*          Users List */}
          {/*        </Typography> */}
          {/*        {VALUES.currentUser?.role === 'gateway' ? null */}
          {/*          : ( */}
          {/*            <div className={classes.headerRight}> */}
          {/*              <Button */}
          {/*                className={classes.textBtn} */}
          {/*                color="primary" */}
          {/*                onClick={handleAddUserModalOpen} */}
          {/*              > */}
          {/*                Add New User */}
          {/*              </Button> */}
          {/*            </div> */}
          {/*          )} */}
          {/*      </Box> */}
          {/*      <AddUserModal */}
          {/*        show={openAddUserModal} */}
          {/*        handleClose={handleAddUserModalClose} */}
          {/*        toggleUsersRefresh={refreshUsers} */}
          {/*      /> */}
          {/*      <UserList usersRefreshEmitter={usersRefreshEmitter} /> */}
          {/*    </Paper> */}
          {/*  </Box> */}
          {/*  /!* <Paper className={classes.paperWithTable} variant="outlined"> *!/ */}
          {/*  /!*  <ListOfAvailableCompanies classes={classes} /> *!/ */}
          {/*  /!* </Paper> *!/ */}
          {/* </Grid> */}
          {/* <Grid item xs={12}> */}
          {/*  <Box mb={2}> */}
          {/*    <Paper className={classes.paperWithTable} variant="outlined"> */}
          {/*      <Box p={2} width={1} display="flex" justifyContent="space-between" alignItems="center"> */}
          {/*        <Typography className={classes.paperText} align="left" variant="h5"> */}
          {/*          Licenses */}
          {/*        </Typography> */}
          {/*        <div className={classes.headerRight}> */}
          {/*          {hasDemoLicense && activeNewLicense */}
          {/*            ? ( */}
          {/*              <> */}
          {/*                <Button */}
          {/*                  className={classes.textBtn} */}
          {/*                  color="primary" */}
          {/*                  onClick={handleKeyModalOpen} */}
          {/*                > */}
          {/*                  Activate new license */}
          {/*                </Button> */}
          {/*              </> */}
          {/*            ) : !hasDemoLicense && activeNewLicense ? ( */}
          {/*              <> */}
          {/*                <Button */}
          {/*                  className={classes.textBtn} */}
          {/*                  color="primary" */}
          {/*                  onClick={() => activateDemoLicense()} */}
          {/*                > */}
          {/*                  Activate demo license */}
          {/*                </Button> */}
          {/*                <Button */}
          {/*                  className={classes.textBtn} */}
          {/*                  color="primary" */}
          {/*                  onClick={handleKeyModalOpen} */}
          {/*                > */}
          {/*                  Activate new license */}
          {/*                </Button> */}
          {/*              </> */}
          {/*            ) : null} */}
          {/*        </div> */}
          {/*      </Box> */}
          {/*      <ActivateKeyModal */}
          {/*        show={openKeyModal} */}
          {/*        handleClose={handleKeyModalClose} */}
          {/*        toggleLicensesRefresh={refreshLicenses} */}
          {/*      /> */}
          {/*      <Licenses licensesRefreshEmitter={licensesRefreshEmitter} /> */}
          {/*    </Paper> */}
          {/*  </Box> */}
          {/*  /!* <Paper className={classes.paperWithTable} variant="outlined"> *!/ */}
          {/*  /!*  <Box p={2} width={1} display="flex" justifyContent="space-between" alignItems="center"> *!/ */}
          {/*  /!*    <Typography className={classes.paperText} align="left" variant="h5"> *!/ */}
          {/*  /!*      Gateway Tokens *!/ */}
          {/*  /!*    </Typography> *!/ */}
          {/*  /!*    <div className={classes.headerRight}> *!/ */}
          {/*  /!*      <Button *!/ */}
          {/*  /!*        className={classes.textBtn} *!/ */}
          {/*  /!*        color="primary" *!/ */}
          {/*  /!*        onClick={() => setOpenAddTokenModal(true)} *!/ */}
          {/*  /!*      > *!/ */}
          {/*  /!*        ADD NEW TOKEN *!/ */}
          {/*  /!*      </Button> *!/ */}
          {/*  /!*    </div> *!/ */}
          {/*  /!*  </Box> *!/ */}
          {/*  /!*  <AddTokenModal *!/ */}
          {/*  /!*    show={openAddTokenModal} *!/ */}
          {/*  /!*    handleClose={() => setOpenAddTokenModal(false)} *!/ */}
          {/*  /!*    toggleTokensRefresh={refreshTokens} *!/ */}
          {/*  /!*  /> *!/ */}
          {/*  /!*  <TokensList tokensRefreshEmitter={tokensRefreshEmitter} /> *!/ */}
          {/*  /!* </Paper> *!/ */}
          {/* </Grid> */}
        </Grid>
        <Notification text="Demo license activated successfully." ref={notificationRef} />
        <SelectAvatarDialog isOpen={isOpenSelectAvatar} handleModel={handleSelectAvatar} />
        <DeleteAvatarDialog isOpen={isOpenDeleteAvatar} handleModel={handleDeleteAvatar} />
      </Box>
    </Layout>
  );
};

export default Profile;

// @ts-nocheck
import { ROUTES } from 'v2/utils/routes';

/**
 * @typedef {Object} IWidgetsBehavior
 * @property {function} init
 * @property {function} create
 * @property {function} update
 * @property {function} delete
 * @property {function} share
 */

/**
 * Кейсы сценариев виджетов
 * @param {{widgets: IWidgetsAdapter}} source Адаптеры данных
 * @param {Object} externalHandlers - Внешние хэндлеры
 * @return {IWidgetsBehavior}
 */

export default ({ widgets }, { setWidgets }) => ({
  init: async () => {
    setWidgets(await widgets.fetchAll());
  },
  create: async (payload) => {
    const data = await widgets.create(payload);

    setWidgets(await widgets.fetchAll());

    return data;
  },
  update: async (payload) => {
    await widgets.update(id, payload);

    setWidgets(await widgets.fetchAll());
  },
  delete: async (id) => {
    await widgets.delete(id);

    setWidgets(await widgets.fetchAll());
  },
  share: async (id, companyID) => {
    const data = await widgets.share(id);

    return new URL(ROUTES.SHARED_WIDGET_WITH_COMPANY.url(companyID, data.id), document.location.href);
  },
});

// @ts-nocheck
import React from 'react';
import { useDialog } from 'v2/providers/DialogProvider';
import ClusterSize from 'v2/widgetActions/ClusterSize';
import ExtendedPhoto from 'v2/widgetActions/ExtendedPhoto';
import OpenInCamera from 'v2/widgetActions/OpenInCamera';
import BadObjectsInfo from 'v2/widgetActions/BadObjectsInfo';

import './UICardOverlay.scss';

export const CARD_OVERLAY_FEED = {
  rightTop: [
    ExtendedPhoto,
    OpenInCamera,
  ],
  leftTop: [
    BadObjectsInfo,
  ],
  rightBottom: [],
  leftBottom: [],
};

export const CARD_OVERLAY_REGULAR = {
  rightTop: [
    ExtendedPhoto,
    OpenInCamera,
  ],
  leftTop: [
    BadObjectsInfo,
  ],
  rightBottom: [],
  leftBottom: [
    ClusterSize,
  ],
};

const makeComponent = (key, Component) => Component && React.cloneElement(Component, { key });

const UICardOverlay = ({ object, overlay = CARD_OVERLAY_REGULAR }) => {
  const { showDialog } = useDialog();
  const data = { object, showDialog };
  const rightTop = (overlay.rightTop || []).map((fn, index) => makeComponent(index, fn(data))).filter((item) => item);
  const rightBottom = (overlay.rightBottom || []).map((fn, index) => makeComponent(index, fn(data))).filter((item) => item);
  const leftBottom = (overlay.leftBottom || []).map((fn, index) => makeComponent(index, fn(data))).filter((item) => item);
  const leftTop = (overlay.leftTop || []).map((fn, index) => makeComponent(index, fn(data))).filter((item) => item);

  return (
    <div className="UICardOverlay">
      {rightTop.length > 0 && (
        <div className="UIGroup right-1 top-1">
          {rightTop}
        </div>
      )}
      {leftTop.length > 0 && (
        <div className="UIGroup left-1 top-1">
          {leftTop}
        </div>
      )}
      {rightBottom.length > 0 && (
        <div className="UIGroup right-1 bottom-1">
          {rightBottom}
        </div>
      )}
      {leftBottom.length > 0 && (
        <div className="UIGroup left-1 bottom-1">
          {leftBottom}
        </div>
      )}
    </div>
  );
};

export default UICardOverlay;

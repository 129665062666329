// @ts-nocheck

import React, { useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { useDialog } from 'v2/providers/DialogProvider';
import { Box, Button } from '@material-ui/core';
import { handlers } from 'v2/redux/store';
import { useSnackbar } from 'notistack';
import FormGenerator, { useFormGenerator } from 'v2/ui-kit/UIForm/FormGenerator';
import { useBackdrop } from 'v2/providers/BackdropProvider';

const AddTag = (props) => {
  const form = useFormGenerator();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const {
    DialogTitle, DialogBody, DialogActions, object, tags
  } = props;
  const { formState: { isValid } } = form;

  const [formScheme] = useState(FORM_SCHEMES.ADD_TAG());

  const Content = (
    <Box width={1} height={1} display="flex" alignItems="center" justifyContent="center" overflow="hidden" position="relative">
      <FormGenerator methods={form} scheme={formScheme} />
    </Box>
  );

  const Actions = (
    <Box width={1} display="flex" justifyContent="space-between">
      <Button
        color="primary"
        variant="outlined"
        onClick={hideDialog}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={!isValid}
        onClick={() => {
          showBackdrop('Adding tag');
          const values = Object.values(form.getValues());
          handlers.tags.create(values).then(([tag]) => {
            object && tags && handlers.tags.bind(object, [...tags, tag.name])
            hideDialog();
            enqueueSnackbar('Tag has been added', { variant: 'success' });
          }).finally(hideBackdrop)
        }}
      >
        Add
      </Button>
    </Box>
  )
  return (
    <>
      <DialogTitle title="Add New Tag" hasCloseIcon />
      <DialogBody className="UIPaddingLess">
        {Content}
      </DialogBody>
      <DialogActions>
        {Actions}
      </DialogActions>
    </>
  )
};

export default AddTag;

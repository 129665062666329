// @ts-nocheck
import api from 'app/api';
import { QUERY_STALE_TIME } from 'v2/constants/constQueries';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['userCompany'];

const useUserCompanyQuery = () => {
  const userCompanyQuery = useBasicQuery({
    queryKey: [...QUERY_KEYS],
    queryFn: async () => {
      const result = await api.get('/auth-manager/v1/user/company');

      return result.data;
    },
    staleTime: QUERY_STALE_TIME,
  })

  return {
    userCompany: userCompanyQuery.data,
    userCompanyQuery,
  }
};

export default useUserCompanyQuery;

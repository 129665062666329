// @ts-nocheck
import { useInfiniteQuery } from '@tanstack/react-query';
import api from 'app/api';
import { FEED_LENGTH } from 'v2/utils/constants';
import useAdvancedSearchFiltersQuery from 'v2/hooks/queries/useAdvancedSearchFiltersQuery';
import { filtersConverter } from 'v2/utils/filtersConverter';
import { clearFilters } from 'v2/utils/functions';
import { v4 } from 'uuid';

const POST = (objectType, filters, pagination) => api.post(`/object-manager/v2/search/${objectType}`, {
  ...clearFilters(filters),
  pagination,
})

const GET = (objectType, filters, pagination) => api.get(`/object-manager/search?${filtersConverter(objectType, filters, pagination)}`)

const useAdvancedSearchResultsQuery = () => {
  const {
    setFilters,
    setObjectType,
    filtersQuery,
    objectTypeQuery
  } = useAdvancedSearchFiltersQuery();

  const resultsQuery = useInfiniteQuery({
    queryKey: ['searchObjects', objectTypeQuery.data, filtersQuery.data],
    queryFn: (args) => POST(objectTypeQuery.data, filtersQuery.data[objectTypeQuery.data], {
      pgoffset: (args.pageParam || 0) * FEED_LENGTH,
      pgsize: FEED_LENGTH,
    }).then((response) => response.data.items.map((item) => ({ ...item, key: v4() }))),
    enabled: Boolean(objectTypeQuery.data && filtersQuery.data),
    getNextPageParam: (lastPage, pages) => (lastPage.length === FEED_LENGTH ? pages.length : undefined),
    select: (data) => (data.pages ? data.pages.flat() : []),
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    setFilters,
    filtersQuery,

    setObjectType,
    objectTypeQuery,

    resultsQuery,
  };
};

export default useAdvancedSearchResultsQuery;

// @ts-nocheck

import React from 'react';
import clsx from 'clsx';
import { Box, Button, Typography } from '@material-ui/core';

import './UIBasicWidget.scss';
import UIInfoIcon from 'v2/icons/UIInfoIcon';

// TODO REMOVE MUI
export const UIWidgetPlaceholderContent = (props) => {
  const Icon = props.icon;
  const iconSize = props.size || 128;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Icon
        color="primary"
        style={{ fontSize: iconSize }}
        size={iconSize}
        onClick={() => props.onIconClick && props.onIconClick()}
      />
      {
        props.title && (
          <Box fontSize={24} p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {props.title}
          </Box>
        )
      }
      {
        props.subTitle && (
          <Box
            fontSize={16}
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            whiteSpace="pre"
          >
            {props.subTitle}
          </Box>
        )
      }
      {(props.onRetry || props.hideBackdrop)
        && (
          <Box mt={8} display="flex" alignItems="center" justifyContent="center">
            {props.onRetry
              && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.onRetry}
                >
                  {props.onRetryTitle || 'Retry'}
                </Button>
              )}
            {props.onRetry && props.hideBackdrop
              && (
                <Box mx={4}>
                  <Typography>or</Typography>
                </Box>
              )}
            {props.hideBackdrop
              && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={props.hideBackdrop}
                >
                  Close
                </Button>
              )}
          </Box>
        )}
    </Box>
  );
};

export const UIWidgetContainer = (props) => (
  <div className={clsx('UIWidgetContainer', props.className)}>
    {props.children}
  </div>
);

export const UIWidgetOverlap = (props) => (
  <div className={clsx('UIWidgetOverlap', props.className)}>
    {props.children}
  </div>
);

export const UIWidgetInfo = (props) => (
  <div className={clsx('UIWidgetAlert', props.className)}>
    <UIInfoIcon />
    {props.children}
  </div>
);

export const UIWidgetPlaceholder = (props) => (
  <div className={clsx('UIWidgetPlaceholder', props.className)}>
    {props.children}
  </div>
);

export const UIWidgetLeftActions = (props) => props.children && (
  <div className={clsx('UIWidgetLeftActions', props.className)}>
    {props.children}
  </div>
);

export const UIWidgetRightActions = (props) => props.children && (
  <div className={clsx('UIWidgetRightActions', props.className)}>
    {props.children}
  </div>
);

export const UIWidgetTitle = (props) => (
  <div className={clsx('UIWidgetTitle', props.className)}>
    {props.children}
  </div>
);

export const UIWidgetHeader = React.forwardRef((props, ref) => (
  <div
    className={clsx('UIWidgetHeader', props.className)}
    ref={ref}
  >
    {props.children}
  </div>
));

export const UIWidgetBody = React.forwardRef(({
  children, className, style, ...props
}, ref) => (
  <div
    {...props}
    className={clsx('UIWidgetBody', className)}
    style={style}
    ref={ref}
  >
    {children}
  </div>
));

export const UIWidgetFooter = React.forwardRef((props, ref) => (
  <div
    className={clsx('UIWidgetFooter', props.className)}
    style={props.style}
    ref={ref}
  >
    {props.children}
  </div>
));

import React, { forwardRef, useImperativeHandle } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type NotificationProps = {
  text: string;
  type?: 'success' | 'info' | 'warning' | 'error' | undefined;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Notification = forwardRef((props: NotificationProps, ref) => {
  const [open, setOpen] = React.useState(false);

  useImperativeHandle(ref, () => ({
    openNotification() {
      setOpen(true);
    },
  }));

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity={props.type ? props.type : 'success'}
          action={(
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        >
          {props.text}
        </Alert>
      </Snackbar>
    </div>
  );
});

export default Notification;

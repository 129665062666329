// @ts-nocheck

import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FIELD_TYPES } from 'v2/constants/fieldTypes';
import { FormSchemeToValues } from 'v2/utils/functions';
import TextInput from 'v2/ui-kit/UIForm/fields/TextInput';
import TextArea from 'v2/ui-kit/UIForm/fields/TextArea';
import TextPassword from 'v2/ui-kit/UIForm/fields/TextPassword';
import TextCombo from 'v2/ui-kit/UIForm/fields/TextCombo';
import SelectSingle from 'v2/ui-kit/UIForm/fields/SelectSingle';
import SelectMultiple from 'v2/ui-kit/UIForm/fields/SelectMultiple';
import SliderRange from 'v2/ui-kit/UIForm/fields/SliderRange';
import AutoComplete from 'v2/ui-kit/UIForm/fields/AutoComplete';
import TagsAutocomplete from 'v2/ui-kit/UIForm/fields/TagsAutocomplete';
import SelectDate from 'v2/ui-kit/UIForm/fields/SelectDate';
import SelectCameras from 'v2/ui-kit/UIForm/fields/SelectCameras';

// TODO Выпилить на другой компонент из UI-Kit
const FieldSelector = (props) => {
  switch (props.type) {
    case (FIELD_TYPES.TEXT_INPUT):
      return <TextInput {...props} />;
    case (FIELD_TYPES.TEXT_AREA):
      return <TextArea {...props} />;
    case (FIELD_TYPES.TEXT_PASSWORD):
      return <TextPassword {...props} />;
    case (FIELD_TYPES.TEXT_COMBO):
      return <TextCombo {...props} />;
    case (FIELD_TYPES.SELECT_SINGLE):
      return <SelectSingle {...props} />;
    case (FIELD_TYPES.SELECT_MULTIPLE):
      return <SelectMultiple {...props} />;
    case (FIELD_TYPES.SELECT_DATE):
      return <SelectDate {...props} />;
    case (FIELD_TYPES.SELECT_CAMERAS):
      return <SelectCameras {...props} />;
    case (FIELD_TYPES.SLIDER_RANGE):
      return <SliderRange {...props} />;
    case (FIELD_TYPES.AUTO_COMPLETE):
      return <AutoComplete {...props} />
    case (FIELD_TYPES.TAGS_AUTO_COMPLETE):
      return <TagsAutocomplete {...props} />
    default:
      return null;
  }
};

const FormGenerator = ({
  scheme, methods, onClose, watch, onChange, ...args
}) => {
  useEffect(() => {
    if (scheme) {
      methods.reset(FormSchemeToValues(scheme), { keepDirty: true });
      onChange && onChange();
    }
  }, [scheme, methods]);

  useEffect(() => {
    const subscription = methods.watch((...args) => {
      onChange && onChange(...args);
    });

    return () => subscription.unsubscribe();
  }, [methods.watch, scheme]);

  const canRender = (scheme || []).length > 0;

  return canRender && (
    <FormProvider {...methods}>
      <div className="form-generator">
        {(scheme || []).map((field, index) => (
          FieldSelector({
            ...field,
            onClose,
            control: methods.control,
            key: index
          })
        ))}
      </div>
    </FormProvider>
  );
};

export const useFormGenerator = () => useForm({
  mode: 'all',
});

export default FormGenerator;

// @ts-nocheck

import React from 'react';
import HTMLParser, { domToReact } from 'html-react-parser';
import Layout from 'partials/Layout';
import { isEmpty } from 'lodash';
import {
  NavLink, Route, Switch, useLocation,
} from 'react-router-dom';
import {
  Box, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import BasicWidget from 'v2/components/basic/BasicWidget';
import 'v2/ui-kit/UIHTMLStyles/UIHTMLStyles.scss';
import UISectionMessage from 'v2/ui-kit/UISectionMessage/UISectionMessage';
import UICodeBlock from 'v2/ui-kit/UICodeBlock/UICodeBlock';
import UILinkPreview from 'v2/ui-kit/UILinkPreview/UILinkPreview';
import UIHelpCollapsed from '../ui-kit/UIHelpCollapsed/UIHelpCollapsed';
import UIHelpImage from '../ui-kit/UIHelpImage/UIHelpImage';

const parserOptions = (links = false) => ({
  transform: (reactNode, domNode, index) => {
    const {
      attribs,
      name,
      type,
      parent,
      children
    } = domNode;

    // Remove default title h1
    const isHeader = name === 'h1' && type === 'tag';
    if (isHeader) {
      return <></>;
    }

    // FAQ collapsed
    const isExpandContainer = attribs?.class?.includes('expand-container') && !parent;
    if (isExpandContainer) {
      const title = domToReact(children[0]?.children);
      const content = domToReact(children[1]?.children, parserOptions(links));

      return (
        <UIHelpCollapsed headerText={title}>
          <div>{content}</div>
        </UIHelpCollapsed>
      );
    }

    // Image
    const isImage = name === 'img' && type === 'tag';
    if (isImage) {
      return <UIHelpImage src={attribs.src} />;
    }

    // SectionMessage, return include parserOptions for find links
    const isBlockMessagePanel = attribs?.['data-macro-name'] === 'panel' && !parent;
    if (isBlockMessagePanel) {
      const panelContent = children?.[0]?.attribs?.class === 'panelContent';
      if (panelContent) {
        delete children[0].attribs.style;
      }
      return (
        <UISectionMessage
          type="note"
          className="mb-2"
        >
          {domToReact(children, parserOptions(links))}
        </UISectionMessage>
      );
    }

    const isBlockMessage = attribs?.class?.includes('confluence-information-macro') && !parent;
    if (isBlockMessage) {
      const listOfClass = {
        'aui-iconfont-warning': 'warning',
        'aui-iconfont-info': 'info',
        'aui-iconfont-error': 'error',
        'aui-iconfont-success': 'success'
      };

      const messageClass = Object.keys(listOfClass).find((className) => (
        children[0]?.attribs?.class?.includes(className)
      ));

      const type = listOfClass[messageClass] || 'empty';
      return (
        <UISectionMessage
          title=""
          type={type}
          className="mb-2"
        >
          {domToReact(children, parserOptions(links))}
        </UISectionMessage>
      );
    }

    // Link Preview
    const isLink = name === 'a' && type === 'tag';

    if (isLink) {
      return <UILinkPreview url={attribs.href} links={links} />;
    }

    // CodeBlock
    const isCodeBlock = (name === 'code' || name === 'pre') && type === 'tag';

    if (isCodeBlock) {
      return <UICodeBlock>{domToReact(children)}</UICodeBlock>;
    }

    return reactNode;
  },
});

const SubMenu = ({ MenuListTitle, MenuListRoutes }) => {
  const { pathname } = useLocation();

  const MenuList = ({ url, title, ROUTES }) => (
    !isEmpty(ROUTES) && (
      <BasicWidget title={title} p={0}>
        <List
          component="nav"
        >
          {Object.values((ROUTES)).map((item, index) => (!item.hideLink) && (
          <NavLink
            key={index}
            className="UIMenuLink"
            exact={item.exact}
            to={item.path}
          >
            <ListItem className="UIListItem" button selected={url === item.path}>
              <ListItemIcon>
                {item.Icon ? <item.Icon /> : <HelpOutline />}
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </NavLink>
          ))}
        </List>
      </BasicWidget>
    )
  );

  const MenuContent = (ROUTES) => Object.values(ROUTES).map((item, index) => (
    <Route
      key={index}
      path={item.path}
      exact
      component={item.component ? item.component : () => (
        <div className="m-2">
          <BasicWidget title={item.title} p={0}>
            <Box p={2} overflow="auto">
              <div className="UIHTMLStyles">
                {HTMLParser(item.content, parserOptions(item.links))}
              </div>
            </Box>
          </BasicWidget>
        </div>
      )}
    />
  ));

  return (
    <Layout>
      <Box height={1} width={1} display="flex" overflow="hidden">
        <Box height={1} p={2} pr={0}>
          <Box minWidth={300} height={1} overflow="hidden" borderRadius="borderRadius" bgcolor="background.main">
            <MenuList title={MenuListTitle} ROUTES={MenuListRoutes} url={pathname} />
          </Box>
        </Box>
        <Box height={1} width={1} overflow="auto">
          <Switch>
            {MenuContent(MenuListRoutes)}
          </Switch>
        </Box>
      </Box>
    </Layout>
  );
};
export default SubMenu;

// @ts-nocheck

import licenses from '../reducers/licenses';
import api from '../../../app/api';

const licensesHandlers = (store) => ({
  fetch: () => store.dispatch(licenses.dataFetch()),
  activateDemoLicense: () => api.get('/auth-manager/demo-licenses/').then((response) => {
    store.dispatch(licenses.dataFetch());
    return response.data;
  }),
  addLicense: ({ key }) => api.patch(`/auth-manager/licenses/${key}`).then((response) => {
    store.dispatch(licenses.dataFetch())
    return response.data;
  }),
});

export default licensesHandlers;

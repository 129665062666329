// @ts-nocheck

import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, Checkbox, Dialog, Divider, Grid, Paper, TextField, Typography
} from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import {
  difference, flatMap, includes, intersection, uniq
} from 'lodash/fp';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import VideocamIcon from '@material-ui/icons/Videocam';
import UIHighLightText from 'v2/ui-kit/UIHighLightText/UIHighLightText';
import UISectionMessage from 'v2/ui-kit/UISectionMessage/UISectionMessage';

import { ILocation, ILocationCamera } from '../pages/locations/types';
import { vars } from '../Theme';
import useDevicesQuery from '../v2/hooks/queries/useDevicesQuery';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    // background: vars.primaryBackground,
    border: `1px solid ${vars.primaryBorderColor}`,
    width: '480px',
  },
  desc: {
    // fontSize: '14px',
    // color: vars.textGray,
    // margin: `${`${vars.spacing}px`} 0`,
  },
  infoDesc: {
    fontSize: '14px',
    color: vars.white,
  },
  treeItemRoot: {
    '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'unset',
    },
    '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content': {
      // backgroundColor: vars.dark5,
    },
    '&.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'unset',
    },
  },
  treeItemLoc: {
    display: 'flex',
    background: '#181E20',
    borderRadius: '.25rem',
    padding: vars.spacing,
    marginTop: '.5rem',
  },
  locContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  locLabel: {
    paddingLeft: 0,
    height: '100%',
    overflow: 'hidden',
    '&:hover': {
      background: 'none',
    },
  },
  treeItemCamera: {
    display: 'flex',
    background: '#181E20',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '.25rem',
    marginTop: '.5rem',
    alignItems: 'center',
    padding: vars.spacing,
  },
  cameraLabel: {
    paddingLeft: 0,
    '&:hover': {
      background: 'none',
    },
  },
  cameraContent: {
    display: 'unset',
  },
  treeItemLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  treeItemRight: {
    display: 'flex',
    alignItems: 'center',
  },
  treeItemIcon: {
    marginRight: `${vars.spacing}px`,
  },
  rootTreeView: {
    '& .Mui-expanded > .MuiTreeItem-content': {
      backgroundColor: '#1f3134',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${vars.spacing}px`,
  },
  name: {
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dialogContent: {
    padding: `${vars.spacing}px`,
    // minHeight: '70%',
  },
  bottomContainer: {
    padding: `${vars.spacing}px`,
    position: 'sticky',
    justifyContent: 'flex-start',
  },
  rightButton: {
    marginRight: 'auto',
  },
  icon: {
    // color: vars.primary,
    // padding: '0',
  },
  checkbox: {
    padding: 0,
    '& svg': {
      // fill: '#303D42',
    },
  },
  chackboxChecked: {
    '& svg': {
      // fill: vars.primary,
    },
  },
  tabs: {
    minHeight: 30,
    // background: vars.primaryBackground,
    '& > span': {
      width: '100%',
    },
  },
  tabLabel: {
    fontSize: 16,
    padding: 0,
    minWidth: 80,
    minHeight: 30,
    marginRight: 24,
    // textTransform: 'none',
  },
  tabLabelSelected: {
    // color: vars.white,
  },
  input: {
    // width: '100%',
    // borderRadius: vars.primaryBorderRadius,
    // backgroundColor: vars.backgroundInput,
    // marginBottom: `${vars.spacing}px`,
  },
  noInputBorder: {
    border: 'none',
  },
  divider: {
    // backgroundColor: vars.primaryBorderColor,
  },
  button: {
    // padding: '10px 24px',
  },
  headerTitle: {
    // display: 'flex',
    // alignItems: 'center',
  },
  arrowBack: {
    // color: vars.primary,
    // padding: 0,
    // marginRight: 12,
  },
}));

type SelectorProps = {
  value: string;
  handleCheck: (id: string) => void;
  handleUncheck: (id: string) => void;
  checked: boolean;
  classes?: {
    root: string,
    checked: string,
  },
  partially?: boolean;
};

const Selector: React.FunctionComponent<SelectorProps> = ({
  value,
  handleCheck,
  handleUncheck,
  checked,
  classes,
  partially = false,
}) => (
  <Checkbox
    checked={checked}
    color="primary"
    indeterminate={partially}
    classes={classes}
    onClick={(e) => e.stopPropagation()}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        handleCheck(value);
      } else {
        handleUncheck(value);
      }
    }}
  />
);

type CameraItemProps = {
  id: string;
  name: string;
  handleCheck: (id: string) => void;
  handleUncheck: (id: string) => void;
  checked: boolean;
};

const CameraItem: React.FunctionComponent<CameraItemProps> = ({
  id,
  name,
  handleCheck,
  handleUncheck,
  checked,
  active,
  searchText,
}) => {
  const classes = useStyles();

  return (
    <TreeItem
      nodeId={id}
      label={(
        <div className={classes.treeItemCamera}>
          <div className={classes.treeItemLeft}>
            <VideocamIcon color={active ? 'primary' : 'secondary'} className={classes.treeItemIcon} />
            {UIHighLightText({ searchText, text: name })}
          </div>
          <Selector
            value={id}
            checked={checked}
            classes={{
              root: classes.checkbox,
              checked: classes.chackboxChecked,
            }}
            handleCheck={handleCheck}
            handleUncheck={handleUncheck}
          />
        </div>
      )}
      classes={{
        root: classes.treeItemRoot,
        label: classes.cameraLabel,
        content: classes.cameraContent,
      }}
    />
  );
};

const gatherIds = (items: ILocation[] | ILocationCamera[]): any => flatMap(
  (item) => [
    (item as ILocation).id,
    ...gatherIds((item as ILocation).child_locations),
    ...gatherIds((item as ILocation).cameras),
  ],
  items,
);

const gatherChildrenCamerasIds = (children: ILocation[]): string[] => {
  const result = children.map((child) => [
    ...child.cameras.map((cam) => cam.id),
    ...gatherChildrenCamerasIds(child.child_locations),
  ]).reduce((prev, curr) => prev.concat(curr), []);
  return result;
};

const gatherChildrenLocationsIds = (children: ILocation[]): string[] => {
  const result = children.map((child) => [
    child.id,
    ...gatherChildrenLocationsIds(child.child_locations),
  ]).reduce((prev, curr) => prev.concat(curr), []);
  return result;
};

const gatherChildrenIds = (cameras: ILocationCamera[], childLocations: ILocation[]): SelectedIds => {
  const result = {
    cameras: [...cameras.map((cam) => cam.id), ...gatherChildrenCamerasIds(childLocations)],
    locations: gatherChildrenLocationsIds(childLocations),
  };

  return result;
};

const filterChildrenCamerasIds = (locations: ILocation[], searchValue: string): any => {
  const result = locations
    .map((location) => {
      const matchedCameras = location.cameras.filter(({ name }) => name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
      const matchedLocation = !!location.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());

      return ({
        ...location,
        child_locations: filterChildrenCamerasIds(location.child_locations, searchValue),
        cameras: matchedLocation ? location.cameras : matchedCameras,
      });
    }).filter((location) => {
      const result = location.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        || location.child_locations.length > 0
        || location.cameras.length > 0;
      return result;
    });

  return result;
};

type LocationItemProps = {
  id: string;
  name: string;
  child_locations: ILocation[];
  cameras: ILocationCamera[];
  handleCheck: (ids: SelectedIds) => void;
  handleUncheck: (ids: SelectedIds) => void;
  checked: boolean;
  selectedIds: SelectedIds;
};

const LocationItem: React.FunctionComponent<LocationItemProps> = ({
  id,
  name,
  cameras,
  child_locations,
  handleCheck,
  handleUncheck,
  checked,
  selectedIds,
  searchText,
}) => {
  const classes = useStyles();

  const children = gatherChildrenIds(cameras, child_locations);
  const childrenCount = children.cameras.length + children.locations.length;
  const selectedChildrenCount = intersection([...selectedIds.locations, ...selectedIds.cameras], [...children.locations, ...children.cameras]).length;

  const partiallySelected = (selectedChildrenCount > 0 && selectedChildrenCount <= childrenCount && !checked)
    || (checked && selectedChildrenCount < childrenCount);

  return (
    <TreeItem
      nodeId={id}
      label={(
        <div className={classes.locContent}>
          <span className={classes.name}>{UIHighLightText({ searchText, text: name })}</span>
          <Selector
            value={id}
            checked={checked}
            classes={{
              root: classes.checkbox,
              checked: classes.chackboxChecked,
            }}
            partially={partiallySelected}
            handleCheck={(id) => {
              handleCheck({
                locations: [id, ...children.locations],
                cameras: children.cameras,
              });
            }}
            handleUncheck={(id) => {
              handleUncheck({
                locations: [id, ...children.locations],
                cameras: children.cameras,
              });
            }}
          />
        </div>
      )}
      classes={{
        root: classes.treeItemRoot,
        content: classes.treeItemLoc,
        label: classes.locLabel,
      }}
    >
      {child_locations.map((childLoc, index) => (
        <LocationItem
          {...childLoc}
          selectedIds={selectedIds}
          checked={includes(childLoc.id, selectedIds.locations)}
          handleCheck={handleCheck}
          handleUncheck={handleUncheck}
          key={index}
          searchText={searchText}
        />
      ))}
      {cameras.map((camera, index) => (
        <CameraItem
          {...camera}
          checked={includes(camera.id, selectedIds.cameras)}
          handleCheck={(id) => handleCheck({ cameras: [id], locations: [] })}
          handleUncheck={(id) => handleUncheck({ cameras: [id], locations: [] })}
          key={index}
          searchText={searchText}
        />
      ))}
    </TreeItem>
  );
};

export type SelectedIds = {
  cameras: string[],
  locations: string[],
};

type LocationSelectorProps = {
  onSelect: (selected: SelectedIds) => void;
  handleClose: () => void;
  defaultSelectedIds?: SelectedIds;
  open: boolean;
  isSecondPopup?: boolean,
  closeAdvancedSearchPopup?: () => void,
};

const LocationSelector: React.FunctionComponent<LocationSelectorProps> = ({
  onSelect,
  defaultSelectedIds,
  handleClose,
  open,
}) => {
  const [isExpand, setIsExpend] = useState(true);
  const [search, setSearch] = useState('');
  const [filterCameras, setFilterCameras] = useState([]);
  const [filterLocations, setFilterLocations] = useState([]);

  const [expanded, setExpanded] = React.useState([]);
  const [selectedIds, setSelectedIds] = useState(defaultSelectedIds || { cameras: [], locations: [] });
  const [isAllIdSelected, setIsAllIdSelected] = useState(true);

  const { locations, cameras } = useDevicesQuery();

  const handleCheck = (ids: SelectedIds) => {
    setSelectedIds({
      cameras: uniq([...selectedIds.cameras, ...ids.cameras]),
      locations: uniq([...selectedIds.locations, ...ids.locations]),
    });
  };

  const handleUncheck = (ids: SelectedIds) => {
    setSelectedIds({
      cameras: difference(selectedIds.cameras, ids.cameras),
      locations: difference(selectedIds.locations, ids.locations),
    });
  };

  const handleSelectAllClick = () => {
    if (!isAllIdSelected && (cameras.length === selectedIds.cameras.length)) {
      setSelectedIds({
        cameras: [],
        locations: [],
      });
      setIsAllIdSelected(true);
    }
    if (isAllIdSelected && selectedIds.cameras.length === 0 && selectedIds.locations.length === 0) {
      setSelectedIds({
        cameras: uniq(camerasContent.map((camera) => camera.id)),
        locations: uniq(gatherChildrenLocationsIds(locations)),
      });
      setIsAllIdSelected(false);
    }
    if (isAllIdSelected && (selectedIds.cameras.length > 0 || selectedIds.locations.length > 0)) {
      setSelectedIds({
        cameras: [],
        locations: [],
      });
      setIsAllIdSelected(true);
    }
    if (!isAllIdSelected && (selectedIds.cameras.length > 0 || selectedIds.locations.length > 0)) {
      setSelectedIds({
        cameras: [],
        locations: [],
      });
      setIsAllIdSelected(true);
    }
    // if (isAllIdSelected) {
    //   setSelectedIds({
    //     cameras: uniq(camerasContent.map((camera) => camera.id)),
    //     locations: uniq(gatherChildrenLocationsIds(locations)),
    //   });
    //   setIsAllIdSelected(false);
    // } else {
    //   setSelectedIds({
    //     cameras: [],
    //     locations: [],
    //   });
    //   setIsAllIdSelected(true);
    // }
  };

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearch(value);
    const LocationFilterReuslt = filterChildrenCamerasIds(locations, value);
    if (LocationFilterReuslt.length > 0) {
      setExpanded(gatherIds(LocationFilterReuslt));
    } else {
      setExpanded([]);
    }
    setFilterLocations(
      LocationFilterReuslt,
    );
    setFilterCameras(
      cameras.filter(({ name }) => name && name.toLocaleLowerCase().includes(value.toLocaleLowerCase())),
    );
  };

  const handleTreeViewToggle = (_: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const locationsContent = useMemo(
    () => (search.length ? filterLocations : locations),
    [filterLocations, locations, search],
  );

  const camerasContent = useMemo(
    () => (search.length ? filterCameras : cameras),
    [filterCameras, cameras, search],
  );

  const classes = useStyles();

  const disabledButtonSelectAll = (locationsList, camerasList) => {
    if (!locationsList.length && !camerasList.length) {
      return true;
    }
    return false;
  };

  const disabledButtonApply = () => selectedIds.cameras.length === 0;

  useEffect(() => {
    const tempSelected = defaultSelectedIds || { cameras: [], locations: [] };

    if (tempSelected.cameras.length === 0) {
      setSelectedIds({
        cameras: uniq(cameras.map((camera) => camera.id)),
        locations: uniq(gatherChildrenLocationsIds(locations)),
      });
      setIsAllIdSelected(false);
    } else {
      setSelectedIds(tempSelected);
      setIsAllIdSelected(true);
    }
  }, [cameras, locations, defaultSelectedIds]);

  return (
    <Dialog
      open={open}
      // onClose={() => {
      //   setSelectedIds(defaultSelectedIds || { cameras: [], locations: [] });
      //   handleClose();
      //   setSearch('');
      // }}
      maxWidth="xs"
      fullWidth
    >
      <Paper variant="outlined">
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Typography align="left">
            Filter by Cameras / Locations
          </Typography>
          <IconButton
            onClick={() => {
              handleClose();
              setSearch('');
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Search cameras or locations"
                onChange={handleSearchChange}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                  classes: { notchedOutline: classes.noInputBorder },
                }}
              />
            </Grid>
            <Grid item xs>
              {
                selectedIds.cameras.length > 0 ? (
                  <UISectionMessage type="info">
                    {`You have selected ${selectedIds.cameras.length} camera${selectedIds.cameras.length > 1 ? 's' : ''}`}
                  </UISectionMessage>
                ) : (
                  <UISectionMessage type="error">
                    You must allocate at least one camera
                  </UISectionMessage>
                )
              }
            </Grid>
            <Grid item xs>
              <Box maxHeight="50vh" overflow="auto">
                <TreeView
                  defaultCollapseIcon={<KeyboardArrowUpIcon className={classes.icon} />}
                  defaultExpandIcon={<KeyboardArrowDownIcon className={classes.icon} />}
                  expanded={expanded}
                  onNodeToggle={handleTreeViewToggle}
                  disableSelection
                  classes={{
                    root: classes.rootTreeView,
                  }}
                >
                  {locationsContent.length === 0 && camerasContent.length === 0
                    && <Typography>No locations/cameras found</Typography>}
                  {locationsContent.length > 0 && (locationsContent.map((location, index) => (
                    <LocationItem
                      {...location}
                      checked={includes(location.id, [...selectedIds.locations, ...selectedIds.cameras])}
                      selectedIds={selectedIds}
                      handleCheck={handleCheck}
                      handleUncheck={handleUncheck}
                      key={index}
                      searchText={search}
                    />
                  )))}
                  {camerasContent.length > 0 && (camerasContent.filter((camera) => camera.locations.length === 0)?.sort((a, b) => b.active - a.active).map((camera, index) => (
                    <CameraItem
                      {...camera}
                      checked={includes(camera.id, selectedIds.cameras)}
                      handleCheck={(id) => handleCheck({ cameras: [id], locations: [] })}
                      handleUncheck={(id) => handleUncheck({ cameras: [id], locations: [] })}
                      key={index}
                      active={camera.active}
                      searchText={search}
                    />
                  )))}
                </TreeView>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2} width={1}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (isExpand) {
                    setExpanded(gatherIds(locations));
                    setIsExpend(false);
                  } else {
                    setExpanded([]);
                    setIsExpend(true);
                  }
                }}
                disabled={!locationsContent.length}
              >
                {isExpand ? 'Expand all' : 'Collapse all'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSelectAllClick}
                disabled={disabledButtonSelectAll(locationsContent, camerasContent)}
              >
                {/* {isAllIdSelected ? 'Select all' : 'Clear all'} */}
                {(selectedIds.cameras.length > 0 || selectedIds.locations.length > 0) ? 'Clear all' : 'Select all'}
              </Button>
            </Grid>
            <Grid item xs>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (selectedIds.cameras.length === cameras.length) {
                      onSelect({ cameras: [], locations: [] });
                    } else {
                      onSelect(selectedIds);
                    }
                    handleClose();
                  }}
                  disabled={disabledButtonApply()}
                >
                  Apply
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default LocationSelector;

// @ts-nocheck

import { useCollapse } from 'react-collapsed';
import clsx from 'clsx';
import React from 'react';
import UISubHeader from '../UISubHeader/UISubHeader';
import UIWidgetAction from '../UIWidgetAction/UIWidgetAction';
import UIArrowForwardIosIcon from '../../icons/UIArrowForwardIosIcon';

import './UIHelpCollapsed.scss';

const UIHelpCollapsed = ({ headerText, children }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: false
  });

  const actionClassName = clsx(
    'UIActionIcon',
    isExpanded && 'UICollapsed',
  );

  return (
    <div className="UIHelpCollapsed">
      <div className="UIHelpCollapsedHeader" {...getToggleProps()}>
        <UIWidgetAction className={actionClassName}>
          <UIArrowForwardIosIcon />
        </UIWidgetAction>
        <UISubHeader>{headerText}</UISubHeader>
      </div>
      <div className="UIHelpCollapsedContent" {...getCollapseProps()}>
        {children}
      </div>
    </div>
  )
}

export default UIHelpCollapsed;

// @ts-nocheck
import { IconButton } from '@material-ui/core';
import React from 'react';
import BasicOnlineAvatar from 'v2/components/basic/BasicOnlineAvatar';
import { Tooltip, TooltipContent, TooltipTrigger } from '../basic/BasicPopoverNew';
import AvatarPopover from '../../popovers/AvatarPopover';

const ShortVariant = ({
  user, online,
}) => (
  <Tooltip byClick>
    <TooltipTrigger>
      <IconButton>
        <BasicOnlineAvatar online={online} src={user.photo_url} />
      </IconButton>
    </TooltipTrigger>
    <TooltipContent arrow>
      <AvatarPopover />
    </TooltipContent>
  </Tooltip>
);

export default ShortVariant;

// @ts-nocheck
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OBJECT_TYPES } from 'v2/constants/objectTypes';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import useUserCompanyQuery from 'v2/hooks/queries/useUserCompanyQuery';
import { FormSchemeToValues } from 'v2/utils/functions';
import useBasicQuery from './useBasicQuery';

export const getSearchDefaultFilters = (objectType, filters = {}) => ({
  camera_filters: FormSchemeToValues(FORM_SCHEMES.SEARCH_CAMERAS(filters?.camera_filters))?.devices,
  timestamp_filters: FormSchemeToValues(FORM_SCHEMES.SEARCH_DATES(filters?.timestamp_filters)),
  common_filters: FormSchemeToValues(FORM_SCHEMES.SEARCH_COMMON_FILTERS(filters?.common_filters)),
  object_type_filters: FormSchemeToValues(FORM_SCHEMES.SEARCH_OBJECT_TYPE_FILTERS[objectType](filters?.object_type_filters)),
  ...FormSchemeToValues(FORM_SCHEMES.SEARCH_ORDERING(filters?.orderings)),
});

const useAdvancedSearchFiltersQuery = () => {
  const { userCompanyQuery } = useUserCompanyQuery();
  const queryClient = useQueryClient();

  // FILTERS
  const filtersQuery = useBasicQuery({
    queryKey: ['filters'],
    queryFn: () => {
      const storedFilters = JSON.parse(localStorage.getItem(`searchFilters-${userCompanyQuery.data.id}`)) || {};

      return {
        [OBJECT_TYPES.FACE]: getSearchDefaultFilters(OBJECT_TYPES.FACE, storedFilters[OBJECT_TYPES.FACE]),
        [OBJECT_TYPES.VEHICLE]: getSearchDefaultFilters(OBJECT_TYPES.VEHICLE, storedFilters[OBJECT_TYPES.VEHICLE]),
        [OBJECT_TYPES.PERSON]: getSearchDefaultFilters(OBJECT_TYPES.PERSON, storedFilters[OBJECT_TYPES.PERSON]),
      };
    },
    enabled: Boolean(userCompanyQuery.isFetched)
  });

  const filtersMutation = useMutation({
    mutationFn: ([objectType, data]) => {
      const storedFilters = JSON.parse(localStorage.getItem(`searchFilters-${userCompanyQuery.data.id}`)) || {};

      const filters = {
        ...storedFilters,
        [objectType]: data,
      };

      localStorage.setItem(`searchFilters-${userCompanyQuery.data.id}`, JSON.stringify(filters));

      return filters;
    },
    onSuccess: () => queryClient.invalidateQueries(['filters']),
  });

  const setFilters = ([objectType, data]) => {
    filtersMutation.mutateAsync([objectType, data]).then();
  };

  // OBJECT TYPE
  const objectTypeQuery = useBasicQuery({
    queryKey: ['objectType'],
    queryFn: () => {
      const value = localStorage.getItem(`searchObjectType-${userCompanyQuery.data.id}`);
      return Object.values(OBJECT_TYPES).includes(value) ? value : OBJECT_TYPES.FACE;
    },
    enabled: Boolean(userCompanyQuery.isFetched),
  });

  const objectTypeMutation = useMutation({
    mutationFn: (data) => {
      localStorage.setItem(`searchObjectType-${userCompanyQuery.data.id}`, data);

      return data;
    },
    onSuccess: () => queryClient.invalidateQueries(['objectType']),
  });

  const setObjectType = (data) => {
    objectTypeMutation.mutateAsync(data).then();
  };

  return {
    setFilters,
    setObjectType,
    filtersQuery,
    objectTypeQuery,
  };
};

export default useAdvancedSearchFiltersQuery;

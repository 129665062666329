// @ts-nocheck
import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const Image = ({ theme }) => (
  <svg width="100%" height="100%" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M73.0002 37C73.0002 30.089 71.0109 23.324 67.27 17.5131C63.529 11.7021 58.1945 7.09077 51.9035 4.22974C45.6125 1.36872 38.631 0.378954 31.7927 1.37866C24.9543 2.37836 18.5483 5.32528 13.3396 9.86746L37.0002 37H73.0002Z" fill={theme.palette.primary.main} stroke="#24292A" />
    <path d="M13.3392 9.86719C9.62274 13.1081 6.61169 17.078 4.49281 21.5307C2.37392 25.9833 1.19239 30.8239 1.02148 35.752L36.9998 36.9997L13.3392 9.86719Z" fill="#595959" stroke="#24292A" />
    <path d="M1.02164 35.752C0.776126 42.8313 2.62459 49.8257 6.33557 55.8595C10.0465 61.8932 15.4551 66.698 21.884 69.6724C28.3129 72.6467 35.4764 73.6583 42.4775 72.5805C49.4786 71.5027 56.0062 68.3834 61.2428 63.6132L37 36.9997L1.02164 35.752Z" fill="#2c2c2c" stroke="#24292A" />
    <path d="M61.2429 63.6136C64.9465 60.2398 67.9053 56.1301 69.9298 51.5474C71.9543 46.9648 73 42.01 73 37H37L61.2429 63.6136Z" fill="#dadada" stroke="#24292A" />
  </svg>
);

export default withTheme(Image);

// @ts-nocheck

import React from 'react';
import { Box, } from '@material-ui/core';
import { useDialog } from 'v2/providers/DialogProvider';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
  UIWidgetPlaceholderContent,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';

const Confirmation = (props) => {
  const { hideDialog } = useDialog();

  const isHeaderShowing = props.hasOwnProperty('hasHeader') ? props.hasHeader : true;
  const isButtonsShowing = props.hasOwnProperty('hasButtons') ? props.hasButtons : true;

  return (
    <UIWidgetContainer className="UIDialog">
      {isHeaderShowing && (
        <UIWidgetHeader>
          <UIWidgetTitle>{props.title}</UIWidgetTitle>
          <UIWidgetRightActions>
            <CloseAction onClick={hideDialog} />
          </UIWidgetRightActions>
        </UIWidgetHeader>
      )}
      <UIWidgetBody>
        {props.content}
        {props.text && (
        <Box fontSize={16} px={4} py={8} textAlign="center" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box overflow="hidden" width={1} textOverflow="ellipsis">
            {props.text}
          </Box>
        </Box>
        )}
        {props.subText && (
          <Box
            fontSize={14}
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            whiteSpace="pre"
          >
            {props.subText}
          </Box>
        )}
      </UIWidgetBody>
      {isButtonsShowing && (
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          {props.cancelText || 'Cancel'}
        </UIButton>
        <UIButton
          variant="contained"
          onClick={() => (props.onConfirmation ? props.onConfirmation(hideDialog) : hideDialog())}
        >
          {props.confirmText || 'Confirm'}
        </UIButton>
      </UIWidgetFooter>
      )}
    </UIWidgetContainer>
  )
};

export default Confirmation;

// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIZoomOutMapIcon from 'v2/icons/UIZoomOutMapIcon';

const ZoomReset = (props) => (!props.hasOwnProperty('visible') || props.visible) && (
  <UIWidgetAction
    byHover
    tooltip="Reset scale"
    onClick={(e) => props.onChange && props.onChange(e)}
    disabled={props.disabled}
  >
    <UIZoomOutMapIcon />
  </UIWidgetAction>
);

export default ZoomReset;

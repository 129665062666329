// @ts-nocheck

import React, { useState } from 'react';
import {
  Dialog, Button, Paper, Box, Typography, Grid, IconButton, Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const BasicDialog = ({
  title, isOpen, closeDialog, onChange, initialValue, Component, fromValidator, hideButtons, maxWidth, fullWidth,
}) => {
  const [value, setValue] = useState(initialValue);

  return (
    <Dialog
      maxWidth={maxWidth || 'lg'}
      fullWidth={fullWidth}
      open={isOpen}
      onClose={closeDialog}
    >
      <Paper variant="outlined">
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Typography>
            {title}
          </Typography>
        </Box>
        <Divider />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              {Component && <Component initialValue={value} onChange={setValue} />}
            </Grid>
          </Grid>
        </Box>
        {!hideButtons && (
          <>
            <Divider />
            <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  closeDialog && closeDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onChange && onChange(value);
                  closeDialog && closeDialog();
                }}
                disabled={fromValidator && !fromValidator(value)}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Dialog>
  );
};

export default BasicDialog;

// @ts-nocheck
import api from 'app/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clearEmptyValues } from 'v2/utils/functions';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['watchListPredicates'];

const useWatchListPredicatesQuery = (id) => {
  const queryClient = useQueryClient();

  const watchListPredicatesQuery = useBasicQuery({
    queryKey: [...QUERY_KEYS, id],
    queryFn: async () => {
      const result = await api.post(`/watchlist-manager/v1/watchlists/${id}/predicates`, {
        pagination: {
          pgoffset: 0,
          pgsize: -1,
        }
      });

      return result.data.items;
    }
  });

  const addWatchListPredicate = useMutation({
    mutationFn: async ([watchlist_id, filter]) => {
      const result = await api.post('/watchlist-manager/v1/predicates', ({
        watchlist_id,
        filter: clearEmptyValues(filter),
      }));

      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  })

  const editWatchListPredicate = useMutation({
    mutationFn: async ([id, filter]) => {
      const result = await api.patch(`/watchlist-manager/v1/predicates/${id}`, ({
        filter: clearEmptyValues(filter),
      }));

      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  })

  const deleteWatchListPredicate = useMutation({
    mutationFn: async (id) => {
      const result = await api.delete(`/watchlist-manager/v1/predicates/${id}`);

      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  })

  return {
    watchListPredicatesQuery,
    addWatchListPredicate,
    editWatchListPredicate,
    deleteWatchListPredicate
  }
};

export default useWatchListPredicatesQuery;

// @ts-nocheck

import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Tooltip } from '@material-ui/core';
import { mergeRules } from 'v2/utils/functions';
import UIRangeSlider from 'v2/ui-kit/UIRangeSlider/UIRangeSlider';

const WrappedSlider = ({ field }) => (
  <div className="py-3 w-full">
    <UIRangeSlider
      {...field}
      step={1}
      showTooltip
    />
  </div>
)

const SliderRange = (props) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={mergeRules(props.rules)}
    defaultValue={props.defaultValue}
    render={(controller) => (
      <Tooltip arrow title={props.tooltip || ''} enterDelay={500} enterNextDelay={500}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label={props.label}
          error={!!controller.formState?.errors[props.name]?.message}
          helperText={controller.formState?.errors[props.name]?.message}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            className: 'UIOutlinedSliderRange',
            inputProps: {
              field: {
                ...controller.field,
                range: props.range,
                onChange: (value) => {
                  props.onChange ? controller.field.onChange(props.onChange(value)) : controller.field.onChange(value);
                },
              },
            },
            inputComponent: WrappedSlider
          }}
        />
      </Tooltip>
    )}
  />
)

export default SliderRange

// @ts-nocheck
import { useState } from 'react';

const useAutoFill = () => {
  const [autoFill, setAutoFill] = useState(false)
  let iterations = 0;

  const autoFillCheck = () => {
    const checkAutofilled = () => (
      !!document.querySelector("[name='login']")?.matches('*:-webkit-autofill')
      && !!document.querySelector("[name='password']")?.matches('*:-webkit-autofill')
    );

    const interval = setInterval(() => {
      if (iterations > 20) {
        return clearInterval(interval);
      }
      iterations++;
      if (checkAutofilled()) {
        clearInterval(interval)
        setAutoFill(true);
      }
    }, 100);
  }
  return { autoFill, autoFillCheck }
};

export default useAutoFill;

// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';
import { FEED_LENGTH } from 'v2/utils/constants';
import { OBJECT_TYPES } from 'v2/constants/objectTypes';
import { clearEmptyValues } from 'v2/utils/functions';

const initialData = {
  // filters: {},
  // orderings: [],
  pagination: {
    pgoffset: 0,
    pgsize: FEED_LENGTH
  }
}

const readStorage = () => {
  try {
    const savedData = localStorage.getItem('advancedSearchFilters');
    return {
      ...JSON.parse(savedData),
      pagination: initialData.pagination,
    }
  } catch (e) {
    return initialData
  }
}

const saveStorage = (data) => {
  localStorage.setItem('advancedSearchFilters', JSON.stringify(data))
}

const advancedSearchFiltersReducer = createSlice({
  name: 'advancedSearchFilters',
  initialState: {
    data: readStorage(),
    objectType: OBJECT_TYPES.FACE,
  },
  reducers: {
    setFilters(state, action) {
      const { pagination, ...args } = action.payload || {};

      const filters = clearEmptyValues({
        timestamp_filters: clearEmptyValues(args.timestamp_filters),
        common_filters: clearEmptyValues(args.common_filters),
        object_type_filters: clearEmptyValues(args.object_type_filters),
        camera_filters: clearEmptyValues(args.camera_filters),
        orderings: clearEmptyValues(args.orderings),
      })

      state.data = {
        ...filters,
        pagination: pagination || state.data.pagination,
      };

      saveStorage(filters);
    },
    setObjectType(state, action) {
      state.objectType = action.payload
    }
  },
});
export default advancedSearchFiltersReducer;

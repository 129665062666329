// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UILinkIcon from 'v2/icons/UILinkIcon';

const LinkAction = () => (
  <UIWidgetAction byHover tooltip="Open object card">
    <UILinkIcon />
  </UIWidgetAction>
);

export default LinkAction;

// @ts-nocheck

import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const BasicMotion = ({ isShow, ...args }) => (
  <AnimatePresence>
    {isShow && (
      <motion.div
        {...args}
      />
    )}
  </AnimatePresence>
);

export default BasicMotion;

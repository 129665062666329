// @ts-nocheck
import React, { useState } from 'react';
import { TextField, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function CustumField({
  label, variant = 'outlined', className, name, value, onChange, error, helperText, type,
  handleFilterValueChange, key, textFieldName
}) {
  const [fieldType, setFieldType] = useState(type)
  const [filters, setFilters] = useState(false)

  const allFilters = '*'

  const checkBoxHandler = () => {
    if (value !== '*') {
      setFilters((prev) => !prev)
      onChange(allFilters)
    }
    else {
      setFilters((prev) => !prev)
      onChange('')
    }
  }

  const showIcon = () => (type === 'password' ? (
    {
      endAdornment:
      <InputAdornment position="start">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setFieldType((prev) => (prev === 'password' ? 'text' : 'password'))}
        >
          {fieldType === 'password' ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    }
  ) : (
    {
      startAdornment:
      <InputAdornment position="start">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => checkBoxHandler()}
        >
          {value === '*' ? <CheckBoxIcon /> : (
            <Tooltip title="Click to get results that have at least one digit in the content">
              <CheckBoxOutlineBlankIcon />
            </Tooltip>
          )}
        </IconButton>
      </InputAdornment>
    }
  ))

  return (
    <>
      <TextField
        key={key}
        size="small"
        fullWidth
        label={label}
        variant={variant}
        className={className}
        name={name}
        value={value === '*' && textFieldName ? 'Not empty' : value}
        onChange={filters ? (e) => onChange(e.target.value) : handleFilterValueChange || onChange}
        type={fieldType}
        error={error}
        helperText={helperText}
        disabled={value === '*' && type}
        placeholder={label}
        InputProps={showIcon()}
      />
    </>
  )
}

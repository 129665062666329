// @ts-nocheck

import * as Sentry from '@sentry/react';
import DataReducer from '../DataReducer';
import api from '../../../app/api';
import { FETCH_STATE } from '../../utils/constants';

const user = DataReducer({
  name: 'user',
  dataProvider: async () => {
    const result = await api.get('/auth-manager/v1/user');
    const scope = Sentry.getCurrentScope();
    scope.setTags({
      appVersion: process.env.REACT_APP_VERSION,
      appBuild: process.env.REACT_APP_BUILD,
      appBranch: process.env.REACT_APP_BRANCH,
      appBranchHash: process.env.REACT_APP_BRANCH_HASH,
    });
    scope.setUser({
      id: result.data.id,
      email: result.data.email,
    });

    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Loading user',
    [FETCH_STATE.REJECTED]: 'Error loading user',
  }
});

export default user;

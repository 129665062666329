// @ts-nocheck

import React from 'react';
import { useDialog } from 'v2/providers/DialogProvider';
import { TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import UISectionMessage from 'v2/ui-kit/UISectionMessage/UISectionMessage';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from '../ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from '../widgetActions/CloseAction';
import usePermissionsQuery, { PERMISSIONS } from '../hooks/queries/usePermissionsQuery';

const ShowToken = (props) => {
  const { permissions } = usePermissionsQuery();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = props;

  return (
    <>
      <UIWidgetContainer className="UIDialog">
        <UIWidgetHeader>
          <UIWidgetTitle>Please copy token value to the Metapix Plugin</UIWidgetTitle>
          <UIWidgetRightActions>
            <CloseAction onClick={hideDialog} />
          </UIWidgetRightActions>
        </UIWidgetHeader>
        <UIWidgetBody>
          <UISectionMessage className="m-2 mt-0" type="warning">
            Please note that if you do not copy the token value before closing the pop-up, you will need to generate a
            new one
          </UISectionMessage>
          <div className="p-2">
            <TextField
              fullWidth
              size="small"
              label="Token"
              variant="outlined"
              value={token}
              multiline
              readOnly
            />
          </div>
        </UIWidgetBody>
        <UIWidgetFooter className="justify-between">
          <UIButton
            variant="outlined"
            onClick={hideDialog}
          >
            Close
          </UIButton>
          {
            permissions[PERMISSIONS.CLIPBOARD_WRITE] && (
              <UIButton
                variant="contained"
                onClick={() => {
                  enqueueSnackbar('Token copied', { variant: 'success' });
                  navigator.clipboard.writeText(token);
                  hideDialog();
                }}
              >
                Copy
              </UIButton>
            )
          }
        </UIWidgetFooter>
      </UIWidgetContainer>
    </>
  );
};

export default ShowToken;

// @ts-nocheck
import { useEffect, useState } from 'react';

const useResizeEvent = () => {
  const [state, setState] = useState();

  useEffect(() => {
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, t)
    }
  }, [state]);

  const triggerResize = () => {
    setState(Date.now());
  };

  return { triggerResize };
};

export default useResizeEvent;

// @ts-nocheck

import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { UIWidgetOverlap, UIWidgetPlaceholderContent } from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';

const Loader = (props) => (
  <UIWidgetOverlap>
    <UIWidgetPlaceholderContent
      icon={CircularProgress}
      title={props.title}
    />
  </UIWidgetOverlap>
);

export default Loader;

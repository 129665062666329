// @ts-nocheck

import React, { useEffect } from 'react';
import {
  Paper,
  Grid,
  Box,
  Typography,
  Dialog,
} from '@material-ui/core/';
import {
  NavLink
} from 'react-router-dom';
import { handlers } from 'v2/redux/store';

const NoCompanies = () => {
  useEffect(() => {
    handlers.user.clear()
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open
    >
      <Paper variant="outlined">
        <Box display="flex" alignItems="center" justifyContent="center">
          <div className="UILogo" />
        </Box>
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="center">
          <Grid container spacing={2} direction="column">
            <Box mb={5} p={2} alignItems="center" justifyContent="center">
              <Typography align="center">
                You don't have an access to this company, please contact administrator or support
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="center">
          <NavLink color="primary" to="/log-in" className="UINavLink">
            Go to Login
          </NavLink>
        </Box>
      </Paper>
    </Dialog>
  )
};

export default NoCompanies;

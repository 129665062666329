// @ts-nocheck
export const FACE = 'face';
export const VEHICLE = 'vehicle';
export const PERSON = 'person';

export const FIELD_WIDGET_TYPES = 'widget-types';

export const WIDGET_FEED = 'feed';
export const WIDGET_CHART = 'chart';
export const WIDGET_PIE = 'pieChart';
export const WIDGET_VALUE = 'value';
export const WIDGET_STATISTICS = 'statistic';

const values = {
  [FACE]: { value: FACE, name: 'Face' },
  [VEHICLE]: { value: VEHICLE, name: 'Vehicle' },
  [PERSON]: { value: PERSON, name: 'Person' },
}

export const mapFields = {
  [WIDGET_FEED]: {
    [FIELD_WIDGET_TYPES]: [values[FACE], values[VEHICLE], values[PERSON]],
  },
  [WIDGET_CHART]: {
    [FIELD_WIDGET_TYPES]: [values[FACE], values[VEHICLE], values[PERSON]],
  },
  [WIDGET_PIE]: {
    [FIELD_WIDGET_TYPES]: [values[FACE], values[VEHICLE]],
  },
  [WIDGET_VALUE]: {
    [FIELD_WIDGET_TYPES]: [values[FACE], values[VEHICLE], values[PERSON]],
  },
  [WIDGET_STATISTICS]: {
    [FIELD_WIDGET_TYPES]: [values[FACE], values[VEHICLE], values[PERSON]],
  },
};

// @ts-nocheck
import api from 'app/api';
import useBasicQuery from './useBasicQuery';
import { QUERY_STALE_TIME } from '../../constants/constQueries';

const QUERY_KEYS = ['companiesTypes'];

const useCompaniesTypesQuery = () => {
  const companiesTypesQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.get('/auth-manager/v1/company-types');

      return result.data.map((item) => ({
        value: item.title,
        name: item.full_name,
        short: item.short_name,
        rank: item.rank
      }))
    },
    staleTime: QUERY_STALE_TIME,
  })

  return {
    companiesTypes: companiesTypesQuery.data,
    companiesTypesQuery,
  }
};

export default useCompaniesTypesQuery;

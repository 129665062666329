// @ts-nocheck
import React from 'react';
import './UILinkPreview.scss';

function htmlDecode(input) {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes[0].nodeValue;
}

const UILinkPreview = ({ url, links = [] }) => {
  const isEmail = url.includes('mailto:');
  if (isEmail) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <span>{url.replace('mailto:', '')}</span>
      </a>
    );
  }
  const link = links.find((item) => htmlDecode(item.href) === url);
  if (link) {
    return (
      <div className="UILinkPreview">
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <img src={link?.meta?.favicon} alt={link?.meta?.title} />
          {link?.meta?.title || url}
        </a>
      </div>
    );
  }
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <span>{url}</span>
    </a>
  );
};
export default UILinkPreview;

// @ts-nocheck

import axios from 'axios';
import { EventEmitter } from 'eventemitter3';

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const errorEmitter = new EventEmitter();

instance.interceptors.request.use((config) => {
  config.headers['access-token'] = localStorage.getItem('access-token');
  return config;
}, (error) => Promise.reject(error));

const reqInterceptor = (error) => {
  const defaultErrorMessage = 'Something went wrong. Try again or contact support';
  const message = error.response?.data?.message || defaultErrorMessage;
  const errorCode = error.response?.data?.error_code;
  const status = error.response?.status;

  const criticalError = (message) => errorEmitter.emit('apiError', { message, redirect: true });
  const regularError = (message) => errorEmitter.emit('apiError', { message, redirect: false });

  switch (status) {
    case 403:
      switch (errorCode) {
        case 'INVALID_TOKEN_CLAIM_ERROR':
        case 'INVALID_COMMON_TOKEN_ERROR':
        case 'INVALID_ACCESS_TOKEN_ERROR':
        case 'TOKEN_EXPIRED_ERROR':
        case 'ACCESS_TOKEN_REVOKED':
        case 'COMPANY_NOT_SELECTED_ERROR':
          criticalError(message);
          break;
        default:
          regularError(message);
          break;
      }
      break;
    case 401:
      criticalError(message);
      break;
    default:
      regularError(message);
      break;
  }

  return Promise.reject(error);
};

instance.interceptors.response.use((response) => response, reqInterceptor);

export default instance;

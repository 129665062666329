import React from 'react';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment } from 'moment-timezone';

type DateTimePickerType = {
  initialValue: Moment;
  onChange: (date: Moment) => void;
  disableFuture?: boolean;
  minutesStep?: number;
}

const DateTimePickerComponent = ({
  initialValue, onChange, disableFuture, minutesStep = 5,
}: DateTimePickerType) => (
  <div className="mui-picker">
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        value={initialValue}
        variant="static"
        minutesStep={minutesStep}
        disableFuture={disableFuture}
        onChange={(date) => {
          onChange(date as Moment);
        }}
      />
    </MuiPickersUtilsProvider>
  </div>
)

export default DateTimePickerComponent;

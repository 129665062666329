// @ts-nocheck

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FIELD_TYPES } from 'v2/constants/fieldTypes';
import { FormSchemeToValues } from 'v2/utils/functions';
import TextInput from './fields/TextInput';
import TextArea from './fields/TextArea';
import TextPassword from './fields/TextPassword';
import TextCombo from './fields/TextCombo';
import SelectSingle from './fields/SelectSingle';
import SelectMultiple from './fields/SelectMultiple';
import SliderRange from './fields/SliderRange';
import AutoComplete from './fields/AutoComplete';
import TagsAutocomplete from './fields/TagsAutocomplete';
import SelectDate from './fields/SelectDate';
import SelectCameras from './fields/SelectCameras';
import SelectObject from './fields/SelectObject';

const FieldSelector = (props) => {
  switch (props.type) {
    case (FIELD_TYPES.TEXT_INPUT):
      return <TextInput {...props} />;
    case (FIELD_TYPES.TEXT_AREA):
      return <TextArea {...props} />;
    case (FIELD_TYPES.TEXT_PASSWORD):
      return <TextPassword {...props} />;
    case (FIELD_TYPES.TEXT_COMBO):
      return <TextCombo {...props} />;
    case (FIELD_TYPES.SELECT_SINGLE):
      return <SelectSingle {...props} />;
    case (FIELD_TYPES.SELECT_OBJECT):
      return <SelectObject {...props} />;
    case (FIELD_TYPES.SELECT_MULTIPLE):
      return <SelectMultiple {...props} />;
    case (FIELD_TYPES.SELECT_DATE):
      return <SelectDate {...props} />;
    case (FIELD_TYPES.SELECT_CAMERAS):
      return <SelectCameras {...props} />;
    case (FIELD_TYPES.SLIDER_RANGE):
      return <SliderRange {...props} />;
    case (FIELD_TYPES.AUTO_COMPLETE):
      return <AutoComplete {...props} />
    case (FIELD_TYPES.TAGS_AUTO_COMPLETE):
      return <TagsAutocomplete {...props} />
    default:
      return null;
  }
};

const UIForm = ({ scheme, methods }) => (
  <div className="form-generator">
    {(scheme || []).map((field, index) => (
      FieldSelector({
        ...field,
        control: methods.control,
        key: index,
        methods,
      })
    ))}
  </div>
);

export const useUIForm = (scheme, onChange, resetOptions = {}) => {
  const methods = useForm({
    mode: 'all',
  });

  useEffect(() => {
    scheme && methods.reset(FormSchemeToValues(scheme), resetOptions);
  }, [scheme]);

  useEffect(() => {
    const subscription = methods.watch((...args) => {
      onChange && onChange(...args, methods);
    });

    return () => {
      subscription.unsubscribe();
    }
  }, [onChange]);

  return methods;
}

export default UIForm;

// @ts-nocheck

import React from 'react';
import clsx from 'clsx';

import './UISubHeader.scss';

const UISubHeader = ({ children, className }) => (
  <div className={clsx('UISubHeader', className)}>{children}</div>
);

export default UISubHeader;

// @ts-nocheck

import { useEffect } from 'react';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { useParams } from 'react-router';

const RegistrationPage = () => {
  const dialog = useDialog();
  const params = useParams();

  useEffect(() => {
    dialog.showDialog(DIALOGS.REGISTRATION_DIALOG, { params });
  }, []);
};

export default RegistrationPage;

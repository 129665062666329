// @ts-nocheck

import api from 'app/api';
import { FETCH_STATE } from 'v2/utils/constants';
import DataReducer from '../../DataReducer';

const tagsGetObjects = DataReducer({
  name: 'tagsGetObjects',
  dataProvider: async ([tagId, type]) => {
    const sort = '&sort_field=date&sort_order=desc'
    const paginationQuery = '&pgsize=100';
    const result = await api.get(`/object-manager/search/?filters=object_type:${type}&filters=is_reference:true&filters=tag_id:${tagId}${paginationQuery}${sort}`)
    const result2 = await api.get(`/object-manager/search/?filters=object_type:${type}&filters=is_reference:false&filters=matched:false&filters=tag_id:${tagId}${paginationQuery}${sort}`)

    if (result2.data.items.length > 0) {
      const items = [...result.data.items, ...result2.data.items]
      items.sort((i1, i2) => Date.parse(i1.timestamp) - Date.parse(i2.timestamp));
      return { items }
    }

    return result.data;
  },
  // TO DO: ждём, когда v2 нормально работать начнёт
  // dataProvider: async ([tagId, type = 'face']) => {
  //   const result = await api.post(`/object-manager/v2/search/${type}`, {
  //     common_filters: { object_tagIds: [Number.parseInt(tagId, 10)] },
  //     orderings: {
  //       timestamp: 'timestamp desc'
  //     },
  //     pagination: {
  //
  //       pgoffset: 0,
  //       pgsize: 100
  //
  //     }
  //   })
  //   return result.data;
  // },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching tags',
    [FETCH_STATE.REJECTED]: 'Error fetch tags',
  }
});
export default tagsGetObjects;

// @ts-nocheck

import React, { useState } from 'react';
import {
  Handles, Rail, Slider, Tracks
} from 'react-compound-slider';
import clsx from 'clsx';
import Handle from './Atoms/Handle';
import Track from './Atoms/Track';

import './UIRangeSlider.scss';

const UIRangeSlider = ({
  range = [0, 100],
  value = [0, 100],
  step = 1,
  className,
  onChange,
  onUpdate,
  showTooltip,
  vertical = false,
}) => {
  const [activeHandler, setActiveHandler] = useState();

  const showLeftTrack = (value || range || []).length === 1;

  return (
    <Slider
      mode={1}
      step={step}
      domain={range}
      className={clsx('UIRangeSlider', className)}
      onUpdate={(val) => onUpdate && onUpdate(val, activeHandler)}
      onChange={(val) => onChange && onChange(val, activeHandler)}
      onSlideStart={(val, params) => setActiveHandler(params.activeHandleID)}
      onSlideEnd={() => setActiveHandler(false)}
      values={value || range}
      vertical={vertical}
    >
      <Rail>
        {({ getRailProps }) => (
          <div className="rail" {...getRailProps()} />
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                range={range}
                getHandleProps={getHandleProps}
                isDragging={handle.id === activeHandler}
                showTooltip={showTooltip}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={showLeftTrack} right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );
};

export default UIRangeSlider;

// @ts-nocheck

import React from 'react';
import clsx from 'clsx';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../components/basic/BasicPopoverNew';

import './UIWidgetAction.scss';

const UIWidgetAction = ({
  children,
  tooltip,
  disabled,
  overlap,
  byHover,
  byClick,
  onClick,
  isDisabled,
  collapsedProps = {},
  ...props
}) => {
  const className = clsx(
    'UIWidgetAction',
    !disabled && onClick && 'clickable',
    collapsedProps.onClick && 'clickable',
    (isDisabled || disabled) && 'disabled',
    overlap && 'overlap',
    props.className
  );

  return (
    tooltip ? (
      <Tooltip byHover={byHover} byClick={byClick}>
        <TooltipTrigger>
          <span className={className} onClick={(e) => !disabled && onClick && onClick(e)} {...collapsedProps}>
            {children}
          </span>
        </TooltipTrigger>
        <TooltipContent arrow>
          <div className="p-1">
            {tooltip}
          </div>
        </TooltipContent>
      </Tooltip>
    ) : (
      <span {...{ ...props, className, ...collapsedProps }}>
        {React.cloneElement(children, {})}
      </span>
    )
  );
};

export default UIWidgetAction;

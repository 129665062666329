// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';
import { FETCH_STATE } from '../../utils/constants';

const suggestions = DataReducer({
  name: 'suggestions',
  dataProvider: async (objectType) => {
    const result = await api.get(`/object-manager/v1/names/${objectType}`);
    return result.data.names
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching suggestions',
    [FETCH_STATE.REJECTED]: 'Error fetch suggestions',
  }
});
export default suggestions;

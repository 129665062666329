// @ts-nocheck
import api from 'app/api';
import { QUERY_STALE_TIME } from 'v2/constants/constQueries';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['usersTypes'];

const useUsersTypesQuery = () => {
  const usersTypesQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.get('/auth-manager/roles');

      return result.data.map((item) => ({ ...item, value: item.id }));
    },
    staleTime: QUERY_STALE_TIME,
  })

  return {
    usersTypes: usersTypesQuery.data,
    usersTypesQuery
  }
};

export default useUsersTypesQuery;

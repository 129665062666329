// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';
import { FETCH_STATE } from '../../utils/constants';

const usersTypes = DataReducer({
  name: 'usersTypes',
  dataProvider: async () => {
    const result = await api.get('/auth-manager/roles');

    return result.data.map((item) => ({ ...item, value: item.id }));
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching usersTypes',
    [FETCH_STATE.REJECTED]: 'Error fetch usersTypes',
  }
});
export default usersTypes;

// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tokensDelete = DataReducer({
  name: 'tokensDelete',
  dataProvider: async (id) => {
    const result = await api.delete(`/auth-manager/v1/gateway_token/${id}`)
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Deleting tokens',
    [FETCH_STATE.REJECTED]: 'Error deleting tokens',
  }
});
export default tokensDelete;

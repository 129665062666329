// @ts-nocheck
import React, {
  FunctionComponent, useEffect, useRef, useState
} from 'react';
import moment from 'moment-timezone';
import ms from 'ms';
import Chart from 'components/chart/Chart';
import { vars } from 'Theme';
import { ChartData } from 'components/chart/dataTransformers';
import api from 'app/api';
import { Box, CircularProgress } from '@material-ui/core';
import { ChartDateRange, DetalizationType, WidgetFilters } from 'pages/dashboard/types';
import { SelectedIds } from 'components/LocationSelector';
import {
  getCurrentDetalizationOptions,
  getDateRange,
  getDefaultActiveDetalization,
  getDetalizationByValue,
  prepareGetQuery,
} from 'utils/chart';
import { CHART_REFRESH_TIMEOUT, EXCLUDED_KEYS } from 'utils/constants';
import debounce from 'lodash/debounce';

import { yellowToDateRangeMap } from '../../utils/yelloMapping';

export const parseStringFilters = (filters) => {
  const filtersObject = {};

  filters.forEach((sFilters) => {
    const [k, v] = sFilters.split(':');
    if (k === 'locations' || k === 'cameras') {
      filtersObject.selectedIds = {
        ...filtersObject.selectedIds,
        [k]: v.split(',').map((vv) => vv.trim()).filter(Boolean),
      };
    } else if (k === 'detalizationOptionsList' || k === 'dateRange') {
      const [a, b] = sFilters.split(':{');
      filtersObject[a] = `{${b}`;
    } else {
      if (v !== '0-100' && v !== '1-99') {
        filtersObject[k] = v;
      }
    }
  });

  return filtersObject;
};

type ChartFilter = {
  selectedIds: SelectedIds;
  timeslice: string;
  detalizationOptionsList: DetalizationType[];
  detalization: number;
  dateRange: ChartDateRange;
}

type Props = {
  filters: WidgetFilters;
  id: string;
  isPieChart?: boolean;
  selectedChartType?: string;
  onChangeRealtime: (realtime: boolean) => void;
  height?: string;
  isRealtime: boolean;
  isSharedWidget: boolean,
  openCamerasSelector: any,
  setOpenCamerasSelector: any,
  selectedIds: any,
  filtersObject:any,
  setFiltersObject:any,
  state: any,
}

const DashboardChart: FunctionComponent<Props> = ({
  isSharedWidget,
  isPieChart,
  onChangeRealtime,
  selectedChartType,
  id,
  isRealtime,
  height,
  openCamerasSelector,
  setOpenCamerasSelector,
  selectedIds,
  filtersObject,
  setFiltersObject,
  state,
  updateLayout,
  setLastUpdateTime,
  setIsFetching,
}) => {
  const [detalizationOptionsList, setDetalizationOptionsList] = useState<DetalizationType[]>([]);
  const [activeDetalization, setActiveDetalization] = useState<number>();
  const [chartDataLoading, setChartDataLoading] = useState<boolean>(true);
  const [legend, setLegend] = useState(state.legend || []);
  const showStackLabels = state.hasOwnProperty('showStackLabels') ? state.showStackLabels : true;
  const showLegend = state.hasOwnProperty('showLegend') ? state.showLegend : true;
  const showTimeSlice = state.hasOwnProperty('showTimeSlice') ? state.showTimeSlice : true;

  const debounced = useRef(debounce((legend) => {
    api.patch(`/layout-manager/widgets/${id}`, { state: { ...state, legend } }).catch(() => {});
  }, 500))

  const getDetalizationOptions2 = (filtersObject: NodeJS.Dict<any>) => {
    if (filtersObject.timeslice !== undefined && filtersObject.timeslice !== '') {
      const startAt = moment(moment().valueOf() - ms(filtersObject.timeslice as string)).unix();
      const endAt = moment().unix();

      return getCurrentDetalizationOptions(startAt, endAt);
    }
    // Check if dateFrom and DateTo set
    if (filtersObject.dateRange !== undefined) {
      // First run, filters as string
      if (filtersObject.dateRange.dateFrom === undefined && filtersObject.dateRange.dateTo === undefined) {
        filtersObject.dateRange = JSON.parse(filtersObject.dateRange);
      }

      return getCurrentDetalizationOptions(filtersObject.dateRange.dateFrom, filtersObject.dateRange.dateTo);
    }

    const startAt = moment().add(-12, 'hours').unix();
    const endAt = moment().unix();

    return getCurrentDetalizationOptions(startAt, endAt);
  };

  const getDetalizationOptions = (filtersObject: NodeJS.Dict<any>) => {
    if (filtersObject.timeslice !== undefined && filtersObject.timeslice !== '') {
      const startAt = moment(moment().valueOf() - ms(filtersObject.timeslice as string)).unix();
      const endAt = moment().unix();

      setFilter({
        ...filter,
        dateRange: { dateFrom: startAt, dateTo: endAt },
      });

      return getCurrentDetalizationOptions(startAt, endAt);
    }
    // Check if dateFrom and DateTo set
    if (filtersObject.dateRange !== undefined) {
      // First run, filters as string
      if (filtersObject.dateRange.dateFrom === undefined && filtersObject.dateRange.dateTo === undefined) {
        filtersObject.dateRange = JSON.parse(filtersObject.dateRange);
      }

      return getCurrentDetalizationOptions(filtersObject.dateRange.dateFrom, filtersObject.dateRange.dateTo);
    }

    const startAt = moment().add(-12, 'hours').unix();
    const endAt = moment().unix();
    setFilter({
      ...filter,
      dateRange: { dateFrom: startAt, dateTo: endAt },
    });

    return getCurrentDetalizationOptions(startAt, endAt);
  };

  const type = filtersObject.object_type;
  const defaultLocations = filtersObject.selectedIds ? filtersObject.selectedIds.locations : [];
  const defaultCameras = filtersObject.selectedIds ? filtersObject.selectedIds.cameras : [];
  const [data, setData] = useState<ChartData>();
  const [filter, setFilter] = useState<ChartFilter>({
    selectedIds: {
      locations: defaultLocations,
      cameras: defaultCameras,
    },
    timeslice: filtersObject.timeslice !== undefined ? filtersObject.timeslice : '12h',
    detalizationOptionsList: filtersObject.detalizationOptionsList,
    detalization: filtersObject.detalization,
    dateRange: typeof (filtersObject.dateRange) === 'string' ? JSON.parse(filtersObject.dateRange) : filtersObject.dateRange,
  });

  useEffect(() => {
    const detalizationsList = getDetalizationOptions(filtersObject);
    if (detalizationsList) {
      const activeValue = getDefaultActiveDetalization(detalizationsList, filtersObject);

      setActiveDetalization(activeValue);
      setDetalizationOptionsList(detalizationsList);
    }

    // getChartData(parseFilter(filter));
  }, []);

  const stateRef = useRef<ChartFilter>();
  stateRef.current = filter;

  const parseFilter = ({ selectedIds, timeslice, dateRange }: ChartFilter) => {
    const UUID_REGEXP = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const UUID_FILTER = (item) => UUID_REGEXP.test(item);

    let selectedIdsFilter = '';

    if (Array.isArray(selectedIds?.cameras) && selectedIds?.cameras?.length > 0) {
      selectedIdsFilter += selectedIds.cameras.filter(UUID_FILTER).reduce((prev, next) => `${prev}&filters=camera:${next}`, '')
    }

    if (Array.isArray(selectedIds?.locations) && selectedIds?.locations?.length > 0) {
      selectedIdsFilter += selectedIds.locations.filter(UUID_FILTER).reduce((prev, next) => `${prev}&filters=location:${next}`, '')
    }

    let startAt; let endAt; let
      timestampsFilter;

    if (dateRange) {
      startAt = dateRange.dateFrom;
      endAt = dateRange.dateTo;
      timestampsFilter = `&filters=timestamp_start:${startAt}&filters=timestamp_end:${endAt}`;
    }
    else if (timeslice !== undefined && timeslice !== '') {
      startAt = Math.round((Date.now() - ms(timeslice)) / 1000);
      endAt = Math.round(Date.now() / 1000);
      timestampsFilter = `&filters=timestamp_start:${startAt}&filters=timestamp_end:${endAt}`;
    }

    const filtersObjectString = Object.entries(filtersObject).reduce((acc, [key, value]) => {
      if (value && !EXCLUDED_KEYS.includes(key)) {
        if (key === 'person_age' && value === '1-99') {
          return acc;
        }
        return `${acc}&filters=${key}:${String(value)}`;
      }

      return acc;
    }, '')

    return selectedIdsFilter + timestampsFilter + filtersObjectString;
  };

  const updateWidgetFilters = (
    ids: SelectedIds,
    timeSlice: string,
    detalizationValue: number,
    detalizationList: DetalizationType[],
    chartDateRange: ChartDateRange
  ) => {
    const detalizations = detalizationList && Object.keys(detalizationList).map((i) => JSON.stringify(detalizationList[parseInt(i, 10)])).join('|');

    const filtersValue = [`object_type:${type}`,
      `locations: ${ids?.locations?.join(',')}`,
      `cameras: ${ids?.cameras?.join(',')}`,
      `timeslice:${timeSlice}`,
      `detalizationOptionsList:${detalizations}`,
    ];

    if (chartDateRange) {
      filtersValue.push(`dateRange:${JSON.stringify(chartDateRange)}`);
    }

    if (detalizationValue) {
      filtersValue.push(`detalization:${detalizationValue}`);
    }

    if (isPieChart) {
      filtersValue.push('chart_type:pie');
    }

    Object.entries(filtersObject).reduce((acc, [key, value]) => {
      if (value && !EXCLUDED_KEYS.includes(key)) {
        acc.push(`${key}:${String(value)}`)
      }

      return acc;
    }, filtersValue)

    api.patch(`/layout-manager/widgets/${id}`, {
      filters: filtersValue,
    });

    setFiltersObject && setFiltersObject(parseStringFilters(filtersValue));
    if (!isSharedWidget) {
      updateLayout()
    }
  };

  const getChartData = (type, filter = '', activeDetail: number) => {
    const detalizationsList = getDetalizationOptions2(filtersObject);
    let url = `/chartilla/chart/?filters=object_type:${type}`;

    if (isPieChart) {
      url += '&chart_type=pie_chart';
    }

    if (activeDetail && Number.isInteger(activeDetail)) {
      url += `&detalization=${activeDetail}`;
    } else {
      url += `&detalization=${detalizationsList[0].value}`;
    }

    url += filter;

    setIsFetching && setIsFetching(true);
    api.get(prepareGetQuery(url))
      .then((response) => {
        setData(response.data);
        setLastUpdateTime(moment(new Date().getTime()).format('MM/DD/YYYY HH:mm:ss'));
      })
      .catch(() => {})
      .finally(() => {
        setIsFetching && setIsFetching();
      })
  };

  const debouncedUpdate = useRef(debounce((type, filter, activeDetail) => {
    getChartData(type, filter, activeDetail)
  }, 500))

  useEffect(() => {
    debouncedUpdate.current(type, parseFilter(filter), activeDetalization);
  }, [type, filter, activeDetalization, filtersObject]);

  useEffect(() => {
    if (!isRealtime) {
      return;
    }

    const intervalId = setInterval(
      () => {
        const filter = stateRef.current || {
          timeslice: '12h',
          selectedIds: { cameras: [], locations: [] },
          detalizationOptionsList: [{ name: '12 hours', value: 43200 }, { name: 'days', value: 86400 }],
          detalization: 43200,
          dateRange: getDateRange(),
        };

        if (filter.timeslice) {
          filter.dateRange = getDateRange(filter.timeslice);
        }

        getChartData(type, parseFilter(filter), activeDetalization || filter.detalization);
      },
      CHART_REFRESH_TIMEOUT,
    );

    return () => {
      clearInterval(intervalId)
    };
  }, [isRealtime, filter.timeslice, activeDetalization, data]);

  // Не понятно зачем это вообще нужно
  // useEffect(() => {
  //   if (detalizationOptionsList !== undefined && detalizationOptionsList.length > 0) {
  //     const activeValue = getDefaultActiveDetalization(detalizationOptionsList, filtersObject);
  //
  //     setActiveDetalization(activeValue);
  //     filter.detalization = activeValue;
  //     filter.detalizationOptionsList = detalizationOptionsList;
  //   }
  // }, [detalizationOptionsList, filtersObject]);

  // const handleLocationChange = (ids: SelectedIds) => {
  //   const newFilter = {
  //     ...filter,
  //     selectedIds: ids,
  //   };
  //
  //   console.log('handleLocationChange', newFilter);
  //
  //   setFilter(newFilter);
  //
  //   // getChartData(type, parseFilter(newFilter), filter.detalization);
  //
  //   updateWidgetFilters(
  //     ids,
  //     filter.timeslice,
  //     filter.detalization,
  //     filter.detalizationOptionsList,
  //     filter.dateRange
  //   );
  // };

  useEffect(() => {
    const newFilter = {
      ...filter,
      selectedIds,
    };

    setFilter(newFilter);

    // getChartData(type, parseFilter(newFilter), newFilter.detalization);
    if (Array.isArray(filter.detalizationOptionsList) && filter.dateRange) {
      updateWidgetFilters(
        selectedIds,
        filter.timeslice,
        filter.detalization,
        filter.detalizationOptionsList,
        filter.dateRange
      );
    }
  }, [selectedIds]);

  useEffect(() => {
    setChartDataLoading(false);
  }, [data]);

  const handleTimeRangeChange = (dateRange: string) => {
    const newFilter = {
      ...filter,
      timeslice: dateRange,
    };
    const startAt = Math.round((Date.now() - ms(dateRange as string)) / 1000);
    const endAt = Math.round(Date.now() / 1000);
    const detalizationsList = getDetalizationOptions(newFilter);
    let activeValue;

    if (detalizationsList) {
      setDetalizationOptionsList(detalizationsList);
      activeValue = getDefaultActiveDetalization(detalizationsList, filtersObject);

      newFilter.dateRange = { dateFrom: startAt, dateTo: endAt }
      newFilter.detalizationOptionsList = detalizationsList;
      newFilter.detalization = activeValue;
    }
    setFilter(newFilter);

    setActiveDetalization(activeValue);

    // getChartData(type, parseFilter(newFilter), newFilter.detalization);

    if (detalizationsList && activeValue !== undefined) {
      updateWidgetFilters(
        filter.selectedIds,
        dateRange,
        activeValue,
        detalizationsList,
        { dateFrom: startAt, dateTo: endAt }
      );
    }
    else {
      updateWidgetFilters(
        filter.selectedIds,
        dateRange,
        filter.detalization,
        filter.detalizationOptionsList,
        filter.dateRange
      );
    }
  };

  const handleDateRangeChange = (dateRangeIn: [any, any]) => {
    const dateRange:ChartDateRange = yellowToDateRangeMap(dateRangeIn);

    // if (dateRange.dateTo !== filter.dateRange.dateTo && onChangeRealtime) {
    //   onChangeRealtime(false);
    // }

    let newFilter = {
      ...filter,
      timeslice: '',
      detalization: null,
      dateRange: { dateFrom: dateRange.dateFrom, dateTo: dateRange.dateTo },
    };
    var detalizationsList = getDetalizationOptions(newFilter);
    if (detalizationsList) {
      setDetalizationOptionsList(detalizationsList);
      newFilter = {
        ...newFilter,
        // detalization: detalizationsList[0].value,
        detalizationOptionsList: detalizationsList,
      };
    }
    setFilter(newFilter);

    // getChartData(type, parseFilter(newFilter), newFilter.detalization);

    updateWidgetFilters(
      newFilter.selectedIds,
      '',
      newFilter.detalization,
      newFilter.detalizationOptionsList,
      newFilter.dateRange
    );
  };

  const handleDetalizationChange = (detalizationValue: number) => {
    if (!detalizationValue) return;

    const newFilter = {
      ...filter,
      detalization: detalizationValue,
    };
    setFilter(newFilter);

    setActiveDetalization(detalizationValue);

    // getChartData(type, parseFilter(newFilter), newFilter.detalization);

    const newDetalization = getDetalizationByValue(detalizationValue, detalizationOptionsList);
    const detalizationsList = getDetalizationOptions(newFilter);

    if (newDetalization && detalizationsList) {
      setActiveDetalization(newDetalization.value);
      updateWidgetFilters(
        filter.selectedIds,
        filter.timeslice,
        detalizationValue,
        // newDetalization.value,
        detalizationsList,
        filter.dateRange
      );
    }
  };

  return data
    ? (
      <Chart
        series={data.series}
        isSharedWidget={isSharedWidget}
        isPieChart={isPieChart}
        selectedChartType={selectedChartType}
        categories={data.categories}
        isRealtime={isRealtime}
        timeRangeProp={filter.timeslice}
        dateRangeProp={filter.dateRange}
        onChangeRealtime={onChangeRealtime}
        onDateRangeChange={handleDateRangeChange}
        onTimeRangeChange={handleTimeRangeChange}
        onDetalizationChange={handleDetalizationChange}
        detalizationOptionsList={detalizationOptionsList}
        activeDetalization={activeDetalization}
        dashBoardChart
        background={vars.backgroundInput}
        locationSelector
        defaultSelectedIds={filter.selectedIds}
        // onLocationChange={handleLocationChange}
        height={height}
        chartDataLoading={chartDataLoading}
        setOpenCamerasSelector={setOpenCamerasSelector}
        openCamerasSelector={openCamerasSelector}
        widgetId={id}
        setLegend={setLegend}
        legend={legend}
        showStackLabels={showStackLabels}
        showLegend={showLegend}
        showTimeSlice={showTimeSlice}
        debouncedPatch={debounced}
      />
    )
    : (
      <Box
        p={2}
        height={1}
        width={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    )
}

export default DashboardChart;

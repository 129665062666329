// @ts-nocheck
import React from 'react';

import './UIHighLightText.scss';

const UIHighLightText = ({ searchText, text }) => {
  const replace = () => {
    if (searchText) {
      const pattern = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const matcher = new RegExp(pattern, 'ig');
      const matchValue = text.match(matcher);

      if (matchValue) {
        return text.replaceAll(matcher, `<b>${matchValue[0]}</b>`);
      }
      return text;
    }
    return text
  }

  return (
    <span className="UIHighLight" dangerouslySetInnerHTML={{ __html: replace() }} />
  )
};

export default UIHighLightText;

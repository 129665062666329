// @ts-nocheck

import React, { useEffect } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { THEME_BRAND } from 'v2/utils/brands';
import { handlers } from 'v2/redux/store';
import { useSelector } from 'react-redux';
import 'v2/ui-kit/UIHTMLStyles/UIHTMLStyles.scss';

export default function Terms() {
  const terms = useSelector((state) => state.terms.data);

  useEffect(() => {
    handlers.user.clear()
    handlers.terms.fetch()
  }, []);

  return (
    <Container p={2} pb={4} maxWidth="md">
      <Grid container justifyContent="center" alignItems="center">
        <NavLink color="primary" to="/log-in">
          <Box p={4} pt={8} pb={0} display="flex">
            <img src={`images/${THEME_BRAND}/logo.png`} alt="logo" />
          </Box>
        </NavLink>
      </Grid>
      <Box className="UIHTMLStyles" pb={8} dangerouslySetInnerHTML={{ __html: terms.content }} />
    </Container>
  );
}

// @ts-nocheck
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'app/api';
import { checkCredentials, clearEmptyValues } from 'v2/utils/functions';
import { QUERY_STALE_TIME } from 'v2/constants/constQueries';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['user'];

const useUserQuery = () => {
  const queryClient = useQueryClient();

  const userQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.get('/auth-manager/v1/user');

      return result.data;
    },
    enabled: Boolean(checkCredentials()) || Boolean(process.env.STORYBOOK),
    staleTime: QUERY_STALE_TIME,
  });

  const userCreate = useMutation({
    mutationFn: (data) => api.post('/auth-manager/v3/users/', clearEmptyValues(data)),
  })

  const userRegister = useMutation({
    mutationFn: (data) => api.post('/auth-manager/v2/register/', clearEmptyValues(data)),
  })

  const userUpdate = useMutation({
    mutationFn: ([id, data]) => api.patch(`/auth-manager/users/${id}`, data),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  })

  const userDelete = useMutation({
    mutationFn: ([companyId, userId]) => api.delete(`/auth-manager/v2/company/${companyId}/users/${userId}`),
  });

  const userChangePassword = useMutation({
    mutationFn: (updatedPassword) => api.post('/auth-manager/auth/change-password/', updatedPassword),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  });

  const userUpdateAvatar = useMutation({
    mutationFn: (formData) => api.post('/auth-manager/user-photo/', formData),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  const userRemoveAvatar = useMutation({
    mutationFn: () => api.delete('/auth-manager/user-photo/'),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  const userCheckRegister = useMutation({
    mutationFn: (data) => api.post('/auth-manager/v1/users/check-registered/', clearEmptyValues(data)),
  });

  return {
    user: userQuery.data,
    userQuery,
    userCreate,
    userUpdate,
    userDelete,
    userRegister,
    userChangePassword,
    userUpdateAvatar,
    userRemoveAvatar,
    userCheckRegister,
  }
};

export default useUserQuery;

// @ts-nocheck

import React, { useState } from 'react';
import { Sidebar } from 'react-pro-sidebar';
import { vars } from 'Theme';

const BasicSidebar = ({
  children, mobile, isOpen, ...props
}) => {
  const sidebarProps = mobile ? { toggled: isOpen, breakPoint: 'always' } : { collapsed: !isOpen };

  return (
    <Sidebar
      {...sidebarProps}
      collapsedWidth="60px"
      width="240px"
      backgroundColor={
        vars.backgroundMain
      }
      style={{
        height: '100%',
        borderColor: vars.backgroundMain,
      }}
      {...props}
    >
      {children}
    </Sidebar>
  );
};

export default BasicSidebar;

// @ts-nocheck

import React, { useRef, useState, useEffect } from 'react';
import {
  Typography
} from '@material-ui/core';
import { current } from '@reduxjs/toolkit';

const ContentEditable = (
  {
    value, refresh, cancel, showtEditor, onChange = () => {}, ...props
  }
) => {
  const ref = useRef(null)
  const state = useRef(
    {
      value, prevValue: null, key: null,
    }
  );

  useEffect(() => {
    showtEditor && ref.current.focus()
  }, [showtEditor])

  if (!state.current.key || refresh) {
    state.current.key = Date.now()
  }

  if (state.current.prevValue !== value) {
    state.current.value = value;
  }

  const _handleInput = (e) => {
    const value = e.target.innerText;
    state.current.prevValue = value;
    onChange(value);
  };

  return (
    <Typography
      {...props}
      noWrap={!showtEditor}
      key={state.current.key}
      ref={ref}
      role="textbox"
      className="UIEditableText UIUnDraggable"
      contentEditable
      onFocus={(e) => {
        !showtEditor && e.target.blur()
      }}
      onBlur={(e) => {
        const target = e.relatedTarget;
        if (target?.classList.contains('UICancelButton') || target?.classList.contains('UIApplyButton')) return;
        cancel();
      }}
      dangerouslySetInnerHTML={{ __html: state.current.value }}
      onInput={_handleInput}
    />
  );
};
export default ContentEditable;

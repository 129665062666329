// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIContentCopyIcon from '../icons/UIContentCopyIcon';

const CopyAction = ({ tooltip, onClick }) => (
  <UIWidgetAction
    byHover
    tooltip={tooltip || 'Copy'}
    onClick={onClick}
  >
    <UIContentCopyIcon />
  </UIWidgetAction>
);

export default CopyAction;

// @ts-nocheck
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Slider, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  slider: ({ age }: { age: number[] }) => ({
    marginTop: '1.0rem',
    opacity: age[0] === 0 && age[1] === 100 ? 0.6 : 1,
  }),
  inputLabel: {
    fontSize: 14,
    marginBottom: 8,
    textTransform: 'initial',
  },
  sliderContainer: {
    padding: '0 20px'
  },
  filterName: {
    fontSize: '14px',
    textTransform: 'initial',
  },
  valueLabel: {
    fontSize: 14,
    top: -22,
    '& *': {
      background: 'transparent',
      color: 'white',
    },
  },
}),);

type CustomSliderProps = {
  value: number[];
  label: string;
  name?: string;
  variant?: 'h5',
  gutterBottom?: boolean,
  type: 'inputLabel' | 'filterName',
  onChange: (_: React.ChangeEvent<{}>, value: number | number[]) => void;
}

const CustomSlider = ({
  value, onChange, label, name, type, gutterBottom, variant, min, max
}: CustomSliderProps) => {
  const classes = useStyles({ age: value });

  return (
    <div>
      <Typography
        variant={variant}
        gutterBottom={gutterBottom}
        className={classes[type]}
      >
        {label}
      </Typography>
      <div className={classes.sliderContainer}>
        <Slider
          valueLabelDisplay="on"
          value={value}
          classes={{
            root: classes.slider,
            valueLabel: classes.valueLabel,
          }}
          min={min}
          max={max}
          onChange={onChange}
          name={name}
          aria-labelledby="range-slider"
        />
      </div>
    </div>
  );
};

export default CustomSlider;

// @ts-nocheck
import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const Image = ({ theme }) => (
  <svg width="100%" height="100%" viewBox="0 0 210 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M12 74L45.7778 38.1813L93.7778 33.1554L140 3H198" stroke={theme.palette.primary.main} />
      <path d="M12 105L46.0524 68.6768L94.4426 63.5801L141.041 33H198" stroke="#595959" />
      <circle cx="45.5" cy="37.5" r="2.5" fill={theme.palette.primary.main} />
      <circle cx="140.5" cy="2.5" r="2.5" fill={theme.palette.primary.main} />
      <circle cx="93.5" cy="32.5" r="2.5" fill={theme.palette.primary.main} />
      <circle cx="197.5" cy="2.5" r="2.5" fill={theme.palette.primary.main} />
      <circle cx="45.5" cy="68.5" r="2.5" fill="#595959" />
      <circle cx="94.5" cy="63.5" r="2.5" fill="#595959" />
      <circle cx="141.5" cy="32.5" r="2.5" fill="#595959" />
      <circle cx="197.5" cy="32.5" r="2.5" fill="#595959" />
    </g>
  </svg>
);

export default withTheme(Image);

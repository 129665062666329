// @ts-nocheck

import React from 'react';
import { Popover } from '@material-ui/core';
import { usePopover } from 'v2/providers/PopoverProvider';

const BasicPopover = ({
  Component, initialData, anchorEl, popoverProps
}) => {
  const { showPopover, hidePopover } = usePopover();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={hidePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...popoverProps}
    >
      <Component
        showPopover={showPopover}
        hidePopover={hidePopover}
        {...initialData}
      />
    </Popover>
  );
};

export default BasicPopover;

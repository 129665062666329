// @ts-nocheck

import userHandlers from './userHandlers';
import companiesHandlers from './companiesHandlers';
import licensesHandlers from './licensesHandlers';
import suggestionsHandlers from './suggestionsHandlers';
import helpHandlers from './helpHandlers';
import termsHandlers from './termsHandlers';
import privacyHandlers from './privacyHandlers';
import objectHandlers from './objectHandlers';
import storageHandlers from './storageHandlers';
import layoutsHandlers from './layoutsHandlers';
import networkHandlers from './networkHandlers';
import clusterNotificationsHandlers from './clusterNotificationsHandlers';
import googleIconsHandler from '../googleIcons/googleIconsHandlers';

const Handlers = (store) => ({
  storage: storageHandlers(store),
  user: userHandlers(store),
  companies: companiesHandlers(store),
  licenses: licensesHandlers(store),
  suggestions: suggestionsHandlers(store),
  help: helpHandlers(store),
  terms: termsHandlers(store),
  privacy: privacyHandlers(store),
  object: objectHandlers(store),
  layouts: layoutsHandlers(store),
  network: networkHandlers(store),
  clusterNotifications: clusterNotificationsHandlers(store),
  googleIcons: googleIconsHandler(store),
});

export default Handlers;

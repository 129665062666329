// @ts-nocheck
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'app/api';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['cluster_subscriptions'];

const useSubscriptionsQuery = () => {
  const queryClient = useQueryClient();

  const subscriptionsQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.post('/notification-manager/v1/watchlists/', {
        pagination: {
          pgsize: Number.MAX_SAFE_INTEGER
        }
      });

      return result.data.items;
    },
    initialData: [],
  });

  const subscriptionCreate = useMutation({
    mutationFn: async ([cluster_id, data]) => {
      const result = await api.post('/notification-manager/v1/watchlist/', { cluster_id, ...data });

      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  const subscriptionDelete = useMutation({
    mutationFn: (id) => api.delete(`/notification-manager/v1/watchlist/${id}`),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  const subscriptionUpdate = useMutation({
    mutationFn: ([id, data]) => api.patch(`/notification-manager/v1/watchlist/${id}`, data),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS)
  });

  return {
    subscriptionCreate,
    subscriptionUpdate,
    subscriptionDelete,
    subscriptions: subscriptionsQuery.data,
    subscriptionsQuery,
  }
}

export default useSubscriptionsQuery;

// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UICloseIcon from 'v2/icons/UICloseIcon';

const CloseAction = ({ onClick }) => (
  <UIWidgetAction byHover tooltip="Close" onClick={onClick}>
    <UICloseIcon />
  </UIWidgetAction>
);

export default CloseAction;

// @ts-nocheck

import React, { useState } from 'react';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dashboard from '@material-ui/icons/Dashboard';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipTrigger } from 'v2/components/basic/BasicPopoverNew';
import { Box } from '@material-ui/core';
import { ROUTES } from 'v2/utils/routes';
import BasicSidebar from 'v2/components/basic/BasicSidebar';
import MenuIcon from '@material-ui/icons/Menu';
import useUserStateQuery from 'v2/hooks/queries/useUserStateQuery';
import { SETTINGS } from 'v2/constants/userState';
import useMobile from 'v2/hooks/useMobile';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import useResizeEvent from '../v2/hooks/useResizeEvent';

function DeviceTreeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.54545 3H3V9.54545H9.54545V7.09091H11.1818V18.5455H14.4545V21H21V14.4545H14.4545V16.9091H12.8182V7.09091H14.4545V9.54545H21V3H14.4545V5.45455H9.54545V3ZM19.3636 4.63636H16.0909V7.90909H19.3636V4.63636ZM16.0909 16.0909H19.3636V19.3636H16.0909V16.0909Z"
      />
    </SvgIcon>
  );
}

const routes = () => [
  {
    name: 'Dashboard',
    url: '/',
    icon: Dashboard,
    visible: () => true,
    exact: true,
  },
  {
    name: 'Device Tree',
    url: '/tree-view',
    icon: DeviceTreeIcon,
    visible: () => true,
    exact: true,

  },
  // {
  //   name: 'Detected objects',
  //   url: '/appearances',
  //   icon: SearchIcon,
  //   visible: ({ pathname }) => pathname.includes('/appearance')
  // },
  {
    name: ROUTES.NOTIFICATIONS.title,
    url: ROUTES.NOTIFICATIONS.path,
    icon: ROUTES.NOTIFICATIONS.Icon,
    visible: () => true,
  }, {
    name: ROUTES.SETTINGS.title,
    url: ROUTES.SETTINGS.path,
    icon: ROUTES.SETTINGS.Icon,
    visible: () => true,
  }, {
    name: ROUTES.HELP.title,
    url: ROUTES.HELP.path,
    icon: ROUTES.HELP.Icon,
    visible: () => true,
  },
];

const SwitchButton = [{
  name: 'Switch to new version',
  icon: FiberNewIcon,
  url: `/${process.env.REACT_APP_BETA_URL}`,
  onClick: () => document.location = `/${process.env.REACT_APP_BETA_URL}`,
  visible: () => process.env.REACT_APP_BETA_URL,
}];

const DrawerContent = ({ isOpen, closeHandler }) => {
  const location = useLocation();
  const { triggerResize } = useResizeEvent();

  const handleIconButtonClick = () => {
    triggerResize();
    closeHandler();
  };

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <div className="h-32 p-4 flex justify-center items-center">
        {isOpen && (
          <Link to="/" alt="Logo">
            <div className="w-36 mr-6 UILogo" />
          </Link>
        )}
        <IconButton onClick={handleIconButtonClick}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <List>
        {routes().filter((item) => item.visible(location)).map((item, index) => (
          <Tooltip byHover placement="right" key={index}>
            <TooltipTrigger>
              <NavLink
                key={item.name}
                className="UIMenuLink"
                activeClassName="active"
                exact={item.exact}
                to={item.url || ''}
                target={item.target}
                onClick={() => item.onClick && item.onClick()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ noWrap: true }} primary={item.name} />
                </ListItem>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent>
              {!isOpen && <div className="p-2">{item.name}</div>}
            </TooltipContent>
          </Tooltip>
        ))}
      </List>
      <Box height={1} display="flex" justifyContent="flex-end" alignItems="flex-center" flexDirection="column" />
      <List>
        {SwitchButton.filter((item) => item.visible(location)).map((item, index) => (
          <Tooltip byHover placement="right" key={index}>
            <TooltipTrigger>
              <ListItem button onClick={() => item.onClick && item.onClick()}>
                <ListItemIcon>
                  <item.icon className="blob" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ noWrap: true }} primary={item.name} />
              </ListItem>
            </TooltipTrigger>
            <TooltipContent>
              {!isOpen && <div className="p-2">{item.name}</div>}
            </TooltipContent>
          </Tooltip>
        ))}
      </List>
      <Box padding={2} paddingTop={0} display="flex" alignItems="center" justifyContent="center" width={1}>
        {`v${process.env.REACT_APP_VERSION}`}
      </Box>
    </Box>
  );
};

const LeftMenu = () => {
  const [toggled, setToggled] = useState(false);
  const { getUserState, toggleUserState } = useUserStateQuery();
  const isOpen = getUserState(SETTINGS.MAIN_MENU_OPEN, false);
  const { isMobile } = useMobile();

  const closeMobileSideBar = () => setToggled(!toggled);
  const closeDesktopSideBar = () => toggleUserState(SETTINGS.MAIN_MENU_OPEN);

  if (isMobile) {
    return (
      <>
        <Box mr={2}>
          <IconButton onClick={() => {
            setToggled(!toggled);
          }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <BasicSidebar
          mobile
          onBackdropClick={closeMobileSideBar}
          isOpen={toggled}
        >
          <DrawerContent isOpen={toggled} closeHandler={closeMobileSideBar} mobile />
        </BasicSidebar>
      </>
    );
  }

  return (
    <BasicSidebar isOpen={isOpen}>
      <DrawerContent isOpen={isOpen} closeHandler={closeDesktopSideBar} />
    </BasicSidebar>
  );
};
export default LeftMenu;

// @ts-nocheck

import React, { useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import useTokenQuery from 'v2/hooks/queries/useTokenQuery';
import { useSnackbar } from 'notistack';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';
import UISectionMessage from 'v2/ui-kit/UISectionMessage/UISectionMessage';

const AddToken = () => {
  const { hideDialog, showDialog } = useDialog();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { enqueueSnackbar } = useSnackbar();
  const { createToken } = useTokenQuery();
  const [scheme] = useState(FORM_SCHEMES.ADD_TOKEN());
  const form = useUIForm(scheme);

  const { formState: { isValid } } = form;

  return (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>Generate New Token</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <UISectionMessage className="m-2 mt-0">
          To ensure a connection between the Metapix Plugin and the Metapix Cloud, a token needs to be generated
        </UISectionMessage>
        <UIForm methods={form} scheme={scheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          Cancel
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            showBackdrop('Creating token');
            createToken.mutateAsync(form.getValues()).then((data) => {
              showDialog(DIALOGS.SHOW_TOKEN, { token: data.gateway_token });
              enqueueSnackbar('Token has been added', { variant: 'success' });
            }).finally(hideBackdrop);
          }}
        >
          Generate
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default AddToken;

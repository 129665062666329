// @ts-nocheck

import { configureStore } from '@reduxjs/toolkit';

import userActionsMiddleware from './middlewares/userActions';
import requestsMiddleware from './middlewares/requests';

import reducer from './reducers/mainReducer';
import tokens from './tokens/tokens';
import tags from './tags/tags';
import user from './reducers/user';
import userPatch from './reducers/userPatch';
import companies from './reducers/companies';
import companiesTypes from './reducers/companiesTypes';
import userCompany from './reducers/userCompany';
import companyUsers from './reducers/companyUsers';

import advancedSearchFilters from './advancedSearchFilters/advancedSearchFilters';
import advancedSearch from './advancedSearch/advancedSearch';
import appSettings from './appSettings/appSettings';
import experimentalFeatures from './experimentalFeatures/experimentalFeatures';

import Handlers from './handlers/handlers';
// TODO ОБЪЕДИНИТЬ В ОДИН ФАЙЛ РЕДЮСЕР И ДЕЙСТВИЯ
import tokensHandlers from './tokens/handlers/tokensHandlers';
import tagsHandlers from './tags/handlers/tagsHandlers';

const slices = [
  user,
  userPatch,
  companies,
  companiesTypes,
  userCompany,
  companyUsers,
  ...tokens.slices,
  ...tags.slices,
  ...advancedSearchFilters.slices,
  ...appSettings.slices,
  ...experimentalFeatures.slices
]

const makeReducers = (slices) => slices.reduce((state, slice) => ({ ...state, [slice.name]: slice.reducer }), {});

const store = configureStore({
  reducer: {
    ...reducer,
    ...makeReducers(slices),
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(
    userActionsMiddleware().middleware,
    requestsMiddleware(slices).middleware
  ),
});

export const handlers = {
  ...Handlers(store),
  tokens: tokensHandlers(store),
  tags: tagsHandlers(store),
  advancedSearchFilters: advancedSearchFilters.handlers(store),
  advancedSearch: advancedSearch.handlers(store),
  appSettings: appSettings.handlers(store),
  experimentalFeatures: experimentalFeatures.handlers(store),
};

export default store;

window.store = store;
window.handlers = handlers;

// @ts-nocheck

import {
  Avatar, Box, List, ListItem, ListItemIcon, ListItemText, ListSubheader
} from '@material-ui/core';
import React from 'react';

const CompaniesList = ({
  title, items, selected, button, onClick
}) => (
  <Box width={1} display="flex" flexDirection="column">
    <List
      subheader={title && (
      <ListSubheader>
        {title}
      </ListSubheader>
      )}
    >
      {items.map((item, key) => (
        <Box key={key} borderRadius="borderRadius" border={1} borderColor="background.transparent" overflow="hidden">
          <ListItem button={button} selected={selected} onClick={() => onClick && onClick(item)}>
            <ListItemIcon>
              <Avatar>
                {item.type?.short}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={item.name} secondary={`${item.type?.name}, ${item.role?.name}`} />
          </ListItem>
        </Box>
      ))}
    </List>
  </Box>
)

export default CompaniesList;

// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tokensFetch = DataReducer({
  name: 'tokens',
  dataProvider: async () => {
    const result = await api.get('/auth-manager/v1/gateway_tokens', {
      params: {
        pgsize: Number.MAX_SAFE_INTEGER
      }
    })
    return result.data.items;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching tokens',
    [FETCH_STATE.REJECTED]: 'Error fetch tokens',
  }
});
export default tokensFetch;

// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIWysiwygIcon from 'v2/icons/UIWysiwygIcon';

const ObjectDataToggle = ({ isDisabled, onClick }) => (
  <UIWidgetAction byHover tooltip={isDisabled ? 'Show object information' : 'Hide object information'} isDisabled={isDisabled} onClick={onClick}>
    <UIWysiwygIcon />
  </UIWidgetAction>
);

export default ObjectDataToggle;

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import BasicTable from 'v2/components/basic/BasicTable';
import { TABLE, MONTH_DAY_YEAR_FORMAT } from 'v2/utils/constants';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { handlers } from 'v2/redux/store';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { useSnackbar } from 'notistack';
import useLicensesQuery from 'v2/hooks/queries/useLicensesQuery';
import useUserActions from 'v2/hooks/queries/useUserActions';
import UISectionMessage from 'v2/ui-kit/UISectionMessage/UISectionMessage';
import BasicWidget from './basic/BasicWidget';

const headCells = () => [
  {
    id: 'trial',
    label: 'License Type',
    formatter: ({ trial }) => (trial ? 'Demo' : 'Regular'),
  },
  {
    id: 'id',
    label: 'Key',
    align: 'left',
  },
  {
    id: 'activated_at',
    label: 'Activated at',
    align: 'center',
    formatter: ({ activated_at }) => moment(activated_at).format(MONTH_DAY_YEAR_FORMAT),
  },
  {
    id: 'expired_at',
    label: 'Expires at',
    align: 'center',
    formatter: ({ expired_at }) => moment(expired_at).format(MONTH_DAY_YEAR_FORMAT),
  },
  {
    id: 'days',
    label: 'Days',
    align: 'center',
  },
  {
    id: 'cameras_count',
    label: 'Channels',
    align: 'center',
  },
  {
    id: 'is_enabled',
    label: 'License Status',
    align: 'center',
    formatter: ({ is_enabled, is_expired }) => (is_enabled ? is_expired ? 'Expired' : 'Active' : 'Disabled'),
  },
];
const Licenses = () => {
  const dialog = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { canAddDemoLicense } = useUserActions();
  const { licenses, activateDemoLicense, licensesQuery } = useLicensesQuery();

  useEffect(() => {
    if (licensesQuery.isFetching) {
      showBackdrop('Loading licenses');
    }
    if (licensesQuery.isFetched) {
      hideBackdrop();
    }
  }, [licenses]);

  const rowClassName = (row) => (Date.parse(row.expired_at) <= Date.now() ? 'UIRowDisabledLicense' : 'UIRow');

  const toolbarActions = [{
    title: 'Activate demo license',
    type: TABLE.TOOLBAR.BUTTONS.TYPE,
    visible: canAddDemoLicense,
    onClick: () => {
      showBackdrop('Activating demo license');
      activateDemoLicense.mutateAsync()
        .then(() => {
          enqueueSnackbar('Demo license has been activated', { variant: 'success' });
        })
        .finally(hideBackdrop);
    },
  }, {
    title: 'Activate new license',
    type: TABLE.TOOLBAR.BUTTONS.TYPE,
    visible: true,
    onClick: () => {
      dialog.showDialog(DIALOGS.ADD_LICENSE);
    },
  }];

  const subTitle = (camerasCount) => {
    const prepareText = (count, positive, negative) => (count ? `${count} ${positive}` : `${count} ${negative}`);

    switch (true) {
      case (camerasCount.total_cameras > 0):
        return camerasCount.total_cameras > 0 && (
          <UISectionMessage
            title="Licenses summary"
            className="mb-2"
          >
            {prepareText(camerasCount.total_cameras, 'Channels in total', 'Channels in total')}
            <br />
            {prepareText(camerasCount.total_in_use, 'Channels currently in use', 'Channels currently in use')}
          </UISectionMessage>
        );

      case (camerasCount.total_cameras === 0):
        return camerasCount.total_cameras === 0 && (
          <UISectionMessage
            title="Licenses summary"
            className="mb-2"
          >
            No active licenses
            <br />
            {['You have used', camerasCount.total_in_use, 'channels recently'].join(' ')}
          </UISectionMessage>
        );
    }
  };

  const EmptyTableComponent = () => [
    <Typography variant="subtitle1" align="center">
      Currently, you haven’t activated any license. If you have a License Key, you can activate a New License.
    </Typography>,
    <Typography variant="subtitle2" align="center">
      Otherwise, you can activate a Demo License and use our Solution for 30 business days within the maximum capacity
      of 30 channels
    </Typography>,
  ];

  const FilledTable = () => (
    <BasicTable
      columns={headCells(licenses.items)}
      rows={licenses.items}
      rowClassName={rowClassName}
      storageKey="licensesPerPage"
    />
  );

  if (licensesQuery.isSuccess) {
    return (
      <Box p={2} width={1}>
        <BasicWidget title="Licenses" rightActions={toolbarActions}>
          {subTitle(licenses?.cameras_count || [])}
          {licenses.items.length > 0 ? <FilledTable /> : <EmptyTableComponent />}
        </BasicWidget>
      </Box>
    );
  }

  return null;
};

export default Licenses;

// @ts-nocheck

import React, { useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { useDialog } from 'v2/providers/DialogProvider';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FormGenerator, { useFormGenerator } from 'v2/ui-kit/UIForm/FormGenerator';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import useUserQuery from 'v2/hooks/queries/useUserQuery';

const EditPassword = (props) => {
  const form = useFormGenerator();
  const { userChangePassword } = useUserQuery()
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { DialogTitle, DialogBody, DialogActions } = props;
  const { formState } = form;

  const [formScheme] = useState(FORM_SCHEMES.CHANGE_PASSWORD());

  const Content = (
    <Box width={1} height={1} display="flex" alignItems="center" justifyContent="center" overflow="hidden" position="relative">
      <FormGenerator methods={form} scheme={formScheme} />
    </Box>
  );

  const Actions = (
    <Box width={1} display="flex" justifyContent="space-between">
      <Button
        color="primary"
        variant="outlined"
        onClick={hideDialog}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={!formState.isValid}
        onClick={() => {
          showBackdrop('Changing password');
          userChangePassword.mutateAsync(form.getValues()).then(() => {
            hideDialog();
            enqueueSnackbar('Password has been changed', { variant: 'success' });
          }).finally(hideBackdrop);
        }}
      >
        Change
      </Button>
    </Box>
  )
  return (
    <>
      <DialogTitle title="Change Password" hasCloseIcon />
      <DialogBody className="UIPaddingLess">
        {Content}
      </DialogBody>
      <DialogActions>
        {Actions}
      </DialogActions>
    </>
  )
};

export default EditPassword;

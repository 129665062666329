// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';

const companies = DataReducer({
  name: 'companies',
  dataProvider: async () => {
    const result = await api.post('/auth-manager/v1/user/companies');
    return result.data.items;
  },
});

export default companies;

// @ts-nocheck

import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import {
  Box,
  Checkbox, FormControlLabel, ListItemIcon, ListItemText, MenuItem,
} from '@material-ui/core';
import { v4 } from 'uuid';

const DashboardMenu = ({
  menuItems, MenuIcon, className, title
}) => {
  const [vertAnchorEl, setVertAnchorEl] = useState(null);

  const isVertMenuOpen = Boolean(vertAnchorEl);

  const handleClick = (event: any) => {
    setVertAnchorEl(event.target);
  };

  const handleVertMenuClose = () => {
    setVertAnchorEl(null);
  };

  return (
    <>
      <Box className={className}>
        <Tooltip title={title}>
          <IconButton
            onClick={handleClick}
          >
            {MenuIcon ? <MenuIcon /> : <MoreVertIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        className="UIMUIMenu"
        anchorEl={vertAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isVertMenuOpen}
        onClose={handleVertMenuClose}
      >
        {menuItems.filter((item) => item.visible).map(({
          id = v4(),
          text,
          Icon,
          onClick,
          disabled,
          closeOnClick = true,
          checkBox,
          checked,
        }) => {
          if (checkBox) {
            return (
              <MenuItem
                className="UIMenuItem"
                key={id}
              >
                <FormControlLabel
                  control={<Checkbox checked={checked} onChange={onClick} name={id} />}
                  label={text}
                />
              </MenuItem>
            );
          }
          return (
            <MenuItem
              className="UIMenuItem"
              key={id}
              onClick={(event) => {
                closeOnClick && handleVertMenuClose();
                onClick && onClick(event);
              }}
              disabled={disabled}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default DashboardMenu;

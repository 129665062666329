// @ts-nocheck

import { FEED_LENGTH } from 'v2/utils/constants';

export const filtersConverter = (objectType, filters, pagination) => {
  const QUALITY = ['good', 'bad'];
  const GENDERS = ['female', 'male'];
  const WITH_WITHOUT = ['without', 'with'];

  const converter = {
    V2: {
      V1: {
        // TYPE
        objectType: (objectType) => (objectType ? `filters=object_type:${objectType}` : null),
        // ORDERING
        ordering: (v2) => {
          switch (true) {
            case (v2?.orderings?.timestamp === 'timestamp'):
              return ['sort_field=date', 'sort_order=asc'];
            case (v2?.orderings?.timestamp === 'timestamp desc'):
              return ['sort_field=date', 'sort_order=desc'];
            case (v2?.orderings?.cluster_size === 'cluster_size'):
              return ['sort_field=appearances', 'sort_order=asc'];
            case (v2?.orderings?.cluster_size === 'cluster_size desc'):
              return ['sort_field=appearances', 'sort_order=desc'];
            default:
              return []
          }
        },
        // COMMON
        name: (v2) => (v2?.common_filters?.name ? `filters=name:${v2.common_filters.name}` : null),
        notes: (v2) => (v2?.common_filters?.notes ? `filters=notes:${v2.common_filters.notes}` : null),
        imageQuality: (v2) => (v2?.common_filters?.image_quality ? `filters=image_quality:${QUALITY[Number(v2.common_filters.image_quality)]}` : null),
        // DATE
        timestampStart: (v2) => (v2?.timestamp_filters?.timestamp_start ? `filters=timestamp_start:${v2.timestamp_filters.timestamp_start}` : null),
        timestampEnd: (v2) => (v2?.timestamp_filters?.timestamp_end ? `filters=timestamp_end:${v2.timestamp_filters.timestamp_end}` : null),
        // LOCATIONS & CAMERAS
        location: (v2) => (v2?.camera_filters?.location ? v2.camera_filters.location.map((item) => `filters=location:${item}`) : []),
        camera: (v2) => (v2?.camera_filters?.camera ? v2.camera_filters.camera.map((item) => `filters=camera:${item}`) : []),
        // PAGINATION
        pgOffset: (pagination) => (pagination?.pgoffset ? `pgoffset=${pagination.pgoffset}` : `pgoffset=${0}`),
        pgSize: (pagination) => (pagination?.pgsize ? `pgsize=${pagination.pgsize}` : `pgsize=${FEED_LENGTH}`),
        // FACE,
        faceGender: (v2) => (v2?.object_type_filters?.face_gender ? `filters=person_gender:${GENDERS[Number(v2.object_type_filters.face_gender)]}` : null),
        faceGlasses: (v2) => (v2?.object_type_filters?.face_glasses ? `filters=person_glasses:${WITH_WITHOUT[Number(v2.object_type_filters.face_glasses)]}` : null),
        faceBeard: (v2) => (v2?.object_type_filters?.face_beard ? `filters=person_beard:${WITH_WITHOUT[Number(v2.object_type_filters.face_beard)]}` : null),
        faceMask: (v2) => (v2?.object_type_filters?.face_mask ? `filters=person_mask:${WITH_WITHOUT[Number(v2.object_type_filters.face_mask)]}` : null),
        faceAge: (v2) => (v2?.object_type_filters?.face_age ? `filters=person_age:${v2.object_type_filters.face_age.join('-')}` : null),
        // VEHICLE,
        vehicleType: (v2) => (v2?.object_type_filters?.vehicle_type ? `filters=vehicle_type:${v2.object_type_filters.vehicle_type}` : null),
        vehicleManufacturer: (v2) => (v2?.object_type_filters?.vehicle_manufacturer ? `filters=vehicle_manufacturer:${v2.object_type_filters.vehicle_manufacturer}` : null),
        vehicleModel: (v2) => (v2?.object_type_filters?.vehicle_model ? `filters=vehicle_model:${v2.object_type_filters.vehicle_model}` : null),
        vehicleLicensePlate: (v2) => (v2?.object_type_filters?.vehicle_license_plate ? `filters=vehicle_license_plate:${v2.object_type_filters.vehicle_license_plate}` : null),
        vehicleColor: (v2) => (v2?.object_type_filters?.vehicle_color ? `filters=vehicle_color:${v2.object_type_filters.vehicle_color}` : null),
      },
    },
  };

  const v1 = [
    converter.V2.V1.objectType(objectType),
    ...converter.V2.V1.ordering(filters),
    converter.V2.V1.name(filters),
    converter.V2.V1.notes(filters),
    converter.V2.V1.imageQuality(filters),
    converter.V2.V1.timestampStart(filters),
    converter.V2.V1.timestampEnd(filters),
    ...converter.V2.V1.location(filters),
    ...converter.V2.V1.camera(filters),
    converter.V2.V1.faceGender(filters),
    converter.V2.V1.faceBeard(filters),
    converter.V2.V1.faceMask(filters),
    converter.V2.V1.faceGlasses(filters),
    converter.V2.V1.faceAge(filters),
    converter.V2.V1.vehicleManufacturer(filters),
    converter.V2.V1.vehicleLicensePlate(filters),
    converter.V2.V1.vehicleType(filters),
    converter.V2.V1.vehicleModel(filters),
    converter.V2.V1.vehicleColor(filters),
    converter.V2.V1.pgOffset(pagination),
    converter.V2.V1.pgSize(pagination),
  ];

  return v1.filter((item) => item).join('&');
};

// @ts-nocheck

import React, { useRef, useState } from 'react';
import { IconButton, Input } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Tooltip, TooltipContent, TooltipTrigger } from 'v2/components/basic/BasicPopoverNew';

const AutocompleteComboInput = (props) => {
  const containerRef = useRef();
  const [open, setOpen] = useState(false);
  const items = props.items || [];

  const onInputChange = (item) => {
    props.field.onChange(item);
    setOpen(items.length > 0);
  };

  return (
    <div className="flex content-center justify-items-center px-3 py-1 w-full" ref={containerRef}>
      <Tooltip open={open}>
        <TooltipTrigger>
          <div
            className="flex content-center justify-items-center justify-between w-full"
          >
            <Input
              fullWidth
              disableUnderline
              value={props.field.value}
              onChange={(e) => onInputChange(e.target.value)}
              onFocus={() => setOpen(true)}
              onBlur={(e) => {
                setTimeout(() => {
                  setOpen(false);
                }, 250)
              }}
            />
            {items.length > 0 && (
            <IconButton
              onClick={() => setOpen((prev) => !prev)}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          {
            items.length > 0 && (
              <div className="rounded overflow-hidden w-full">
                {items.map((item) => (
                  <div
                    id={item}
                    className="p-2 cursor-pointer hover:bg-neutral-600 normal-case text-base"
                    key={item}
                    onClick={(e) => {
                      props.field.onChange(item);
                      setOpen(false);
                    }}
                    style={{ width: `${containerRef?.current?.clientWidth}px` }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )
          }
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export default AutocompleteComboInput;

// @ts-nocheck

import React from 'react';
import Button from '@material-ui/core/Button';
import BasicDialog from 'v2/components/basic/BasicDialog';
import { useDialog } from '../providers/DialogProvider';

const TestDialog = () => {
  const { hideDialog } = useDialog();

  const Buttons = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={hideDialog}
    >
      Submit
    </Button>
  )

  return <BasicDialog Buttons={Buttons} />
};

export default TestDialog;

// @ts-nocheck

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Dialog, DialogContent, Divider, Grid, Button, Paper, Box,
} from '@material-ui/core';
import { vars } from '../../Theme';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    // background: vars.primaryBackground,
    border: `1px solid ${vars.primaryBorderColor}`,
    // width: '480px',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${vars.spacing}px`,
  },
  titleHeader: {
    fontWeight: 500,
    fontSize: 18,
  },
  dialogContent: {
    padding: `${vars.spacing}px`,
    // minHeight: '70%',
  },
  divider: {
    backgroundColor: vars.primaryBorderColor,
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  questionText: {
    fontWeight: 500,
    fontSize: 16,
  },
  infoText: {
    fontSize: 14,
    lineHeight: '150%',
    textAlign: 'center',
    // marginTop: 16,
    // marginBottom: 32,
  },
  button: {
    // padding: '9px 24px',
    // textTransform: 'none',
    borderRadius: vars.primaryBorderRadius,
  },
  buttonWrapper: {
    padding: `${vars.spacing}px`,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${vars.primaryBorderColor}`,
  },
}),);

type HandleDelete =
  (() => void)
  | ((event: React.MouseEvent<HTMLButtonElement>) => void);

type DeleteModalProps = {
  name: string,
  isOpen: boolean,
  isArchiveCamera?: boolean
  type: 'camera' | 'location',
  handleClose: () => void,
  handleDelete: HandleDelete,
};

const DeleteModal = ({
  name,
  type,
  isOpen,
  handleClose,
  handleDelete,
  isArchiveCamera = false,
}: DeleteModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Typography align="left">
          {type === 'location'
            ? 'Delete Location'
            : isArchiveCamera ? 'Enable Camera' : 'Disable Camera'}
        </Typography>
      </Box>
      <Divider />
      <Box p={2} width={400} overflow="auto">
        <Box
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            align="center"
            // className="UITruncatedText"
          >
            {type === 'location'
              ? `Are you sure you want to delete ${name}?`
              : `Are you sure you want to ${isArchiveCamera ? 'enable' : 'disable'} ${name}?`}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDelete}
        >
          {type === 'location'
            ? 'Delete'
            : isArchiveCamera ? 'Enable' : 'Disable'}
        </Button>
      </Box>
    </Dialog>
  );
};

export default DeleteModal;

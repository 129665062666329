// @ts-nocheck

import React from 'react';
import SubMenu from 'v2/components/SubMenu';
import { ROUTES } from 'v2/utils/routes';

export default function Help() {
  return (
    <SubMenu MenuListTitle={ROUTES.NOTIFICATIONS.title} MenuListRoutes={ROUTES.NOTIFICATIONS.ROUTES} />
  );
}

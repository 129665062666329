// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import VideocamIcon from '@material-ui/icons/Videocam';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
  IconButton, SvgIconProps, Box,
} from '@material-ui/core';

import DeleteModal from 'pages/locations/DeleteModal';
import { ICamera, ILocation, ILocationCamera } from 'pages/locations/types';
import {
  cameraDragHandler, emptyElement, hideElems, setCameraElName, showElems
} from 'pages/locations/draggableUtils';
import UIHighLightText from 'v2/ui-kit/UIHighLightText/UIHighLightText';

type NotAllocatedCameraProps = {
  model: ICamera;
  selected: boolean;
  handleItemSelect: (item: ILocation | ILocationCamera) => void;
  handleArchiveCamera: (model: any) => void;
  Icon: React.ComponentType<SvgIconProps>;
  isArchiveCamera?: boolean;
};

const NotAllocatedCamera = ({
  model, selected, handleItemSelect, handleArchiveCamera, Icon, isArchiveCamera = false, newCamera, is_new, search,
}: NotAllocatedCameraProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { name, active, archived } = model;

  const handleDragStart = (e: React.DragEvent<HTMLElement>) => {
    e.stopPropagation();
    setCameraElName(name);
    showElems();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(emptyElement, 0, 0);
    e.dataTransfer.setData('application/json', JSON.stringify({ type: 'camera', dragItem: model, isAllocated: false }));
    // newCamera(model)
  };

  const divElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divElementRef.current) {
      divElementRef.current.addEventListener('drag', cameraDragHandler, true);
    }
  }, []);

  const handleArchive = () => {
    handleArchiveCamera(model);
  };

  const handleModalClick = (event) => {
    event.stopPropagation()
    setIsDeleteModalOpen(!isDeleteModalOpen)
  };

  return (
    <Box
      key={model.id}
      mb={1}
      borderRadius="borderRadius"
      border={1}
      display="flex"
      alignItems="center"
      draggable={!archived}
      onDragStart={handleDragStart}
      // onDragEnd={hideElems}
      onDragEnd={() => {
        hideElems();
        newCamera(model)
      }}
      // bgcolor={selected ? 'background.main' : 'background.input'}
      bgcolor="background.input"
      borderColor={is_new ? 'primary.main' : 'background.primaryBorder'}
      ref={divElementRef}
      onClick={() => {
        handleItemSelect(model);
        newCamera(model);
      }}
      style={{ cursor: !archived && 'pointer' }}
    >
      <Box p={2} width={1} display="flex" alignItems="center">
        <Box mr={1} display="flex" alignItems="center">
          <DragIndicatorIcon color="primary" />
        </Box>
        <Box mr={1} display="flex" alignItems="center">
          <VideocamIcon
            style={{ color: archived && (active ? 'rgba(255,0,0)' : 'rgba(255,0,0, 0.5)') }}
            color={active ? 'primary' : 'secondary'}
          />
        </Box>
        <Box mr={1} flexGrow={1} alignItems="center" overflow="hidden" whiteSpace="noWrap" textOverflow="ellipsis">
          <UIHighLightText searchText={search} text={name.toUpperCase()} />
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleModalClick}>
            <Icon color="primary" />
          </IconButton>
        </Box>
        <DeleteModal
          name={name}
          isOpen={isDeleteModalOpen}
          type="camera"
          handleClose={handleModalClick}
          handleDelete={handleArchive}
          isArchiveCamera={isArchiveCamera}
        />
      </Box>
    </Box>
  );
};

export default NotAllocatedCamera;

// @ts-nocheck

import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

const TranslationTest = () => {
  const { t, i18n } = useTranslation(['translation', 'common']);
  //   const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Button onClick={() => changeLanguage('en')}>EN</Button>
      <Button onClick={() => changeLanguage('ru')}>RU</Button>
      <div>{t('test')}</div>
    </>
  )
}

export default TranslationTest

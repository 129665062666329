// @ts-nocheck
import React, { useEffect, useState, } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import api from 'app/api';
import {
  Box, FormControl, Select, MenuItem, Divider
} from '@material-ui/core';
import debounce from 'lodash/debounce';

import { yellowFromDateRangeMap } from 'utils/yelloMapping';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from 'moment-timezone';
import BasicDialog from '../common/BasicDialog';
import ChartDatePicker from '../chart/ChartDatePicker';

const CUSTOM_DATE_RANGE = 'Custom';

const debouncedHandler = (handler: (v: string) => void) => debounce(
  (_: any, newDateRange: string | null) => {
    if (newDateRange === null || newDateRange === CUSTOM_DATE_RANGE) {
      return;
    }
    handler(newDateRange);
  },
  50,
);

export default function ToggleDatePicker({
  onTimeRangeChange, timeRangeProp,
  onDetalizationChange, dateRangeProp, onDateRangeChange, activeDetalization, detalizationOptionsList
}) {
  const [timeSlices, setTimeSlices] = useState([]);
  const [timeRange, setTimeRange] = useState(timeRangeProp || '');

  const initialValues = dateRangeProp || { dateFrom: moment().add(-12, 'hours').unix(), dateTo: moment().unix() }

  useEffect(() => {
    api.get<TimeSlice[]>('/layout-manager/report-timeslices/').then((response) => {
      response.data.push({
        id: '00000000-0000-0000-0000-000000000000',
        name: CUSTOM_DATE_RANGE,
        value: Infinity,
        default: false,
      });
      setTimeSlices(response.data);

      if (timeRangeProp) {
        setTimeRange(timeRangeProp);
      } else {
        setTimeRange(CUSTOM_DATE_RANGE);
      }
    });
  }, []);

  const onTimeButtonClick = (value: TimeSlice) => () => {
    setTimeRange(value.name);
  }

  const handleDetalizationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as number;

    if (newValue && onDetalizationChange !== undefined) onDetalizationChange(newValue);
  };

  const [showDataTimePicker, setShowDataTimePicker] = useState<boolean>(false);
  const handleDataTimePicker = () => {
    setShowDataTimePicker((prevState) => !prevState);
  }

  return (
    <Box className="UIUnDraggable" p={1} display="flex" overflow="auto" flexShrink={0}>
      <BasicDialog
        title="Select custom date range"
        isOpen={showDataTimePicker}
        initialValue={yellowFromDateRangeMap(initialValues)}
        closeDialog={handleDataTimePicker}
        onChange={(value) => {
          onDetalizationChange(null);
          onDateRangeChange && onDateRangeChange(value);
          setTimeRange('Custom');
        }}
        Component={ChartDatePicker}
      />
      <ToggleButtonGroup
        size="small"
        value={timeRange}
        exclusive
        onChange={debouncedHandler(onTimeRangeChange)}
        className="UIButtonGroupChart"
      >
        {timeSlices
          .sort((a, b) => a.value - b.value)
          .map((item) => (
            <ToggleButton
              key={item.id}
              value={item.name}
              onClick={item.name === CUSTOM_DATE_RANGE ? handleDataTimePicker : onTimeButtonClick(item)}
              selected={timeRange === item.name}
              defaultChecked={item.default}
            >
              {item.name}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </Box>
  );
}

// @ts-nocheck

import { BRANDS, THEME_BRAND } from 'v2/utils/brands';

export const colors = {
  primary: '#0B92B7',
  secondary: '#38A699',
  dark: '#0D0E0F',
  dark2: '#171C1F',
  dark3: '#121517',
  dark4: '#333333',
  dark5: '#424242',
  dark6: '#506670',
  dark7: '#212A2F',
  dark8: '#181E20',
  accent: '#4191B3',
  blueGray: '#698795',

  white: '#fff',
  green: '#6FCF97',
  orange: '#FFA360',
  blue: '#6CADDF',
  red: '#F27077',
  purple: '#AE77B2',
  pink: '#E194BC',

  textGray: '#717070',
  menuActive: '#171C1F',
  borders: '#1D2529',
};

export const vars = {
  background: 'background.png',
  spacing: 8,

  primary: '#BB0000',
  secondary: '#303030',

  backgroundDefault: '#000000',
  backgroundMain: '#212121',
  backgroundSecondary: '#303030',
  backgroundContent: '#252525',
  backgroundInput: '#171717',

  primaryBorderColor: '#ffffff1f',
  inputBorderColor: '#3a3a3a',

  global: {}
};

export const palettes = () => {
  switch (THEME_BRAND) {
    case (BRANDS.NX):
      return {
        ...vars,
        primary: '#0B92B7',
        global: {
          // '.UILogo': {
          //   width: '125px',
          //   height: '50px',
          //   background: `url(/images/${THEME_BRAND}/logo.png) no-repeat center center`,
          //   backgroundSize: 'contain',
          // }
        }
      }
    case (BRANDS.DW):
      return {
        ...vars,
        primary: '#ff6c00',
        global: {
          // '.UILogo': {
          //   width: '125px',
          //   height: '50px',
          //   background: `url(/images/${THEME_BRAND}/logo.png) no-repeat center center`,
          //   backgroundSize: 'contain',
          // }
        }
      }
    case (BRANDS.SV):
      return {
        ...vars,
        primary: '#fd6600',
        global: {
          // '.UILogo': {
          //   width: '125px',
          //   height: '50px',
          //   background: `url(/images/${THEME_BRAND}/logo.png) no-repeat center center`,
          //   backgroundSize: 'contain',
          // }
        }
      }
    default:
      return {
        ...vars,
      }
  }
}

export const templateTheme = (vars) => {
  const spacing = (factor) => factor * 0.5 * vars.spacing;

  return ({
    spacing,
    palette: {
      type: 'dark',
      primary: {
        main: vars.primary,
      },
      secondary: {
        main: vars.secondary,
      },
      background: {
        default: vars.backgroundDefault,
        main: vars.backgroundMain,
        secondary: vars.backgroundSecondary,
        content: vars.backgroundContent,
        input: vars.backgroundInput,
        primaryBorder: vars.primaryBorderColor,
        inputBorder: vars.inputBorderColor,
        transparent: '#00000000',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': '2px',
          },
          '*::-webkit-scrollbar': {
            width: '8px',
            height: '4px',
          },
          '*::-webkit-scrollbar-track': {
            // boxShadow: `inset 0 0 6px ${vars.primaryBorderColor}`,
            // webkitBoxShadow: `inset 0 0 6px ${vars.primaryBorderColor}`,
            backgroundColor: 'transparent',
            // outline: `1px solid ${vars.primaryBackground}`,
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: vars.primary,
            // outline: `1px solid ${vars.primaryBorderColor}`,
          },
          'input:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 30px ${vars.backgroundInput} inset !important`,
            borderRadius: 'unset !important'
          },
          // 'input:-webkit-autofill:hover': {
          //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
          // },
          // 'input:-webkit-autofill:focus': {
          //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
          // },
          // 'input:-webkit-autofill:active': {
          //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
          // },
          'html, body, #root': {
            height: '100vh',
            lineHeight: 'unset',
          },
          '.MuiSelect-select:focus': {
            backgroundColor: 'unset !important',
          },
          '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'unset !important',
          },
          '.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'unset',
          },
          '.MuiBox-root': {
            borderColor: vars.primaryBorderColor,
          },
          '.markdown-body': {
            overflow: 'auto',
          },
          '.markdown-body a': {
            color: vars.primary,
          },
          '.markdown-body h1:first-child': {
            marginTop: '0',
          },
          '.react-resizable-handle': {
            borderColor: `transparent transparent ${vars.primaryBorderColor} transparent !important`,
          },
          '.react-grid-item': {
            overflow: 'hidden',
          },
          '.code': {
            borderWidth: '0 !important',

          },
          '.UIListItem': {
            backgroundColor: 'unset !important',
          },
          '.UITermsPolicy': {
            '& blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre': {
              margin: 'revert',
            },
            '& h1, h2, h3, h4, h5, h6': {
              fontSize: 'revert',
              fontWeight: 'revert',
            },
            '& ol, ul, menu': {
              listStyle: 'revert',
              margin: 'revert',
              padding: 'revert',
            }
          },
          '.UICreatableSelect': {
            width: '100%',
            '&__input': {
              opacity: '1 !important'
            },
            '&__control': {
              border: 'none !important',
              boxShadow: 'none !important',
            },
            '&__menu': {
              zIndex: '2 !important',
              marginTop: '4px !important'
            }
          },
          '.UIWidget': {
            height: '100%',
          },
          '.UIMenuLink': {
            color: 'unset',
            textDecoration: 'none'
          },
          '.UINavLink': {
            color: vars.primary,
            textDecoration: 'none',
          },
          '.UIFullSize': {
            width: '100%',
            height: '100%',
            display: 'flex',
          },
          '.UIBackdrop': {
            zIndex: '999999 !important',
            backgroundColor: '#000000dd !important',
          },
          '.UIBasicWidgetBackdrop': {
            zIndex: '999999 !important',
            backgroundColor: 'rgba(26,26,26,0.87)',
          },
          '.UIExtendedPhotoImage': {
            // maxHeight: '100%',
            // maxWidth: '100%',
            minHeight: '100%',
            minWidth: '100%',
            display: 'block',
            objectFit: 'contain'
          },
          '.IUWidgetTypeBig': {
            padding: `${vars.spacing}px !important`,
            height: '110px',
          },
          '.UIPaddingLess': {
            padding: '0 !important',
          },
          '.UIOutlinedSliderRange': {
            padding: '6px 20px',
          },
          '.UIOutlinedTextCombo': {
            '& > input': {
              width: 0,
            }
          },
          '.UIWidgetImage': {
            height: '96px',
            width: '96px',
          },
          '.UIAvatarMenu': {
            textTransform: 'none !important'
          },
          '.UIWidgetTooltip': {
            backgroundColor: `${vars.secondary}!important`
          },
          '.UIWidgetTooltip.Error': {
            color: 'red !important'
          },
          '.UIEditableText': {
            position: 'relative',
            outline: 'none',
          },
          '.UIVerticalFlip': {
            transform: 'rotate(90deg)'
          },
          '.UICropperControls': {
            position: 'absolute',
            display: 'flex',
            gap: '10px',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)'
          },
          '.UIEditableText:focus-visible::after': {
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            position: 'absolute',
            borderBottom: `2px solid ${vars.primary}`,
            pointerEvents: 'none',
          },
          '.UITableCell': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100px',
            whiteSpace: 'nowrap',
          },
          '.UITruncatedText': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
          },
          '.UIRowCurrentCompany': {
            backgroundColor: vars.primary,
            color: 'white',
          },
          '.UIColorSubTitle': {
            color: 'grey',
          },
          '.UIButtonsWidgetActive button': {
            color: vars.primary,
            opacity: 1,
          },
          '.UIButtonsWidgetInactive': {
            color: 'white',
            opacity: 0.25,
          },
          '.UIRowDisabledLicense': {
            opacity: 0.5,
          },
          '.UIRowCurrentCompany:hover': {
            backgroundColor: `${vars.primary} !important`,
          },
          '.UITableAvatar': {
            width: '24px !important',
            height: '24px !important',
          },
          '.UIAvatarBadgeStatus': {
            '& .MuiBadge-badge': {
              boxShadow: `0 0 0 3px ${vars.backgroundMain}`,
              '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.5s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
              },
            },
            '&.UIBadgeOnline .MuiBadge-badge': {
              backgroundColor: '#44b700',
              color: '#44b700',
            },
            '&.UIBadgeOffline .MuiBadge-badge': {
              color: '#f44336',
              backgroundColor: '#f44336',
            },
          },
          '@keyframes ripple': {
            '0%': {
              transform: 'scale(.8)',
              opacity: 1,
            },
            '100%': {
              transform: 'scale(2.4)',
              opacity: 0,
            },
          },
          '.UINetworkSnackbar ': {
            color: '#fff !important',
            backgroundColor: '#d32f2f !important'
          },
          '.UIContentHeader': {
            fontSize: '0.85rem !important',
            fontWeight: '500 !important',
            textTransform: 'uppercase',
            opacity: 0.75
          },
          '.UIContentItemHeader': {
            fontSize: '0.75rem !important',
            fontWeight: '500 !important',
            textTransform: 'uppercase',
            opacity: 0.5
          },
          '.UIAvatarName': {
            fontSize: '1rem !important',
            fontWeight: '500 !important',
            opacity: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
          },
          '.UIAvatarCompany': {
            fontSize: '0.75rem !important',
            fontWeight: '500 !important',
            opacity: 0.5
          },
          '.UITooltip': {
            background: vars.secondary,
            borderRadius: '4px',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            zIndex: 5000,
            textTransform: 'uppercase',
            fontSize: '11px'
          },
          '.UITooltipArrow': {
            fill: vars.secondary,
          },
          '.UIButtonGroupChart': {
            '& .MuiToggleButton-root.Mui-selected ': {
              backgroundColor: vars.primary,
            },
            '& .MuiToggleButton-root': {
              fontSize: '11px',
              padding: '4px',
            },
            '& .MuiToggleButton-root.Mui-selected:hover': {
              backgroundColor: vars.primary,
            },
            '& .MuiToggleButton-root:hover': {
              backgroundColor: '#171717',
            },

          },
          '.UISelectChart': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${vars.primaryBorderColor}`,
              borderWidth: 0,
            },
            '& .MuiSelect-iconOutlined': {
              right: '-1px'
            },
            '& .MuiSelect-outlined': {
              '&.MuiSelect-outlined': {
                paddingRight: '24px',
              },
              color: '#737D80',
              fontSize: '11px',
              padding: 0,
              borderWidth: 0,
            },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: `${vars.primaryBorderColor}`,
              borderWidth: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${vars.primaryBorderColor}`,
              borderWidth: 0,
            },
          },
          '.UISelectItemChart': {
            '&.MuiListItem-root.Mui-selected': {
              color: 'white',
              fontSize: '11px',
            },
            '&.MuiListItem-root': {
              color: '#737D80',
              fontSize: '11px',
            },
          },
          '.UIRoundedMenu': {
            '& li:first-child': {
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            },
            '& li:last-child': {
              borderBottomRightRadius: 4,
              borderBottomLeftRadius: 4,
            }
          },
          '.UIMenuItem .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
          ...vars.global,
        },
      },
      MuiSlider: {
        root: {
          color: 'white',
        }
      },
      MuiTablePagination: {
        toolbar: {
          paddingRight: vars.spacing,
          padding: vars.spacing,
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: 'none',
          borderTop: `1px solid ${vars.primaryBorderColor}`,
          padding: vars.spacing,
        },
        stickyHeader: {
          borderTop: 'none',
          backgroundColor: vars.secondary,
          zIndex: 'unset',
        }
      },
      MuiList: {
        root: {
          '& .active': {
            display: 'block',
            backgroundColor: vars.primary,
            borderRight: '4px solid',
          },
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: 'unset',
          marginRight: vars.spacing
        }
      },
      MuiOutlinedInput: {
        root: {
          '&.Mui-disabled': {
            borderColor: vars.secondary
          }
        },
        adornedEnd: {
          paddingRight: 'unset'
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: vars.backgroundMain,
        },
        // outlined: {
        //   border: `1px solid ${vars.primaryBorderColor}`,
        // },
        // 'input:-webkit-autofill:focus': {
        //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
        // },
        // 'input:-webkit-autofill:active': {
        //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
        // },
        adornedEnd: {
          marginRight: '8px',
        },
        notchedOutline: {
          borderColor: vars.inputBorderColor,
        }
      },
      MuiInputBase: {
        root: {
          backgroundColor: vars.backgroundInput,
        }
      },
      MuiDialogTitle: {
        root: {
          padding: 0,
        }
      },
      MuiDialogContent: {
        root: {
          padding: vars.spacing,
        },
      },
      MuiDialogActions: {
        root: {
          padding: 0,
        }
      },
      MuiAvatar: {
        root: {
          fontSize: '0.9rem',
          '&.UIAvatar-profile': {
            height: '120px',
            width: '120px',
          }
        },
        colorDefault: {
          color: 'white',
          backgroundColor: vars.primary
        }
      },
      MuiCardMedia: {
        media: {
          width: '100%',
          height: '100%',
        }
      },
      MuiIconButton: {
        root: {
          padding: 'unset',
        }
      },
      MuiPopover: {
        paper: {
          backgroundColor: vars.secondary
        }
      },
      MuiTreeItem: {
        root: {
          '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'unset',
          },
        },
        iconContainer: {
          '& svg': {
            fontSize: '1.5rem',
          }
        }
      },
      MuiToggleButtonGroup: {
        groupedHorizontal: {
          '&:not(:first-child)': {
            borderLeft: `1px solid ${vars.primaryBorderColor}`,
          }
        }
      },
      MuiToggleButton: {
        root: {
          border: `1px solid ${vars.primaryBorderColor}`,
          backgroundColor: vars.backgroundInput,
          '&.Mui-selected': {
            backgroundColor: vars.primary,
          }
        }
      },
      MuiButton: {
        root: {
          fontSize: '0.75rem',
          whiteSpace: 'nowrap',
          flexShrink: 0,
          transition: 'initial',
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: vars.secondary
          }
        },
        containedPrimary: {
          color: 'white'
        },
        label: {
          width: '100%',
          height: '100%',
        },
      },
      MuiMenuItem: {
        root: {
          minHeight: 'auto',
          textTransform: 'capitalize',
          paddingTop: spacing(1),
          paddingBottom: spacing(1),
        },
        gutters: {
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        }
      },
      MuiSelect: {
        selectMenu: {
          textTransform: 'capitalize'
        },
        outlined: {
          '&.MuiSelect-outlined': {
            // paddingRight: 'unset',
          }
        }
      },
      MuiGrid: {
        item: {
          '&.MuiGrid-Grow': {
            flex: '1 1'
          }
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: vars.secondary
        }
      },
      MuiPickerDTTabs: {
        tabs: {
          backgroundColor: vars.backgroundInput
        }
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          backgroundColor: vars.secondary,
        }
      },
      MuiListItemSecondaryAction: {
        root: {
          position: 'unset',
          transform: 'unset',
        }
      },
      MuiListItemAvatar: {
        root: {
          minWidth: 'unset',
        }
      },
      MuiListItemText: {
        multiline: {
          marginLeft: vars.spacing,
          marginRight: vars.spacing,
          marginTop: 0,
          marginBottom: 0,
        },
        primary: {
          whiteSpace: 'nowrap'
        }
      },
      MuiListItem: {
        dense: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        // TODO LEFT MENU BUG
        // gutters: {
        //   paddingLeft: spacing(2),
        //   paddingRight: spacing(2),
        // }
      },
      MuiTypography: {
        displayBlock: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      },
      MuiTab: {
        fullWidth: {
          minWidth: 'unset',
        }
      }
    },
  });
}

// @ts-nocheck
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import Grid from '@material-ui/core/Grid';
import TreeView from '@material-ui/lab/TreeView';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArchiveIcon from '@material-ui/icons/Archive';
import {
  Box,
  Collapse,
  createStyles,
  Dialog,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import Location from 'pages/locations/Location';
import Header from 'components/common/Header';
import NotAllocatedCamera from 'pages/locations/NotAllocatedCamera';
import AddLocation from 'pages/locations/AddLocation';
import Details from 'pages/locations/Details';
import useLocationInput from 'pages/locations/useLocationInputHook';
import {
  ICamera, ICameraDetails, ILocation, ILocationCamera, ItemType
} from 'pages/locations/types';
import { colors } from 'Theme';
import api from 'app/api';
import Layout from 'partials/Layout';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import dForest from 'd-forest';
import eventEmitter3 from 'eventemitter3';

const fakeId = 0;

const styles = makeStyles((theme) => createStyles({
  root: {
    textAlign: 'left',
    '& .Mui-expanded > .MuiTreeItem-content': {
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '.75rem',
    },
  },
  camerasList: {
    padding: '1rem',
    overflowY: 'auto',
    maxHeight: '100%',
  },
  overflowContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  button: {
    height: '40px',
    minWidth: '44px',
    marginTop: '8px',
    marginLeft: '.75rem',
  },
  container: {
    display: 'flex',
    padding: '1rem',
    minHeight: '500px',
    height: 'calc(100vh - 118px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '70px',
    },
  },
  mobileSettingsModal: {
    background: colors.dark2,
  },
  icon: {
    // color: colors.dark6,
    padding: '0',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 32px',
    borderBottom: `1px solid ${theme.palette.background.primaryBorder}`,
  },
  headerContainerCameras: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 32px',
    borderBottom: `1px solid ${theme.palette.background.primaryBorder}`,
  },
  headerButton: {
    cursor: 'pointer',
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '32px',
  },
  notSelectText: {
    color: '#737D80',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  column: {
    padding: 0,
    backgroundColor: theme.palette.background.main,
    borderRight: `1px solid ${theme.palette.background.primaryBorder}`,
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    maxHeight: '100%',
    overflowY: 'hidden',
  },
  treeContainer: {
    borderRadius: 5,
    border: `1px solid ${theme.palette.background.primaryBorder}`,
    overflow: 'hidden',
    height: '100vh',
  },
  searchInputContainer: {
    padding: '1rem 1rem 0',
  },
  searchInput: {
    // backgroundColor: colors.dark2,
    // borderRadius: 5,
    // marginBottom: theme.spacing(2),
    // '& fieldset': {
    // border: 'none',
    // },
  },
}));

const TreeViewPage = () => {
  const {
    locationName,
    setLocationName,
    isNewLocationVisible,
    toggleNewLocation,
    closeNewLocation,
  } = useLocationInput();

  const classes = styles();
  const dropEmitter = new eventEmitter3.EventEmitter();

  const [newLocationId, setNewLocationId] = React.useState<string | number | null>(null);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [expandedSearch, setExpandedSearch] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [mobileModalOpen, setMobileModalOpen] = useState<boolean>(false);

  const [defaultLocations, setDefaultLocations] = useState<ILocation[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);

  const [defaultCameras, setDefaultCameras] = useState<ICamera[]>([]);
  const [defaultArchivedCameras, setDefaultArchivedCameras] = useState<ICamera[]>([]);

  const [searchLocations, setSearchLocations] = useState<string>('');
  const [searchCameras, setSearchCameras] = useState('');

  const [cameras, setCameras] = useState<ICamera[]>([]);
  const [archivedCameras, setArchivedCameras] = useState<ICamera[]>([]);
  const [selectedItem, setSelectedItem] = useState<ILocation | ILocationCamera | null>(null);
  const [updateCamerasLists, setUpdateCamerasList] = useState(false);
  const [loadingCameras, setLoadingCameras] = useState(true);
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [allCameras, setAllCameras] = useState([]);
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const reloadLocations = () => api.get<ILocation[]>('/device-manager/locations/?cameras_type=both').then((response) => {
    setDefaultLocations(response.data);
  });

  useEffect(() => {
    api.get<ILocation[]>('/device-manager/locations').then((response) => {
      setDefaultLocations(response.data);
      setLocations(response.data);
      setLoadingLocations(false);
    });
  }, []);

  useEffect(() => {
    setLocations(defaultLocations);
  }, [defaultLocations]);

  useEffect(() => {
    handlerSearchCameras(searchCameras);
  }, [searchCameras, defaultCameras]);

  // useEffect(() => {
  //   if (searchLocations) {
  //     const hierarchy = dForest.findNodes(defaultLocations, (node) => node.name.toLowerCase().includes(searchLocations.toLowerCase()))
  //       .map((item) => dForest.hierarchy(defaultLocations, (node) => node.name === item.name));

  //     const expanded = hierarchy
  //       .flat()
  //       .filter((item) => item.hasOwnProperty('child_locations'))
  //       .map((item) => item.id);

  //     const matches = hierarchy
  //       .flat()
  //       .map((item) => item.name);

  //     const roots = dForest.removeNodes(defaultLocations, (node) => node.name && !matches.includes(node.name))
  //       .flat();

  //     setExpanded(expanded);
  //     setLocations(roots);
  //   } else {
  //     setLocations(defaultLocations);
  //   }
  // }, [defaultLocations, searchLocations]);

  useEffect(() => {
    if (searchLocations) {
      const hierarchy = dForest.findNodes(defaultLocations, (node) => node.name && node.name.toLowerCase().includes(searchLocations.toLowerCase()))
        .map((item) => dForest.hierarchy(defaultLocations, (node) => node.id === item.id));

      const expanded = hierarchy
        .flat()
        .filter((item) => item.hasOwnProperty('child_locations'))
        .map((item) => item.id);

      const matches = hierarchy
        .flat()
        .map((item) => item.id);

      const roots = dForest.removeNodes(defaultLocations, (node) => node.id && !matches.includes(node.id))
        .flat();

      setExpandedSearch(expanded)
      setLocations(roots);
    } else {
      setLocations(defaultLocations);
      expandedSearch.length > 0 ? setExpanded([...expandedSearch, ...expanded]) : setExpanded(expanded)
    }
  }, [defaultLocations, searchLocations]);

  useEffect(() => {
    showBackdrop('Loading page');
    api.get<ICamera[]>('/device-manager/cameras/?cameras_type=both').then((response) => {
      const unarchived = response.data.filter((c) => !c.archived && c.locations.length === 0);
      const archived = response.data.filter((c) => c.archived);
      setCameras(unarchived);
      setDefaultCameras(unarchived);
      setDefaultArchivedCameras(archived);
      setArchivedCameras(archived);
      setAllCameras(response.data);
      setLoadingCameras(false);
      if (selectedItem) {
        const camera = response.data.find((cam) => cam.id === selectedItem.id);
        setSelectedItem(camera);
      }
    }).finally(hideBackdrop);
  }, [updateCamerasLists, defaultLocations]);

  const selectItem = useCallback((item: ILocation | ILocationCamera) => {
    setSelectedItem(item);
  }, []);

  const archiveCamera = useCallback(async (camera: ILocationCamera) => {
    try {
      await api.patch(`/device-manager/cameras/${camera.id}`, {
        archived: !camera.archived,
      });
      setUpdateCamerasList((prev) => !prev);
      if (locations || defaultLocations) {
        reloadLocations();
        setUpdateCamerasList((prev) => !prev);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const newCamera = async (camera) => {
    if (!camera.is_new) return;
    try {
      await api.patch(`/device-manager/cameras/${camera.id}`, {
        is_new: false,
      });
      setUpdateCamerasList((prev) => !prev);
      if (locations || defaultLocations) {
        reloadLocations();
        setUpdateCamerasList((prev) => !prev);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggle = (_event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
    setExpandedSearch(nodeIds)
  };

  const handleSelect = (_event: any, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const saveDetailsRef = useRef<any>();

  const replaceLocationName = (id: string, name: string) => {
    const findAndReplace = (location: ILocation) => {
      if (location.id === id) {
        location.name = name;
      } else {
        location.child_locations = location.child_locations.map(findAndReplace);
      }
      return location;
    };

    reloadLocations();
  };

  const handleSaveDetails = () => {
    if (saveDetailsRef.current) {
      saveDetailsRef.current.saveData()
        .then((newLocName: { id: string, name: string } | null) => {
          if (newLocName) {
            replaceLocationName(newLocName.id, newLocName.name);
          }
        });
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const createLocation = (name: string, parentId: string | null) => api.post('/device-manager/locations/', {
    name,
    description: '',
    coordinates: [0, 0],
    parent_location_id: parentId,
  }).then((response) => {
    showBackdrop('Creating the location');
    return response;
  }).then((response) => reloadLocations().then(() => {
    enqueueSnackbar('Location added successfully', { variant: 'success' });
    return response.data;
  })).finally(hideBackdrop);

  const saveLocation = (location: ILocation, parentId: string) => api.patch(`/device-manager/locations/${location.id}`, {
    name: location.name,
    description: location.description,
    coordinates: [0, 0],
    parent_location_id: parentId,
  }).then(() => showBackdrop('Moving the location'))
    .then((response) => reloadLocations().then(() => {
      enqueueSnackbar('Location moved successfully', { variant: 'success' });
      return response.data;
    }))
    .finally(hideBackdrop);

  const saveCamera = async (cameraId: string, locationId: string) => {
    // const { data } = await api.get<ICameraDetails>(`/device-manager/cameras/${cameraId}`);
    // const { locations } = data;
    showBackdrop('Moving the camera');
    api.patch(`/device-manager/cameras/${cameraId}`, {
      locations: [
        // ...locations,
        locationId,
      ],
    })
      .then((response) => reloadLocations().then(() => {
        enqueueSnackbar('Camera moved successfully', { variant: 'success' });
        return response.data;
      })).finally(hideBackdrop);
  };

  const deleteLocation = (id: string) => {
    showBackdrop('Deleting the location');
    api.delete(`/device-manager/locations/${id}`)
      .then(() => {
        reloadLocations().then(() => {
          enqueueSnackbar('Location deleted', { variant: 'success' });
        });
      }).finally(hideBackdrop);
  };

  const deleteCameraFromLocation = async (cameraId: string, locationId: string) => {
    const { data } = await api.get<ICameraDetails>(`/device-manager/cameras/${cameraId}`);
    const { locations } = data;
    showBackdrop('Removing the camera from the location');
    return api.patch(`/device-manager/cameras/${cameraId}`, {
      locations: locations.filter((item: string) => item !== locationId),
    }).then(() => reloadLocations())
      .then(() => enqueueSnackbar('Camera deleted from location', { variant: 'success' })).finally(hideBackdrop);
  };

  const addItem = (locationId: string, name?: string, existingItem?: ILocation | ILocationCamera, type?: ItemType): Promise<void> => {
    const traverseAndAdd = (location: ILocation): Promise<ILocation> => {
      let promise: Promise<string | number | void> = Promise.resolve();
      if (location.id === locationId) {
        switch (type) {
          case 'location':
            // eslint-disable-next-line no-param-reassign
            if (existingItem) {
              promise = saveLocation(existingItem as ILocation, locationId)
                .then(() => {
                  location.child_locations = [
                    ...location.child_locations,
                    (existingItem as ILocation),
                  ];
                });
              // .catch(() => enqueueSnackbar('Location move error', { variant: 'error' }));
            } else {
              promise = createLocation(name || '', locationId);
              /* .then((data) => {
                  location.child_locations.filter((loc) => loc.id === String(newNodeId));
                  location.child_locations.push({
                    ...data,
                    child_locations: [],
                    cameras: [],
                  });
                }) */
              // .catch(() => enqueueSnackbar('Location add error', { variant: 'error' }));
            }
            break;
          case 'camera':
            existingItem && ((existingItem as ILocationCamera).nodeId = String(fakeId));
            const cameraId = existingItem?.id || '';
            promise = saveCamera(cameraId, location.id)
              .then(() => {
                location.cameras.push(existingItem as ILocationCamera);
              })
              .catch(() => enqueueSnackbar('Location add error', { variant: 'error' }));
            break;
        }
        return promise.then(() => location);
      }
      return Promise.all(location.child_locations.map(traverseAndAdd))
        .then((locations) => {
          location.child_locations = locations;
          return location;
        });
    };

    return Promise.all(locations.map(traverseAndAdd));
  };

  const deleteItem = (id: string, type: ItemType, parentId?: string, deleteFromBackend: boolean = true) => {
    const traverseAndDelete = (location: ILocation): ILocation => {
      switch (type) {
        case 'location':
          if (location.child_locations.find((loc) => loc.id === id)) {
            location.child_locations = location.child_locations.filter((loc) => loc.id !== id);
            if (deleteFromBackend) {
              deleteLocation(id);
            }
          } else {
            location.child_locations = location.child_locations.map(traverseAndDelete);
          }
          break;
        case 'camera':
          if (location.id === parentId) {
            location.cameras = location.cameras.filter((cam) => cam.id !== id);
            deleteCameraFromLocation(id, location.id);
          } else {
            location.child_locations = location.child_locations.map(traverseAndDelete);
          }
          break;
      }
      return location;
    };

    if (locations.find((loc) => loc.id === id)) {
      deleteLocation(id);
    }

    if (selectedItem?.id === id) {
      setSelectedItem(null);
    }

    return Promise.all(locations.map(traverseAndDelete));
  };

  const toggleItem = useCallback((nodeId: string) => {
    setExpanded([...expanded, nodeId]);
    setSelected([...selected, nodeId]);
  }, [expanded]);

  const handlerSearchCameras = (e: string) => {
    const findCameras = defaultCameras.filter((c) => c?.name?.toLowerCase().includes(e.toLowerCase()));

    const findArchivedCameras = defaultArchivedCameras.filter((c) => c?.name?.toLowerCase().includes(e.toLowerCase()));

    setCameras(findCameras);
    setArchivedCameras(findArchivedCameras);
  };

  const [showActive, setShowActive] = useState(true);
  const [showArchive, setShowArchive] = useState(true);

  const handlerActive = () => setShowActive(!showActive);
  const handlerArchive = () => setShowArchive(!showArchive);

  return (
    <Layout title="Device Tree">
      <Box p={2} width={1} height={1} overflow="hidden" display="flex" flexDirection="column">
        <Box height={1} overflow="auto" display="flex">
          <Grid
            container
            justifyContent="flex-start"
            alignItems="stretch"
            direction="row"
          >
            <Grid
              item
              xs={12}
              md={4}
              className={classes.column}
            >
              <Box p={2} pb={0}>
                <Typography align="left" variant="subtitle1">
                  Cameras
                </Typography>
              </Box>
              {loadingCameras ? null
                : defaultCameras.length > 0 || defaultArchivedCameras.length > 0 ? (
                  <Box overflow="hidden" display="flex" flexDirection="column">
                    <Box p={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => setSearchCameras(e.target.value)}
                        value={searchCameras}
                        placeholder="Search by cameras"
                        className={classes.searchInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: colors.dark6 }} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => {
                                  setSearchCameras('');
                                }}
                                disabled={!searchCameras}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                    <Box p={2} pt={0} height={1} overflow="auto">
                      {defaultCameras.length > 0 && (
                      <Box m={2} mt={0} display="flex" justifyContent="space-between">
                        <Typography align="left" variant="subtitle1">
                          Location not Specified
                        </Typography>
                        <IconButton onClick={handlerActive}>
                          {showActive
                            ? <KeyboardArrowUpIcon />
                            : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </Box>
                      )}
                      <Collapse in={showActive} timeout="auto" unmountOnExit>
                        <>
                          {cameras.length > 0 ? cameras.map((cam) => (
                            <NotAllocatedCamera
                              key={cam.id}
                              model={cam}
                              selected={selectedItem?.id === cam.id}
                              handleItemSelect={selectItem}
                              handleArchiveCamera={archiveCamera}
                              Icon={ArchiveIcon}
                              newCamera={newCamera}
                              is_new={cam.is_new}
                              search={searchCameras}
                            />
                          ))
                            : (
                              <Box className={classes.notSelectText}>
                                {defaultCameras.length > 0 ? 'NOT FOUND' : null}
                              </Box>
                            )}
                        </>
                      </Collapse>
                      {defaultArchivedCameras.length > 0 && (
                      <Box m={2} mt={0} display="flex" justifyContent="space-between">
                        <Typography align="left" variant="subtitle1">
                          Disabled
                        </Typography>
                        <IconButton onClick={handlerArchive}>
                          {showArchive
                            ? <KeyboardArrowUpIcon />
                            : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </Box>
                      )}
                      <Collapse in={showArchive} timeout="auto" unmountOnExit>
                        <>
                          {archivedCameras.length > 0 ? archivedCameras.map((cam) => (
                            <NotAllocatedCamera
                              key={cam.id}
                              model={cam}
                              selected={selectedItem?.id === cam.id}
                              handleItemSelect={selectItem}
                              handleArchiveCamera={archiveCamera}
                              Icon={UnarchiveIcon}
                              isArchiveCamera
                              newCamera={newCamera}
                              search={searchCameras}
                            />
                          ))
                            : (
                              <Box className={classes.notSelectText}>
                                {defaultArchivedCameras.length > 0 ? 'NOT FOUND' : null}
                              </Box>
                            )}
                        </>
                      </Collapse>
                    </Box>
                  </Box>
                )
                  : (
                    <Box className={classes.notSelectText}>
                      {allCameras.length > 0
                        ? 'NO UNASSIGNED CAMERAS'
                        : 'CURRENTLY YOU DO NOT HAVE ADDED ANY CAMERAS. YOU CAN CONNECT YOUR VMS CAMERAS THROUGH PLUGIN CONFIGURATION.'}
                    </Box>
                  )}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.column}
            >
              <Box p={2} pb={0} display="flex" justifyContent="space-between">
                <Typography align="left" variant="subtitle1">
                  Locations & Cameras
                </Typography>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleNewLocation}
                >
                  <AddIcon />
                </Button> */}
                <IconButton
                  onClick={toggleNewLocation}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <TreeView
                classes={{
                  root: `${classes.root} ${classes.overflowContent}`,
                }}
                disableSelection
                defaultCollapseIcon={<KeyboardArrowUpIcon className={classes.icon} />}
                defaultExpandIcon={<KeyboardArrowDownIcon className={classes.icon} />}
                // expanded={expanded}
                expanded={searchLocations ? expandedSearch : expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                <AddLocation
                  visible={isNewLocationVisible}
                  locationName={locationName}
                  newLocationId={newLocationId}
                  handleLocationNameInput={(e) => setLocationName(e.target.value.trimLeft())}
                  rootLocation
                  addLocationClick={() => {
                    createLocation(locationName, null)
                      .then((data) => setDefaultLocations([...defaultLocations, {
                        ...data,
                        child_locations: [],
                        cameras: [],
                      }]));
                    // .catch(() => enqueueSnackbar('Location create error', { variant: 'error' }));
                    setLocationName('');
                    closeNewLocation();
                  }}
                  handleClose={closeNewLocation}
                />
                {defaultLocations.length > 0 && (
                  <Box p={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={(e) => setSearchLocations(e.target.value)}
                      value={searchLocations}
                      placeholder="Search by locations"
                      className={classes.searchInput}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ color: colors.dark6 }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => {
                                setSearchLocations('');
                              }}
                              disabled={!searchLocations}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                )}
                {locations.length > 0 ? (
                  <Box p={2} pt={0} height={1} overflow="auto">
                    {locations.map((loc, index) => (
                      <Location
                        newLocationId={newLocationId}
                        setNewLocationId={setNewLocationId}
                        model={loc}
                        dropEmitter={dropEmitter}
                        locations={loc.child_locations}
                        selectedLocationId={selectedItem?.id}
                        handleItemSelect={selectItem}
                        deleteItem={deleteItem}
                        addItem={addItem}
                        toggleItem={toggleItem}
                        isRootNode
                        key={`${index}-${loc.id}`}
                        setMobileModalOpen={setMobileModalOpen}
                        handleArchiveCamera={archiveCamera}
                        search={searchLocations}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box className={classes.notSelectText}>
                    {loadingLocations ? null : !defaultLocations.length > 0
                      ? 'CURRENTLY YOU HAVE NOT ADDED ANY LOCATIONS. YOU CAN ADD A LOCATION BY CLICKING THE "+" BUTTON'
                      : 'NOT FOUND'}
                  </Box>
                )}
              </TreeView>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.column}
              style={{ position: 'relative', borderRight: 'none' }}
            >
              <Box p={2} pb={0}>
                <Typography align="left" variant="subtitle1">
                  Settings
                </Typography>
              </Box>
              {selectedItem ? (
                <Details
                  model={selectedItem}
                  type={(selectedItem as ILocation).cameras ? 'location' : 'camera'}
                  ref={saveDetailsRef}
                  deleteItem={deleteItem}
                  handleSaveDetails={handleSaveDetails}
                />
              )
                : (
                  <Box className={classes.notSelectText}>
                    CAMERA OR LOCATION SETTINGS
                  </Box>
                )}
            </Grid>
            {selectedItem && (
              <Dialog
                open={mobileModalOpen}
                onClose={() => setMobileModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                classes={{ paper: classes.mobileSettingsModal }}
              >
                <Header
                  title="Location/Camera Settings"
                  buttonIcon={SaveIcon}
                  buttonText="SAVE"
                  handleButtonAction={() => handleSaveDetails()}
                />
                <Details
                  model={selectedItem}
                  type={(selectedItem as ILocation).cameras ? 'location' : 'camera'}
                  ref={saveDetailsRef}
                  deleteItem={deleteItem}
                  handleSaveDetails={handleSaveDetails}
                />
              </Dialog>
            )}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default TreeViewPage;

// @ts-nocheck
import api from 'app/api';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['companyUsers'];

const useCompanyUsersQuery = (id) => {
  const companyUsersQuery = useBasicQuery({
    queryKey: [...QUERY_KEYS, id],
    queryFn: async () => {
      const result = await api.post(`/auth-manager/v1/company/${id}/users/`, {
        pagination: {
          pgoffset: 0,
          pgsize: -1,
        },
      });

      return result.data.items;
    },
    initialData: [],
  });

  return {
    companyUsers: companyUsersQuery.data,
    companyUsersQuery,
  };
};

export default useCompanyUsersQuery;

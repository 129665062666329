// @ts-nocheck
import api from '../../app/api';
import useBasicQuery from './queries/useBasicQuery';

const useCompleterNames = (key, type, text) => {
  const completerNamesQuery = useBasicQuery({
    queryKey: ['autocomplete', key],
    queryFn: async () => {
      const result = await api.get(`/object-manager/v1/names/${type}?limit=10&text=${text}`);
      return result.data.names;
    },
  })

  return {
    completerNames: completerNamesQuery.data,
    completerNamesQuery
  }
}

export default useCompleterNames;

// @ts-nocheck
import React, { useEffect } from 'react';
import Layout from 'partials/Layout';
import { useModel } from 'v2/providers/ModelProvider';
import WidgetsGrid from 'v2/components/WidgetsGrid';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import LayoutSelector from 'pages/dashboard/LayoutSelector/LayoutSelector';
import { Box } from '@material-ui/core';

const containerRestrictions = {
  hist_chart: { minH: 4, minW: 4 },
  line_chart: { minH: 4, minW: 4 },
  pie_chart: { minH: 2, minW: 2 },
  value: { minH: 2, minW: 2 },
  face_feed: { minH: 2, minW: 2 },
  vehicle_feed: { minH: 2, minW: 2 },
  person_feed: { minH: 2, minW: 2 },
  last_objects: {},
  nxstats: {},
};

const RegularBoard = () => {
  const {
    layouts,
    layout,
    widgets,
    scenarios
  } = useModel();
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const updateLayout = (grid) => scenarios.layouts.update({ grid }).then(scenarios.widgets.init).finally(hideBackdrop);

  const addWidget = (index, component, widgetPostData) => {
    showBackdrop('Adding widget');
    const { minW, minH } = containerRestrictions[widgetPostData.type];

    return scenarios.widgets.create(widgetPostData).then((data) => updateLayout([...layout.grid, {
      id: data.id, x: 0, y: 0, w: minW, h: minH
    }]))
  }

  const removeWidget = (data) => {
    showBackdrop('Removing widget');
    return updateLayout(layout.grid.filter((item) => item.id !== data.id)).then(() => scenarios.widgets.delete(data.id));
  }

  const selectLayout = (event) => {
    showBackdrop('Changing layout');
    return scenarios.layouts.select(event.target.value).finally(hideBackdrop);
  }

  useEffect(() => {
    showBackdrop('Loading layout');
    scenarios.layouts.init().then(scenarios.widgets.init).finally(hideBackdrop);
  }, [])

  return (
    <Layout title="Dashboard">
      <Box width={1} display="flex" flexDirection="column">
        <Box p={2} pb={0} width={1}>
          <LayoutSelector layout={layout} handleChange={selectLayout} items={layouts} />
        </Box>
        <WidgetsGrid
          editable
          layout={layout}
          widgets={widgets}
          scenarios={scenarios}
          updateLayout={updateLayout}
          removeWidget={removeWidget}
          addWidget={addWidget}
        />
      </Box>
    </Layout>
  )
};

export default RegularBoard;

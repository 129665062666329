// @ts-nocheck

import React, { useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { useDialog } from 'v2/providers/DialogProvider';
import { useSnackbar } from 'notistack';
import UIForm, { useUIForm } from 'v2/ui-kit/UIForm/UIForm';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetFooter,
  UIWidgetHeader,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import CloseAction from 'v2/widgetActions/CloseAction';
import useSubscriptionsQuery from '../hooks/queries/useSubscriptionsQuery';

const ClusterSubscription = (props) => {
  const { item, edit } = props;

  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { subscriptionCreate, subscriptionUpdate, subscriptionsQuery } = useSubscriptionsQuery();

  const [formScheme, setFormScheme] = useState(FORM_SCHEMES.CLUSTER_SUBSCRIPTION(edit && item.name, subscriptionsQuery.data));
  const form = useUIForm(formScheme);
  const { formState: { isValid, isDirty } } = form;

  const onUpdateSubmit = () => {
    showBackdrop('Changing subscription');
    subscriptionUpdate.mutateAsync([
      item.cluster_id,
      form.getValues(),
    ]).then(() => {
      enqueueSnackbar('Subscription has been edited', { variant: 'success' });
      hideDialog();
    }).finally(hideBackdrop);
  };

  const onCreateSubmit = () => {
    showBackdrop('Adding subscription');
    subscriptionCreate.mutateAsync([
      item.cluster_id,
      form.getValues(),
    ]).then(() => {
      enqueueSnackbar('Subscription has been added. Please proceed to the Video platform to set a new Analytics Event', { variant: 'success' });
      hideDialog();
    }).finally(hideBackdrop);
  };

  return (
    <UIWidgetContainer className="UIDialog">
      <UIWidgetHeader>
        <UIWidgetTitle>{edit ? 'Edit subscription' : 'Add subscription'}</UIWidgetTitle>
        <UIWidgetRightActions>
          <CloseAction onClick={hideDialog} />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody>
        <UIForm methods={form} scheme={formScheme} />
      </UIWidgetBody>
      <UIWidgetFooter className="justify-between">
        <UIButton
          variant="outlined"
          onClick={hideDialog}
        >
          Cancel
        </UIButton>
        <UIButton
          variant="contained"
          disabled={!isValid || !isDirty}
          onClick={() => (edit ? onUpdateSubmit() : onCreateSubmit())}
        >
          Submit
        </UIButton>
      </UIWidgetFooter>
    </UIWidgetContainer>
  );
};

export default ClusterSubscription;

// @ts-nocheck

import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  IconButton, InputAdornment, TextField, Tooltip
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FIELD_RULES, FIELD_VALIDATORS } from 'v2/constants/fieldValidators';
import { mergeRules } from 'v2/utils/functions';

const TextPassword = (props) => {
  const [showPassword, setShowPassword] = useState('password');

  const switchVisibility = () => setShowPassword((prev) => !prev);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ ...FIELD_VALIDATORS[FIELD_RULES.MAXLENGTH_100], ...mergeRules(props.rules) }}
      defaultValue={props.value || ''}
      render={(controller) => (
        <Tooltip arrow title={props.tooltip || ''} enterDelay={1000} enterNextDelay={500}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label={props.label}
            type={showPassword ? 'password' : 'text'}
            error={!!controller.formState?.errors[props.name]?.message}
            helperText={controller.formState?.errors[props.name]?.message}
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={switchVisibility}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: {
                autoComplete: 'new-password',
              },
            }}
            {...controller.field}
          />
        </Tooltip>
      )}
    />
  );
}

export default TextPassword

// @ts-nocheck
import api from 'app/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clearEmptyValues } from 'v2/utils/functions';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['companies'];

const useCompaniesQuery = () => {
  const queryClient = useQueryClient();

  const companiesQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.post('/auth-manager/v1/user/companies');
      return result.data;
    }
  });

  const addCompany = useMutation({
    mutationFn: async (data) => {
      const result = await api.post('/auth-manager/v2/company/', clearEmptyValues(data));
      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  })

  return {
    companies: companiesQuery.data?.items,
    addCompany,
    companiesQuery,
  }
};

export default useCompaniesQuery;

// @ts-nocheck

import React from 'react';
import {
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { useDialog, DIALOGS } from 'v2/providers/DialogProvider';
import useUserQuery from 'v2/hooks/queries/useUserQuery';
import useUsersTypesQuery from 'v2/hooks/queries/useUserTypesQuery';

const AccountSettings = () => {
  const { showDialog } = useDialog();
  const { user } = useUserQuery();
  const { usersTypes } = useUsersTypesQuery();

  const userRole = usersTypes && usersTypes.find(({ id }) => id === user?.role)?.name;

  const handleEditUser = () => showDialog(DIALOGS.EDIT_USER);
  const handleEditPassword = () => showDialog(DIALOGS.EDIT_PASSWORD);

  const handleEditTimeZone = () => showDialog(DIALOGS.EDIT_TIMEZONE, { user });

  return user && (
    <Box p={2} height={1} width={1} display="flex" flexDirection="column" alignItems="center">
      <Box mb={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography className="UIContentItemHeader">User role</Typography>
          <Typography>{userRole}</Typography>
        </Box>
        <Button variant="outlined" onClick={handleEditPassword}>Change Password</Button>
      </Box>
      <Box mb={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Box overflow="auto" mr={2}>
          <Typography className="UIContentItemHeader">User name</Typography>
          <Typography className="UITruncatedText">{`${user.first_name} ${user.last_name}`}</Typography>
        </Box>
        <Button onClick={handleEditUser} variant="outlined">Change name</Button>
      </Box>
      <Box mb={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography className="UIContentItemHeader">E-Mail</Typography>
          <Typography>{user.email}</Typography>
        </Box>
      </Box>
      <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography className="UIContentItemHeader">Time zone</Typography>
          <Typography>{user.timezone}</Typography>
        </Box>
        <Button onClick={handleEditTimeZone} variant="outlined">Change</Button>
      </Box>
    </Box>
  );
};

export default AccountSettings;

// @ts-nocheck

import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect, useLocation, useHistory
} from 'react-router-dom';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useSnackbar } from 'notistack';

import ResetPassword from 'pages/auth/ResetPassword';
import RegistrationPage from 'v2/pages/RegistrationPage/RegistrationPage';
import ChangePassword from 'pages/auth/ChangePassword';
import ConfirmAccount from 'pages/auth/ConfirmAccount';
import Terms from 'v2/pages/Terms';
import Policy from 'v2/pages/Policy';
import RegularBoard from 'v2/components/RegularBoard';
import { ROUTES } from 'v2/utils/routes';
import TranslationTest from 'v2/pages/TranslationTest';
import { checkCredentials } from 'v2/utils/functions';
import LocationsTree from '../pages/locations/LocationsTree';
import PageNotFound from '../pages/PageNotFound';
import Profile from '../pages/profile/Profile';
import CompanySelect from '../pages/auth/CompanySelect';
import { errorEmitter } from '../app/api';

const modifyRedirect = (location, redirect_url = null) => {
  if (redirect_url && redirect_url !== '/') {
    return `${location}?redirect_url=${redirect_url}`;
  }
  return location;
};

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const { pathname } = useLocation();

  const redirectPath = modifyRedirect('/log-in/', pathname);

  return (
    <Route
      {...rest}
      render={(props) => (checkCredentials()
        ? <Component {...props} />
        : <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />)}
    />
  );
};

export default function Navigation() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const errorHandler = ({ message, redirect }) => {
    enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000, preventDuplicate: true });

    if (redirect) {
      history.push(modifyRedirect('/log-in/', pathname), { from: pathname });
    }
  };

  useEffect(() => {
    errorEmitter.on('apiError', errorHandler);

    return () => {
      errorEmitter.removeListener('apiError', errorHandler);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <PrivateRoute path="/" exact component={RegularBoard} />
      <PrivateRoute path="/tree-view" exact component={LocationsTree} />
      <PrivateRoute {...ROUTES.OBJECTS_SEARCH} />
      <PrivateRoute {...ROUTES.OBJECT_DETAILS_WITH_COMPANY} />
      <PrivateRoute {...ROUTES.OBJECT_DETAILS} />
      <PrivateRoute path="/company-select" exact component={CompanySelect} />
      <PrivateRoute path="/profile" exact component={Profile} />
      <PrivateRoute path="/test" exact component={TranslationTest} />
      <PrivateRoute {...ROUTES.SETTINGS} />
      <PrivateRoute {...ROUTES.HELP} />
      <PrivateRoute {...ROUTES.NOTIFICATIONS} />
      <PrivateRoute {...ROUTES.SHARED_WIDGET_WITH_COMPANY} />
      <PrivateRoute {...ROUTES.SHARED_WIDGET} />
      <PrivateRoute {...ROUTES.SHARED_LAYOUT_WITH_COMPANY} />
      <PrivateRoute {...ROUTES.SHARED_LAYOUT} />
      <Route {...ROUTES.LOGIN} />
      <Route path="/registration/:token" component={RegistrationPage} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/change-password" component={ChangePassword} />
      <Route path="/confirm-account" component={ConfirmAccount} />
      <Route path="/terms-of-use" component={Terms} />
      <Route path="/privacy-policy" component={Policy} />
      <Route {...ROUTES.NO_COMPANY} />
      <Route {...ROUTES.NO_COMPANIES} />
      <Route component={PageNotFound} />
    </Switch>
  );
}

// @ts-nocheck

import React, {
  createContext, FC, useContext, useEffect, useState
} from 'react';
import { each, entries, omit } from 'lodash/fp';
import api from 'app/api';
import ms from 'ms';
import { handlers } from 'v2/redux/store';
import { ChartData } from 'components/chart/dataTransformers';
import { UserData } from 'pages/profile/Profile.interface';
import {
  AppearanceItem,
  AppearanceType,
  Filter,
  FilterData,
  FilterKeys,
  FiltersResponse,
  Pagination,
  SearchData,
  SearchResponse,
} from './pages/appearance/types';

type TAppStoreValues = {
  isFilterOpen: boolean;
  isMenuOpen: boolean;
  age: number[];
  tab: number;
  filters: FiltersState;
  searchType: AppearanceType;
  appearancesLoading: boolean;
  appearancesChartLoading: boolean;
  appearancesTouchBottom: boolean;
  appearancesChartData: ChartData | undefined;
  appearancesPagination: Pagination | undefined;
  appearances: AppearancesByType;
  appearancesTimeslice: string;
  faceFiltersLoading: boolean;
  vehicleFiltersLoading: boolean;
  personFiltersLoading: boolean;
  appearancesLocationSelectorOpen: boolean;
  faceFilters: FilterData[];
  personFilters: FilterData[];
  vehicleFilters: FilterData[];
  isAuthenticated: boolean;
  currentUser: UserData | undefined,
  isImageChange: boolean,
  currentCompany,
}

type TAppStoreHandlers = {
  setIsFilterOpen: (isFilterOpen: boolean) => void;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  setAge: (age: number[]) => void;
  setTab: (tab: number) => void;
  setFilters: (filters: FiltersState) => void;
  setAppearancesLoading: (appearancesLoading: boolean) => void;
  setAppearancesChartLoading: (appearancesChartLoading: boolean) => void;
  setAppearancesTouchBottom: (appearancesTouchBottom: boolean) => void;
  setAppearancesChartData: (appearancesChartData: ChartData | undefined) => void;
  requestChartData: (type: AppearanceType, timeslice: string, filter: Filter) => void;
  setAppearancesPagination: (appearancesPagination: Pagination | undefined) => void;
  setAppearances: (appearances: AppearancesByType) => void;
  handleSearchChange: (type: AppearanceType, rewrite: boolean, getCharts: boolean, search?: SearchData) => void;
  setAppearancesTimeslice: (appearancesTimeslice: string) => void;
  checkFilters: () => boolean;
  clearAge: (type: AppearanceType) => void;
  setFaceFiltersLoading: (faceFiltersLoading: boolean) => void;
  setVehicleFiltersLoading: (vehicleFiltersLoading: boolean) => void;
  setPersonFiltersLoading: (personFiltersLoading: boolean) => void;
  setAppearancesLocationSelectorOpen: (appearancesLocationSelectorOpen: boolean) => void;
  setFaceFilters: (faceFilters: FilterData[]) => void;
  setVehicleFilters: (vehicleFilters: FilterData[]) => void;
  setPersonFilters: (personFilters: FilterData[]) => void;
  handleTabsChange: (newTab: number) => void;
  appearancesHandleTabsChange: (newTab: number) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsImageChange: (isImageChange: boolean) => void,
  setSortFilters: (sorStr) => void,
  getFilterKey: (id) => void,
}

const initialValues: [TAppStoreValues, TAppStoreHandlers] = [{
  isFilterOpen: false,
  isMenuOpen: false,
  age: [0, 100],
  tab: 0,
  filters: { face: {}, vehicle: {}, person: {} },
  searchType: 'face',
  appearancesLoading: false,
  appearancesChartLoading: true,
  appearancesTouchBottom: false,
  appearancesChartData: undefined,
  appearancesPagination: undefined,
  appearances: { face: null, vehicle: null, person: null },
  appearancesTimeslice: '1d',
  faceFiltersLoading: false,
  vehicleFiltersLoading: false,
  personFiltersLoading: false,
  appearancesLocationSelectorOpen: false,
  faceFilters: [],
  vehicleFilters: [],
  personFilters: [],
  isAuthenticated: false,
  currentUser: undefined,
  isImageChange: false,
}, {
  setIsFilterOpen: () => void 0,
  setIsMenuOpen: () => void 0,
  setAge: () => void 0,
  setTab: () => void 0,
  setFilters: () => void 0,
  setAppearancesLoading: () => void 0,
  setAppearancesChartLoading: () => void 0,
  setAppearancesTouchBottom: () => void 0,
  setAppearancesChartData: () => void 0,
  requestChartData: () => void 0,
  setAppearancesPagination: () => void 0,
  setAppearances: () => void 0,
  handleSearchChange: () => void 0,
  setAppearancesTimeslice: () => void 0,
  checkFilters: () => false,
  clearAge: () => void 0,
  setFaceFiltersLoading: () => void 0,
  setVehicleFiltersLoading: () => void 0,
  setPersonFiltersLoading: () => void 0,
  setAppearancesLocationSelectorOpen: () => void 0,
  setFaceFilters: () => void 0,
  setVehicleFilters: () => void 0,
  setPersonFilters: () => void 0,
  handleTabsChange: () => void 0,
  appearancesHandleTabsChange: () => void 0,
  setIsAuthenticated: () => void 0,
  setIsImageChange: () => void 0,
  setSortFilters: () => void 0,
  getFilterKey: () => void 0,
}];

type AppearancesByType = {
  [key in string]: AppearanceItem[];
};

export type FiltersState = {
  [key in AppearanceType]: Filter
}

const filterKeys: FilterKeys[] = [
  'person_age',
  'timestamp_start',
  'timestamp_end',
  'person_gender',
  'vehicle_type',
  'vehicle_manufacturer',
  'vehicle_model',
  'camera',
  'location',
];

const searchTypes: AppearanceType[] = ['face', 'vehicle', 'person'];
const DEFAULT_PAGE_SIZE = Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE || '30');

const AppStoreContext = createContext<typeof initialValues>(initialValues);

export const AppStoreProvider: FC = ({ children }) => {
  const { storage } = handlers
  const [isFilterOpen, setIsFilterOpen] = useState(initialValues[0].isFilterOpen);
  const [isMenuOpen, setIsMenuOpen] = useState(initialValues[0].isMenuOpen);
  const [age, setAge] = useState(initialValues[0].age);
  const [tab, setTab] = useState(0);
  const [filters, setFilters] = useState<FiltersState>(initialValues[0].filters);
  const [searchType, setSearchType] = useState<AppearanceType>(searchTypes[tab]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(initialValues[0].isAuthenticated);
  const [appearancesLoading, setAppearancesLoading] = useState<boolean>(initialValues[0].appearancesLoading);
  const [appearancesChartLoading, setAppearancesChartLoading] = useState<boolean>(initialValues[0].appearancesChartLoading);
  const [appearancesTouchBottom, setAppearancesTouchBottom] = useState<boolean>(initialValues[0].appearancesTouchBottom);
  const [appearancesChartData, setAppearancesChartData] = useState<ChartData>();
  const [appearancesPagination, setAppearancesPagination] = useState<Pagination>();
  const [appearances, setAppearances] = useState<AppearancesByType>(initialValues[0].appearances);
  const [appearancesTimeslice, setAppearancesTimeslice] = useState<string>(initialValues[0].appearancesTimeslice);

  const [faceFiltersLoading, setFaceFiltersLoading] = useState<boolean>(initialValues[0].faceFiltersLoading);
  const [vehicleFiltersLoading, setVehicleFiltersLoading] = useState<boolean>(initialValues[0].vehicleFiltersLoading);
  const [personFiltersLoading, setPersonFiltersLoading] = useState<boolean>(initialValues[0].personFiltersLoading);
  const [appearancesLocationSelectorOpen,
    setAppearancesLocationSelectorOpen] = useState<boolean>(initialValues[0].appearancesLocationSelectorOpen);
  const [faceFilters, setFaceFilters] = useState<FilterData[]>(initialValues[0].faceFilters);
  const [vehicleFilters, setVehicleFilters] = useState<FilterData[]>(initialValues[0].vehicleFilters);
  const [personFilters, setPersonFilters] = useState<FilterData[]>(initialValues[0].personFilters);

  const [isImageChange, setIsImageChange] = useState<boolean>(initialValues[0].isImageChange);
  const [currentUser, setCurrentUser] = useState<UserData>();
  const [currentCompany, setCurrentCompany] = useState<UserData>();

  // Parse filters to create a query string
  function parseFilter(filter: Filter): string {
    const pairs = entries(filter);
    if (pairs.length === 0) {
      return '';
    }

    let filterText = '';
    each(([k, v]) => {
      if (!Array.isArray(v)) {
        v && (filterText += `&filters=${k}:${v}`);
      } else {
        if (k === 'person_age') {
          const ageValues = v.join('-');
          if (ageValues !== '1-99') {
            filterText += `&filters=${k}:${ageValues}`
          }
        } else {
          filterText += (v || []).filter((item) => item.length > 0).reduce((prev, curr) => `${prev}&filters=${k}:${curr}`, '');
        }
      }
    }, pairs);
    return filterText;
  }

  // Check if filters set
  const checkFilters = (company) => {
    const storageFilter = storage.get(getFilterKey(currentCompany?.id || company.id));
    const storageTab = +storage.get('selected-tab', 0);

    if (storageFilter !== null) {
      const parsedFilter = JSON.parse(storageFilter);
      for (const type in parsedFilter) {
        if (type === 'face') { for (const faceKey in parsedFilter[type]) {
          if (parsedFilter.face.hasOwnProperty(faceKey) && storageTab === 0) return true;
        } }
        else if (type === 'vehicle') { for (const vehicleKey in parsedFilter[type]) {
          if (parsedFilter.vehicle.hasOwnProperty(vehicleKey) && storageTab === 1) return true;
        } }
        else if (type === 'person') { for (const filterKey in parsedFilter[type]) {
          if (parsedFilter.person.hasOwnProperty(filterKey) && storageTab === 2) return true;
        } }
      }
      return false;
    }
    return false;
  };
  // const checkFilters = (filters) => {
  //   if (filters.face && filters.vehicle && filters.person) {
  //     return filters
  //   }

  // }
  const clearFilter = (filter) => {
    for (const filterType in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, filterType)) {
        for (const filterKey in filter[filterType]) {
          if (Object.prototype.hasOwnProperty.call(filter[filterType], filterKey)) {
            if (filterKey === 'is_reference' || filterKey === 'matched') {
              delete filter[filterType][filterKey]
            }
          }
        }
      }
    }
  }
  const getFilterKey = (id) => `search-filter-${id}`;
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     api.get<{ user_id: string }>('/auth-manager/me/')
  //       .then(({ data }) => api.get<UserData>(`/auth-manager/users/${data?.user_id}`))
  //       .then(({ data }) => {
  //         setCurrentUser(data);
  //         moment.tz.setDefault(data.timezone);
  //         api.get('/auth-manager/company/')
  //           .then(({ data }) => {
  //             const localKey = getFilterKey(data.id);
  //             setCurrentCompany(data);
  //             if (storage.get(localKey)) {
  //               let restoredFilters: FiltersState;
  //               try {
  //                 const filtersStr = storage.get(localKey);
  //                 if (filtersStr) {
  //                   restoredFilters = JSON.parse(filtersStr);
  //                   const selectedTab = +storage.get('selected-tab', '0');
  //                   const selectedType = searchTypes[selectedTab];
  //                   const validFilters = checkFilters(data);
  //                   setTab(selectedTab)
  //                   setSearchType(selectedType)
  //                   const filter = validFilters ? restoredFilters : initialValues[0].filters;
  //                   clearFilter(filter)
  //                   setFilters(filter)
  //                   handleSearchChange(selectedType, true, false, { filter: filter[selectedType] })
  //                 }
  //               } catch (e) {
  //                 storage.set(localKey, null)
  //               }
  //             }
  //           });
  //       });
  //   }
  // }, [isImageChange, isAuthenticated]);

  // Get current filters from localStorage and set it for using in appearances, search and filter panel
  // useEffect(() => {
  //   if (localStorage.getItem('search-filter')) {
  //     let restoredFilters: FiltersState;
  //     try {
  //       const filtersStr = localStorage.getItem('search-filter');
  //       if (filtersStr) {
  //         restoredFilters = JSON.parse(filtersStr);
  //         setFilters(restoredFilters);
  //       }
  //     } catch (e) {
  //       localStorage.removeItem('search-filter');
  //     }
  //   }
  // }, []);

  // Get current tab from localStorage and set it for using in appearances and filter panel
  const firsAppearancesRender = (filter) => {
    let defaultAppearanceType = storage.get('selected-tab', '0');
    if (!Object.keys(searchTypes).includes(defaultAppearanceType)) {
      setTab(0);
      defaultAppearanceType = 0;
    } else {
      setTab(+defaultAppearanceType);
      setSearchType(searchTypes[defaultAppearanceType]);
    }
    handleSearchChange(searchTypes[defaultAppearanceType], true, false, { filter: filter[searchTypes[defaultAppearanceType]] });
  }

  // Handle tabs change for right filter panel tabs
  const handleTabsChange = (newTab: number) => {
    setTab(newTab);
    setSearchType(searchTypes[newTab]);
    setAppearancesPagination(undefined)
    storage.set('selected-tab', String(newTab));
  };

  // Handle tabs change for appearances page tabs
  const appearancesHandleTabsChange = (newTab: number) => {
    setTab(newTab);
    setSearchType(searchTypes[newTab]);
    handleSearchChange(searchTypes[newTab], true, false, { filter: filters[searchTypes[newTab]] });
    storage.set('selected-tab', String(newTab));
  };

  // Request data for appearances chart
  const requestChartData = (type: AppearanceType, timeslice: string, filter: Filter) => {
    if (!isAuthenticated) return

    setAppearancesChartLoading(true);
    const chartFilters = omit(['timestamp_start', 'timestamp_end'], filter);
    const tsStart = Math.round((Date.now() - ms(timeslice || '1d')) / 1000);
    const tsEnd = Math.round(Date.now() / 1000);
    api.get<any>(
      `/chartilla/chart/?filters=object_type:${type}${parseFilter(chartFilters)}&filters=timestamp_start:${tsStart}&filters=timestamp_end:${tsEnd}`,
    ).then((response) => {
      if (response.data !== undefined) {
        setAppearancesChartData(response.data);
        setAppearancesChartLoading(false);
      }
    });
  };

  const getDefaultFilters = (searchType) => {
    const outFilters = {};
    switch (searchType) {
      case 'face':
        faceFilters.forEach(({ name, default_value }) => {
          if (default_value) {
            outFilters[name] = default_value
          }
        })
        break;
      case 'vehicle':
        vehicleFilters.forEach(({ name, default_value }) => {
          if (default_value) {
            outFilters[name] = default_value
          }
        })
        break;
      case 'person':
        personFilters.forEach(({ name, default_value }) => {
          if (default_value) {
            outFilters[name] = default_value
          }
        })
        break;
    }

    return outFilters;
  }
  const handleSearchChange = (type: AppearanceType, rewrite: boolean = false, getCharts: boolean, search?: SearchData) => {
    if (!isAuthenticated || appearancesLoading) return
    setAppearancesLoading(true);
    if (rewrite) {
      setAppearances(initialValues[0].appearances);
    }
    let newFilters;
    if (search) {
      newFilters = search.filter;
    }

    const resultFilters = {
      camera: [],
      location: [],
      timestamp_start: null,
      timestamp_end: null,
      ...getDefaultFilters(type),
      ...newFilters
    } || {};

    const storedFilters = {
      ...filters,
      [type]: resultFilters,
    }
    setFilters(storedFilters);
    currentCompany && storage.set(getFilterKey(currentCompany.id), JSON.stringify(storedFilters));

    const query = parseFilter(resultFilters);

    const pageSize = rewrite ? 100 : DEFAULT_PAGE_SIZE;

    const sort = storage.get('sort') || '&sort_field=date&sort_order=desc'

    const paginationQuery = appearancesPagination && !rewrite ? `&pgsize=${pageSize}&pgoffset=${appearancesPagination.offset}` : `&pgsize=${pageSize}`;
    api.get<SearchResponse>(`/object-manager/search/?filters=object_type:${type}${query}${paginationQuery}${sort}`)
      .then((response) => {
        const items = response.data ? response.data.items : [];

        if (rewrite) {
          setAppearances({
            ...initialValues[0].appearances,
            [type]: items,
          });
          setAppearancesPagination(response.data ? { ...response.data.pagination, offset: pageSize } : undefined);
        } else {
          setAppearances({
            ...appearances,
            [type]: [...appearances[type], ...items],
          });
          setAppearancesPagination(response.data ? { ...response.data.pagination, offset: appearancesPagination ? appearancesPagination.offset + pageSize : pageSize } : undefined);
        }

        response?.data?.items?.length < pageSize ? setAppearancesTouchBottom(true) : setAppearancesTouchBottom(false);
      })
      .catch(() => {})
      .finally(() => {
        setAppearancesLoading(false);
      })
    if (getCharts) requestChartData(type, appearancesTimeslice, newFilters || {});
  };

  const parseVehicleFilters = () => {
    let filtersQuery = '';
    let firstIteration = true;

    for (const vf of vehicleFilters) {
      const filterKey = vf.name.toLowerCase() as FilterKeys;
      if (filters[searchType][filterKey]) {
        filtersQuery += `${firstIteration ? '?' : '&'}filters=${vf.name.toLowerCase()}:${filters[searchType][filterKey]}`;
        firstIteration = false;
      }
    }
    return filtersQuery;
  };

  const removeRefAndMatchedFilters = (filters) => filters.filter((item) => item.name !== 'is_reference' && item.name !== 'matched')
  const updateVehicleFilters = (firstRun = false) => {
    firstRun && setVehicleFiltersLoading(true);

    const url = `/object-manager/search/filters/vehicle${firstRun ? '' : parseVehicleFilters()}`;

    api.get<FiltersResponse>(url)
      .then((response) => {
        if (response.data !== undefined) {
          response.data.filters && setVehicleFilters(removeRefAndMatchedFilters(response.data.filters));
          firstRun && setVehicleFiltersLoading(false);
        }
      });
  };

  const updatePersonFilters = (firstRun = false) => {
    firstRun && setPersonFiltersLoading(true);

    const url = `/object-manager/search/filters/person${firstRun ? '' : parseVehicleFilters()}`;

    api.get<FiltersResponse>(url)
      .then((response) => {
        if (response.data !== undefined) {
          response.data.filters && setPersonFilters(removeRefAndMatchedFilters(response.data.filters));
          firstRun && setPersonFiltersLoading(false);
        }
      });
  };

  const updateFaceFilters = (firstRun = false) => {
    setFaceFiltersLoading(true);
    if (searchType !== 'face' || !firstRun) {
      setFaceFiltersLoading(false);
      return;
    }

    const url = `/object-manager/search/filters/face${firstRun ? '' : parseVehicleFilters()}`;

    api.get<FiltersResponse>(url)
      .then((response) => {
        if (response.data !== undefined) {
          response.data.filters && setFaceFilters(removeRefAndMatchedFilters(response.data.filters));
          setFaceFiltersLoading(false);
        }
      });
  };

  const clearAge = (type: AppearanceType) => {
    if (type === 'face') setAge([0, 100]);
  };

  useEffect(() => {
    const data = storage.get('person_age');
    if (data) setAge(JSON.parse(data));
  }, []);

  // Update set of face filters inputs when searchType or filters change
  // useEffect(() => {
  //   if (searchType === 'face' && isAuthenticated) {
  //     updateFaceFilters(faceFilters.length === 0);
  //     storage.set('person_age', JSON.stringify(age));
  //   }
  // }, [isAuthenticated, filters[searchType], searchType, faceFilters.length]);
  //
  // useEffect(() => {
  //   if (searchType === 'vehicle' && isAuthenticated) {
  //     updateVehicleFilters(vehicleFilters.length === 0);
  //   }
  // }, [isAuthenticated, filters[searchType], searchType, vehicleFilters.length]);
  //
  // useEffect(() => {
  //   if (searchType === 'person' && isAuthenticated) {
  //     updatePersonFilters(personFilters.length === 0);
  //   }
  // }, [isAuthenticated, filters[searchType], searchType, personFilters.length]);

  window.AppStore = {
    setIsFilterOpen,
    setIsMenuOpen,
    setAge,
    setTab,
    handleTabsChange,
    appearancesHandleTabsChange,
    setFilters,
    setAppearances,
    setAppearancesLoading,
    setAppearancesChartLoading,
    setAppearancesTouchBottom,
    requestChartData,
    setAppearancesChartData,
    setAppearancesPagination,
    handleSearchChange,
    setAppearancesTimeslice,
    checkFilters,
    clearAge,
    setFaceFiltersLoading,
    setVehicleFiltersLoading,
    setPersonFiltersLoading,
    setAppearancesLocationSelectorOpen,
    setFaceFilters,
    setVehicleFilters,
    setPersonFilters,
    setIsAuthenticated,
    setIsImageChange,
    setCurrentUser,
    getFilterKey,
  };

  return (
    <AppStoreContext.Provider value={[{
      isFilterOpen,
      isMenuOpen,
      age,
      tab,
      filters,
      searchType,
      appearances,
      appearancesLoading,
      appearancesChartLoading,
      appearancesTouchBottom,
      appearancesChartData,
      appearancesPagination,
      appearancesTimeslice,
      faceFiltersLoading,
      vehicleFiltersLoading,
      personFiltersLoading,
      appearancesLocationSelectorOpen,
      faceFilters,
      vehicleFilters,
      personFilters,
      isAuthenticated,
      currentUser,
      isImageChange,
      currentCompany,
    }, {
      setIsFilterOpen,
      setIsMenuOpen,
      setAge,
      setTab,
      handleTabsChange,
      appearancesHandleTabsChange,
      setFilters,
      setAppearances,
      setAppearancesLoading,
      setAppearancesChartLoading,
      setAppearancesTouchBottom,
      requestChartData,
      setAppearancesChartData,
      setAppearancesPagination,
      handleSearchChange,
      setAppearancesTimeslice,
      checkFilters,
      clearAge,
      setFaceFiltersLoading,
      setVehicleFiltersLoading,
      setPersonFiltersLoading,
      setAppearancesLocationSelectorOpen,
      setFaceFilters,
      setVehicleFilters,
      setPersonFilters,
      setIsAuthenticated,
      setIsImageChange,
      setCurrentUser,
      getFilterKey,
    }]}
    >
      {children}
    </AppStoreContext.Provider>
  );
};

export const useAppStore = () => useContext(AppStoreContext);

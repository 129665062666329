// @ts-nocheck

import { AxiosInstance } from 'axios';

/**
 * @typedef {Object} IFiltersAdapter
 * @property {function} fetch
 * @property {function} create
 * @property {function} update
 * @property {function} delete
 * @property {function} copy
 */

/**
 * @param {AxiosInstance} api - Экземпляр Axios
 * @return {IFiltersAdapter}
 */

// https://api.alphatest1.dev.metapixai.com/object-manager/public/v2/filters/{object_type}/{widget_type}/

// {
//   "name": "person_gender",
//   "rendered_name": "Gender",
//   "type": "select_field",
//   "value_type": "str",
//   "sub_value_type": null,
//   "hint": "Person's gender",
//   "values": [
//   "all",
//   "male",
//   "female"
// ],
//   "default_value": "all",
//   "applied_value": null
// }

export default (api) => ({
  fetchForWidget: (objectType, widgetType, payload) => api.post(`/object-manager/v2/filters/${objectType}/${widgetType}`, { filters: payload }).then(({ data }) => {
    const face = [
      {
        name: 'person_age',
        type: 'sliderRange',
        label: 'Age',
        tooltip: "Person's age",
        displayEmpty: true,
        defaultValue: [
          5,
          95
        ],
        value: [
          5,
          95
        ],
        items: [
          {
            name: '1',
            value: 1
          },
          {
            name: '99',
            value: 99
          }
        ]
      },
      {
        name: 'person_gender',
        type: 'selectSingle',
        label: 'Gender',
        tooltip: "Person's gender",
        displayEmpty: true,
        defaultValue: '',
        value: '',
        items: [
          {
            name: 'male',
            value: 'male'
          },
          {
            name: 'female',
            value: 'female'
          }
        ]
      },
      {
        name: 'person_mask',
        type: 'selectSingle',
        label: 'Mask',
        tooltip: "Person's mask",
        displayEmpty: true,
        defaultValue: '',
        value: '',
        items: [
          {
            name: 'with',
            value: 'with'
          },
          {
            name: 'without',
            value: 'without'
          }
        ]
      },
      {
        name: 'person_glasses',
        type: 'selectSingle',
        label: 'Glasses',
        tooltip: "Person's glasses",
        displayEmpty: true,
        defaultValue: '',
        value: '',
        items: [
          {
            name: 'with',
            value: 'with'
          },
          {
            name: 'without',
            value: 'without'
          }
        ]
      },
      {
        name: 'person_beard',
        type: 'selectSingle',
        label: 'Beard',
        tooltip: "Person's beard",
        displayEmpty: true,
        defaultValue: '',
        value: '',
        items: [
          {
            name: 'with',
            value: 'with'
          },
          {
            name: 'without',
            value: 'without'
          }
        ]
      },
      {
        name: 'name',
        type: 'textCombo',
        label: "Object's name",
        tooltip: "Object's name",
        defaultValue: '',
        value: 'zzzzzz',
        items: [
          {
            value: '*',
            name: 'Only filled'
          },
          {
            value: '',
            name: 'Specific name'
          }
        ]
      },
      {
        name: 'notes',
        type: 'textCombo',
        label: "Object's notes",
        tooltip: "Object's notes",
        defaultValue: '',
        value: '',
        items: [
          {
            value: '*',
            name: 'Only filled'
          },
          {
            value: '',
            name: 'Specific note'
          }
        ]
      },
      {
        name: 'image_quality',
        type: 'selectSingle',
        label: 'Source image quality',
        tooltip: "Source image crop's quality",
        displayEmpty: true,
        defaultValue: 'good',
        value: 'good',
        items: [
          {
            name: 'good',
            value: 'good'
          },
          {
            name: 'bad',
            value: 'bad'
          }
        ]
      },
      // {
      //   name: 'matched',
      //   type: 'selectSingle',
      //   label: 'Matched',
      //   tooltip: 'Matched objects',
      //   displayEmpty: true,
      //   defaultValue: '',
      //   value: '',
      //   items: [
      //     {
      //       name: 'true',
      //       value: true
      //     },
      //     {
      //       name: 'false',
      //       value: false
      //     }
      //   ]
      // },
      // {
      //   name: 'is_reference',
      //   type: 'selectSingle',
      //   label: 'References',
      //   tooltip: 'References objects',
      //   displayEmpty: true,
      //   defaultValue: '',
      //   value: '',
      //   items: [
      //     {
      //       name: 'true',
      //       value: true
      //     },
      //     {
      //       name: 'false',
      //       value: false
      //     }
      //   ]
      // }
    ]

    const vehicle = [
      {
        name: 'vehicle_type',
        type: 'selectMultiple',
        label: 'Vehicle type',
        tooltip: 'Vehicle type. E.g truck/sedan...',
        defaultValue: [],
        items: [
          {
            name: 'Van',
            value: 'Van'
          },
          {
            name: 'SUV',
            value: 'SUV'
          },
          {
            name: 'Sedan',
            value: 'Sedan'
          },
          {
            name: 'Wagon',
            value: 'Wagon'
          },
          {
            name: 'Cab',
            value: 'Cab'
          },
          {
            name: 'Hatchback',
            value: 'Hatchback'
          },
          {
            name: 'Minivan',
            value: 'Minivan'
          },
          {
            name: 'Truck',
            value: 'Truck'
          }
        ]
      },
      {
        name: 'vehicle_manufacturer',
        type: 'selectMultiple',
        label: 'Vehicle manufacturer',
        tooltip: "Vehicle's manufacturer. E.g Toyota/Kia...",
        defaultValue: [],
        items: [
          {
            name: 'GMC',
            value: 'GMC'
          },
          {
            name: 'Chevrolet',
            value: 'Chevrolet'
          },
          {
            name: 'AM General',
            value: 'AM General'
          },
          {
            name: 'Audi',
            value: 'Audi'
          },
          {
            name: 'Lamborghini',
            value: 'Lamborghini'
          },
          {
            name: 'Maybach',
            value: 'Maybach'
          },
          {
            name: 'Geo',
            value: 'Geo'
          },
          {
            name: 'Hyundai',
            value: 'Hyundai'
          },
          {
            name: 'Jeep',
            value: 'Jeep'
          },
          {
            name: 'Ford',
            value: 'Ford'
          },
          {
            name: 'Mercedes-Benz',
            value: 'Mercedes-Benz'
          },
          {
            name: 'BMW',
            value: 'BMW'
          },
          {
            name: 'Land',
            value: 'Land'
          },
          {
            name: 'Ram',
            value: 'Ram'
          },
          {
            name: 'Scion',
            value: 'Scion'
          },
          {
            name: 'Volvo',
            value: 'Volvo'
          },
          {
            name: 'smart',
            value: 'smart'
          },
          {
            name: 'Dodge',
            value: 'Dodge'
          },
          {
            name: 'Acura',
            value: 'Acura'
          },
          {
            name: 'Honda',
            value: 'Honda'
          },
          {
            name: 'Buick',
            value: 'Buick'
          },
          {
            name: 'Cadillac',
            value: 'Cadillac'
          },
          {
            name: 'MINI',
            value: 'MINI'
          },
          {
            name: 'Volkswagen',
            value: 'Volkswagen'
          },
          {
            name: 'Nissan',
            value: 'Nissan'
          },
          {
            name: 'Spyker',
            value: 'Spyker'
          },
          {
            name: 'Infiniti',
            value: 'Infiniti'
          },
          {
            name: 'Suzuki',
            value: 'Suzuki'
          },
          {
            name: 'HUMMER',
            value: 'HUMMER'
          },
          {
            name: 'Chrysler',
            value: 'Chrysler'
          },
          {
            name: 'Bentley',
            value: 'Bentley'
          },
          {
            name: 'Daewoo',
            value: 'Daewoo'
          },
          {
            name: 'FIAT',
            value: 'FIAT'
          },
          {
            name: 'Aston',
            value: 'Aston'
          },
          {
            name: 'Plymouth',
            value: 'Plymouth'
          },
          {
            name: 'Fisker',
            value: 'Fisker'
          },
          {
            name: 'Tesla',
            value: 'Tesla'
          },
          {
            name: 'Rolls-Royce',
            value: 'Rolls-Royce'
          },
          {
            name: 'Isuzu',
            value: 'Isuzu'
          },
          {
            name: 'Mazda',
            value: 'Mazda'
          },
          {
            name: 'Lincoln',
            value: 'Lincoln'
          },
          {
            name: 'Toyota',
            value: 'Toyota'
          },
          {
            name: 'McLaren',
            value: 'McLaren'
          },
          {
            name: 'Ferrari',
            value: 'Ferrari'
          },
          {
            name: 'Eagle',
            value: 'Eagle'
          },
          {
            name: 'Mitsubishi',
            value: 'Mitsubishi'
          },
          {
            name: 'Bugatti',
            value: 'Bugatti'
          },
          {
            name: 'Porsche',
            value: 'Porsche'
          },
          {
            name: 'Jaguar',
            value: 'Jaguar'
          }
        ]
      },
      {
        name: 'vehicle_model',
        type: 'selectMultiple',
        label: 'Vehicle model',
        tooltip: "Vehicle's model. E.g Camry/Ceed...",
        defaultValue: [],
        items: [
          {
            name: 'Sprinter Cargo Van',
            value: 'Sprinter Cargo Van'
          },
          {
            name: 'Hummer SUV',
            value: 'Hummer SUV'
          },
          {
            name: '100 Wagon',
            value: '100 Wagon'
          },
          {
            name: 'Landaulet Convertible',
            value: 'Landaulet Convertible'
          },
          {
            name: 'Metro Convertible',
            value: 'Metro Convertible'
          },
          {
            name: 'Wrangler SUV',
            value: 'Wrangler SUV'
          },
          {
            name: 'S-Class Sedan',
            value: 'S-Class Sedan'
          },
          {
            name: 'Savana Van',
            value: 'Savana Van'
          },
          {
            name: 'F-450 Super Duty Crew Cab',
            value: 'F-450 Super Duty Crew Cab'
          },
          {
            name: 'E-Series Wagon Van',
            value: 'E-Series Wagon Van'
          },
          {
            name: 'C/V Cargo Van Minivan',
            value: 'C/V Cargo Van Minivan'
          },
          {
            name: 'Grand Cherokee SUV',
            value: 'Grand Cherokee SUV'
          },
          {
            name: 'Terrain SUV',
            value: 'Terrain SUV'
          },
          {
            name: '240 Sedan',
            value: '240 Sedan'
          },
          {
            name: 'Accent Sedan',
            value: 'Accent Sedan'
          },
          {
            name: 'fortwo Convertible',
            value: 'fortwo Convertible'
          },
          {
            name: 'TSX Sedan',
            value: 'TSX Sedan'
          },
          {
            name: 'xD Hatchback',
            value: 'xD Hatchback'
          },
          {
            name: 'S6 Sedan',
            value: 'S6 Sedan'
          },
          {
            name: 'Canyon Extended Cab',
            value: 'Canyon Extended Cab'
          },
          {
            name: 'NV Passenger Van',
            value: 'NV Passenger Van'
          },
          {
            name: 'Reventon Coupe',
            value: 'Reventon Coupe'
          },
          {
            name: 'C-Class Sedan',
            value: 'C-Class Sedan'
          },
          {
            name: 'Golf Hatchback',
            value: 'Golf Hatchback'
          },
          {
            name: 'Gallardo LP 570-4 Superleggera',
            value: 'Gallardo LP 570-4 Superleggera'
          },
          {
            name: '300-Class Convertible',
            value: '300-Class Convertible'
          },
          {
            name: 'Santa Fe SUV',
            value: 'Santa Fe SUV'
          },
          {
            name: 'Express Van',
            value: 'Express Van'
          },
          {
            name: 'F-150 Regular Cab',
            value: 'F-150 Regular Cab'
          },
          {
            name: 'SRX SUV',
            value: 'SRX SUV'
          },
          {
            name: 'Durango SUV',
            value: 'Durango SUV'
          },
          {
            name: 'Cooper Roadster Convertible',
            value: 'Cooper Roadster Convertible'
          },
          {
            name: 'Aerio Sedan',
            value: 'Aerio Sedan'
          },
          {
            name: 'Tahoe Hybrid SUV',
            value: 'Tahoe Hybrid SUV'
          },
          {
            name: 'Odyssey Minivan',
            value: 'Odyssey Minivan'
          },
          {
            name: 'Caliber Wagon',
            value: 'Caliber Wagon'
          },
          {
            name: 'Genesis Sedan',
            value: 'Genesis Sedan'
          },
          {
            name: 'Beetle Hatchback',
            value: 'Beetle Hatchback'
          },
          {
            name: 'Tucson SUV',
            value: 'Tucson SUV'
          },
          {
            name: 'Continental Flying Spur Sedan',
            value: 'Continental Flying Spur Sedan'
          },
          {
            name: 'Impala Sedan',
            value: 'Impala Sedan'
          },
          {
            name: 'Accord Sedan',
            value: 'Accord Sedan'
          },
          {
            name: 'Caravan Minivan',
            value: 'Caravan Minivan'
          },
          {
            name: 'Ram Pickup 3500 Crew Cab',
            value: 'Ram Pickup 3500 Crew Cab'
          },
          {
            name: 'Nubira Wagon',
            value: 'Nubira Wagon'
          },
          {
            name: 'Sonata Sedan',
            value: 'Sonata Sedan'
          },
          {
            name: 'Silverado 1500 Classic Extended Cab',
            value: 'Silverado 1500 Classic Extended Cab'
          },
          {
            name: 'X3 SUV',
            value: 'X3 SUV'
          },
          {
            name: 'C8 Convertible',
            value: 'C8 Convertible'
          },
          {
            name: '240SX Coupe',
            value: '240SX Coupe'
          },
          {
            name: '6 Series Convertible',
            value: '6 Series Convertible'
          },
          {
            name: 'Diablo Coupe',
            value: 'Diablo Coupe'
          },
          {
            name: '500 Convertible',
            value: '500 Convertible'
          },
          {
            name: 'ActiveHybrid 5 Sedan',
            value: 'ActiveHybrid 5 Sedan'
          },
          {
            name: 'Journey SUV',
            value: 'Journey SUV'
          },
          {
            name: 'Neon Coupe',
            value: 'Neon Coupe'
          },
          {
            name: 'Karma Sedan',
            value: 'Karma Sedan'
          },
          {
            name: 'Silverado 1500 Extended Cab',
            value: 'Silverado 1500 Extended Cab'
          },
          {
            name: 'Silverado 1500 Hybrid Crew Cab',
            value: 'Silverado 1500 Hybrid Crew Cab'
          },
          {
            name: 'Fiesta Sedan',
            value: 'Fiesta Sedan'
          },
          {
            name: 'Town Car Sedan',
            value: 'Town Car Sedan'
          },
          {
            name: 'Focus Sedan',
            value: 'Focus Sedan'
          },
          {
            name: 'Edge SUV',
            value: 'Edge SUV'
          },
          {
            name: 'ZDX Hatchback',
            value: 'ZDX Hatchback'
          },
          {
            name: 'Dakota Club Cab',
            value: 'Dakota Club Cab'
          },
          {
            name: 'Ram Pickup 3500 Quad Cab',
            value: 'Ram Pickup 3500 Quad Cab'
          },
          {
            name: 'Compass SUV',
            value: 'Compass SUV'
          },
          {
            name: 'H2 SUT Crew Cab',
            value: 'H2 SUT Crew Cab'
          },
          {
            name: 'Model S Sedan',
            value: 'Model S Sedan'
          },
          {
            name: '100 Sedan',
            value: '100 Sedan'
          },
          {
            name: 'Silverado 2500HD Regular Cab',
            value: 'Silverado 2500HD Regular Cab'
          },
          {
            name: 'PT Cruiser Convertible',
            value: 'PT Cruiser Convertible'
          },
          {
            name: 'Veloster Hatchback',
            value: 'Veloster Hatchback'
          },
          {
            name: 'Corvette Ron Fellows Edition Z06',
            value: 'Corvette Ron Fellows Edition Z06'
          },
          {
            name: 'Express Cargo Van',
            value: 'Express Cargo Van'
          },
          {
            name: 'X6 SUV',
            value: 'X6 SUV'
          },
          {
            name: 'Silverado 1500 Regular Cab',
            value: 'Silverado 1500 Regular Cab'
          },
          {
            name: 'Corolla Sedan',
            value: 'Corolla Sedan'
          },
          {
            name: 'Juke Hatchback',
            value: 'Juke Hatchback'
          },
          {
            name: 'Azera Sedan',
            value: 'Azera Sedan'
          },
          {
            name: 'Tribute SUV',
            value: 'Tribute SUV'
          },
          {
            name: 'TTS Coupe',
            value: 'TTS Coupe'
          },
          {
            name: 'Traverse SUV',
            value: 'Traverse SUV'
          },
          {
            name: 'TL Type-S',
            value: 'TL Type-S'
          },
          {
            name: 'Leaf Hatchback',
            value: 'Leaf Hatchback'
          },
          {
            name: 'Expedition EL SUV',
            value: 'Expedition EL SUV'
          },
          {
            name: 'Rainier SUV',
            value: 'Rainier SUV'
          },
          {
            name: '3 Series Sedan',
            value: '3 Series Sedan'
          },
          {
            name: 'Sonata Hybrid Sedan',
            value: 'Sonata Hybrid Sedan'
          },
          {
            name: 'Rover LR2 SUV',
            value: 'Rover LR2 SUV'
          },
          {
            name: 'Sequoia SUV',
            value: 'Sequoia SUV'
          },
          {
            name: 'XC90 SUV',
            value: 'XC90 SUV'
          },
          {
            name: '4Runner SUV',
            value: '4Runner SUV'
          },
          {
            name: 'Mustang Convertible',
            value: 'Mustang Convertible'
          },
          {
            name: 'Freestar Minivan',
            value: 'Freestar Minivan'
          },
          {
            name: 'Patriot SUV',
            value: 'Patriot SUV'
          },
          {
            name: 'X5 SUV',
            value: 'X5 SUV'
          },
          {
            name: 'M3 Coupe',
            value: 'M3 Coupe'
          },
          {
            name: 'Arnage Sedan',
            value: 'Arnage Sedan'
          },
          {
            name: 'Ranger SuperCab',
            value: 'Ranger SuperCab'
          },
          {
            name: 'QX56 SUV',
            value: 'QX56 SUV'
          },
          {
            name: 'Malibu Sedan',
            value: 'Malibu Sedan'
          },
          {
            name: 'Z4 Convertible',
            value: 'Z4 Convertible'
          },
          {
            name: 'V8 Sedan',
            value: 'V8 Sedan'
          },
          {
            name: 'Malibu Hybrid Sedan',
            value: 'Malibu Hybrid Sedan'
          },
          {
            name: '3 Series Wagon',
            value: '3 Series Wagon'
          },
          {
            name: 'Ascender SUV',
            value: 'Ascender SUV'
          },
          {
            name: 'Camry Sedan',
            value: 'Camry Sedan'
          },
          {
            name: 'Elantra Sedan',
            value: 'Elantra Sedan'
          },
          {
            name: '300 SRT-8',
            value: '300 SRT-8'
          },
          {
            name: 'Town and Country Minivan',
            value: 'Town and Country Minivan'
          },
          {
            name: 'Martin V8 Vantage Convertible',
            value: 'Martin V8 Vantage Convertible'
          },
          {
            name: 'Elantra Touring Hatchback',
            value: 'Elantra Touring Hatchback'
          },
          {
            name: 'RL Sedan',
            value: 'RL Sedan'
          },
          {
            name: 'Charger SRT-8',
            value: 'Charger SRT-8'
          },
          {
            name: 'Acadia SUV',
            value: 'Acadia SUV'
          },
          {
            name: 'Rover Range Rover SUV',
            value: 'Rover Range Rover SUV'
          },
          {
            name: 'Kizashi Sedan',
            value: 'Kizashi Sedan'
          },
          {
            name: 'SL-Class Coupe',
            value: 'SL-Class Coupe'
          },
          {
            name: 'Aspen SUV',
            value: 'Aspen SUV'
          },
          {
            name: 'G Coupe IPL',
            value: 'G Coupe IPL'
          },
          {
            name: 'H3T Crew Cab',
            value: 'H3T Crew Cab'
          },
          {
            name: 'Avalanche Crew Cab',
            value: 'Avalanche Crew Cab'
          },
          {
            name: 'Sonic Sedan',
            value: 'Sonic Sedan'
          },
          {
            name: 'Liberty SUV',
            value: 'Liberty SUV'
          },
          {
            name: 'TrailBlazer SS',
            value: 'TrailBlazer SS'
          },
          {
            name: 'M5 Sedan',
            value: 'M5 Sedan'
          },
          {
            name: 'Yukon Hybrid SUV',
            value: 'Yukon Hybrid SUV'
          },
          {
            name: 'Verano Sedan',
            value: 'Verano Sedan'
          },
          {
            name: 'CTS-V Sedan',
            value: 'CTS-V Sedan'
          },
          {
            name: 'Escalade EXT Crew Cab',
            value: 'Escalade EXT Crew Cab'
          },
          {
            name: 'Integra Type R',
            value: 'Integra Type R'
          },
          {
            name: 'HHR SS',
            value: 'HHR SS'
          },
          {
            name: 'Continental GT Coupe',
            value: 'Continental GT Coupe'
          },
          {
            name: 'Enclave SUV',
            value: 'Enclave SUV'
          },
          {
            name: 'A5 Coupe',
            value: 'A5 Coupe'
          },
          {
            name: 'C30 Hatchback',
            value: 'C30 Hatchback'
          },
          {
            name: 'Accord Coupe',
            value: 'Accord Coupe'
          },
          {
            name: 'TT RS Coupe',
            value: 'TT RS Coupe'
          },
          {
            name: 'TL Sedan',
            value: 'TL Sedan'
          },
          {
            name: 'S4 Sedan',
            value: 'S4 Sedan'
          },
          {
            name: 'S5 Coupe',
            value: 'S5 Coupe'
          },
          {
            name: 'Monte Carlo Coupe',
            value: 'Monte Carlo Coupe'
          },
          {
            name: 'Panamera Sedan',
            value: 'Panamera Sedan'
          },
          {
            name: 'Charger Sedan',
            value: 'Charger Sedan'
          },
          {
            name: 'Aventador Coupe',
            value: 'Aventador Coupe'
          },
          {
            name: 'Sprinter Van',
            value: 'Sprinter Van'
          },
          {
            name: 'E-Class Sedan',
            value: 'E-Class Sedan'
          },
          {
            name: 'SX4 Sedan',
            value: 'SX4 Sedan'
          },
          {
            name: 'Crossfire Convertible',
            value: 'Crossfire Convertible'
          },
          {
            name: '1 Series Coupe',
            value: '1 Series Coupe'
          },
          {
            name: 'Magnum Wagon',
            value: 'Magnum Wagon'
          },
          {
            name: 'Dakota Crew Cab',
            value: 'Dakota Crew Cab'
          },
          {
            name: 'Cobalt SS',
            value: 'Cobalt SS'
          },
          {
            name: 'Camaro Convertible',
            value: 'Camaro Convertible'
          },
          {
            name: 'Martin V8 Vantage Coupe',
            value: 'Martin V8 Vantage Coupe'
          },
          {
            name: 'Regal GS',
            value: 'Regal GS'
          },
          {
            name: 'Talon Hatchback',
            value: 'Talon Hatchback'
          },
          {
            name: 'Lancer Sedan',
            value: 'Lancer Sedan'
          },
          {
            name: 'XK XKR',
            value: 'XK XKR'
          },
          {
            name: 'SX4 Hatchback',
            value: 'SX4 Hatchback'
          },
          {
            name: 'Sebring Convertible',
            value: 'Sebring Convertible'
          },
          {
            name: '1 Series Convertible',
            value: '1 Series Convertible'
          },
          {
            name: 'R8 Coupe',
            value: 'R8 Coupe'
          },
          {
            name: 'S5 Convertible',
            value: 'S5 Convertible'
          },
          {
            name: 'Challenger SRT8',
            value: 'Challenger SRT8'
          },
          {
            name: 'Veracruz SUV',
            value: 'Veracruz SUV'
          },
          {
            name: 'Phantom Sedan',
            value: 'Phantom Sedan'
          },
          {
            name: 'RS 4 Convertible',
            value: 'RS 4 Convertible'
          },
          {
            name: 'M6 Convertible',
            value: 'M6 Convertible'
          },
          {
            name: 'Phantom Drophead Coupe Convertible',
            value: 'Phantom Drophead Coupe Convertible'
          },
          {
            name: 'C8 Coupe',
            value: 'C8 Coupe'
          },
          {
            name: 'TT Hatchback',
            value: 'TT Hatchback'
          },
          {
            name: 'Mulsanne Sedan',
            value: 'Mulsanne Sedan'
          },
          {
            name: 'Corvette Convertible',
            value: 'Corvette Convertible'
          },
          {
            name: 'Corvette ZR1',
            value: 'Corvette ZR1'
          },
          {
            name: '458 Italia Coupe',
            value: '458 Italia Coupe'
          },
          {
            name: 'Ghost Sedan',
            value: 'Ghost Sedan'
          },
          {
            name: 'MP4-12C Coupe',
            value: 'MP4-12C Coupe'
          },
          {
            name: 'Veyron 16.4 Coupe',
            value: 'Veyron 16.4 Coupe'
          },
          {
            name: 'Continental Supersports Conv. Convertible',
            value: 'Continental Supersports Conv. Convertible'
          }
        ]
      },
      {
        name: 'vehicle_color',
        type: 'selectMultiple',
        label: 'Vehicle color',
        tooltip: "Vehicle's color. E.g blue/red...",
        defaultValue: [],
        items: [
          {
            name: 'black',
            value: 'black'
          },
          {
            name: 'blue',
            value: 'blue'
          },
          {
            name: 'brown',
            value: 'brown'
          },
          {
            name: 'green',
            value: 'green'
          },
          {
            name: 'grey',
            value: 'grey'
          },
          {
            name: 'orange',
            value: 'orange'
          },
          {
            name: 'pink',
            value: 'pink'
          },
          {
            name: 'purple',
            value: 'purple'
          },
          {
            name: 'red',
            value: 'red'
          },
          {
            name: 'silver',
            value: 'silver'
          },
          {
            name: 'white',
            value: 'white'
          },
          {
            name: 'yellow',
            value: 'yellow'
          }
        ]
      },
      {
        name: 'vehicle_license_plate',
        type: 'textInput',
        label: 'Vehicle license plate',
        tooltip: "Vehicle's license plate",
        defaultValue: '',
        value: ''
      },
      {
        name: 'name',
        type: 'textCombo',
        label: "Object's name",
        tooltip: "Object's name",
        defaultValue: '',
        value: '',
        items: [
          {
            value: '*',
            name: 'Only filled'
          },
          {
            value: '',
            name: 'Specific name'
          }
        ]
      },
      {
        name: 'notes',
        type: 'textCombo',
        label: "Object's notes",
        tooltip: "Object's notes",
        defaultValue: '',
        value: ''
      },
      {
        name: 'image_quality',
        type: 'selectSingle',
        label: 'Source image quality',
        tooltip: "Source image crop's quality",
        displayEmpty: true,
        defaultValue: 'good',
        value: 'good',
        items: [
          {
            name: 'good',
            value: 'good'
          },
          {
            name: 'bad',
            value: 'bad'
          }
        ]
      },
      // {
      //   name: 'matched',
      //   type: 'selectSingle',
      //   label: 'Matched',
      //   tooltip: 'Matched objects',
      //   displayEmpty: true,
      //   defaultValue: '',
      //   value: '',
      //   items: [
      //     {
      //       name: 'true',
      //       value: true
      //     },
      //     {
      //       name: 'false',
      //       value: false
      //     }
      //   ]
      // },
      // {
      //   name: 'is_reference',
      //   type: 'selectSingle',
      //   label: 'References',
      //   tooltip: 'References objects',
      //   displayEmpty: true,
      //   defaultValue: '',
      //   value: '',
      //   items: [
      //     {
      //       name: 'true',
      //       value: true
      //     },
      //     {
      //       name: 'false',
      //       value: false
      //     }
      //   ]
      // }
    ]

    const person = [
      {
        name: 'name',
        type: 'textCombo',
        label: "Object's name",
        tooltip: "Object's name",
        defaultValue: '',
        value: '',
        items: [
          {
            value: '*',
            name: 'Only filled'
          },
          {
            value: '',
            name: 'Specific name'
          }
        ]
      },
      {
        name: 'notes',
        type: 'textInput',
        label: "Object's notes",
        tooltip: "Object's notes",
        defaultValue: '',
        value: ''
      },
      {
        name: 'image_quality',
        type: 'selectSingle',
        label: 'Source image quality',
        tooltip: "Source image crop's quality",
        displayEmpty: true,
        defaultValue: 'good',
        value: 'good',
        items: [
          {
            name: 'good',
            value: 'good'
          },
          {
            name: 'bad',
            value: 'bad'
          }
        ]
      },
      // {
      //   name: 'matched',
      //   type: 'selectSingle',
      //   label: 'Matched',
      //   tooltip: 'Matched objects',
      //   displayEmpty: true,
      //   defaultValue: '',
      //   value: '',
      //   items: [
      //     {
      //       name: 'true',
      //       value: true
      //     },
      //     {
      //       name: 'false',
      //       value: false
      //     }
      //   ]
      // },
      // {
      //   name: 'is_reference',
      //   type: 'selectSingle',
      //   label: 'References',
      //   tooltip: 'References objects',
      //   displayEmpty: true,
      //   defaultValue: '',
      //   value: '',
      //   items: [
      //     {
      //       name: 'true',
      //       value: true
      //     },
      //     {
      //       name: 'false',
      //       value: false
      //     }
      //   ]
      // }
    ]

    switch (objectType) {
      case 'face':
        return face;
      case 'vehicle':
        return vehicle
      case 'person':
        return person
    }

    //
    // const fields = data.map((filter) => {
    //   const mapped = ({
    //     name: filter.name,
    //     type: filter.type,
    //     label: filter.rendered_name,
    //     tooltip: filter.hint,
    //     displayEmpty: true, // +++
    //     defaultValue: filter.default_value || FIELD_DEFAULT_VALUE[filter.type]?.value, // +++
    //     value: filter.applied_value || filter.default_value || FIELD_DEFAULT_VALUE[filter.type]?.value, // +++
    //   })
    //
    //   if (filter.values) {
    //     mapped.items = [
    //       ...filter?.values.map((item) => ({ name: item.toString(), value: item }))
    //     ];
    //   } // +++
    //
    //   if (filter.name === 'name') {
    //     mapped.type = FIELD_TYPES.TEXT_VARIANT;
    //     mapped.value = 'zzzzzz';
    //     mapped.items = [
    //       { value: '*', name: 'Only filled' },
    //       { value: '', name: 'Specific name' }
    //     ];
    //   }
    //
    //   return mapped;
    // });
    //
    // console.log('FIELDS', fields)
    //
    // return fields
  }),
});

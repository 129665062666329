// @ts-nocheck
import api from 'app/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clearEmptyValues } from 'v2/utils/functions';
import useBasicQuery from './useBasicQuery';

const QUERY_KEYS = ['watchLists'];

const useWatchListsQuery = () => {
  const queryClient = useQueryClient();

  const watchListsQuery = useBasicQuery({
    queryKey: QUERY_KEYS,
    queryFn: async () => {
      const result = await api.post('/watchlist-manager/v1/company-watchlists', {
        pagination: {
          pgoffset: 0,
          pgsize: -1,
        }
      });

      return result.data.items;
    }
  });

  const addWatchLists = useMutation({
    mutationFn: async (data) => {
      const result = await api.post('/watchlist-manager/v1/watchlists', clearEmptyValues(data));
      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  });

  const editWatchLists = useMutation({
    mutationFn: async ([id, data]) => {
      const result = await api.patch(`/watchlist-manager/v1/watchlists/${id}`, clearEmptyValues(data));
      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  });

  const deleteWatchLists = useMutation({
    mutationFn: async (id) => {
      const result = await api.delete(`/watchlist-manager/v1/watchlists/${id}`);
      return result.data;
    },
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS),
  });

  return {
    watchListsQuery,
    addWatchLists,
    editWatchLists,
    deleteWatchLists
  }
};

export default useWatchListsQuery;

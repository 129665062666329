// @ts-nocheck

import { clearEmptyValues } from 'v2/utils/functions';
import companyUsers from '../reducers/companyUsers';
import companies from '../reducers/companies';
import api from '../../../app/api';
import companiesTypes from '../reducers/companiesTypes';

const companiesHandlers = (store) => ({
  fetch: () => store.dispatch(companies.dataFetch()),
  types: () => store.dispatch(companiesTypes.dataFetch()),
  createCompany: (data) => api.post('/auth-manager/v2/company/', clearEmptyValues(data)).then((response) => {
    store.dispatch(companies.dataFetch())
    return response.data;
  }),
  companyUsers: (id) => store.dispatch(companyUsers.dataFetch(id)),
  createUser: (id, data) => api.post('/auth-manager/v2/users/', clearEmptyValues(data)).then((response) => {
    store.dispatch(companyUsers.dataFetch(id));
    return response.data;
  }),
  deleteUser: (userId, companyId) => api.delete(`/auth-manager/v2/company/${companyId}/users/${userId}`).then((response) => {
    store.dispatch(companyUsers.dataFetch(companyId));
    return response.data;
  }),
});

export default companiesHandlers;

// @ts-nocheck

import React, { useState } from 'react';
import {
  Typography, Dialog, Grid, Button, CardMedia, Box, Paper, Avatar, Divider
} from '@material-ui/core';

import api from 'app/api';
import { useAppStore } from 'AppStore';
import { useSnackbar } from 'notistack';
import { handlers } from 'v2/redux/store';

type SelectAvatarDialogProps = {
  isOpen: boolean;
  handleModel: () => void;
};

const SelectAvatarDialog = ({
  isOpen,
  handleModel,
}: SelectAvatarDialogProps) => {
  const [file, setFile] = useState<Blob>();
  const [imageURL, setImageURL] = useState<string>();
  const [VALUES, HANDLERS] = useAppStore();
  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    setFile(e.target.files[0]);

    const objectUrl = URL.createObjectURL(file)
    e.target.value = null;
    setImageURL(objectUrl);
  };

  const handleCloseClick = () => {
    setFile(null);
    setImageURL(null);
  };

  const handleUploadClick = () => {
    const formData = new FormData();
    formData.append('file', file as Blob);
    api.post('/auth-manager/user-photo/', formData).then(() => {
      HANDLERS.setIsImageChange(!VALUES.isImageChange);
      handlers.user.fetch()
      enqueueSnackbar('File uploaded successfully', { variant: 'success' })
    })
      .finally(() => {
        handleModel();
      })
  };

  return (
    <Dialog
      open={isOpen}
      onExited={handleCloseClick}
      onClose={handleModel}
      maxWidth="xs"
      fullWidth
    >
      <Paper variant="outlined">
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Typography align="left">
            Select User Avatar
          </Typography>
        </Box>
        <Divider variant="fullWidth" />
        <Box p={2} width={1}>
          <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs>
              <Box borderRadius="50%" overflow="hidden" width={256} height={256} alignItems="center" justifyContent="center" display="flex">
                {imageURL || VALUES.currentUser?.photo_url
                  ? (
                    <CardMedia
                      component="img"
                      image={imageURL || VALUES.currentUser?.photo_url}
                    />
                  )
                  : <Avatar className="MuiAvatar-profile" />}
              </Box>
            </Grid>
            <Grid item xs>
              <label htmlFor="upload-photo">
                <input
                  style={{ display: 'none' }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={handleFileUpload}
                />
                <Button
                  aria-label="upload"
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  Select file
                </Button>
              </label>
            </Grid>
          </Grid>
        </Box>
        <Divider variant="fullWidth" />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Button
            aria-label="cancel"
            variant="outlined"
            color="primary"
            onClick={handleModel}
          >
            Cancel
          </Button>
          <Button
            aria-label="upload"
            variant="contained"
            color="primary"
            onClick={handleUploadClick}
            disabled={!file}
          >
            Upload
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default SelectAvatarDialog;

// @ts-nocheck

import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, IconButton, MenuItem, TextField, Tooltip
} from '@material-ui/core';
import { mergeRules } from 'v2/utils/functions';
import ClearIcon from '@material-ui/icons/Clear';
import useChangedState from '../../../hooks/useChangedState';

const SelectMultiple = (props) => {
  const [_value, _setValue] = useChangedState(props.value);
  const [open, setOpen] = useState(false);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={mergeRules(props.rules)}
      defaultValue={props.value || []}
      render={(controller) => (
        <Tooltip arrow title={props.tooltip || ''} enterDelay={1000} enterNextDelay={500}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            select
            label={props.label}
            error={!!controller.formState?.errors[props.name]?.message}
            helperText={controller.formState?.errors[props.name]?.message}
            InputLabelProps={{ shrink: true }}
            SelectProps={{
              multiple: true,
              open,
              displayEmpty: props.display_empty,
              onClose: () => {
                controller.field.onChange(_value);
                setOpen(false);
              },
              onClick: () => !open && setOpen(true),
              renderValue: (selected) => (selected.length > 0 ? selected.join(', ') : 'All'),
              onChange: (e, element) => {
                if (element.props.value === '') {
                  _setValue([]);
                  setOpen(false);
                  controller.field.onChange([]);
                } else {
                  _setValue(e.target.value.filter((item) => !!item));
                }
              },
              endAdornment: controller.field.value?.length > 0 && (
                <Box right={24} position="absolute">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      _setValue([]);
                      controller.field.onChange([]);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              ),
            }}
            {...controller.field}
            value={_value}
          >
            {props.display_empty && (
              <MenuItem value="">
                All
              </MenuItem>
            )}
            {props.items.map((item) => (
              <MenuItem
                value={item.value}
                key={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Tooltip>
      )}
    />
  );
};

export default SelectMultiple;

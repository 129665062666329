// @ts-nocheck

import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, createStyles, Dialog, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import api from 'app/api';
import SelectInputWrapper from 'components/SelectInput';
import { TextInputWrapper } from 'components/TextInput';
import { WIDGET_TYPES } from 'utils/constants';
import { vars } from 'Theme';
import { validationCheck } from 'v2/utils/functions';
import { FIELD_RULES } from 'v2/constants/fieldValidators';
import OutputTextFields from './OutputTextFields';
import CustomTabs from './CustomTabs';
import { FIELD_WIDGET_TYPES, mapFields } from '../../../description/FiltersCategories';
import { DIALOG_IMAGES } from '../../../utils/dashboardDialog';

const useStyles = makeStyles((theme) => createStyles({
  header: {
    padding: `${vars.spacing}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${vars.primaryBorderColor}`,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  headerIcons: {
    // color: vars.primary,
    // padding: '0',
  },
  popupTitle: {
    fontSize: 18,
    // textTransform: 'none',
  },
  blockHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  dialog: {
    minWidth: 770,
  },
  img: {
    width: 352,
    height: 200,
    objectFit: 'none',
    marginRight: `${vars.spacing}px`,
    // backgroundColor: vars.dark8,
    borderRadius: vars.primaryBorderRadius,
    // padding: '24px 45px',
  },
  input: {
    backgroundColor: vars.backgroundInput,
    border: `1px solid ${vars.primaryBorderColor}`,
    width: 344,
  },
  inputLabel: {
    fontSize: 14,
    marginBottom: 8,
  },
  root: {
    // background: vars.primaryBackground,
    maxWidth: '782px',
    border: `1px solid ${vars.primaryBorderColor}`,
  },
  dialogContent: {
    padding: `${vars.spacing}px`,
    // minHeight: '70%',
  },
  button: {
    display: 'block',
    margin: 'auto',
  },
  wrapperButton: {
    width: '100%',
    marginTop: 40,
    marginBottom: 20,
  },
  popupName: {
    fontSize: 18,
    // textTransform: 'none',
    marginBottom: `${vars.spacing}px`,
    marginRight: `${vars.spacing}px`,
  },
  valueLabel: {
    fontSize: 14,
    top: -22,
    '& *': {
      background: 'transparent',
      color: 'white',
    },
  },
  wrapperInput: {
    marginBottom: 24,
  },
  rooContainer: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  divider: {
    backgroundColor: vars.primaryBorderColor,
  },
  bottomContainer: {
    padding: `${vars.spacing}px`,
  },
}),);

type TypesDiagram = 'hist_chart' | 'line_chart';

const selectWidget = (widgetType, filed) => {
  const type = WIDGET_TYPES[widgetType];
  return typeof type === 'object' ? type[filed] : type;
};

const ListDialog = ({
  drawerNumber,
  widgetsStock,
  typeDrawer,
  setTypeDrawer,
  setIsFirstState,
  submitHandler,
  indexWidget,
}) => {
  const dialogData = useMemo(() => widgetsStock[drawerNumber], [widgetsStock, drawerNumber]);
  const [widgetName, setWidgetName] = useState(dialogData.data.name);
  const [widgetTitleValid, setWidgetTitleValid] = useState(true);
  const [widgetTitleErrorText, setWidgetTitleErrorText] = useState('');
  const [typesDiagram, setTypesDiagram] = useState(widgetsStock[drawerNumber].data.type);
  const [filters, setFilters] = useState({});
  const [widgetTypes, setWidgetTypes] = useState('');
  const [errors, setErrors] = useState({});
  const [fieldsWidget, setFieldsWidget] = useState({ filters: [] });
  const [waitCreating, setWaitCreating] = useState(false);
  const widgetRootType = widgetsStock[drawerNumber].typeWidget;

  const handleSelectBaseChange = useCallback((event) => {
    setWidgetTypes(event.target.value);
  }, []);

  useEffect(() => {
    if (widgetTypes) {
      api.get<any>(`/object-manager/search/filters/${widgetTypes}`)
        .then((response: any) => setFieldsWidget(response.data));
    }
  }, [widgetTypes]);

  const handleFilterValueChange = (newFilter) => {
    const newFilters = {
      ...filters,
      ...newFilter
    };

    setFilters(newFilters);
  };

  const handleSelectTab = (tab) => {
    setTypesDiagram(tab);
  };

  const handleAddClick = () => {
    const outFilters = {
      object_type: widgetTypes
    }

    fieldsWidget.filters
      .forEach(({ name, default_value }) => {
        if (default_value) {
          outFilters[name] = default_value
        }
      })

    Object.entries(filters)
      .forEach(([field, value]) => {
        outFilters[field] = value;
      });

    const outArrayFilters = Object
      .entries(outFilters)
      .map(([field, value]) => (Array.isArray(value) ? [field, value.join('-')] : [field, value]))
      .map(([field, value]) => `${field}:${value}`)

    const type = typeDrawer === 'pagination'
      ? selectWidget(dialogData.typeWidget, typesDiagram)
      : selectWidget(dialogData.typeWidget, widgetTypes)

    const data = {
      ...dialogData.data,
      filters: outArrayFilters,
      name: widgetName,
      type,
    };

    setIsFirstState(true);
    submitHandler(indexWidget, dialogData.component, data);

    // setWaitCreating(true);
    // addComponent(indexWidget, dialogData.component, data).finally(() => {
    //   setWaitCreating(false);
    // });
  };

  const onTitleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setWidgetName(event.target.value.trimLeft())
    const { error, success } = validationCheck(event.target.value.trimLeft(), [FIELD_RULES.REQUIRED, FIELD_RULES.MAXLENGTH_100])
    setWidgetTitleValid(success)
    setWidgetTitleErrorText(error)
  }, []);

  const Image = DIALOG_IMAGES[typesDiagram];

  const defaultFilters = fieldsWidget?.filters.filter(({ name }) => name !== 'matched' && name !== 'is_reference');
  const pieFaceFilters = defaultFilters?.filter(({ name }) => name !== 'person_gender');
  const pieVehicleFilters = defaultFilters?.filter(({ name }) => name !== 'vehicle_type' && name !== 'vehicle_manufacturer' && name !== 'vehicle_model')

  const chartFilters = (widgetType, objectType) => {
    if (widgetType === 'normal') {
      switch (objectType) {
        case 'face':
          return pieFaceFilters;
        case 'vehicle':
          return pieVehicleFilters;
        default:
          return defaultFilters
      }
    } else {
      return defaultFilters
    }
  }

  return (
    <Dialog
      maxWidth="xl"
      open
      onClose={() => setIsFirstState(true)}
    >
      <Paper variant="outlined">
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography align="left">
            Create widget
          </Typography>
        </Box>
        <Divider variant="fullWidth" />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Grid container spacing={2}>
            <Grid item>
              <Grid container spacing={2} direction="column">
                {typeDrawer === 'pagination'
                      && (
                      <Grid item>
                        <CustomTabs
                          typeWidget={typesDiagram}
                          handleClick={handleSelectTab}
                        />
                      </Grid>
                      )}
                <Grid item>
                  <Box width={1} height="100px" p={2} display="flex" alignItems="center" justifyContent="center">
                    <Image />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Box>
                <Grid container spacing={2} direction="column">
                  <Grid item xs>
                    <TextInputWrapper
                      label="Widget title"
                      name="widget-title"
                      value={widgetName}
                      onChange={onTitleChange}
                      error={!widgetTitleValid}
                      helperText={widgetTitleErrorText}
                    />
                  </Grid>
                  <Grid item xs>
                    <SelectInputWrapper
                      value={widgetTypes}
                      onChange={handleSelectBaseChange}
                      label="Select base"
                      items={mapFields[widgetRootType][FIELD_WIDGET_TYPES]}
                      name={FIELD_WIDGET_TYPES}
                    />
                  </Grid>
                  {typeDrawer
                    && chartFilters(typeDrawer, widgetTypes)?.map((element, index) => (
                      <Grid item xs key={index}>
                        <OutputTextFields
                          textField={element}
                          handleFilterValueChange={handleFilterValueChange}
                          value={filters[element.name]}
                          object_type={widgetTypes}
                          typeWidget={widgetRootType}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider variant="fullWidth" />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsFirstState(true)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setTypeDrawer('default')}
              >
                CHANGE TYPE
              </Button>
            </Grid>
            <Grid item xs>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddClick}
                  disabled={!widgetTypes || waitCreating || !widgetTitleValid || Object.keys(errors).length > 0}
                >
                  Add widget
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default React.memo(ListDialog);

// @ts-nocheck

import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { WIDGET_TYPES } from '../../../utils/constants';

const CustomTabs = ({ handleClick, typeWidget }) => (
  <Grid container spacing={2}>
    <Grid item>
      <Button
        onClick={() => handleClick(WIDGET_TYPES.chart.hist_chart)}
        variant={typeWidget === WIDGET_TYPES.chart.hist_chart ? 'contained' : 'outlined'}
        color="primary"
      >
        Bar Chart
      </Button>
    </Grid>
    <Grid item>
      <Button
        onClick={() => handleClick(WIDGET_TYPES.chart.line_chart)}
        variant={typeWidget === WIDGET_TYPES.chart.line_chart ? 'contained' : 'outlined'}
        color="primary"
      >
        Line Chart
      </Button>
    </Grid>
  </Grid>
)

export default CustomTabs;

// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tagsGetById = DataReducer({
  name: 'tagsGetById',
  dataProvider: async (tagID) => {
    const result = await api.get(`/object-manager/v1/tags/${tagID}`)
    return result.data;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Fetching tags',
    [FETCH_STATE.REJECTED]: 'Error fetch tags',
  }
});
export default tagsGetById;

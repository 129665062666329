// @ts-nocheck

import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Box, Button, createStyles, makeStyles, TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import BarChart from '@material-ui/icons/BarChart';
import ShowChart from '@material-ui/icons/ShowChart';
import ShareIcon from '@material-ui/icons/Share';
import FilterList from '@material-ui/icons/FilterList';
import TuneIcon from '@material-ui/icons/Tune';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { WidgetFilters } from 'pages/dashboard/types';
import SettingsDialog from 'components/Dashboard/Dialogs/SettingsDialog';
import { vars } from 'Theme';
import api from 'app/api';
import ChangeWidgetTitle from 'v2/components/ChangeWidgetTitleNew';
import { WIDGET_TYPES } from 'utils/constants';
import { useSnackbar } from 'notistack';
import useUserCompanyQuery from 'v2/hooks/queries/useUserCompanyQuery';
import debounce from 'lodash/debounce';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import AutoRefresh from 'v2/widgetActions/AutoRefresh/AutoRefresh';
import AutoRefreshInfo from 'v2/widgetActions/AutoRefreshInfo/AutoRefreshInfo';
import { prepareGetQuery } from 'utils/chart';
import DashboardMenu from './Menu/DashboardMenu';
import WidgetLocationSelector from '../WidgetLocationSelector';
import { parseStringFilters } from './DashboardChart';
import Dialog from '../common/BasicDialog';
import { useModel } from '../../v2/providers/ModelProvider';
import usePermissionsQuery, { PERMISSIONS } from '../../v2/hooks/queries/usePermissionsQuery';

const useStyles = makeStyles((theme) => createStyles({
  maxHeight: {
    // height: '550px',
    // maxHeight: '550px',
  },
  container: {
    padding: '2rem',
    width: '100%',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      padding: '.75rem',
      marginTop: '70px',
    },
  },
  papper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: vars.primaryBackground,
    // border: `1px solid ${vars.primaryBorderColor}`,
  },
  papperFeed: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: vars.primaryBackground,
    // border: `1px solid ${vars.primaryBorderColor}`,
    overflow: 'hidden',
  },
  papperStat: {
    height: '100%',
    // backgroundColor: vars.backgroundInput,
    border: `1px solid ${vars.primaryBorderColor}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: `${vars.spacing}px`,
    // borderBottom: `1px solid ${vars.primaryBorderColor}`,
    width: '100%',
    zIndex: 10,
  },
  headerRight: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  blockHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcons: {
    padding: '0',
    marginLeft: '10px',
  },
  headerTabIcons: {
    marginLeft: '10px',
  },
  popover: {
    pointerEvents: 'none',
  },
  clipboardPopoverPaper: {
    padding: '.75rem',
    fontSize: '12px',
    color: vars.primary,
    // backgroundColor: vars.dark4,
    maxWidth: '180px',
    textAlign: 'center',
  },
  widgetHead: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  widgetContent: {
    boxSizing: 'border-box',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '560px',
  },
  formRowButton: {
    whiteSpace: 'nowrap',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    textAlign: 'left',
    justifyContent: 'center',
  },
  formRowInput: {
    width: '100%',
  },
  noData: {
    fontSize: 32,
    color: '#666666',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '680px',
  },
}));

type DashboardWidgetType = {
  id: string,
  size: number,
  type: string,
  title: string,
  showComponent: boolean,
  filters: WidgetFilters,
  index: number,
  resizeWidget: () => void,
  removeComponent: (index: number) => void,
  updateLayout: () => void,
  Component: React.FunctionComponent<any>,
  isRealtime: boolean,
  websocket_url: string,
  timeslice: string,
  isResizable: boolean,
  other?: any,
  state: any,
};

const DashboardWidget: React.FunctionComponent<DashboardWidgetType> = ({
  editable,
  size,
  id,
  type,
  title,
  filters,
  state,
  index,
  showComponent,
  resizeWidget,
  removeComponent,
  Component,
  isRealtime,
  websocket_url,
  timeslice,
  isResizable,
  updateLayout,
  ...other
}) => {
  const classes = useStyles();
  const { scenarios } = useModel();
  const { userCompany } = useUserCompanyQuery();
  const { permissions } = usePermissionsQuery();

  const [isRealtimeComponent, setIsRealtimeComponent] = useState(isRealtime);
  const [selectedChartType, setSelectedTypeChart] = useState(type);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [filtersObject, setFiltersObject] = useState(parseStringFilters(filters));
  const [dialogOptions, setDialogOptions] = useState();
  const [lastUpdateTime, setLastUpdateTime] = useState();
  const [isFetching, setIsFetching] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [openCamerasSelector, setOpenCamerasSelector] = useState(false);
  const { showBackdrop, hideBackdrop } = useBackdrop();

  useEffect(() => {
    setIsRealtimeComponent(isRealtime);
  }, [isRealtime]);

  const [selectedIds, setSelectedIds] = useState({
    cameras: filtersObject.selectedIds && Array.isArray(filtersObject.selectedIds.cameras) ? filtersObject.selectedIds.cameras : [],
    locations: filtersObject.selectedIds && Array.isArray(filtersObject.selectedIds.locations) ? filtersObject.selectedIds.locations : [],
  });

  const makeURL = (selectedIds, filtersObject) => [
    ...selectedIds.cameras.map((item: string) => `filters=camera:${item}`),
    ...Object.entries(filtersObject).filter(([key, value]) => !!value && key !== 'selectedIds').map(([key, value]) => `filters=${key}:${value}`),
  ].join('&');

  const url = new URL(process.env.REACT_APP_API_URL);
  const token = localStorage.getItem('access-token');

  const socketURL = useMemo(() => prepareGetQuery(`wss://${url.host}/notification-manager/feed_ws?token=${token}&${makeURL(selectedIds, parseStringFilters(filters))}`), [selectedIds, filters]);
  const httpURL = useMemo(() => prepareGetQuery(`/object-manager/search/?pgsize=32&${makeURL(selectedIds, parseStringFilters(filters))}`), [selectedIds, filters]);

  useEffect(() => {
    if (type !== selectedChartType) {
      setSelectedTypeChart(type);
    }
  }, [type]);

  useEffect(() => {
    setFiltersObject(parseStringFilters(filters));
  }, [filters, setFiltersObject]);

  const onSettingsToggle = (value: boolean) => () => {
    setSettingsOpen(value);
  };

  const debouncedPatch = debounce((newState = {}, loader) => {
    loader && showBackdrop('Update widget');

    return api
      .patch(`/layout-manager/widgets/${id}`, { state: { ...state, ...newState } })
      .then(() => scenarios.widgets.init())
      .catch(() => {
      })
      .finally(() => {
        loader && hideBackdrop();
      });
  }, 500);

  const shareForm = ({ initialValue }) => (
    <Box display="flex" flexDirection="column" width="450px">
      <TextField
        className={classes.formRowInput}
        size="small"
        label="Link"
        variant="outlined"
        value={initialValue}
        readOnly
        fullWidth
        multiline
      />
      <Box mt={2} display="flex" justifyContent="flex-end">
        {
          permissions[PERMISSIONS.CLIPBOARD_WRITE] ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                enqueueSnackbar('Link copied', { variant: 'success' });
                navigator.clipboard.writeText(initialValue);
                closeDialog && closeDialog();
              }}
            >
              Copy link
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                closeDialog && closeDialog();
              }}
            >
              Close
            </Button>
          )
        }
      </Box>
    </Box>
  );

  const closeDialog = () => {
    setDialogOptions();
  };

  const handleRealtimeChange = useCallback((realtime: boolean) => {
    api.patch(`/layout-manager/widgets/${id}`, {
      online: realtime,
    }).then(() => {
      setIsRealtimeComponent(realtime);
    });
  }, [id]);

  const menuItems = useMemo(() => [
    {
      text: 'Share',
      visible: true,
      Icon: ShareIcon,
      onClick: () => {
        scenarios.widgets.share(id, userCompany.id).then((response) => {
          setDialogOptions({
            title: 'Sharable link',
            initialValue: response,
            hideButtons: true,
            Component: shareForm,
            closeDialog,
          });
        });
      },
    },
    {
      text: 'Settings',
      visible: true,
      Icon: SettingsIcon,
      onClick: onSettingsToggle(true),
    },
    {
      text: 'Delete',
      visible: true,
      Icon: CloseIcon,
      onClick: () => {
        setDialogOptions({
          title: 'Delete widget',
          Component: () => 'Do you really want to delete this widget?',
          closeDialog,
          onChange: () => {
            removeComponent();
          },
        });
      },
    },
  ], [removeComponent, resizeWidget, size]);

  const showLabels = selectedChartType === WIDGET_TYPES.chart.hist_chart;
  const showLegend = type === 'hist_chart' || type === 'line_chart' || type === 'pie_chart';
  const showTimePicker = type === 'hist_chart' || type === 'line_chart' || type === 'pie_chart' || type === 'value';
  const showAdjustMenu = showLabels || showLegend || showTimePicker;

  const menuChartItems = useMemo(() => [{
    text: 'Column label',
    visible: showLabels,
    closeOnClick: false,
    checkBox: true,
    checked: state.hasOwnProperty('showStackLabels') ? state.showStackLabels : true,
    onClick: (event) => {
      debouncedPatch({ showStackLabels: event.target.checked }, true);
    },
  },
  {
    text: 'Legend',
    visible: showLegend,
    closeOnClick: false,
    checkBox: true,
    checked: state.hasOwnProperty('showLegend') ? state.showLegend : true,
    onClick: (event) => {
      debouncedPatch({ showLegend: event.target.checked }, true);
    },
  },
  {
    text: 'Time interval',
    visible: showTimePicker,
    closeOnClick: false,
    checkBox: true,
    checked: state.hasOwnProperty('showTimeSlice') ? state.showTimeSlice : true,
    onClick: (event) => {
      debouncedPatch({ showTimeSlice: event.target.checked }, true);
    },
  }], [state, selectedChartType]);

  const handleTypeWidgetChange = (newTypeWidget) => () => {
    setSelectedTypeChart(newTypeWidget);

    api.patch(`/layout-manager/widgets/${id}`, {
      type: newTypeWidget,
    });
  };

  const showChart = type === 'hist_chart' || type === 'line_chart';

  return (
    <>
      <Box
        className="UIWidget"
        width={1}
        height={1}
        display="flex"
        flexDirection="column"
        borderRadius="borderRadius"
        border={1}
        bgcolor="background.main"
        borderColor="background.primaryBorder"
      >
        <Box width={1} display="flex" overflow="auto" flexShrink={0}>
          <Box
            width={1}
            p={2}
            overflow="auto"
            display="flex"
            className="UIDraggable"
            alignItems="center"
            justifyContent="space-between"
          >
            <ChangeWidgetTitle
              title={title}
              id={id}
              updateLayout={updateLayout}
            />
            <Box className="UIUnDraggable" display="flex" justifyContent="flex-end" wrap="nowrap" gridGap={4}>
              {type !== 'value'
                && (
                  <AutoRefresh
                    isRealtime={isRealtimeComponent}
                    handleRealtimeChange={handleRealtimeChange}
                    isFetching={isFetching}
                    lastUpdateTime={lastUpdateTime}
                  />
                )}
              {type === 'value' && (
                <AutoRefreshInfo
                  isFetching={isFetching}
                  lastUpdateTime={lastUpdateTime}
                />
              )}
              <Box
                className={selectedIds.cameras.length > 0 || selectedIds.locations.length > 0 ? 'UIButtonsWidgetActive' : 'UIButtonsWidgetInactive'}
              >
                <Tooltip title="Filter by cameras">
                  <IconButton
                    onClick={() => setOpenCamerasSelector(true)}
                  >
                    <FilterList />
                  </IconButton>
                </Tooltip>
              </Box>
              {showChart && (
                <>
                  <Box
                    className={selectedChartType === WIDGET_TYPES.chart.hist_chart ? 'UIButtonsWidgetActive' : 'UIButtonsWidgetInactive'}
                  >
                    <Tooltip title="Bar chart">
                      <IconButton
                        onClick={handleTypeWidgetChange(WIDGET_TYPES.chart.hist_chart)}
                      >
                        <BarChart />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    className={selectedChartType === WIDGET_TYPES.chart.line_chart ? 'UIButtonsWidgetActive' : 'UIButtonsWidgetInactive'}
                  >
                    <Tooltip title="Line chart">
                      <IconButton
                        onClick={handleTypeWidgetChange(WIDGET_TYPES.chart.line_chart)}
                      >
                        <ShowChart />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
              {showAdjustMenu && (
                <DashboardMenu
                  title="Actions"
                  menuItems={menuChartItems}
                  MenuIcon={TuneIcon}
                  className="UIButtonsWidgetInactive"
                />
              )}
              <DashboardMenu title="More" menuItems={menuItems} className="UIButtonsWidgetInactive" />
            </Box>
          </Box>
        </Box>
        <Box width={1} height={1} display="flex" flexDirection="column" overflow="hidden" className="UIWidget">
          <Component
            onChangeRealtime={handleRealtimeChange}
            isPieChart={type === 'pie_chart'}
            selectedChartType={selectedChartType}
            height={type === 'hist_chart' || type === 'line_chart' || type === 'pie_chart' ? '350px' : undefined}
            id={id}
            type={type}
            size={size}
            title={title}
            isRealtime={isRealtimeComponent}
            filters={filters}
            timeslice={timeslice}
            isResizable={isResizable}
            setOpenCamerasSelector={setOpenCamerasSelector}
            openCamerasSelector={openCamerasSelector}
            {...other}
            objectType={filtersObject.object_type}
            socketURL={socketURL}
            httpURL={httpURL}
            selectedIds={selectedIds}
            filtersObject={filtersObject}
            setFiltersObject={setFiltersObject}
            state={state}
            cameras={selectedIds.cameras}
            liveFeed
            updateLayout={updateLayout}
            debouncedPatch={debouncedPatch}
            setLastUpdateTime={setLastUpdateTime}
            setIsFetching={setIsFetching}
          />
        </Box>
      </Box>
      {openCamerasSelector && (
        <WidgetLocationSelector
          id={id}
          filters={filters}
          selected={selectedIds}
          onChange={(ids) => setSelectedIds({ ...ids })}
          handleClose={() => setOpenCamerasSelector(false)}
          open={openCamerasSelector}
          updateLayout={updateLayout}
        />
      )}
      {isSettingsOpen && (
        <SettingsDialog
          widgetId={id}
          type={selectedChartType}
          title={title}
          filters={filters}
          isOpen={isSettingsOpen}
          onClose={onSettingsToggle(false)}
          updateLayout={updateLayout}
          filtersObject={filtersObject}
          setFiltersObject={setFiltersObject}
          selectedIds={selectedIds}
          setSelectedTypeChart={setSelectedTypeChart}
        />
      )}
      {dialogOptions && <Dialog isOpen {...dialogOptions} />}
    </>
  );
};

export default DashboardWidget;

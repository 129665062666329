// @ts-nocheck

import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIRangeSlider from 'v2/ui-kit/UIRangeSlider/UIRangeSlider';
import UIZoomInIcon from 'v2/icons/UIZoomInIcon';
import UIZoomOutIcon from 'v2/icons/UIZoomOutIcon';
import clsx from 'clsx';
import { Tooltip, TooltipContent, TooltipTrigger } from 'v2/components/basic/BasicPopoverNew';

const ZoomSlider = (props) => (
  <div className={clsx('flex items-center justify-center', props.className)}>
    <UIWidgetAction
      byHover
      tooltip="Zoom out"
      disabled={props.minScale === props.value}
      onClick={(e) => props.onZoomOut && props.onZoomOut(e)}
    >
      <UIZoomOutIcon />
    </UIWidgetAction>
    <Tooltip byHover>
      <TooltipTrigger>
        <div className="w-full flex mx-2">
          <UIRangeSlider
            className="sm"
            value={[props.value]}
            onUpdate={(value, activeHandler) => props.onUpdate && props.onUpdate(value[0], activeHandler)}
            onChange={(value) => props.onChange && props.onChange(value[0])}
            step={props.stepScale}
            range={[props.minScale, props.maxScale]}
          />
        </div>
      </TooltipTrigger>
      <TooltipContent arrow>
        <div className="p-2">
          {props.tooltipFormat(props.value)}
        </div>
      </TooltipContent>
    </Tooltip>
    <UIWidgetAction
      byHover
      tooltip="Zoom in"
      onClick={(e) => props.onZoomIn && props.onZoomIn(e)}
      disabled={props.maxScale === props.value}
    >
      <UIZoomInIcon />
    </UIWidgetAction>
  </div>
);

export default ZoomSlider;

// @ts-nocheck
import React from 'react';
import UIWidgetAction from 'v2/ui-kit/UIWidgetAction/UIWidgetAction';
import UIReportIcon from 'v2/icons/UIReportIcon';

const ToolTip = () => (
  <div className="tooltip w-60">
    <p>
      Objects categorized as "bad" are determined by characteristics found in their metadata, including:
    </p>
    <div className="h-1" />
    <ul className="indent-2 text-neutral-300 list-disc list-inside">
      <li>blur</li>
      <li>inadequate resolution</li>
      <li>unusual motion</li>
      <li>noise</li>
      <li>low light capture</li>
      <li>unstable footage</li>
    </ul>
  </div>
);

const BadObjectsInfo = ({ object }) => {
  const { meta } = object;

  return meta.bad_quality === 'bad' && (
    <UIWidgetAction
      byHover
      tooltip={<ToolTip />}
    >
      <UIReportIcon />
    </UIWidgetAction>
  );
};
export default BadObjectsInfo;

// @ts-nocheck

import axios from 'axios';
import DataReducer from '../DataReducer';

const terms = DataReducer({
  name: 'terms',
  dataProvider: async () => {
    const result = await axios.get('/content/terms.json');
    return result.data;
  },
});

export default terms;

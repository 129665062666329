// @ts-nocheck

import React from 'react';
import {
  Box, Button, IconButton, Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserAvatar from 'v2/components/userAvatar/UserAvatar';
import useMobile from 'v2/hooks/useMobile';
import { DRAWERS, useDrawer } from 'v2/providers/DrawerProvider';
import LeftMenu from './LeftMenu';

const Header = (props) => {
  const history = useHistory();
  const { showDrawer } = useDrawer();
  const { t } = useTranslation(['layout']);
  const { isMobile } = useMobile();

  return (
    <Box width={1} display="flex" bgcolor="background.main">
      <Box py={2} px={2} pl={2} width={1} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          {isMobile && <LeftMenu />}
          {props.hasBackIcon && (
            <Box mr={2}>
              <IconButton
                onClick={() => history.goBack()}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Box>
          )}
          <Typography variant="h6">{props.title}</Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          {isMobile ? (
            <IconButton
              color="primary"
              component="span"
              onClick={() => {
                showDrawer(DRAWERS.ADVANCED_SEARCH)
              }}
            >
              <SearchIcon fontSize="large" />
            </IconButton>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                showDrawer(DRAWERS.ADVANCED_SEARCH)
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box mr={2} display="flex" alignItems="center" justifyContent="center">
                  <SearchIcon />
                </Box>
                {t('search')}
              </Box>
            </Button>
          )}
          <Box ml={2}>
            <UserAvatar />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;

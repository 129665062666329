// @ts-nocheck

import { clearEmptyValues } from 'v2/utils/functions';
import api from '../../../app/api';
import clusterNotifications from '../reducers/clusterNotifications';

const clusterNotificationsHandlers = (store) => ({
  fetch: () => store.dispatch(clusterNotifications.dataFetch()),
  subscribe: (data) => api.post('/notification-manager/v1/watchlist/', clearEmptyValues(data)).then((response) => {
    store.dispatch(clusterNotifications.dataFetch());
    return response.data;
  }),
  unsubscribe: (id) => api.delete(`/notification-manager/v1/watchlist/${id}`).then((response) => {
    store.dispatch(clusterNotifications.dataFetch());
    return response.data;
  }),
});

export default clusterNotificationsHandlers;

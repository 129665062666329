// @ts-nocheck

import user from '../reducers/user';
import userCompany from '../reducers/userCompany';
import api from '../../../app/api';
import userPatch from '../reducers/userPatch';
import { revertAll } from '../DataReducer';
import usersTypes from '../reducers/usersTypes';

const userHandlers = (store) => ({
  fetch: () => store.dispatch(user.dataFetch()),
  company: () => store.dispatch(userCompany.dataFetch()),
  types: () => store.dispatch(usersTypes.dataFetch()),
  switchCompany: (id, redirectUrl) => api.patch('/auth-manager/context/active-company/', { company: id })
    .then((response) => {
      localStorage.setItem('user-company', id);
      redirectUrl ? document.location = redirectUrl : window.location.reload();
    }),
  // editUser: (userId, updatedUser) => api.patch(`/auth-manager/users/${userId}`, updatedUser)
  //   .then((response) => {
  //     store.dispatch(user.dataFetch())
  //     return response
  //   }),
  patch: (id, payload) => store.dispatch(userPatch.dataFetch([id, payload])).then(() => store.dispatch(user.dataFetch())),
  changePassword: (updatedPassword) => api.post('/auth-manager/auth/change-password/', updatedPassword).then((response) => response.data),
  logout: async () => {
    localStorage.removeItem('user-company');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    document.location = '/';
  },
  clear: () => {
    store.dispatch(revertAll());
    localStorage.removeItem('user-company');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
  },
  changeAvatar: (formData) => api.post('/auth-manager/user-photo/', formData).then((response) => {
    store.dispatch(user.dataFetch())
    return response
  }),
  deleteAvatar: () => api.delete('/auth-manager/user-photo/').then((response) => {
    store.dispatch(user.dataFetch())
    return response
  }),
});

export default userHandlers;

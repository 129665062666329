// @ts-nocheck
import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const Image = ({ theme }) => (
  <svg width="100%" height="100%" viewBox="0 0 77 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9766 35H9.30469V7.60156L0.9375 10.4609V5.65625L14.25 0.757812H14.9766V35ZM49.1781 20.6328C49.1781 25.5391 48.2563 29.2422 46.4125 31.7422C44.5844 34.2266 41.7953 35.4688 38.0453 35.4688C34.3578 35.4688 31.5766 34.2578 29.7016 31.8359C27.8422 29.3984 26.8891 25.7969 26.8422 21.0312V15.125C26.8422 10.2188 27.7563 6.53906 29.5844 4.08594C31.4281 1.63281 34.2328 0.40625 37.9984 0.40625C41.7328 0.40625 44.5141 1.60156 46.3422 3.99219C48.1859 6.38281 49.1313 9.96094 49.1781 14.7266V20.6328ZM43.4828 14.2578C43.4828 11.0547 43.0453 8.71094 42.1703 7.22656C41.2953 5.72656 39.9047 4.97656 37.9984 4.97656C36.1391 4.97656 34.7719 5.6875 33.8969 7.10938C33.0219 8.51562 32.5609 10.7188 32.5141 13.7188V21.4531C32.5141 24.6406 32.9594 27.0156 33.85 28.5781C34.7406 30.1406 36.1391 30.9219 38.0453 30.9219C39.8734 30.9219 41.225 30.2031 42.1 28.7656C42.975 27.3125 43.4359 25.0469 43.4828 21.9688V14.2578ZM76.5594 20.6328C76.5594 25.5391 75.6375 29.2422 73.7938 31.7422C71.9656 34.2266 69.1766 35.4688 65.4266 35.4688C61.7391 35.4688 58.9578 34.2578 57.0828 31.8359C55.2234 29.3984 54.2703 25.7969 54.2234 21.0312V15.125C54.2234 10.2188 55.1375 6.53906 56.9656 4.08594C58.8094 1.63281 61.6141 0.40625 65.3797 0.40625C69.1141 0.40625 71.8953 1.60156 73.7234 3.99219C75.5672 6.38281 76.5125 9.96094 76.5594 14.7266V20.6328ZM70.8641 14.2578C70.8641 11.0547 70.4266 8.71094 69.5516 7.22656C68.6766 5.72656 67.2859 4.97656 65.3797 4.97656C63.5203 4.97656 62.1531 5.6875 61.2781 7.10938C60.4031 8.51562 59.9422 10.7188 59.8953 13.7188V21.4531C59.8953 24.6406 60.3406 27.0156 61.2313 28.5781C62.1219 30.1406 63.5203 30.9219 65.4266 30.9219C67.2547 30.9219 68.6063 30.2031 69.4813 28.7656C70.3563 27.3125 70.8172 25.0469 70.8641 21.9688V14.2578Z" fill={theme.palette.primary.main} />
  </svg>

);

export default withTheme(Image);

// @ts-nocheck

import React from 'react';
import { useDialog } from 'v2/providers/DialogProvider';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const MuiCloseButton = (props) => (
  <IconButton onClick={props.onClose}>
    <CloseIcon />
  </IconButton>
);

export const DialogTitle = (props) => {
  const { hideDialog } = useDialog();

  return (
    <MuiDialogTitle disableTypography>
      <Box p={2} width={1} display="flex" justifyContent="space-between" alignItems="center">
        {props.title && (
          <Typography noWrap>{props.title}</Typography>
        )}
        {props.child && <props.child />}
        {(props.icons || props.hasCloseIcon) && (
          <Box display="flex" alignItems="center" justifyContent="right">
            {(props.icons || []).map((item, index) => <Box key={index} item>{item}</Box>)}
            {props.hasCloseIcon && (
              <Box ml={2}>
                <MuiCloseButton onClose={props.onClose || hideDialog} />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {props.children}
      <Divider />
      {props.subTitle && (
        <Box p={2} width={1}>
          <Typography variant="subtitle2">{props.subTitle}</Typography>
        </Box>
      )}
    </MuiDialogTitle>
  );
};

export const DialogBody = (props) => (
  <MuiDialogContent {...props}>
    {props.children}
  </MuiDialogContent>
);

export const DialogActions = (props) => (
  <MuiDialogActions {...props}>
    <Box width={1}>
      <Divider />
      <Box p={2} width={1} display="flex" justifyContent="space-between" alignItems="center">
        {props.children}
      </Box>
    </Box>
  </MuiDialogActions>
);

const BasicDialog = ({
  Component, initialData, dialogProps,
}) => {
  const dialog = useDialog();
  const { open, hideDialog } = process.env.STORYBOOK === true
    ? { open: true, hideDialog: () => { } }
    : dialog;

  const V2 = open && (
    <div className="absolute left-0 top-0 z-50 w-full h-full bg-black/50 flex items-center justify-center">
      <Component
        DialogTitle={DialogTitle}
        DialogBody={DialogBody}
        DialogActions={DialogActions}
        {...initialData}
      />
    </div>
  );

  const V1 = (
    <Dialog
      open={open}
      fullWidth
      maxWidth={dialogProps.maxWidth || 'xs'}
      transitionDuration={1000}
      onClose={() => dialogProps.backdropClose && hideDialog()}
    >
      <Component
        DialogTitle={DialogTitle}
        DialogBody={DialogBody}
        DialogActions={DialogActions}
        {...initialData}
      />
    </Dialog>
  );

  return dialogProps.V2 ? V2 : V1;
};

export default BasicDialog;

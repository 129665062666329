// @ts-nocheck

import React, { useRef, useState } from 'react';
import {
  UIWidgetBody,
  UIWidgetContainer,
  UIWidgetHeader,
  UIWidgetLeftActions,
  UIWidgetRightActions,
  UIWidgetTitle,
} from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';
import UIButton from 'v2/ui-kit/UIButton/UIButton';
import useMobile from 'v2/hooks/useMobile';
import UIPhotoCard from 'v2/ui-kit/UIPhotoCard/UIPhotoCard';
import ObjectDataToggle from 'v2/widgetActions/ObjectDataToggle';
import ZoomSlider from 'v2/widgetActions/ZoomSlider';
import ZoomReset from 'v2/widgetActions/ZoomReset';
import DataNotFound from '../placeHolders/WidgetDataNotFound';
import ErrorRequest from '../placeHolders/ErrorRequest';
import Loader from '../placeHolders/Loader';
import LeftPanelOpen from '../widgetActions/LeftPanelOpen';

const ResultsFooter = ({ fetchNextPage, isFetching }) => (
  <div className="w-full p-2 pt-0 flex items-center justify-center">
    <UIButton
      disabled={isFetching}
      variant="contained"
      onClick={fetchNextPage}
    >
      Fetch More
    </UIButton>
  </div>
);

const AdvancedSearchResult = ({
  items,
  error,
  isError,
  isFetching,
  isHasNextPage,
  fetchNextPage,
  isPanelOpen,
  onClickOpenPanel,
}) => {
  const { isMobile } = useMobile();
  const [zoom, setZoom] = useState(100);
  const [showMeta, setShowMeta] = useState(true);
  const scrollContainerRef = useRef();

  const onScroll = () => {
    if (scrollContainerRef.current && items) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        items.length > 0 && isHasNextPage && !isFetching && !isError && fetchNextPage();
      }
    }
  };

  return (
    <UIWidgetContainer className="h-full w-full">
      <UIWidgetHeader>
        <UIWidgetLeftActions>
          {!isPanelOpen && !isMobile && <LeftPanelOpen onClick={onClickOpenPanel} tooltip="SHOW APPLIED FILTERS" />}
        </UIWidgetLeftActions>
        <UIWidgetTitle>Search results</UIWidgetTitle>
        <UIWidgetRightActions>
          <ZoomReset
            onChange={() => setZoom(100)}
            visible={zoom !== 100}
          />
          <ZoomSlider
            minScale={25}
            maxScale={100}
            stepScale={5}
            initialScale={100}
            value={zoom}
            onZoomOut={() => setZoom((prev) => Math.max(prev - 5, 0))}
            onZoomIn={() => setZoom((prev) => Math.min(prev + 5, 100))}
            onUpdate={(value) => setZoom(value)}
            onChange={(value) => setZoom(value)}
            tooltipFormat={() => `Scale ${Math.floor(zoom)}%`}
            className="w-32"
          />
          <ObjectDataToggle
            onClick={() => setShowMeta((val) => !val)}
            isDisabled={!showMeta}
          />
        </UIWidgetRightActions>
      </UIWidgetHeader>
      <UIWidgetBody onScroll={onScroll} ref={scrollContainerRef}>
        {
          items?.length > 0 && (
            <div
              className="p-2"
              style={{
                zoom: (zoom / 100).toFixed(2)
              }}
            >
              <div className="w-full overflow-auto UICardsContainer">
                {
                  items.map((object) => (
                    <UIPhotoCard
                      key={object.key}
                      object={object}
                      showMeta={showMeta}
                    />
                  ))
                }
              </div>
            </div>
          )
        }
        { isFetching && <Loader title="Searching..." /> }
        { !isFetching && isError && <ErrorRequest error={error} onRetry={fetchNextPage} /> }
        { !isFetching && !isError && items?.length === 0 && <DataNotFound /> }
        {
          !isFetching
          && !isError
          && isHasNextPage
          && items?.length > 0 && <ResultsFooter isFetching={isFetching} fetchNextPage={fetchNextPage} />
         }
      </UIWidgetBody>
    </UIWidgetContainer>
  );
};

export default AdvancedSearchResult;

// @ts-nocheck

import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Grid, IconButton } from '@material-ui/core';
import { mergeRules } from 'v2/utils/functions';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ClearIcon from '@material-ui/icons/Clear';
import { MONTH_DAY_YEAR_TIME_A_FORMAT } from '../../../../utils/timeFormats';

const SelectDate = (props) => {
  const [tempValue, setTempValue] = useState(props.value || null);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={mergeRules(props.rules)}
      defaultValue={props.defaultValue}
      render={(controller) => !props.hidden && (
        <Grid item xs>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              fullWidth
              inputVariant="outlined"
              variant="inline"
              size="small"
              disableFuture
              hideTabs
              minutesStep={5}
              label={props.label}
              format={MONTH_DAY_YEAR_TIME_A_FORMAT}
              value={controller.field.value}
              onChange={(value) => {
                setTempValue(value.valueOf());
              }}
              onClose={() => {
                props.onChange ? controller.field.onChange(props.onChange(tempValue, props.methods.getValues())) : controller.field.onChange(tempValue);
              }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                placeholder: props.placeHolder,
                endAdornment: controller.field.value && (
                  <Box mr="5px">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        controller.field.onChange(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
    />
  )
}

export default SelectDate;

// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import useNotifications from 'v2/hooks/useNotifications';
import { Box, CircularProgress, Grid } from '@material-ui/core/';
import UIPhotoCard from 'v2/ui-kit/UIPhotoCard/UIPhotoCard';
import { CARD_OVERLAY_FEED } from 'v2/ui-kit/UICardOverlay/UICardOverlay';
import moment from 'moment-timezone';
import { v4 } from 'uuid';
import UITextWidget from 'v2/ui-kit/UITextWidget/UITextWidget';
import { DEFAULT_TEXT } from 'v2/constants/defaultText';
import api from '../../app/api';

const WidgetFeed = ({
  socketURL,
  httpURL,
  isRealtime,
  setLastUpdateTime,
}) => {
  const [isFetching, seIsFetching] = useState();
  const [isFetched, setIsFetched] = useState(false);
  const [objects, setObjects] = useState();
  const accumulator = useRef([]);

  useEffect(() => {
    setLastUpdateTime && setLastUpdateTime(moment(new Date().getTime()).format('MM/DD/YYYY HH:mm:ss'));
  }, [objects]);

  useEffect(() => {
    if (httpURL) {
      seIsFetching(true);

      api.get(httpURL)
        .then((response) => {
          accumulator.current = response.data.items.map((item) => ({ ...item, key: v4() }));

          setObjects(accumulator.current);
          setIsFetched(true);
        })
        .catch(() => {
        })
        .finally(() => {
          seIsFetching(false);
        });
    }
  }, [httpURL]);

  useNotifications(isRealtime && isFetched, socketURL, accumulator, setObjects);
  return (
    <Box width={1} height={1} overflow="hidden">
      <Box p={1} width={1} height={1} overflow="auto">
        {
          isFetching ? (
            <Box
              p={2}
              height={1}
              width={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            objects?.length > 0 ? (
              <Grid className="UICardsContainer">
                {objects.map((object) => (
                  <UIPhotoCard
                    key={object.key}
                    object={object}
                    showMeta
                    overlay={CARD_OVERLAY_FEED}
                  />
                ))}
              </Grid>
            ) : (
              <UITextWidget content={DEFAULT_TEXT.NO_DATA} className="UITextWidgetNoData" />
            )
          )
        }
      </Box>
    </Box>
  );
};

export default WidgetFeed;

// @ts-nocheck

import React, { useState } from 'react';
import { FORM_SCHEMES } from 'v2/constants/formSchemes';
import { useDialog } from 'v2/providers/DialogProvider';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FormGenerator, { useFormGenerator } from 'v2/ui-kit/UIForm/FormGenerator';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import moment from 'moment-timezone';
import { useModel } from '../providers/ModelProvider';
import useUserQuery from '../hooks/queries/useUserQuery';

const timeZones = moment.tz.names();

const timeZonesList = timeZones.map((zone) => ({
  name: `${moment.tz(zone).format('Z')} ${zone}`,
  value: zone,
  offset: moment.tz(zone)._offset
}));

timeZonesList.sort((a, b) => (a.offset >= b.offset ? 1 : -1));

const EditTimeZone = (props) => {
  const form = useFormGenerator();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { scenarios } = useModel();
  const { userUpdate } = useUserQuery();

  const {
    DialogTitle, DialogBody, DialogActions, user
  } = props;

  const { formState: { isValid } } = form;

  const [formScheme] = useState(FORM_SCHEMES.EDIT_TIMEZONE(user.timezone, timeZonesList));

  const Content = (
    <Box
      width={1}
      height={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      position="relative"
    >
      <FormGenerator methods={form} scheme={formScheme} />
    </Box>
  );

  const Actions = (
    <Box width={1} display="flex" justifyContent="space-between">
      <Button
        color="primary"
        variant="outlined"
        onClick={hideDialog}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={!isValid}
        onClick={() => {
          showBackdrop('Updating user');
          userUpdate.mutateAsync([user.id, form.getValues()]).then(() => {
            hideDialog();
            enqueueSnackbar('User timezone has been changed', { variant: 'success' });
            scenarios.auth.init();
          }).finally(hideBackdrop);
        }}
      >
        Change
      </Button>
    </Box>
  );

  return (
    <>
      <DialogTitle title="Change Timezone" hasCloseIcon />
      <DialogBody className="UIPaddingLess">
        {Content}
      </DialogBody>
      <DialogActions>
        {Actions}
      </DialogActions>
    </>
  );
};

export default EditTimeZone;

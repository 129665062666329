// @ts-nocheck

import ErrorOutlineIcon from '@material-ui/icons/ErrorTwoTone';
import React from 'react';
import { UIWidgetPlaceholder, UIWidgetPlaceholderContent } from 'v2/ui-kit/UIBasicWidget/UIBasicWidget';

const Warning = (props) => (
  <UIWidgetPlaceholder>
    <UIWidgetPlaceholderContent
      icon={ErrorOutlineIcon}
      title={props.title}
      subTitle={props.subtitle}
      {...props}
    />
  </UIWidgetPlaceholder>
);

export default Warning;

// @ts-nocheck

import api from 'app/api';

const addNewTags = async (tags) => {
  const names = tags.filter((item) => !item.hasOwnProperty('id')).map((item) => item.name);

  if (names.length > 0) {
    return api.post('/object-manager/v1/tags/', { names })
      .then((response) => [...tags.filter((item) => item.id), ...response.data])
  }

  return tags;
}

const objectHandlers = () => ({
  patch: async (id, data) => {
    const { tags, metadata } = data;

    return api.patch(
      `/object-manager/v2/objects/${id}`,
      {
        tags: (await addNewTags(tags)).map((item) => item.name),
        metadata
      }
    ).then((response) => response.data)
  }
});

export default objectHandlers;

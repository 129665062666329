// @ts-nocheck

import tokensHandlers from './handlers/tokensHandlers';
import tokensCreate from './reducers/create';
import tokensDelete from './reducers/delete';
import tokensFetch from './reducers/fetch';

export default {
  handlers: tokensHandlers,
  slices: [tokensCreate, tokensDelete, tokensFetch]
}

// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import BasicTable from 'v2/components/basic/BasicTable';
import { TABLE, MONTH_DAY_YEAR_FORMAT } from 'v2/utils/constants';
import { DIALOGS, useDialog } from 'v2/providers/DialogProvider';
import { handlers } from 'v2/redux/store';
import { useSnackbar } from 'notistack';
import Delete from '@material-ui/icons/Delete';
import useTokenQuery from 'v2/hooks/queries/useTokenQuery';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import BasicWidget from './basic/BasicWidget';

const headCells = () => [
  {
    id: 'name',
    label: 'Token Name',
  },
  {
    id: 'created_at',
    label: 'Generated at',
    align: 'left',
    formatter: ({ created_at }) => moment(created_at).format(MONTH_DAY_YEAR_FORMAT)
  },
  {
    id: 'action',
    align: 'center',
  }
];

const TokensNew = () => {
  const dialog = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { tokens, deleteToken, tokensQuery } = useTokenQuery();

  useEffect(() => {
    if (tokensQuery.isFetching) {
      showBackdrop('Loading licenses');
    }
    if (tokensQuery.isFetched) {
      hideBackdrop();
    }
  }, [tokens]);

  const toolbarActions = [{
    title: 'Add token',
    type: TABLE.TOOLBAR.BUTTONS.TYPE,
    visible: true,
    onClick: () => {
      dialog.showDialog(DIALOGS.ADD_TOKEN);
    }
  }];

  const onDeleteToken = ({ id }) => (hideDialog) => {
    showBackdrop('Deleting token');
    deleteToken.mutateAsync(id).then(() => {
      hideDialog();
      enqueueSnackbar('The token has been deleted', { variant: 'success' });
    }).finally(hideBackdrop)
  }

  const rowActions = (row) => (
    <IconButton onClick={() => dialog.showDialog(DIALOGS.CONFIRMATION, {
      title: titleDialogue(),
      text: textDialogue(),
      onConfirmation: onDeleteToken(row),
      confirmText: 'Delete',
    })}
    >
      <Delete />
    </IconButton>
  )

  const titleDialogue = () => (
    <Box p={1}>
      <Typography variant="subtitle1">
        Do you really want to delete this token?
      </Typography>
    </Box>
  )

  const textDialogue = () => (
    <Box p={1}>
      <Typography variant="subtitle1">
        If you delete a Token used to connect Metapix Plugin with the Cloud, the solution will cease to detect objects, resulting in a significant disruption to the overall system.
      </Typography>
    </Box>
  );

  const EmptyTableComponent = () => [
    <Typography variant="subtitle1" align="center">
      Currently, you haven’t added any Token. You can generate one right on this page.
    </Typography>,
    <Typography variant="subtitle2" align="center">
      Gateway Tokens are used to authorize the Metapix Plugin with the Metapix Cloud.
    </Typography>
  ];

  const FilledTable = () => (
    <BasicTable
      columns={headCells(tokens.items)}
      rows={tokens.items}
      rowActions={rowActions}
      storageKey="tokensPerPage"
    />
  )

  if (tokensQuery.isSuccess) {
    return (
      <Box p={2} width={1}>
        <BasicWidget title="Gateway tokens" rightActions={toolbarActions}>
          {tokens.items.length > 0 ? <FilledTable /> : <EmptyTableComponent />}
        </BasicWidget>
      </Box>
    )
  }

  return null;
}

export default TokensNew;

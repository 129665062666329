// @ts-nocheck

import axios from 'axios';
import DataReducer from '../DataReducer';

const help = DataReducer({
  name: 'help',
  dataProvider: async () => {
    const result = await axios.get('/content/help.json');
    return result.data;
  },
});

export default help;

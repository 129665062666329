// @ts-nocheck

import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import userPatch from '../reducers/userPatch';

import requestsReducer from '../reducers/requests';

import { FETCH_STATE } from '../../utils/constants';

// export const stateNames = Object.assign({}, ...dataFetchers.map((item) => {
//   const meta = item.rejected();
//
//   return {
//     [meta.type]: item
//   }
// }));

const requestMiddleware = (slices) => {
  const middleware = createListenerMiddleware();

  middleware.startListening({
    matcher: isAnyOf(
      ...slices.map((slice) => (slice?.dataFetch ? [
        slice.dataFetch.fulfilled,
        slice.dataFetch.pending,
        slice.dataFetch.rejected,
      ] : [])).flat(),
    ),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState();

      const pending = { ...state.requests.data.pending };
      const errors = { ...state.requests.data.errors };

      switch (action.meta.requestStatus) {
        case (FETCH_STATE.PENDING):
          pending[action.meta.requestId] = action;
          listenerApi.dispatch(requestsReducer.actions.setRequests({ ...state.requests.data, pending }));
          break;
        case (FETCH_STATE.FULFILLED):
          delete pending[action.meta.requestId];
          delete errors[action.meta.requestId];

          // TODO DEBUG ERROR BACKDROP
          if (action.type === userPatch.dataFetch.fulfilled().type) {
            errors[action.meta.requestId] = action;
          }

          listenerApi.dispatch(requestsReducer.actions.setRequests({ ...state.requests.data, pending, errors }));
          break;
        case (FETCH_STATE.REJECTED):
          delete pending[action.meta.requestId];
          errors[action.meta.requestId] = action;
          listenerApi.dispatch(requestsReducer.actions.setRequests({ ...state.requests.data, pending, errors }));
          break;
      }
    },
  });

  return middleware;
}

export default requestMiddleware;

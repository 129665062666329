// @ts-nocheck

import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from 'serviceWorker';
import * as Sentry from '@sentry/react';
import App from 'App';
import { SENTRY_CONFIG } from 'v2/utils/brands';
import { MODE_PRODUCTION } from 'v2/utils/constants';

import 'v2/utils/localization';
import './main.scss';

if (SENTRY_CONFIG && MODE_PRODUCTION) {
  Sentry.init({
    integrations: [
      new Sentry.BrowserTracing(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: true,
      })
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0,
    sampleRate: 0.5,
    ...SENTRY_CONFIG,
  });
}

console.log('Version:', `${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD} ${process.env.REACT_APP_BRANCH} ${process.env.REACT_APP_BRANCH_HASH} ${process.env.NODE_ENV}`);

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

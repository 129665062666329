// @ts-nocheck

import React from 'react';
import SharedWidgets from 'pages/shared-widgets/SharedWidgets';
import UserSettings from 'v2/components/UserSettings';
import Companies from 'v2/components/Companies';
import Licenses from 'v2/components/Licenses';
import CompanyUsers from 'v2/components/CompanyUsers';
import Tokens from 'v2/components/Tokens';
import Settings from 'v2/pages/Settings';
import AppearanceDetails from 'components/AppearanceDetails';
import SharedBoard from 'v2/components/SharedBoard';
import ClusterNotifications from 'v2/components/ClusterNotifications';
import { AssignmentSharp } from '@material-ui/icons';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import HelpIcon from '@material-ui/icons/Help';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import Notifications from 'v2/pages/Notifications';
import Help from 'v2/pages/Help';
import AdvancedSearch from 'v2/pages/AdvancedSearch/AdvancedSearchPage';
import UIStoryBookIcon from 'v2/icons/UIStoryBookIcon';
import LogIn from 'v2/pages/LogIn';
import WatchListsPage from 'v2/pages/WatchListsPage/WatchListsPage';
import WatchListsPredicatesPage from 'v2/pages/WatchListsPredicatesPage/WatchListsPredicatesPage';
import NoCompany from '../../pages/auth/NoCompany';
import NoCompanies from '../../pages/auth/NoCompanies';

export const ROUTES = {
  NO_COMPANIES: {
    path: '/no-companies',
    exact: true,
    component: NoCompanies,
  },
  NO_COMPANY: {
    path: '/no-company',
    exact: true,
    component: NoCompany,
  },
  SHARED_LAYOUT: {
    path: '/shared-layout/:entityID',
    exact: true,
    component: SharedBoard,
  },
  SHARED_LAYOUT_WITH_COMPANY: {
    path: '/shared-layout/:companyID/:entityID',
    url: (companyID, entityID) => `/shared-layout/${companyID}/${entityID}`,
    exact: true,
    component: SharedBoard,
  },
  SHARED_WIDGET: {
    path: '/shared-widget/:entityID',
    exact: true,
    component: SharedWidgets,
  },
  SHARED_WIDGET_WITH_COMPANY: {
    path: '/shared-widget/:companyID/:entityID',
    url: (companyID, entityID) => `/shared-widget/${companyID}/${entityID}`,
    exact: true,
    component: SharedWidgets,
  },
  LOGIN: {
    path: '/log-in',
    component: LogIn,
  },
  OBJECTS_SEARCH: {
    path: '/appearances',
    exact: true,
    component: AdvancedSearch,
  },
  OBJECT_DETAILS_WITH_COMPANY: {
    path: '/appearance/:companyID/:id',
    url: (companyID, id) => `/appearance/${companyID}/${id}`,
    exact: true,
    component: AppearanceDetails,
  },
  OBJECT_DETAILS: {
    path: '/appearance/:id',
    url: (id) => `/appearance/${id}`,
    exact: true,
    component: AppearanceDetails,
  },
  SETTINGS: {
    path: '/settings',
    component: Settings,
    title: 'Settings',
    Icon: SettingsIcon,
    ROUTES: {
      PROFILE: {
        title: 'User settings',
        path: '/settings',
        Icon: PersonOutlinedIcon,
        component: UserSettings,
        exact: true,
      },
      LICENSES: {
        title: 'Licenses',
        path: '/settings/licenses',
        Icon: VerifiedUserOutlinedIcon,
        component: Licenses,
      },
      COMPANIES: {
        title: 'Companies and Users',
        path: '/settings/companies',
        Icon: BusinessCenterOutlinedIcon,
        component: Companies,
      },
      COMPANY_USERS: {
        title: 'Company users',
        path: '/settings/companies/:id',
        url: (id) => `/settings/companies/${id}`,
        Icon: GroupOutlinedIcon,
        component: CompanyUsers,
        hideLink: true,
      },
      TOKENS: {
        title: 'Gateway Tokens',
        path: '/settings/tokens',
        Icon: ConfirmationNumberOutlinedIcon,
        component: Tokens,
      }
    },
  },
  NOTIFICATIONS: {
    title: 'Watch Lists',
    path: '/notifications/watch-lists',
    Icon: AssignmentSharp,
    component: Notifications,
    ROUTES: {
      // CLUSTER: {
      //   title: 'Clusters with Active Notifications',
      //   path: '/notifications',
      //   Icon: NotificationsIcon,
      //   component: ClusterNotifications,
      //   exact: true,
      // },
      WATCH_LISTS: {
        title: 'Watch Lists',
        path: '/notifications/watch-lists',
        Icon: NotificationsIcon,
        component: WatchListsPage,
      },
      WATCH_LISTS_DETAILS: {
        title: 'Watch Lists',
        path: '/notifications/watch-lists/:id',
        url: (id) => `/notifications/watch-lists/${id}`,
        Icon: NotificationsIcon,
        component: WatchListsPredicatesPage,
        hideLink: true,
      },
      // Will be hidden until release
      // TAGS: {
      //   title: 'Object Tags',
      //   path: '/notifications/objectTags',
      //   Icon: BookmarkIcon,
      //   component: TagsList,
      // },
      // TAG_DETAIL: {
      //   title: 'Tag Detail',
      //   path: '/notifications/objectTags/:id',
      //   url: (id) => `/notifications/objectTags/${id}`,
      //   Icon: BookmarkIcon,
      //   component: TagDetail,
      //   hideLink: true,
      // },
    },
  },
  HELP: {
    title: 'Help',
    path: '/help',
    Icon: HelpIcon,
    component: Help,
    ROUTES: (data) => ({ ...(data || []).map((item) => ({ ...item, path: `/help${item.url}` })) })
  },
  STORYBOOK: {
    title: 'Storybook',
    path: '/storybook/index.html',
    Icon: () => <div className="w-6 h-6"><UIStoryBookIcon /></div>,
  }
}

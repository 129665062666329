// @ts-nocheck

export const MODE_PRODUCTION = process.env.NODE_ENV === 'production';

export const MODE_DEVELOPMENT = !MODE_PRODUCTION;

export const TOKEN_REFRESH_INTERVAL_TIMER = Number.parseInt(process.env.REACT_APP_TOKEN_REFRESH_INTERVAL_TIMER || '1000', 10);

export const FEED_LENGTH = Number.parseInt(process.env.REACT_APP_FEED_LENGTH || '32', 10);

export const TIME_5MIN = 1000 * 60 * 5;

export const MONTH_DAY_YEAR_FORMAT = 'MM/DD/YYYY';

export const ACCEPTED_FILES = [
  'image/png',
  'image/jpeg',
];

export const BUTTON_TYPES = {
  ICON: 'icon',
  ICON_END: 'iconEnd',
  ICON_START: 'iconStart',
  TEXT: 'text',
};

export const TABLE = {
  TOOLBAR: {
    BUTTONS: {
      TYPE: BUTTON_TYPES.TEXT,
    },
  },
};

export const FETCH_STATE = {
  IDLE: 'idle',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export const WIDGET_STATE = {
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const BACKDROP_STATE = {
  HIDDEN: 'HIDDEN',
  LOADING: 'LOADING',
};

export const WIDGET_TYPES = {
  FEED: 'feed',
  BAR: 'hist_chart',
  LINE: 'line_chart',
  PIE: 'pie_chart',
  NUMERIC: 'numeric',
};

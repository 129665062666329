// @ts-nocheck

import React from 'react';
import WidgetWrapper from 'v2/components/WidgetWrapper';
import { useGrid } from 'v2/providers/GridProvider';
import AddWidget from '../../components/Dashboard/AddWidget';
import Responsive from '../vendors/reactGridLayout/ResponsiveReactGridLayout';
import WidthProvider from '../vendors/reactGridLayout/components/WidthProvider';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './grid.css';

const containerRestrictions = {
  hist_chart: { minH: 2, minW: 2 },
  line_chart: { minH: 2, minW: 2 },
  pie_chart: { minH: 2, minW: 2 },
  value: {},
  face_feed: {},
  vehicle_feed: {},
  person_feed: {},
  last_objects: {},
  nxstats: {},
};

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const WidgetsGrid = ({
  editable,
  layout,
  widgets,
  updateLayout,
  addWidget,
  removeWidget,
  scenarios,
}) => {
  const convertToInternal = (gridItems) => gridItems
    .map(({
      i, w, h, x, y
    }) => ({
      w, h, x, y, id: i
    }));

  const findWidget = (id) => widgets.find((item) => item.id === id);
  const { isDraggable, isResizable } = useGrid();

  const layoutChange = (grid) => {
    const convertedGrid = convertToInternal(grid).filter((item) => !item.id.includes('00000000-'));
    const newGrid = JSON.stringify(convertedGrid);
    const oldGrid = JSON.stringify(layout.grid);

    if (newGrid !== oldGrid) {
      updateLayout(convertedGrid);
    }
  }

  const overrideWidgetContainer = (item, widget) => {
    const restrictions = containerRestrictions[widget.type];
    return {
      ...item,
      ...restrictions,
      h: Math.max(item.h, restrictions.minH || 1),
      w: Math.max(item.w, restrictions.minW || 1),
    }
  }

  return (
    <ResponsiveReactGridLayout
      className="layout"
      resizeHandles={['se']}
      rowHeight={150}
      margin={[8, 8]}
      cols={{
        lg: 8, md: 8, sm: 8, xs: 8, xxs: 8
      }}
      onLayoutChange={layoutChange}
      isDraggable={isDraggable}
      isResizable={isResizable}
      draggableHandle=".UIDraggable"
      draggableCancel=".UIUnDraggable"
    >
      {
        layout?.grid?.length > 0 ? (
          layout.grid.map((item) => {
            const widget = findWidget(item.id);
            return widget && (
              <div key={item.id} data-grid={overrideWidgetContainer(item, widget)}>
                <WidgetWrapper
                  editable={editable}
                  widget={widget}
                  scenarios={scenarios}
                  updateLayout={updateLayout}
                  removeWidget={() => removeWidget(widget)}
                />
              </div>
            );
          })
        ) : null
      }
      {
        editable && (
          <div
            className="UIUnDraggable"
            key={`00000000-addNewWidget-${Date.now()}`}
            data-grid={{
              w: 8,
              h: 1,
              x: 0,
              y: Infinity,
              isDraggable: false,
              isResizable: false,
            }}
          >
            <AddWidget
              submitHandler={addWidget}
            />
          </div>
        )
      }
    </ResponsiveReactGridLayout>
  );
}

export default WidgetsGrid;

// @ts-nocheck

import React from 'react';
import {
  Typography, Dialog, Button, Box, Paper, Divider
} from '@material-ui/core';

import api from 'app/api';
import { useAppStore } from 'AppStore';
import { handlers } from 'v2/redux/store';

const SelectAvatarDialog = ({ isOpen, handleModel }: SelectAvatarDialogProps) => {
  const [VALUES, HANDLERS] = useAppStore();

  const handleCloseClick = () => {
    handleModel();
  };

  const handleRemoveAvatar = () => {
    api.delete('/auth-manager/user-photo/')
      .then(() => {
        HANDLERS.setIsImageChange(!VALUES.isImageChange);
        handlers.user.fetch()
      }).finally(() => {
        handleModel();
      })
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleModel}
      maxWidth="xs"
      fullWidth
    >
      <Paper variant="outlined">
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Typography align="left">
            Delete User Avatar
          </Typography>
        </Box>
        <Divider />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Typography align="left">
            Do you really want to delete this avatar?
          </Typography>
        </Box>
        <Divider />
        <Box p={2} width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Button
            aria-label="cancel"
            variant="outlined"
            color="primary"
            onClick={handleCloseClick}
          >
            Cancel
          </Button>
          <Button
            aria-label="Delete"
            variant="contained"
            color="primary"
            onClick={handleRemoveAvatar}
          >
            Delete
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default SelectAvatarDialog;

// @ts-nocheck

import React from 'react';
import {
  CircleStencil, Cropper, ImageRestriction, RectangleStencil
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { Box, IconButton } from '@material-ui/core';
import { Flip, RotateLeft, RotateRight } from '@material-ui/icons';

// TODO REMOVE MUI

const UIBasicCropper = React.forwardRef(({
  showControls, src, restrictionType, circle, restrictions
}, ref) => {
  const choseForm = () => (circle ? CircleStencil : RectangleStencil);

  const choseRestriction = () => {
    switch (restrictionType) {
      case 'fillArea':
        return ImageRestriction.fillArea;
      case 'stencil':
        return ImageRestriction.stencil;
      case 'none':
        return ImageRestriction.none;
      case 'fitArea':
      default:
        return ImageRestriction.fitArea;
    }
  }
  const flip = (horizontal: boolean, vertical: boolean) => {
    if (ref.current) { ref.current.flipImage(horizontal, vertical); }
  };
  const rotate = (angle: number) => {
    if (ref.current) { ref.current.rotateImage(angle); }
  };

  return (
    <>
      <Cropper
        sizeRestrictions={restrictions}
        imageRestriction={choseRestriction()}
        retrieveSizeRestriction={{ minWidth: 100, minHeight: 100 }}
        ref={ref}
        src={src}
        stencilComponent={choseForm()}
        stencilProps={{ aspectRatio: 1 / 1, }}
        className="cropper"

      />
      {showControls && (
      <Box className="UICropperControls">
        <IconButton
          onClick={() => rotate(90)}
        >
          <RotateRight fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => flip(true)}
        >
          <Flip fontSize="small" />
        </IconButton>
        <IconButton
          className="UIVerticalFlip"
          onClick={() => flip(false, true)}
        >
          <Flip fontSize="small" />
        </IconButton>

        <IconButton
          onClick={() => rotate(-90)}
        >
          <RotateLeft fontSize="small" />
        </IconButton>
      </Box>
      )}

    </>
  )
})

export default UIBasicCropper;

// @ts-nocheck

import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const SearchField = (props) => {
  const [value, setValue] = useState('');

  const onChange = (e) => {
    setValue(e.target.value);
    props.onChange && props.onChange(e.target.value);
  }

  return (
    <TextField
      size="small"
      label="Search"
      variant="outlined"
      name="Search"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
      value={value}
      onChange={onChange}
    />
  )
}

export default SearchField;

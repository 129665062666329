// @ts-nocheck
import React from 'react';
import { useSnackbar } from 'notistack';
import CopyAction from 'v2/widgetActions/CopyAction';

import './UICodeBlock.scss';

const UICodeBlock = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    enqueueSnackbar('Copied!', { variant: 'success', autoHideDuration: 1000 });
  };

  return (
    <div className="UICodeBlock">
      <code>
        {children}
        <CopyAction onClick={handleCopy} />
      </code>
    </div>
  )
};

export default UICodeBlock;

// @ts-nocheck
import React, { useState, useEffect } from 'react'
import {
  Grid, Paper, makeStyles, createStyles, Button,
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import {
  useHistory, useLocation, NavLink
} from 'react-router-dom';
import api from 'app/api';
import envelope from 'Re-Send confirmation code.png'
import { handlers } from 'v2/redux/store';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    height: '100vh',
    display: 'flex',
    background: 'url(../img/login-bg.png) no-repeat center center fixed',
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '460px',
    // background: vars.primaryBackground,
    paddingTop: '20px',
    paddingBottom: '2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 9,
    alignItems: 'center',
  },
  img: {
    width: '170px',
  },
  header: {
    // color: vars.textGray,
    marginTop: 10,
  },
  info: {
    marginTop: '1rem',
    display: 'flex',
    textAlign: 'center',
  }
}),);

export default function ConfirmAccount() {
  const location = useLocation()
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [checkLogin, setCheckLogin] = useState({
    login: '',
  })

  useEffect(() => {
    handlers.user.clear()
    if (location?.state?.login) {
      setCheckLogin((prev) => ({ ...prev, login: location.state.login }))
    } else {
      history.push('/log-in')
    }
  }, [])

  const SendLink = () => {
    api.post('/auth-manager/auth/resend-confirmation-email/', checkLogin)
      .then(() => enqueueSnackbar('A confirmation link has been sent to your email', { variant: 'success' }))
  }

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.form}>
        <div className={classes.header}>
          <div className="UILogo" />
        </div>
        <div>
          <img className={classes.img} src={envelope} alt="envelope" />
        </div>
        <Button variant="contained" color="primary" onClick={SendLink}>
          Send code one more time
        </Button>
        <span className={classes.info}>To complete the setup and log in, follow the confirmation link in the email</span>
        <div>
          <span>Have you already confirmed your email?</span>
          {' '}
          <NavLink className="UINavLink" to="/log-in">
            Go to login page
          </NavLink>
        </div>
      </Paper>
    </Grid>
  )
}

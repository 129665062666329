// @ts-nocheck

import DataReducer from '../../DataReducer';
import api from '../../../../app/api';
import { FETCH_STATE } from '../../../utils/constants';

const tagsPatch = DataReducer({
  name: 'tagsPatch',
  dataProvider: async ([tagID, params]) => {
    const result = await api.patch(`/object-manager/v1/tags/${tagID}`, params)
    return result.data.items;
  },
  messages: {
    [FETCH_STATE.PENDING]: 'Patching tags',
    [FETCH_STATE.REJECTED]: 'Error patch tags',
  }
});
export default tagsPatch;

// @ts-nocheck

import DataReducer from '../DataReducer';
import api from '../../../app/api';

const companiesTypes = DataReducer({
  name: 'companiesTypes',
  dataProvider: async () => {
    const result = await api.get('/auth-manager/v1/company-types');
    return result.data.map((item) => ({
      value: item.title,
      name: item.full_name,
      short: item.short_name,
      rank: item.rank
    }))
  },
});

export default companiesTypes;

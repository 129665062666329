export const FIELD_TYPES = {
  TEXT_INPUT: 'textInput',
  TEXT_AREA: 'textArea',
  TEXT_PASSWORD: 'textPassword',
  TEXT_COMBO: 'textCombo',
  SELECT_SINGLE: 'selectSingle',
  SELECT_OBJECT: 'selectObject',
  SELECT_MULTIPLE: 'selectMultiple',
  SELECT_DATE: 'selectDate',
  SELECT_CAMERAS: 'selectCameras',
  SLIDER_RANGE: 'sliderRange',
  AUTO_COMPLETE: 'autoComplete',
  TAGS_AUTO_COMPLETE: 'tagsAutoComplete',
};

// @ts-nocheck

import axios from 'axios';
import DataReducer from '../DataReducer';

const googleIcons = DataReducer({
  name: 'googleIcons',
  dataProvider: async () => {
    const result = await axios.get('/icons.json');
    return result.data.icons;
  },
});

export default googleIcons;

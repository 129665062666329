// @ts-nocheck

import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useAppStore } from 'AppStore';
import { checkCredentials } from 'v2/utils/functions';
import Header from './Header';
import LeftMenu from './LeftMenu';
import useMobile from '../v2/hooks/useMobile';

const Layout = ({
  title, hasBackIcon, onlyRoot, children, hasSearchButton, className,
}) => {
  const [, HANDLERS] = useAppStore();
  const { isMobile } = useMobile();

  useEffect(() => {
    if (checkCredentials()) {
      HANDLERS.setIsAuthenticated(true);
    }
  }, []);

  return onlyRoot
    ? (
      <Grid container>
        <Box width={1}>
          {children}
        </Box>
      </Grid>
    ) : (
      <Box display="flex" flexWrap="nowrap" width={1} height={1} className={className}>
        {!isMobile && <LeftMenu />}
        <Box width={1} height={1} overflow="hidden" display="flex" flexDirection="column">
          <Header
            title={title}
            hasBackIcon={hasBackIcon || false}
            hasSearchButton={hasSearchButton === undefined ? true : hasSearchButton}
          />
          <Box width={1} height={1} display="flex" flexDirection="column" overflow="auto">
            {children}
          </Box>
        </Box>
      </Box>
    );
};

export default Layout;

// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  V2Search: {
    id: 'V2Search',
    name: 'New Advanced Search',
    enabled: true,
  },
};

const experimentalFeaturesReducer = createSlice({
  name: 'experimentalFeatures',
  initialState: {
    data: initialData,
  },
  reducers: {
    switchExperimentalFeature(state, action) {
      if (state.data[action.payload]) {
        state.data[action.payload].enabled = !state.data[action.payload].enabled;
      }
    },
  },
});

export default experimentalFeaturesReducer;

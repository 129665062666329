// @ts-nocheck
import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const Image = ({ theme }) => (
  <svg width="100%" height="100%" viewBox="0 0 245 109" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 62.9082H24.1762V109H0V62.9082Z" fill="#595959" />
    <path d="M64.5417 4.71777H88.7179V109H64.5417V4.71777Z" fill="#595959" />
    <path d="M129.084 45.6084H153.26V109H129.084V45.6084Z" fill="#595959" />
    <path d="M193.626 0H217.802V109H193.626V0Z" fill="#595959" />
    <path d="M27.1982 35.9297H51.3745V109H27.1982V35.9297Z" fill={theme.palette.primary.main} />
    <path d="M91.74 90.4902H115.916V109H91.74V90.4902Z" fill={theme.palette.primary.main} />
    <path d="M156.282 7.0166H180.458V109H156.282V7.0166Z" fill={theme.palette.primary.main} />
    <path d="M220.824 69.9248H245V109H220.824V69.9248Z" fill={theme.palette.primary.main} />
  </svg>
);

export default withTheme(Image);

// @ts-nocheck

export const BRANDS = {
  DW: '4f2550f0-d262-4f01-b5df-f84f3aa4d755',
  NX: 'c55a73a5-9c72-4c7b-92a1-ca0e67d2d2f2',
  SV: 'd4f8110a-2008-4a26-98ea-39018c0fe595',
};

export const THEME_BRAND = process.env.REACT_APP_THEME_BRAND || BRANDS.NX;

const SENTRY = {
  [BRANDS.NX]: {
    dsn: 'https://19b922fdbcb9c9dbc17d78c5de4d1b3c@o4504530234572800.ingest.sentry.io/4505787239038976',
    tracePropagationTargets: ['localhost:3000', /^https:\/\/my.metapix\.ai/],
  },
  [BRANDS.DW]: {
    dsn: 'https://19b922fdbcb9c9dbc17d78c5de4d1b3c@o4504530234572800.ingest.sentry.io/4505787239038976',
    tracePropagationTargets: ['localhost:3000', /^https:\/\/mydw.metapix\.ai/],
  }
}

export const SENTRY_CONFIG = SENTRY[THEME_BRAND];

const CLOUDS = {
  [BRANDS.DW]: {
    url: (redirect) => `https://dwspectrum.digital-watchdog.com/authorize?redirect_url=${redirect}&client_id=api-tool`,
    buttonText: 'DW Cloud',
    scope: 'dw',
  },
  [BRANDS.NX]: {
    url: (redirect) => `https://nxvms.com/authorize?redirect_url=${redirect}&client_id=api-tool`,
    buttonText: 'NX Cloud',
    scope: 'nx',
  },
};

const CLOUD_SELECTOR = () => {
  switch (process.env.REACT_APP_THEME_BRAND) {
    case BRANDS.DW:
      return CLOUDS[BRANDS.DW];
    default:
      return CLOUDS[BRANDS.NX];
  }
};

export const CLOUD = CLOUD_SELECTOR();

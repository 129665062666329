// @ts-nocheck

import tokensFetch from '../reducers/fetch';
import tokensCreate from '../reducers/create';
import tokensDelete from '../reducers/delete';

const tokensHandlers = (store) => ({
  fetch: () => store.dispatch(tokensFetch.dataFetch()),
  createToken: ({ name }) => store.dispatch(tokensCreate.dataFetch(name)).then((response) => {
    store.dispatch(tokensFetch.dataFetch())
    return response.payload;
  }),
  deleteToken: (id) => store.dispatch(tokensDelete.dataFetch(id)).then((response) => {
    store.dispatch(tokensFetch.dataFetch())
    return response.payload;
  }),
});

export default tokensHandlers;

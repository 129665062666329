// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import useCompaniesTypesQuery from 'v2/hooks/queries/useCompaniesTypesQuery';
import useUserCompanyQuery from 'v2/hooks/queries/useUserCompanyQuery';
import useUserQuery from 'v2/hooks/queries/useUserQuery';
import useMobile from 'v2/hooks/useMobile';
import FullVariant from './FullVariant';
import ShortVariant from './ShortVariant';

const UserAvatar = () => {
  const { user } = useUserQuery()
  const { companiesTypes } = useCompaniesTypesQuery()
  const { userCompany } = useUserCompanyQuery();
  const online = useSelector((state) => state.networkStatus.data.status);
  const { isMobile } = useMobile();

  const userCompanyShortname = companiesTypes && userCompany && companiesTypes.find(({ value }) => userCompany.company_type === value);

  return user && userCompanyShortname && (
    isMobile
      ? <ShortVariant online={online} user={user} />
      : <FullVariant online={online} user={user} userCompany={userCompany} userCompanyShortname={userCompanyShortname} />
  )
}

export default UserAvatar

// @ts-nocheck
import React from 'react';

const UIAutorenew600Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
    <path d="M195.435-314.608q-24.522-38.826-34.783-79.848-10.261-41.022-10.261-84.674 0-133.261 96.478-230.87 96.479-97.609 229.174-97.609h43l-73.217-73.782 43.522-43.522L645.696-768 489.348-611.652l-44.522-43.957 72.217-72.782h-39.869q-101.348 0-174.456 73.674-73.109 73.674-73.109 175.587 0 30.695 6.065 57.826 6.066 27.13 15.761 50.695l-56 56.001ZM468.652-32.086 312.304-188.435l156.348-157.478 44.087 44.087-73.782 73.217h43.869q101.348 0 174.456-73.674 73.109-73.674 73.109-176.152 0-30.13-5.848-57.261-5.848-27.13-16.978-50.695l56.565-56.001q23.957 39.391 34.718 80.131 10.761 40.739 10.761 83.826 0 133.826-96.478 231.718-96.479 97.891-228.609 97.891h-45.565l73.782 73.217-44.087 43.522Z" />
  </svg>
);

export default UIAutorenew600Icon;

// @ts-nocheck

import React, {
  useEffect, useState, forwardRef, useImperativeHandle, useCallback
} from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  TextField,
  makeStyles,
  createStyles,
  Switch,
  Typography,
  Button,
  Grid,
  IconButton,
  Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useSnackbar } from 'notistack';
import TextInput from 'components/TextInput';
import api from 'app/api';
import { vars } from 'Theme';
import { fontSize } from '@material-ui/system';
import TagInput from './Tags';
import DeleteModal from './DeleteModal';
import {
  ILocationCamera, ILocation, ItemType, ICameraDetails
} from './types.d';

const useStyles = makeStyles(() => createStyles({
  container: {
    padding: `${vars.spacing}px`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  input: {
    width: '100%',
    '& textarea': {
      height: 120,
    },
  },
  titlePadding: {
    paddingBottom: '20px',
  },
  switchMargin: {
    marginBottom: '50px',
  },
  textFieldMargin: {
    '& div': {
      marginBottom: '1rem',
    },
  },
  propertiesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  propertiesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: `${vars.spacing}px`,
    // backgroundColor: vars.backgroundInput,
    // borderRadius: vars.primaryBorderRadius,
  },
  labelName: {
    fontSize: 14,
    marginBottom: `${vars.spacing}px`,
    textAlign: 'left',
  },
  tagButton: {
    marginLeft: `${vars.spacing}px`,
    borderRadius: vars.primaryBorderRadius,
  },
  tagWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapperInput: {
    marginBottom: `${vars.spacing}px`,
  },
  wrapperButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
  button: {
    // textTransform: 'none',
    borderRadius: vars.primaryBorderRadius,
  },
  deleteButton: {
  },
  selectItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 10,
    marginBottom: 10,
    borderRadius: vars.primaryBorderRadius,
    padding: '6px 12px',
    backgroundColor: vars.primary,
  },
  tagName: {
    fontSize: 13,
    // textTransform: 'none',
  },
  tagClose: {
    color: vars.white,
    marginLeft: 9,
    padding: 0,
    '& svg': {
      fontSize: '1rem',
    },
  },
  formGroup: {
    display: 'block',
  },
  iconProperties: {
    // color: vars.primary,
  },
  labelProperties: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 0,
    '& span': {
      fontSize: '14px',
    },
  },
}));

type LocationState = {
  description?: string;
  name: string;
  parent_location_id?: string;
  tags?:string[],
  id: string,
};

type CameraDetailsProps = {
  model: ILocation | ILocationCamera;
  type: ItemType;
  deleteItem: (id: string, type: ItemType, parentId?: string, deleteFromBackend?: boolean) => void;
  handleSaveDetails: () => void,
};

type LocationFormProps = {
  useState: LocationState;
  setState: (item: any) => void;
  tags: any,
  setTags: (item: any) => void;

};

const LocationForm = (props: LocationFormProps) => {
  const { name, description } = props.useState;
  const classes = useStyles();

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    props.setState({ ...props.useState, [event.target.name]: event.target.value });
  };

  return (
    <FormGroup className={classes.formGroup}>
      <Grid className={classes.wrapperInput}>
        <Typography className={classes.labelName}>Name</Typography>
        <TextInput
          name="name"
          id="location-name-form"
          value={name}
          onChange={handleLocationChange}
          variant="outlined"
          placeholder="Add a name"
          className={classes.input}
          size="small"
        />
      </Grid>
      <Grid className={classes.wrapperInput}>
        <Typography className={classes.labelName}>Description</Typography>
        <TextInput
          name="description"
          id="outlined-multiline-flexible"
          multiline
          rows={4}
          value={description}
          onChange={handleLocationChange}
          variant="outlined"
          placeholder="Add a description"
          className={classes.input}
          size="small"
        />
      </Grid>
      {/* <Grid className={classes.wrapperInput}> */}
      {/*  <TagInput */}
      {/*    value={props.tags} */}
      {/*    onChange={props.setTags} */}
      {/*  /> */}
      {/* </Grid> */}
    </FormGroup>
  );
};

type CameraFormProps = {
  useState: ICameraDetails;
  model: ILocationCamera;
  setState: (item: any) => void;
  type:any,
  tags: any,
  setTags: (item: any) => void;
};

const CameraForm = (props: CameraFormProps) => {
  const classes = useStyles();

  const [showCameraProperties, setShowCameraProperties] = useState(true);
  const [showAnalytics, setShowAnalytics] = useState(true);

  return (
    <FormGroup className={classes.formGroup}>
      <Grid className={classes.wrapperInput}>
        <Typography className={classes.labelName}>Camera name</Typography>
        <TextInput
          id="camera-name-read-only"
          value={props.model.name}
          InputProps={{
            readOnly: true,
          }}
          onChange={() => {}}
          variant="outlined"
          className={classes.input}
          placeholder="Camera name"
          size="small"
        />
      </Grid>
      <Grid className={classes.wrapperInput}>
        <Typography className={classes.labelName}>UUID</Typography>
        <TextInput
          id="camera-uuid-read-only"
          value={props.model.id}
          InputProps={{
            readOnly: true,
          }}
          onChange={() => {}}
          variant="outlined"
          className={classes.input}
          placeholder="53c9de12-48c4-32d5-a694-b89efb05a03f"
          size="small"
        />
      </Grid>
      {/* <Grid className={classes.wrapperInput}> */}
      {/*  <TagInput */}
      {/*    value={props.tags} */}
      {/*    onChange={props.setTags} */}
      {/*  /> */}
      {/* </Grid> */}
      <div className={classes.propertiesHeader}>
        <Typography variant="h6" style={{ fontSize: '16px' }}>Camera Properties</Typography>
        <IconButton onClick={() => setShowCameraProperties(!showCameraProperties)}>
          {showCameraProperties
            ? <KeyboardArrowUpIcon />
            : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <Collapse in={showCameraProperties} timeout="auto" unmountOnExit>
        <>
          <FormControlLabel
            value="start"
            control={(
              <Switch
                // style={{ fontSize: '14px' }}
                color="primary"
                checked={props.useState.active}
                disableRipple
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
          )}
            label="Plugin On"
            labelPlacement="start"
            className={classes.labelProperties}
          />
          <FormControlLabel
            value="start"
            control={(
              <Switch
                color="primary"
                checked={props.useState.archived}
                disableRipple
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
          )}
            label="Camera Disabled"
            labelPlacement="start"
            className={classes.labelProperties}
          />
        </>
      </Collapse>
      <div className={classes.propertiesHeader}>
        <Typography variant="h6" style={{ fontSize: '16px' }}>Analytics</Typography>
        <IconButton onClick={() => setShowAnalytics(!showAnalytics)}>
          {showAnalytics
            ? <KeyboardArrowUpIcon />
            : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <Collapse in={showAnalytics} timeout="auto" unmountOnExit>
        {props.useState.analytics.map((analytic) => (
          <FormControlLabel
            key={analytic.id}
            value="start"
            control={(
              <Switch
                color="primary"
                checked={analytic.enabled}
                disableRipple
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
          )}
            label={analytic.label}
            labelPlacement="start"
            className={classes.labelProperties}
          />
        ))}
      </Collapse>
    </FormGroup>
  );
};

const Details = forwardRef((props: CameraDetailsProps, ref) => {
  const [cameraSettings, setCameraSettings] = useState<ICameraDetails>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  // const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (props.type === 'camera') {
      api.get<ICameraDetails>(`/device-manager/cameras/${props.model.id}`)
        .then((response) => {
        // setTags(response.data.tags.map((item) => item.name));
          setCameraSettings(response.data)
        });
    }
  }, [props.model.id, props.type, props.model.archived]);

  const [locationSettings, setLocationSettings] = useState<LocationState>({
    name: '',
    description: '',
    parent_location_id: '',
    id: '',
    // tags: [],
  });

  useEffect(() => {
    if (props.type === 'location') {
      api.get<ILocation>(`/device-manager/locations/${props.model.id}`)
        .then((response) => {
        // setTags(response.data.tags.map((item) => item.name));
          setLocationSettings(response.data);
        });
    }
  }, [props.model.id, props.type]);

  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    saveData() {
      if (props.type === 'camera') {
        return api.patch(`/device-manager/cameras/${props.model.id}`, {
          // tags: [...tags],
        })
          .then(() => {
            enqueueSnackbar('Camera setting saved.', { variant: 'success' });
            return null;
          });
      } if (props.type === 'location') {
        return api.patch(
          `/device-manager/locations/${props.model.id}`,
          {
            ...locationSettings,
            parent_location_id: locationSettings.parent_location_id === 'None' ? null : locationSettings.parent_location_id,
            // tags: [...tags],
          }
        )
          .then((response) => {
            enqueueSnackbar('Location setting saved.', { variant: 'success' });
            return { id: props.model.id, name: response.data.name };
          });
      }
    },
  }));

  const handleModalClick = useCallback(
    () => setIsDeleteModalOpen(!isDeleteModalOpen),
    [isDeleteModalOpen],
  );

  const handleDelete = useCallback(
    () => props.deleteItem(props.model.id, props.type),
    [props.deleteItem, props.type, props.model.id],
  );

  const handleSave = useCallback(
    () => props.handleSaveDetails(),
    [props.handleSaveDetails],
  );

  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.container}>
      {props.type === 'camera' && cameraSettings
        ? (
          <CameraForm
            useState={cameraSettings}
            setState={setCameraSettings}
            model={props.model as ILocationCamera}
            type={props.type}
            // tags={tags}
            // setTags={setTags}
          />
        )
        : (
          <LocationForm
            useState={locationSettings}
            setState={setLocationSettings}
            // tags={tags}
            // setTags={setTags}
          />
        )}
      {props.type !== 'camera' && (
        <Grid className={classes.wrapperButtons}>
          <Button
            aria-label="save"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save Changes
          </Button>
          <Button
            aria-label="delete"
            variant="outlined"
            color="primary"
            onClick={handleModalClick}
          >
            Delete Location
          </Button>
        </Grid>
      )}
      <DeleteModal
        name={props.model.name}
        isOpen={isDeleteModalOpen}
        type="location"
        handleDelete={handleDelete}
        handleClose={handleModalClick}
      />
    </FormControl>
  );
});

export default Details;
